import React, { useState, FC } from 'react'
import {
  CloudUploadOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import {
  Card,
  Table,
  Input,
  Button,
  Space,
  Result,
  message,
  Spin,
  notification
} from 'antd'

import ResolveExcel from '../../components/ResolveExcel'
import { getData, checkProcessExcelValidate } from './data'
import { generateExport } from '../../components/UploadBatch'
import {
  ProcessBudgetImportData,
  Maybe,
  Scalars,
  useImportProcessBudgetMutation,
  usePreImportProcessBudgetMutation
} from '../../graphql'
import { useBatchUpload } from '../../components/UploadBatch'
import { createHash } from '../../lib/createHash'

export type FileProps = {
  name?: string
  list?: Array<{ [key: string]: any }>
}

const ProcessBudgetImport: FC = () => {
  const options = { concurrency: 2, chunkSize: 100 }
  const [fileName, setFileName] = useState('')
  const [importId, setImportId] = useState('')
  const [tableSource, updateTableSource] = useState([] as any)
  const [excelData, updateExcelData] = useState([] as any)
  const [excelCorrect, setExcelCorrect] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [preChecking, setPreChecking] = useState(false)
  const [importing, setImporting] = useState(false)

  const [, startProcessPreCheck] = usePreImportProcessBudgetMutation()
  const [, startProcessImport] = useImportProcessBudgetMutation()

  // 批量数据检查结束后，拿到所有接口的返回结果，整理数据格式
  const preCheckResultsResolver = (
    preCheckResults: Array<Maybe<Scalars['JSON']>>,
    excelData: ProcessBudgetImportData[]
  ) => {
    const tableData: Array<Maybe<Scalars['JSON']>> = []
    const tableKey = createHash()
    const listWithKey: { [key: string]: ProcessBudgetImportData } = {}

    excelData.forEach((item) => {
      listWithKey[`${item.number}`] = { ...item }
    })

    console.log(preCheckResults)
    preCheckResults?.forEach((result: any, index: number) => {
      if (result?.error?.length) {
        const errorData = result.error.map(
          (error: { [key: string]: any }, i: number) => {
            if (error?.budgetNumber) {
              return {
                ...listWithKey[`${error.budgetNumber}`],
                ...error,
                customizedIndex: `${tableKey}_${index}_${i}`
              }
            }
          }
        )
        tableData.push(...errorData)
      }
    })

    setPreChecking(false)
    setExcelCorrect(!tableData.length)
    updateExcelData(excelData)
    updateTableSource(tableData)
  }

  const preProcessCheckTask = async (
    item: ProcessBudgetImportData[],
    uniqId?: string
  ) => {
    const result: Maybe<Scalars['JSON']> = await startProcessPreCheck({
      data: item,
      importId: uniqId
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      // 取出接口返回的上传任务id
      setImportId(result.data?.result?.importId)
      return result.data?.result
    }
  }

  // 表格数据预检验
  const [budgetPreCheck] = useBatchUpload({
    task: preProcessCheckTask,
    options: options,
    noMessage: true,
    resultResolver: preCheckResultsResolver,
    errorResolver: () => {
      setExcelCorrect(false)
      setPreChecking(false)
    }
  })

  const importTask = async (
    item: ProcessBudgetImportData[],
    uniqId?: string
  ) => {
    const result = await startProcessImport({
      data: item,
      importId: uniqId || ''
    })
    if (!result.data?.result?.success) {
      throw new Error(result.data?.result?.message || '上传失败')
    }
  }

  // 确认导入
  const [importBudget] = useBatchUpload({
    task: importTask,
    options: options,
    noMessage: true,
    resultResolver: () => {
      setImporting(false)
      setExcelCorrect(false)
      setImportId('')
      // notification.destroy()
      notification.success({
        message: '流程预算导入成功',
        description: '流程预算导入成功',
        duration: 0
      })
    },
    errorResolver: () => {
      setImporting(false)
    }
  })

  // 文件读取之后进行数据校验和处理
  const onUploaded = (file: FileProps, uniqImportId: string) => {
    let result: Array<ProcessBudgetImportData> = []
    const list = file.list || []
    const validateRes = checkProcessExcelValidate(list || [])

    if (validateRes.success) {
      result = list.map((row) => {
        return {
          number: String(row.流程编号),
          amount: String(row.预算金额),
          operationRemark: row.操作说明 ? String(row.操作说明).trim() : ''
        }
      })
      setFileName(file.name || '')
      budgetPreCheck(result, uniqImportId)
    } else {
      setPreChecking(false)
      // notification.destroy()
      notification.error({
        message: '流程预算导入错误',
        description: validateRes.message,
        duration: 0
      })
    }
  }

  const onExportClick = () => {
    setExporting(true)
    const dataResult = getData(tableSource, true)
    generateExport(
      dataResult,
      `流程预算导入_${new Date().getTime()}`,
      null,
      (err) => {
        setExporting(false)
        if (err) {
          message.error('导出失败')
        } else {
          message.success('EXCEL下载成功，请查收')
        }
      }
    )
  }

  const onTemplateClick = () => {
    const tagA = document.createElement('a')
    tagA.href =
      'https://yhl-upright-admin-upload.oss-cn-hangzhou.aliyuncs.com/admin/HS%E9%A2%84%E7%AE%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88/HS%E6%B5%81%E7%A8%8B%E9%A2%84%E7%AE%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
    tagA.setAttribute('target', '_blank')
    const id = `template-hansoh-budget` + new Date().getTime()
    tagA.setAttribute('id', id)
    if (!document.getElementById(id)) {
      document.body.appendChild(tagA)
    }
    tagA.click()
    document.body.removeChild(tagA)
  }

  return (
    <Spin
      spinning={preChecking || importing}
      tip={`正在${
        importing ? '上传' : '检查'
      }流程预算信息，请不要关闭此页面...`}
    >
      <Card bordered={false}>
        <Space wrap={true} size={12}>
          <div>预算导入</div>
          <Input
            disabled
            placeholder={fileName || `上传excel，预算文件`}
            style={{
              backgroundColor: '#fff',
              width: '300px',
              marginRight: '50px'
            }}
          />

          <ResolveExcel
            buttonText='文件上传'
            buttonType='primary'
            buttonIcon={<CloudUploadOutlined />}
            onUploaded={(file) => {
              // 定义当前这次上传的唯一 id
              const uniqImportId =
                new Date().getTime().toString() + createHash()
              onUploaded(file, uniqImportId)
            }}
            onStart={() => {
              setPreChecking(true)
            }}
          />

          <Button
            ghost
            type='primary'
            icon={<DownloadOutlined />}
            onClick={onTemplateClick}
          >
            流程预算模版下载
          </Button>
        </Space>
      </Card>
      <Card
        bordered={false}
        extra={
          <Space size={12}>
            <Button
              type='primary'
              disabled={!excelCorrect || importing}
              onClick={() => {
                setImporting(true)
                importBudget(excelData, importId)
              }}
            >
              确认导入
            </Button>
            <Button
              type='primary'
              loading={exporting}
              disabled={!tableSource.length || exporting}
              onClick={onExportClick}
            >
              报表导出
            </Button>
          </Space>
        }
      >
        {excelCorrect ? (
          <Result
            status='success'
            icon={<CheckCircleOutlined />}
            subTitle={
              <div style={{ fontSize: 16 }}>
                已查验上传信息正确，继续
                <Button
                  type='link'
                  style={{ fontSize: 16, padding: 5 }}
                  onClick={() => {
                    setImporting(true)
                    importBudget(excelData, importId)
                  }}
                >
                  确认导入
                </Button>
              </div>
            }
          />
        ) : tableSource?.length ? (
          <Table
            bordered
            dataSource={tableSource}
            columns={[
              {
                align: 'center',
                title: '流程编号',
                dataIndex: 'budgetNumber',
                key: 'budgetNumber',
                render(v: string) {
                  return v || '-'
                }
              },
              {
                align: 'center',
                title: '预算金额',
                dataIndex: 'amount',
                key: 'amount',
                render(v: string) {
                  return v || '-'
                }
              },
              {
                align: 'center',
                title: '操作说明',
                dataIndex: 'operationRemark',
                key: 'operationRemark',
                render(v: string) {
                  return v || '-'
                }
              },
              {
                align: 'center',
                title: '报错信息',
                dataIndex: 'msg',
                key: 'msg',
                render(v: string) {
                  return <span style={{ color: 'red' }}>{v || '-'}</span>
                }
              }
            ]}
            rowKey={'customizedIndex'}
          />
        ) : (
          <Result
            style={{ background: '#f5f6fa' }}
            icon={<InfoCircleOutlined style={{ color: '#cccccc' }} />}
            subTitle={
              <div style={{ fontSize: 16 }}>暂无文件，请先上传文件</div>
            }
          />
        )}
      </Card>
    </Spin>
  )
}

export default ProcessBudgetImport
