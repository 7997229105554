import React, { FC, useState } from 'react'
import { Modal, Input, Form, Row, Col, Tag } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { consumptionOrderRetreatReasons } from '../../constant/user'
import './index.less'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

const ApprovalRetreatModal: FC<{
  onCancel: () => void
  onSubmit: (values: any, callBack?: () => void) => Promise<void>
}> = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const tagOnClick = (tag: string) => {
    const prev = form.getFieldValue('reason') || ''
    form.setFieldsValue({
      reason: prev + tag
    })
  }

  const onFinish = (values: any) => {
    setLoading(true)
    onSubmit(values, () => {
      setLoading(false)
    })
  }

  const onConfirmSubmit = () => {
    form.submit()
  }

  return (
    <Modal
      maskClosable={false}
      title='审核退回'
      className='orderBillApprovalModal'
      visible
      centered
      destroyOnClose
      cancelText='取消'
      onCancel={onCancel}
      okText='确认'
      onOk={onConfirmSubmit}
      confirmLoading={loading}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#ccc' }} />
      }
    >
      <Form form={form} colon={false} {...layout} onFinish={onFinish}>
        <Form.Item
          name='reason'
          label='退回理由'
          rules={[{ required: true, message: '请填写退回理由' }]}
        >
          <Input.TextArea
            maxLength={300}
            placeholder='请填写退回理由'
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Form.Item>
        <Row>
          <Col span={20} offset={4}>
            <div className='tagsContainer'>
              {consumptionOrderRetreatReasons.map((tag: any, index: number) => (
                <Tag
                  key={index}
                  color={tag.type}
                  title={tag.value}
                  className='sales-tag'
                  onClick={() => tagOnClick(tag.value)}
                >
                  {tag.value}
                </Tag>
              ))}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ApprovalRetreatModal
