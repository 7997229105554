import React, { FC } from 'react'
import { Button, Row, Tag, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

interface RepeatTagProps {
  label?: string | undefined
  repeatStatus?: number | undefined
  properties?: string[] | undefined
  contentStyle?: React.CSSProperties | undefined
}

const RepeatTag: FC<RepeatTagProps> = ({
  label,
  repeatStatus,
  properties = [],
  contentStyle
}) => {
  return (
    <Row
      justify='start'
      align='middle'
      style={{ marginTop: 15, marginBottom: 15 }}
      className='repeatTagContainer'
    >
      <span
        style={{
          color: 'rgba(0, 0, 0, 0.85)',
          fontWeight: 'bold'
        }}
      >
        {label}
      </span>
      {repeatStatus === 0 ? (
        <Tooltip
          placement='rightTop'
          title={
            properties.length > 0 ? (
              <div>
                {properties?.map((propertie) => {
                  return (
                    <Button
                      key={propertie}
                      type='link'
                      onClick={() => {
                        window.open(`/meetingApproveDetail/${propertie}`)
                      }}
                    >
                      {propertie}
                    </Button>
                  )
                })}
              </div>
            ) : null
          }
          color='#F2F3F9'
          overlayInnerStyle={{
            color: '#828282',
            padding: 5,
            width: 200
          }}
        >
          <Tag
            style={{
              border: 'none',
              marginLeft: 5,
              background: '#fff',
              color: 'red',
              ...contentStyle
            }}
          >
            重复
            {properties.length > 0 && (
              <InfoCircleOutlined style={{ marginLeft: 5, color: '#7D7F7F' }} />
            )}
          </Tag>
        </Tooltip>
      ) : repeatStatus === 1 ? (
        <Tag
          style={{
            border: 'none',
            marginLeft: 5,
            background: '#fff',
            color: 'green',
            ...contentStyle
          }}
        >
          不重复
        </Tag>
      ) : (
        <Tag
          style={{
            border: 'none',
            marginLeft: 5,
            background: '#fff',
            color: 'rgba(0, 0, 0, 0.25)',
            ...contentStyle
          }}
        >
          -
        </Tag>
      )}
    </Row>
  )
}

export default RepeatTag
