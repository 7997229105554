import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any } | { [key: string]: any }[]
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { [key: string]: any }
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any
}

export type AccountBaseInfo = {
  __typename?: 'AccountBaseInfo'
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  accountCerts?: Maybe<Array<AccountCertInfo>>
  ouId?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  defaultChannel: Scalars['String']
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  custom4?: Maybe<Scalars['String']>
  verifyResult?: Maybe<Scalars['String']>
  verifyImages?: Maybe<Scalars['String']>
  disableReason?: Maybe<Scalars['String']>
}

export type AccountBaseInfoInput = {
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  accountCerts?: Maybe<Array<AccountCertInfoInput>>
  ouId?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  defaultChannel?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  custom4?: Maybe<Scalars['String']>
  verifyResult?: Maybe<Scalars['String']>
  verifyImages?: Maybe<Scalars['String']>
  disableReason?: Maybe<Scalars['String']>
}

export type AccountCertInfo = {
  __typename?: 'AccountCertInfo'
  accountCertId?: Maybe<Scalars['String']>
  type?: Maybe<AccountCertType>
  certNo?: Maybe<Scalars['String']>
  rawCertNo?: Maybe<Scalars['String']>
  certImages?: Maybe<Array<Scalars['String']>>
  custom1?: Maybe<Scalars['String']>
  cnFirstPinyin?: Maybe<Scalars['String']>
  cnLastPinyin?: Maybe<Scalars['String']>
  enFirstName?: Maybe<Scalars['String']>
  enLastName?: Maybe<Scalars['String']>
  enMiddleName?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  qkkBankName?: Maybe<Scalars['String']>
  ssqBankName?: Maybe<Scalars['String']>
}

export type AccountCertInfoInput = {
  accountCertId?: Maybe<Scalars['String']>
  type?: Maybe<AccountCertType>
  certNo?: Maybe<Scalars['String']>
  rawCertNo?: Maybe<Scalars['String']>
  certImages?: Maybe<Array<Scalars['String']>>
  custom1?: Maybe<Scalars['String']>
  cnFirstPinyin?: Maybe<Scalars['String']>
  cnLastPinyin?: Maybe<Scalars['String']>
  enFirstName?: Maybe<Scalars['String']>
  enLastName?: Maybe<Scalars['String']>
  enMiddleName?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  qkkBankName?: Maybe<Scalars['String']>
  ssqBankName?: Maybe<Scalars['String']>
}

export enum AccountCertType {
  Idcard = 'IDCARD',
  Passport = 'PASSPORT',
  TwowayPermit = 'TWOWAY_PERMIT',
  Mtp = 'MTP',
  TaiwanPass = 'TAIWAN_PASS',
  QualificationCert = 'QUALIFICATION_CERT',
  StaffCert = 'STAFF_CERT',
  Face = 'FACE',
  Other = 'OTHER',
  Bankcard = 'BANKCARD',
  ComplexMaterial = 'COMPLEX_MATERIAL'
}

export type AccountGroupInfo = {
  __typename?: 'AccountGroupInfo'
  groupId: Scalars['String']
  name: Scalars['String']
  pid?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  parent?: Maybe<AccountGroupInfo>
  children?: Maybe<Array<AccountGroupInfo>>
  custom4?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  custom3?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  desc?: Maybe<Scalars['String']>
  province?: Maybe<Array<CityInfo>>
}

export type AccountGroupInfoInput = {
  groupId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  pid?: Maybe<Scalars['String']>
  province?: Maybe<Array<Scalars['String']>>
}

export type AccountInfo = {
  __typename?: 'AccountInfo'
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  accountCerts?: Maybe<Array<AccountCertInfo>>
  ouId?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  defaultChannel: Scalars['String']
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  custom4?: Maybe<Scalars['String']>
  verifyResult?: Maybe<Scalars['String']>
  verifyImages?: Maybe<Scalars['String']>
  disableReason?: Maybe<Scalars['String']>
  company?: Maybe<CompanyInfo>
  reportAccount?: Maybe<AccountInfo>
  department?: Maybe<DepartmentInfo>
  group?: Maybe<AccountGroupInfo>
  hospital?: Maybe<HospitalInfo>
  authenticated: Scalars['Boolean']
  contentActionStats: Array<ContentActionStatInfo>
  responsibleGroups?: Maybe<Array<AccountGroupInfo>>
  roles: Array<RoleInfo>
  bankInfo?: Maybe<AccountCertInfo>
  idCardInfo?: Maybe<AccountCertInfo>
  docMicroAppBinded: Scalars['String']
  appMenus?: Maybe<Array<AppMenu>>
  roleAuthorities?: Maybe<Array<RoleAuthority>>
  roleAuthoritiesIncludeQcxs?: Maybe<Scalars['Boolean']>
  /** 作为参会人信息 */
  infoAsMeetingAttender?: Maybe<StandardMeetingBaseAttender>
  /** 是否完善参会人信息 */
  hasCompeleteHRImgDoctorCert?: Maybe<Scalars['Boolean']>
  /**
   * 是否已注册商税宝
   * @deprecated no longer use
   */
  shangshuibaoRegistered?: Maybe<Scalars['Boolean']>
  /** 是否已注册财税云 */
  caishuiyunRegistered?: Maybe<Scalars['Boolean']>
  defaultChannelOnPreview: Scalars['String']
  qkkUser?: Maybe<Scalars['JSONObject']>
  ou?: Maybe<CompanyInfo>
  userCertificationImages?: Maybe<AccountCertInfo>
  userTitleCertificationImages?: Maybe<AccountCertInfo>
  userComplexMaterialCertImages?: Maybe<AccountCertInfo>
  hrProduction?: Maybe<Scalars['String']>
  qkkStudioOpenStatus?: Maybe<Scalars['String']>
  bankCode?: Maybe<Scalars['String']>
  ptxOpenStatus?: Maybe<Scalars['String']>
  ptxOpenStatusOriginal?: Maybe<Scalars['Int']>
  ownedProductions: Array<ProjectProduction>
  budgetInfo?: Maybe<BudgetUnit>
  isJunziqianSigned?: Maybe<Scalars['Boolean']>
  isCertAndSigned?: Maybe<Scalars['Boolean']>
  budgetWhite?: Maybe<BudgetWhiteDetail>
  qualificationUploadLimitation?: Maybe<Scalars['Boolean']>
  attenderTitleRuleConfig?: Maybe<DoctorTitleRuleConfig>
  doctorMeetingWhite?: Maybe<MeetingWhiteInfo>
  /** 参会老师是否待补充材料 */
  hasWaitSupplement?: Maybe<Scalars['Boolean']>
}

export type AccountInfoCnNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type AccountInfoCompanyMobileArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type AccountInfoGroupArgs = {
  groupType: Scalars['String']
}

export type AccountInfoContentActionStatsArgs = {
  contentId?: Maybe<Scalars['String']>
}

export type AccountInfoResponsibleGroupsArgs = {
  isOwner?: Maybe<Scalars['Boolean']>
  groupType: Scalars['String']
}

export type AccountInfoAppMenusArgs = {
  appCode?: Maybe<Scalars['String']>
}

export type AccountInfoRoleAuthoritiesArgs = {
  appCode?: Maybe<Scalars['String']>
}

export type AccountInfoInfoAsMeetingAttenderArgs = {
  meetingId: Scalars['String']
}

export type AccountInfoHasCompeleteHrImgDoctorCertArgs = {
  channel?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
}

export type AccountInfoIsJunziqianSignedArgs = {
  channel?: Maybe<MeetingSignChannel>
}

export type AccountInfoInput = {
  accountId?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  cnFirstName?: Maybe<Scalars['String']>
  cnLastName?: Maybe<Scalars['String']>
  staffId?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatus>
  gender?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  companyMobile?: Maybe<Scalars['String']>
  companyEmail?: Maybe<Scalars['String']>
  companyPhone?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  reportAccountId?: Maybe<Scalars['String']>
  accountCerts?: Maybe<Array<AccountCertInfoInput>>
  ouId?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  custom1?: Maybe<Scalars['String']>
  custom2?: Maybe<Scalars['String']>
  custom4?: Maybe<Scalars['String']>
  verifyResult?: Maybe<Scalars['String']>
  verifyImages?: Maybe<Scalars['String']>
  disableReason?: Maybe<Scalars['String']>
}

/** 员工状态 */
export enum AccountStatus {
  AccountActivated = 'ACCOUNT_ACTIVATED',
  AccountDisabled = 'ACCOUNT_DISABLED',
  AccountInactive = 'ACCOUNT_INACTIVE',
  AccountReviewPending = 'ACCOUNT_REVIEW_PENDING',
  AccountReviewRefused = 'ACCOUNT_REVIEW_REFUSED'
}

export type AddAttenderInput = {
  name: Scalars['String']
  mobile: Scalars['String']
  accountId?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  titleId?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['JSON']>
}

export type AddAttendersInput = {
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  inviterName?: Maybe<Scalars['String']>
  inviterMobile?: Maybe<Scalars['String']>
}

export type AddDocumentInput = {
  id?: Maybe<Scalars['String']>
  title: Scalars['String']
  author: Scalars['String']
  source: Scalars['String']
  publishDate: Scalars['Timestamp']
  describe?: Maybe<Scalars['String']>
  tagIds?: Maybe<Array<Scalars['String']>>
  url?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  productCode?: Maybe<Scalars['String']>
  categoryId?: Maybe<Scalars['String']>
  sphere?: Maybe<Scalars['String']>
}

export enum AppInfoType {
  News = 'NEWS',
  Doc = 'DOC',
  Grc = 'GRC',
  Video = 'VIDEO'
}

export type AppMenu = {
  __typename?: 'AppMenu'
  id?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  access?: Maybe<Scalars['String']>
  orderNum?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  childs?: Maybe<Array<AppMenu>>
}

export type ApprovalUnion = HansohSalesOrder | SalesClaimFormInfo

export type ApproveInfo = {
  __typename?: 'ApproveInfo'
  instanceId?: Maybe<Scalars['String']>
  processId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  tasks?: Maybe<Array<TaskInfo>>
}

export type ArchiveCreateParams = {
  archiveUrl: Scalars['String']
  title: Scalars['String']
  describe: Scalars['String']
  companyId: Scalars['String']
  products?: Maybe<Array<Scalars['String']>>
  isExternal?: Maybe<Scalars['Boolean']>
}

export type ArchiveInfo = {
  __typename?: 'ArchiveInfo'
  id?: Maybe<Scalars['String']>
  archiveType?: Maybe<Scalars['String']>
  creator?: Maybe<AccountInfo>
  archiveUrl?: Maybe<Scalars['String']>
  archiveTitle?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  createTimestamp?: Maybe<Scalars['Timestamp']>
  isEnable?: Maybe<Scalars['Boolean']>
  tags?: Maybe<Array<ArchiveTag>>
  signedUrl?: Maybe<Scalars['String']>
  archiveTags?: Maybe<Array<ArchiveTagInfo>>
}

export type ArchiveInfoSignedUrlArgs = {
  expiredAfter?: Maybe<Scalars['Int']>
}

export type ArchiveSearchParams = {
  bizTypes?: Maybe<Array<Scalars['String']>>
  keyword?: Maybe<Scalars['String']>
  archiveTypes?: Maybe<Array<Scalars['String']>>
  meetingId?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['String']>>
  needDisabled?: Maybe<Scalars['Boolean']>
  creatorIds?: Maybe<Array<Scalars['String']>>
  tagIds?: Maybe<Array<Scalars['String']>>
}

export type ArchiveTag = {
  __typename?: 'ArchiveTag'
  typeName?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type ArchiveTagInfo = {
  __typename?: 'ArchiveTagInfo'
  id?: Maybe<Scalars['String']>
  refId?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
}

export type AthenaForm = {
  __typename?: 'AthenaForm'
  name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  desc?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export enum AttenderBillStatus {
  BillStatusNone = 'BILL_STATUS_NONE',
  BillStatusInvoiced = 'BILL_STATUS_INVOICED'
}

export type AttenderTaskLog = {
  __typename?: 'AttenderTaskLog'
  taskId?: Maybe<Scalars['String']>
  rejectMsg?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['String']>
}

export type AuditRecordResult = {
  __typename?: 'AuditRecordResult'
  taskId?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  attenderId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  verifyType?: Maybe<Scalars['String']>
  verifyDimension?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Int']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  properties?: Maybe<Scalars['String']>
}

export enum BaeyCarModel {
  Economic = 'economic',
  Comfortable = 'comfortable',
  Business = 'business',
  Luxurious = 'luxurious',
  Minibus = 'minibus',
  Midibus = 'midibus',
  Bigbus = 'bigbus'
}

export enum BaeyCarRentType {
  Btbc = 'BTBC',
  Qtbc = 'QTBC',
  Jjz = 'JJZ',
  Sjz = 'SJZ',
  Snjs = 'SNJS',
  Ksjs = 'KSJS'
}

export type BalancePoolDetail = {
  __typename?: 'BalancePoolDetail'
  /** 大区ID */
  regionId?: Maybe<Scalars['String']>
  /** 总预付 */
  totalPrepaid?: Maybe<Scalars['Float']>
  /** 冻结 */
  frozenFee?: Maybe<Scalars['Float']>
  /** 已使用 */
  usedFee?: Maybe<Scalars['Float']>
  /** 余额 */
  balance?: Maybe<Scalars['Float']>
  region?: Maybe<AccountGroupInfo>
  records?: Maybe<Array<BalancePoolRecord>>
}

export type BalancePoolDetailRecordsArgs = {
  companyId?: Maybe<Scalars['String']>
}

export type BalancePoolRecord = {
  __typename?: 'BalancePoolRecord'
  /** 预付时间内 */
  paymentTime?: Maybe<Scalars['String']>
  /** 预付金额 */
  amount?: Maybe<Scalars['Float']>
}

export type BankCardInfo = {
  __typename?: 'BankCardInfo'
  cardBin?: Maybe<Scalars['String']>
  cardDesc?: Maybe<Scalars['String']>
  cardName?: Maybe<Scalars['String']>
  cardType?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
  bankId?: Maybe<Scalars['String']>
}

export type BankCodeInfo = {
  __typename?: 'BankCodeInfo'
  bankName?: Maybe<Scalars['String']>
  bankCode?: Maybe<Scalars['String']>
}

export type BaseBmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
}

export type BaseCategoryType = {
  __typename?: 'BaseCategoryType'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['Int']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  store?: Maybe<Scalars['String']>
}

export type BaseForm = {
  __typename?: 'BaseForm'
  id?: Maybe<Scalars['String']>
  account?: Maybe<AccountInfo>
  accountMobile?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  company?: Maybe<AccountInfo>
  properties?: Maybe<Array<BaseProperty>>
}

export type BaseOptions = {
  __typename?: 'BaseOptions'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type BaseProperty = {
  __typename?: 'BaseProperty'
  id?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type BaseResponse = {
  __typename?: 'BaseResponse'
  errorCode?: Maybe<Scalars['Int']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type BaseResult = {
  __typename?: 'BaseResult'
  success?: Maybe<Scalars['Boolean']>
  message?: Maybe<Scalars['String']>
}

export type BasicInfoNode = {
  __typename?: 'BasicInfoNode'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  previewUrl?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['Int']>
  reviewed?: Maybe<Scalars['Int']>
  createTimestamp?: Maybe<Scalars['Timestamp']>
  signedUrl?: Maybe<Scalars['String']>
}

export type BatchInfo = {
  sales: SaleAccountInput
  doctor: DocotorAccountInput
}

export type BizBtnInfo = {
  __typename?: 'BizBtnInfo'
  title: Scalars['String']
  action: Scalars['String']
  btnProps?: Maybe<Scalars['JSONObject']>
}

export type BizExt = {
  __typename?: 'BizExt'
  rating?: Maybe<Scalars['String']>
  cost?: Maybe<Scalars['String']>
  hotelOrdering?: Maybe<Scalars['String']>
  mealOrdering?: Maybe<Scalars['String']>
  seatOrdering?: Maybe<Scalars['String']>
  ticketOrdering?: Maybe<Scalars['String']>
}

export type BizStatusInfo = {
  __typename?: 'BizStatusInfo'
  text: Scalars['String']
  color: Scalars['String']
}

export type BmsLoginResponse = {
  __typename?: 'BmsLoginResponse'
  token: Scalars['String']
  account: AccountInfo
}

export type BmsMeetingHotel = {
  __typename?: 'BmsMeetingHotel'
  name: Scalars['String']
  address?: Maybe<Scalars['String']>
  position: Scalars['String']
  amapId: Scalars['String']
  city: Scalars['String']
}

export type BmsMeetingHotelInput = {
  name: Scalars['String']
  address?: Maybe<Scalars['String']>
  position: Scalars['String']
  amapId: Scalars['String']
  city: Scalars['String']
}

export type BmsMeetingSearchH5Input = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<BmsMeetingStatus>
}

export type BmsMeetingSearchInput = {
  cityId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<BmsMeetingStatus>
}

export enum BmsMeetingStatus {
  WaitTravelUpload = 'WAIT_TRAVEL_UPLOAD',
  WaitQuotation = 'WAIT_QUOTATION',
  WaitQuotationToConfirm = 'WAIT_QUOTATION_TO_CONFIRM',
  InProgress = 'IN_PROGRESS',
  WaitConfirm = 'WAIT_CONFIRM',
  Finished = 'FINISHED',
  Canceled = 'CANCELED'
}

export type BmsMeetingStatusOption = {
  __typename?: 'BmsMeetingStatusOption'
  value: BmsMeetingStatus
  label: Scalars['String']
}

export type BudgetImportData = {
  name: Scalars['String']
  bu: Scalars['String']
  region: Scalars['String']
  area: Scalars['String']
  monthBudget: Array<BudgetImportUnit>
  year: Scalars['String']
  financeNo: Scalars['String']
  operationRemark?: Maybe<Scalars['String']>
}

export type BudgetImportUnit = {
  month: Scalars['String']
  budget: Scalars['String']
}

export type BudgetSearch = {
  businessIds?: Maybe<Array<Scalars['String']>>
  areaIds?: Maybe<Array<Scalars['String']>>
  regionIds?: Maybe<Array<Scalars['String']>>
  keyword?: Maybe<Scalars['String']>
  isProcess?: Maybe<Scalars['Boolean']>
}

export type BudgetSearchWithPager = {
  query?: Maybe<BudgetSearch>
  pager?: Maybe<PagerInput>
}

export type BudgetUnit = {
  __typename?: 'BudgetUnit'
  month?: Maybe<Scalars['Int']>
  year?: Maybe<Scalars['Int']>
  /** 预算金额 */
  monthBudget?: Maybe<Scalars['String']>
  /** 预算金额 */
  budgetAmount?: Maybe<Scalars['String']>
  /** 扣减金额 */
  deductionAmount?: Maybe<Scalars['String']>
  /** 已使用金额 */
  usedAmount?: Maybe<Scalars['String']>
  /** 结语金额 */
  balanceAmount?: Maybe<Scalars['String']>
  /** 释放增加 */
  increaseAmount?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type BudgetWhiteDetail = {
  __typename?: 'BudgetWhiteDetail'
  id?: Maybe<Scalars['Int']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type BudgetWhiteDetailInput = {
  id?: Maybe<Scalars['Int']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type BusinessCategory = {
  __typename?: 'BusinessCategory'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  businessOrderCategory?: Maybe<Array<OrderCategory>>
}

export type CarInfo = {
  __typename?: 'CarInfo'
  carType?: Maybe<Scalars['String']>
  carTypeDesc?: Maybe<Scalars['String']>
  carService?: Maybe<Array<CarServiceInfo>>
}

export type CarOfferPrice = {
  __typename?: 'CarOfferPrice'
  id?: Maybe<Scalars['String']>
  supplier?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['String']>
  carType?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
}

export type CarOrderInfo = {
  __typename?: 'CarOrderInfo'
  orderId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  serviceType?: Maybe<Scalars['String']>
  rentType?: Maybe<Scalars['String']>
  supplier?: Maybe<Scalars['String']>
  predictCost?: Maybe<Scalars['Float']>
  actualCost?: Maybe<Scalars['Float']>
  peopleNum?: Maybe<Scalars['Float']>
  departureTime?: Maybe<Scalars['Timestamp']>
  destination?: Maybe<LocationInfo>
  origin?: Maybe<LocationInfo>
  status?: Maybe<Scalars['String']>
  schedule?: Maybe<Array<CarOrderScheduleInfo>>
}

export type CarOrderScheduleInfo = {
  __typename?: 'CarOrderScheduleInfo'
  scheduleId?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  origin?: Maybe<LocationInfo>
  departureTime?: Maybe<Scalars['String']>
  destination?: Maybe<LocationInfo>
  contactPeople?: Maybe<Scalars['String']>
  contactPeoplePhone?: Maybe<Scalars['String']>
  predictCost?: Maybe<Scalars['Float']>
  passenger?: Maybe<Scalars['String']>
  passengerPhone?: Maybe<Scalars['String']>
  model?: Maybe<BaeyCarModel>
  peopleNum?: Maybe<Scalars['Int']>
  totalCost?: Maybe<Scalars['Float']>
  liquidatedDamages?: Maybe<Scalars['Float']>
  rentType?: Maybe<BaeyCarRentType>
  flightDate?: Maybe<Scalars['String']>
  flightNumber?: Maybe<Scalars['String']>
  flightDepartDelayTime?: Maybe<Scalars['Timestamp']>
}

export type CarServiceInfo = {
  __typename?: 'CarServiceInfo'
  id: Scalars['String']
  serviceType?: Maybe<Scalars['String']>
  servicePrice?: Maybe<Scalars['String']>
  serviceDesc: Scalars['String']
  timeOutPrice?: Maybe<Scalars['String']>
  timeOutDesc?: Maybe<Scalars['String']>
  overDistancePrice?: Maybe<Scalars['String']>
  overDistanceDesc?: Maybe<Scalars['String']>
}

export type CarTravelCreat = {
  __typename?: 'CarTravelCreat'
  num: Scalars['String']
  carType: Scalars['String']
  type: Scalars['String']
  unit: Scalars['String']
  note: Scalars['String']
}

export type CarTravelCreatInput = {
  num: Scalars['String']
  carType: Scalars['String']
  type: Scalars['String']
  unit: Scalars['String']
  note: Scalars['String']
}

export type CityInfo = {
  __typename?: 'CityInfo'
  id: Scalars['String']
  name: Scalars['String']
  nameEn: Scalars['String']
  initialEn: Scalars['String']
  pid: Scalars['String']
  pCity?: Maybe<CityInfo>
}

export type ClaimFormRejectOrderParam = {
  orderId: Scalars['String']
  reason?: Maybe<Scalars['String']>
}

export type CompanyInfo = {
  __typename?: 'CompanyInfo'
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  cnName?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  group: AccountGroupInfo
}

export type CompanyInfoGroupArgs = {
  type: Scalars['String']
}

export type ConsumptionCategory = {
  __typename?: 'ConsumptionCategory'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['Int']>
  sort?: Maybe<Scalars['Int']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  store?: Maybe<Scalars['String']>
  ifFillPeopleNumber?: Maybe<Scalars['Boolean']>
  relResource?: Maybe<Scalars['Boolean']>
}

export type ContentActionBaseInfo = {
  __typename?: 'ContentActionBaseInfo'
  id: Scalars['String']
  contentId: Scalars['String']
  doctor?: Maybe<AccountInfo>
  actionTime: Scalars['String']
  doctorAccountId: Scalars['String']
  salesAccountId: Scalars['String']
  companyId: Scalars['String']
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
}

export type ContentActionInfo = {
  __typename?: 'ContentActionInfo'
  id: Scalars['String']
  contentId: Scalars['String']
  doctor?: Maybe<AccountInfo>
  actionTime: Scalars['String']
  doctorAccountId: Scalars['String']
  salesAccountId: Scalars['String']
  companyId: Scalars['String']
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  comment?: Maybe<Scalars['String']>
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
  content?: Maybe<ContentInfo>
  hasDoctorRead: Scalars['Boolean']
}

export type ContentActionStatInfo = {
  __typename?: 'ContentActionStatInfo'
  type: ContentActionType
  value?: Maybe<Scalars['Int']>
  period?: Maybe<StatPeriod>
  quota: Scalars['Float']
}

export enum ContentActionType {
  Share = 'SHARE',
  Read = 'READ',
  Reshare = 'RESHARE',
  ReadTime = 'READ_TIME',
  Up = 'UP',
  Comment = 'COMMENT'
}

export type ContentInfo = {
  __typename?: 'ContentInfo'
  id?: Maybe<Scalars['String']>
  type: ContentType
  title: Scalars['String']
  quizId: Scalars['String']
  creatorAccountId: Scalars['String']
  releaseTime: Scalars['String']
  gmtCreated: Scalars['String']
  gmtModified: Scalars['String']
  companyId?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  contentActions?: Maybe<Scalars['JSONObject']>
  contentStats?: Maybe<Array<ContentActionStatInfo>>
  hasLike: Scalars['Boolean']
  comments: Array<ContentActionBaseInfo>
}

export type ContentInfoHasLikeArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
}

export type ContentListQueryResult = {
  __typename?: 'ContentListQueryResult'
  items: Array<ContentInfo>
  pager: PagerResult
}

export type ContentQueryInput = {
  sortField?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortType>
  status?: Maybe<ContentQueryStatus>
  pager?: Maybe<PagerInput>
  targetDoctorId?: Maybe<Scalars['String']>
}

export enum ContentQueryStatus {
  All = 'ALL',
  Unshare = 'UNSHARE',
  Unread = 'UNREAD',
  Shared = 'SHARED'
}

export enum ContentType {
  Article = 'ARTICLE',
  Video = 'VIDEO'
}

export type CostCenterBudget = {
  __typename?: 'CostCenterBudget'
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  groupName?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['Float']>
  budgetAmount?: Maybe<Scalars['Float']>
  usedAmount?: Maybe<Scalars['Float']>
  balanceAmount?: Maybe<Scalars['Float']>
  deductionAmount?: Maybe<Scalars['Float']>
  increaseAmount?: Maybe<Scalars['Float']>
  initialBudget?: Maybe<Scalars['Float']>
  start?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
  month?: Maybe<Scalars['Int']>
  end?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  belongCostcenterId?: Maybe<Scalars['String']>
  belongCostcenterName?: Maybe<Scalars['String']>
}

export type CostCenterBudgetImportData = {
  costCenterName: Scalars['String']
  year: Scalars['String']
  amount: Scalars['String']
  operationRemark?: Maybe<Scalars['String']>
}

export type CostCenterBudgetInfo = {
  __typename?: 'CostCenterBudgetInfo'
  id?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  groupName?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  lastMonthAmount?: Maybe<Scalars['String']>
  lastMonthBalanceAmount?: Maybe<Scalars['String']>
  currentMonthAmount?: Maybe<Scalars['String']>
  currentMonthIncreaseAmount?: Maybe<Scalars['String']>
  currentMonthUsedAmount?: Maybe<Scalars['String']>
  nextMonthAmount?: Maybe<Scalars['String']>
}

export type CostCenterBudgetLog = {
  __typename?: 'CostCenterBudgetLog'
  id: Scalars['String']
  budgetId: Scalars['String']
  groupId: Scalars['String']
  budgetAmountChanged: Scalars['Float']
  budgetAmountBefore: Scalars['Float']
  increaseAmount: Scalars['Float']
  usedAmount: Scalars['Float']
  balanceAmountChanged: Scalars['Float']
  balanceAmountBefore: Scalars['Float']
  remark: Scalars['String']
  operator: Scalars['String']
  operatorAccountInfo?: Maybe<AccountInfo>
  operationTime: Scalars['String']
}

export type CostCenterBudgetLogSearch = {
  groupId?: Maybe<Scalars['String']>
  budgetId?: Maybe<Scalars['String']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type CostCenterBudgetLogSearchWithPager = {
  query?: Maybe<CostCenterBudgetLogSearch>
  pager?: Maybe<PagerInput>
}

export type CostCenterBudgetSearch = {
  groupIds?: Maybe<Array<Scalars['String']>>
  keyword?: Maybe<Scalars['String']>
  belongCostcenterIds?: Maybe<Array<Scalars['String']>>
}

export type CostCenterBudgetSearchWithPager = {
  query?: Maybe<CostCenterBudgetSearch>
  pager?: Maybe<PagerInput>
}

export type CostCenterBudgetUsedDetail = {
  __typename?: 'CostCenterBudgetUsedDetail'
  formCategory: Scalars['String']
  consumptionCategoryCode: Scalars['String']
  targetName: Scalars['String']
  accountId: Scalars['String']
  accountName: Scalars['String']
  accountMobile: Scalars['String']
  planAmount: Scalars['String']
  actualAmount: Scalars['String']
  claimAmount: Scalars['String']
  deductionAmount: Scalars['String']
  financialDeductionAmount: Scalars['String']
  submitTime: Scalars['String']
  orderId: Scalars['String']
}

export type CostCenterBudgetUsedDetailSearch = {
  companyId?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  targetYear?: Maybe<Scalars['String']>
  type?: Maybe<PersonalPlanBudgetType>
}

export type CostCenterBudgetUsedDetailSearchWithPager = {
  query?: Maybe<CostCenterBudgetUsedDetailSearch>
  pager?: Maybe<PagerInput>
}

export type CostCenterYearBudgetSearch = {
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  groupIds?: Maybe<Array<Scalars['String']>>
  budgetType?: Maybe<Scalars['Float']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type CreateHansohSalesOrderInput = {
  id?: Maybe<Scalars['String']>
  /** 1 -> 项目  2 -> 成本中心  3 -> 日常  4 -> 专项  5 -> 学术 */
  budgetChannel?: Maybe<Scalars['Int']>
  /** 会议类型 */
  conferenceCategoryCode?: Maybe<Scalars['String']>
  /** 消费形式 */
  consumptionCategoryCode?: Maybe<Scalars['String']>
  /** 消费类型(1: 餐饮 2：商超) */
  formCategory?: Maybe<Scalars['String']>
  targetHospital?: Maybe<Scalars['String']>
  targetName?: Maybe<Scalars['String']>
  targetAddress?: Maybe<Scalars['String']>
  applyReason?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  consumptionAmount?: Maybe<Scalars['String']>
  peopleNumber?: Maybe<Scalars['String']>
  actualConsumptionAmount?: Maybe<Scalars['String']>
  actualPeopleNumber?: Maybe<Scalars['String']>
  claimAmount?: Maybe<Scalars['String']>
  /** 费用小项 */
  costItem?: Maybe<Scalars['String']>
  /** 学术编号 */
  academicNumber?: Maybe<Scalars['String']>
  /** 核减金额 */
  deductionAmount?: Maybe<Scalars['String']>
  /** 核减报销金额 */
  financialDeductionAmount?: Maybe<Scalars['String']>
  /** 核减报销金额原因 */
  deductionReason?: Maybe<Scalars['String']>
  finalCorrection?: Maybe<Scalars['String']>
  finalCorrectionReason?: Maybe<Scalars['String']>
  usageScenario?: Maybe<Scalars['String']>
  /** 审批编号 */
  oaNumber?: Maybe<Scalars['String']>
}

export type CreateHengruiMeeting = {
  type: Scalars['String']
  name?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  address?: Maybe<Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  peopleNum: Scalars['Float']
  desc?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  activityType?: Maybe<Scalars['String']>
  costcenter?: Maybe<Scalars['String']>
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>
  hospital?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  needBudget?: Maybe<Scalars['Boolean']>
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>
  budgetNumber?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  activityPurpose?: Maybe<Scalars['String']>
  activityRequire?: Maybe<Scalars['String']>
  conferencePlatform?: Maybe<Scalars['String']>
  conferenceNo?: Maybe<Scalars['String']>
  /** 会议种类 */
  category?: Maybe<Scalars['String']>
  location?: Maybe<MeetingChooseLocationInput>
  therapyArea?: Maybe<Scalars['String']>
  drugName?: Maybe<Scalars['String']>
}

export type CreateStandardMeetingInput = {
  id?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  startTime: Scalars['String']
  endTime: Scalars['String']
  desc: Scalars['String']
  topic: Scalars['String']
  products: Array<ProductInfoInput>
  speaker: Scalars['String']
  pptUrl: Array<Scalars['String']>
  topicPictureUrls: Array<Scalars['String']>
  needAssistance: Scalars['Boolean']
  needQuiz: Scalars['Boolean']
  lectureFee: Scalars['Float']
  otherFee: Scalars['Float']
}

export type DefNodeInfo = {
  approver?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  defNodeId?: Maybe<Scalars['String']>
}

export type DepartmentInfo = {
  __typename?: 'DepartmentInfo'
  departmentId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  status?: Maybe<GroupStatus>
}

export enum DistributeOrderType {
  Document = 'DOCUMENT',
  Questionnaire = 'QUESTIONNAIRE',
  Collection = 'COLLECTION',
  Conference = 'CONFERENCE'
}

export type DockingHospitalInfo = {
  __typename?: 'DockingHospitalInfo'
  department?: Maybe<Scalars['String']>
  bus?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  img?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  mainDisease?: Maybe<Scalars['String']>
  info?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  specialDepartment?: Maybe<Scalars['String']>
}

export type DocotorAccountInput = {
  cnName: Scalars['String']
  mobile: Scalars['String']
  hospitalId: Scalars['String']
  departmentId: Scalars['String']
  titleId: Scalars['String']
  staffId: Scalars['String']
}

export type DoctorAttenderTasks = {
  __typename?: 'DoctorAttenderTasks'
  items: Array<StandardMeetingBaseAttender>
  pager: PagerResult
}

export type DoctorCompleteCertInfo = {
  certNo: Scalars['String']
  cardNo?: Maybe<Scalars['String']>
  certImages?: Maybe<Array<Scalars['String']>>
  bankName?: Maybe<Scalars['String']>
}

export type DoctorTaskQueryInput = {
  companyId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  meetingType?: Maybe<Array<Scalars['String']>>
  activityType?: Maybe<Array<Scalars['String']>>
  meetingState?: Maybe<Array<StandardMeetingServiceBizStatus>>
}

export type DoctorTaskQueryInputWithPager = {
  query?: Maybe<DoctorTaskQueryInput>
  pager?: Maybe<PagerInput>
}

export type DoctorTaskSearchInput = {
  id?: Maybe<Array<Scalars['String']>>
  taskCategory?: Maybe<Array<Scalars['String']>>
  attenderId?: Maybe<Array<Scalars['String']>>
  accountId?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  templateCode?: Maybe<Scalars['String']>
}

export type DoctorTaskSearchInputWithPager = {
  query?: Maybe<DoctorTaskSearchInput>
  pager?: Maybe<PagerInput>
}

export type DoctorTitleRuleConfig = {
  __typename?: 'DoctorTitleRuleConfig'
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  categoryCode?: Maybe<Scalars['String']>
  titleGrade?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  durationStart?: Maybe<Scalars['Int']>
  durationEnd?: Maybe<Scalars['Int']>
  pointLimit?: Maybe<Scalars['Int']>
  remark?: Maybe<Scalars['String']>
}

export type Document = {
  __typename?: 'Document'
  id?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  owner?: Maybe<AccountInfo>
  title?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  createdTimestamp?: Maybe<Scalars['String']>
  releaseTimestamp?: Maybe<Scalars['String']>
  category?: Maybe<DocumentArchiveCategory>
  language?: Maybe<Scalars['String']>
  productCode?: Maybe<Scalars['String']>
  sphere?: Maybe<Scalars['String']>
  productInfo?: Maybe<UpProduct>
  company?: Maybe<CompanyInfo>
  hasChoosenByAttender?: Maybe<Scalars['Boolean']>
  documentReview?: Maybe<DocumentReviewInfo>
  documentSphere?: Maybe<DocumentSphereInfo>
}

export type DocumentAuthorArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type DocumentHasChoosenByAttenderArgs = {
  attenderId: Scalars['String']
}

export type DocumentArchiveCategory = {
  __typename?: 'DocumentArchiveCategory'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type DocumentArchivePagerSearchResult = {
  __typename?: 'DocumentArchivePagerSearchResult'
  items: Array<DocumentInfo>
  pager: PagerResult
}

export type DocumentArchiveProperty = {
  __typename?: 'DocumentArchiveProperty'
  id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
}

export type DocumentArchiveReviewsSearchParams = {
  tagIds?: Maybe<Array<Scalars['String']>>
  documentCategoryIds?: Maybe<Array<Scalars['String']>>
  documentReviewStatus?: Maybe<Array<Scalars['Int']>>
  keywords?: Maybe<Scalars['String']>
}

export type DocumentArchiveReviewsSearchParamsWithPager = {
  query?: Maybe<DocumentArchiveReviewsSearchParams>
  pager?: Maybe<PagerInput>
}

export type DocumentArchiveSearchParams = {
  tagIds?: Maybe<Array<Scalars['String']>>
  documentCategoryIds?: Maybe<Array<Scalars['String']>>
  documentReviewStatus?: Maybe<Array<Scalars['Int']>>
  keywords?: Maybe<Scalars['String']>
  productCodes?: Maybe<Array<Scalars['String']>>
  companyId?: Maybe<Scalars['String']>
  templateCode?: Maybe<Scalars['String']>
  activityCode?: Maybe<Scalars['String']>
  sphere?: Maybe<Array<Scalars['String']>>
}

export type DocumentArchiveSearchParamsWithPager = {
  query?: Maybe<DocumentArchiveSearchParams>
  pager?: Maybe<PagerInput>
}

export type DocumentInfo = {
  __typename?: 'DocumentInfo'
  document?: Maybe<Document>
  archive?: Maybe<Array<ArchiveInfo>>
  properties?: Maybe<Array<DocumentArchiveProperty>>
}

export type DocumentReviewContentInfo = {
  __typename?: 'DocumentReviewContentInfo'
  id?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  documentId?: Maybe<Scalars['String']>
  documentReviewId?: Maybe<Scalars['String']>
  createdTimestamp?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export type DocumentReviewInfo = {
  __typename?: 'DocumentReviewInfo'
  id?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  account?: Maybe<AccountInfo>
  companyId?: Maybe<Scalars['String']>
  createdTimestamp?: Maybe<Scalars['String']>
  inviterId?: Maybe<Scalars['String']>
  inviter?: Maybe<AccountInfo>
  inviterName?: Maybe<Scalars['String']>
  inviterRegionId?: Maybe<Scalars['String']>
  documentId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['Int']>
  state?: Maybe<Scalars['Int']>
  stateText?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  rejectReason?: Maybe<Scalars['String']>
  editable: Scalars['Boolean']
  reviewed: Scalars['Boolean']
  isOpen?: Maybe<Scalars['Boolean']>
  documentInfo?: Maybe<DocumentInfo>
}

export type DocumentReviewInfoInviterNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type DocumentReviewInfoNameArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type DocumentReviewInfoPhoneArgs = {
  encrypted?: Maybe<Scalars['Boolean']>
}

export type DocumentReviewPagerSearchResult = {
  __typename?: 'DocumentReviewPagerSearchResult'
  items: Array<DocumentReviewInfo>
  pager: PagerResult
}

export type DocumentSphereInfo = {
  __typename?: 'DocumentSphereInfo'
  id?: Maybe<Scalars['String']>
  sphereName?: Maybe<Scalars['String']>
  sphereCode?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type DocumentSphereInfoInput = {
  id?: Maybe<Scalars['String']>
  sphereName?: Maybe<Scalars['String']>
  sphereCode?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type DocumentSphereSearchParams = {
  companyIds?: Maybe<Array<Scalars['String']>>
  sphereNames?: Maybe<Array<Scalars['String']>>
  sphereCodes?: Maybe<Array<Scalars['String']>>
}

export type EditDoctorInfo = {
  accountId?: Maybe<Scalars['String']>
  name: Scalars['String']
  mobile: Scalars['String']
  hospital?: Maybe<Scalars['String']>
  /** 卡号 */
  cardNo?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  departmentId?: Maybe<Scalars['String']>
  titleId?: Maybe<Scalars['String']>
  /** 银行 */
  bankName?: Maybe<Scalars['String']>
  /** 开户行 */
  bankBranchName?: Maybe<Scalars['String']>
  /** 联行号 */
  bankCode?: Maybe<Scalars['String']>
  /** 身份证号 */
  certNo?: Maybe<Scalars['String']>
  /** 默认渠道 */
  channel?: Maybe<Scalars['String']>
  /** 身份证照片 */
  idCardImages?: Maybe<Array<Scalars['String']>>
  /** 执业证件类型 */
  qualificationCertTypeName?: Maybe<Scalars['String']>
  /** 执业证件 */
  qualificationCertImages?: Maybe<Array<Scalars['String']>>
  /** 复核材料 */
  complexMaterialCertImages?: Maybe<Array<Scalars['String']>>
  /** 职称证 */
  titleCertImages?: Maybe<Array<Scalars['String']>>
  /** 全程审核结果 */
  verifyResult?: Maybe<Scalars['String']>
  /** 全程审核验证 */
  verifyImages?: Maybe<Scalars['String']>
}

export enum FinanceApproveResult {
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export type GroupNodeInfo = {
  __typename?: 'GroupNodeInfo'
  name?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  owner?: Maybe<AccountInfo>
  code?: Maybe<Scalars['String']>
  children?: Maybe<GroupNodeInfo>
  members: Array<AccountInfo>
}

export type GroupPaginationRes = {
  __typename?: 'GroupPaginationRes'
  items: Array<AccountGroupInfo>
  pager: PagerResult
}

/** 组织状态 */
export enum GroupStatus {
  GroupActivated = 'GROUP_ACTIVATED',
  GroupDisabled = 'GROUP_DISABLED'
}

export type HrCostCenter = {
  __typename?: 'HRCostCenter'
  id: Scalars['String']
  name: Scalars['String']
}

export type HrDoctorExcelImport = {
  __typename?: 'HRDoctorExcelImport'
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HrDoctorExcelImportInput = {
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  hospital?: Maybe<Scalars['String']>
  department?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HrMeetingStatusOption = {
  __typename?: 'HRMeetingStatusOption'
  value: StandardMeetingServiceBizStatus
  label: Scalars['String']
}

export type HrTaskExcelImport = {
  __typename?: 'HRTaskExcelImport'
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  taskCode?: Maybe<Scalars['String']>
  activityCode?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  product?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['String']>
}

export type HrTaskExcelImportInput = {
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  taskCode?: Maybe<Scalars['String']>
  activityCode?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  product?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['String']>
}

export enum HadesPaymentChannel {
  Qkk = 'QKK',
  Csy = 'CSY',
  Ssq = 'SSQ'
}

export enum HadesReceiptType {
  Vouchaer = 'VOUCHAER',
  Tax = 'TAX',
  Invoice = 'INVOICE'
}

export type HansohBudget = {
  __typename?: 'HansohBudget'
  accountId?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  financeNo?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  budget?: Maybe<Array<BudgetUnit>>
}

export type HansohOrderInvoice = {
  __typename?: 'HansohOrderInvoice'
  orderId?: Maybe<Scalars['String']>
  formId?: Maybe<Scalars['String']>
  invoiceUrl?: Maybe<Scalars['String']>
  invoiceNumber?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  checkOcr?: Maybe<Scalars['Boolean']>
  checkPassed?: Maybe<Scalars['Boolean']>
  checkRepeat?: Maybe<Scalars['Boolean']>
  checkTitle?: Maybe<Scalars['Boolean']>
  salesInvoiceDetail?: Maybe<HansohSalesOrderInvoiceDetail>
}

export type HansohSalesClaim = {
  __typename?: 'HansohSalesClaim'
  id?: Maybe<Scalars['String']>
  account?: Maybe<AccountInfo>
  /** 快照 */
  accountMobile?: Maybe<Scalars['String']>
  /** 快照 */
  accountName?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  company?: Maybe<AccountInfo>
  properties?: Maybe<Array<BaseProperty>>
  claimFormCode?: Maybe<Scalars['String']>
  claimFormName?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  reason?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  qrCodeImage?: Maybe<Scalars['String']>
}

export type HansohSalesOrder = {
  __typename?: 'HansohSalesOrder'
  id?: Maybe<Scalars['String']>
  account?: Maybe<AccountInfo>
  accountMobile?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  company?: Maybe<AccountInfo>
  properties?: Maybe<Array<BaseProperty>>
  /** 消费类型(1: 餐饮 2：商超 3：网购 4：快递 5：交通) */
  formCategory?: Maybe<Scalars['String']>
  /** 消费类型(1: 餐饮 2：商超 3：网购 4：快递 5：交通) */
  formCategoryText?: Maybe<Scalars['String']>
  /** 1 -> 项目  2 -> 成本中心  3 -> 日常  4 -> 专项  5 -> 学术 */
  budgetChannel?: Maybe<Scalars['Int']>
  budgetNumber?: Maybe<Scalars['String']>
  /** 会议类型 */
  conferenceCategoryCode?: Maybe<Scalars['String']>
  /** 消费形式 */
  consumptionCategoryCode?: Maybe<Scalars['String']>
  targetHospital?: Maybe<Scalars['String']>
  targetName?: Maybe<Scalars['String']>
  targetAddress?: Maybe<Scalars['String']>
  targetOccurTime?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  applyReason?: Maybe<Scalars['String']>
  consumptionAmount?: Maybe<Scalars['String']>
  peopleNumber?: Maybe<Scalars['String']>
  actualPeopleNumber?: Maybe<Scalars['String']>
  actualConsumptionAmount?: Maybe<Scalars['String']>
  status?: Maybe<SalesOrderStatus>
  statusText?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['String']>
  appointmentTime?: Maybe<Scalars['String']>
  isRelated?: Maybe<Scalars['Boolean']>
  isOtherFormRelated?: Maybe<Scalars['Boolean']>
  claimAmount?: Maybe<Scalars['String']>
  costcenterId?: Maybe<Scalars['String']>
  costcenterName?: Maybe<Scalars['String']>
  /** 核减金额 */
  deductionAmount?: Maybe<Scalars['String']>
  /** 核减报销金额 */
  financialDeductionAmount?: Maybe<Scalars['String']>
  /** 核减金额操作人员 */
  deductionOperator?: Maybe<Scalars['String']>
  /** 核减报销金额操作人员 */
  financialDeductionOperator?: Maybe<Scalars['String']>
  /** 核减报销金额原因 */
  deductionReason?: Maybe<Scalars['String']>
  finalCorrection?: Maybe<Scalars['String']>
  finalCorrectionReason?: Maybe<Scalars['String']>
  usageScenario?: Maybe<Scalars['String']>
  oaNumber?: Maybe<Scalars['String']>
  resource?: Maybe<UnsignedVendor>
  conferenceCategory?: Maybe<BaseCategoryType>
  consumptionCategory?: Maybe<ConsumptionCategory>
  businessCategory?: Maybe<BusinessCategory>
  belongedPlan?: Maybe<HansohSalesPlan>
  isCurrentApprover: Scalars['Boolean']
  salesOrderLogs?: Maybe<Array<SalesOrderOperationLog>>
  invoices: Array<HansohOrderInvoice>
  isOwner: Scalars['Boolean']
  orderProperties: Array<BaseProperty>
  formCategoryInfo?: Maybe<OrderCategory>
}

export type HansohSalesOrderConsumptionCategoryArgs = {
  relResource?: Maybe<Scalars['Boolean']>
}

export type HansohSalesOrderInvoiceDetail = {
  __typename?: 'HansohSalesOrderInvoiceDetail'
  id?: Maybe<Scalars['String']>
  invoiceType?: Maybe<Scalars['String']>
  invoiceName?: Maybe<Scalars['String']>
  invoiceCode?: Maybe<Scalars['String']>
  invoiceNumber?: Maybe<Scalars['String']>
  checkCode?: Maybe<Scalars['String']>
  invoiceDate?: Maybe<Scalars['String']>
  purchaserName?: Maybe<Scalars['String']>
  purchaserTaxIdentNumber?: Maybe<Scalars['String']>
  purchaserAddress?: Maybe<Scalars['String']>
  purchaserBank?: Maybe<Scalars['String']>
  salesName?: Maybe<Scalars['String']>
  salesTaxIdentNumber?: Maybe<Scalars['String']>
  salesAddress?: Maybe<Scalars['String']>
  salesBank?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['String']>
  taxAmount?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['String']>
  totalAmountUppercase?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  payee?: Maybe<Scalars['String']>
  review?: Maybe<Scalars['String']>
  drawer?: Maybe<Scalars['String']>
  checkPassed?: Maybe<Scalars['Int']>
  commodityName?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  invoiceUrl?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  uniqueCode?: Maybe<Scalars['String']>
}

export type HansohSalesPlan = {
  __typename?: 'HansohSalesPlan'
  id?: Maybe<Scalars['String']>
  account?: Maybe<AccountInfo>
  accountMobile?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  company?: Maybe<AccountInfo>
  properties?: Maybe<Array<BaseProperty>>
  applyTime?: Maybe<Scalars['String']>
  serviceBusinessStatus?: Maybe<SalesPlanStatus>
  serviceBusinessStatusText?: Maybe<Scalars['String']>
  /** 渠道字段:0 -> 默认, 1 -> 项目预算, 2 -> 成本中心预算 */
  budgetChannel?: Maybe<Scalars['Int']>
  budgetNumber?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  planAmount?: Maybe<Scalars['String']>
  costcenterName?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  applyReason?: Maybe<Scalars['String']>
  orders?: Maybe<Array<HansohSalesOrder>>
  isCurrentApprover: Scalars['Boolean']
  isOwner: Scalars['Boolean']
}

export type HansohSalesPlanCreateRequest = {
  costcenterId: Scalars['String']
  /** 渠道字段:0 -> 默认, 1 -> 项目预算, 2 -> 成本中心预算 */
  budgetChannel: Scalars['Int']
  planName: Scalars['String']
}

export type HansohSalesPlanEditRequest = {
  id: Scalars['String']
  costcenterId: Scalars['String']
  /** 渠道字段:0 -> 默认, 1 -> 项目预算, 2 -> 成本中心预算 */
  budgetChannel: Scalars['Int']
  planName: Scalars['String']
}

export type HansohSalesPlanSearchParams = {
  ids?: Maybe<Array<Scalars['String']>>
  serviceBusinessStatus?: Maybe<Array<SalesPlanStatus>>
}

export type HansohSalesPlanSearchParamsWithPager = {
  query?: Maybe<HansohSalesPlanSearchParams>
  pager?: Maybe<PagerInput>
}

export type HengruiMeetingSpeaker = {
  __typename?: 'HengruiMeetingSpeaker'
  name: Scalars['String']
  accountId: Scalars['String']
  mobile: Scalars['String']
  topic?: Maybe<Scalars['String']>
  fee: Scalars['String']
  amount?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Int']>
  materials?: Maybe<Array<Scalars['String']>>
  data?: Maybe<Scalars['JSON']>
}

export type HengruiMeetingSpeakerInput = {
  name: Scalars['String']
  accountId: Scalars['String']
  mobile: Scalars['String']
  topic?: Maybe<Scalars['String']>
  fee: Scalars['String']
  amount?: Maybe<Scalars['Int']>
  duration?: Maybe<Scalars['Int']>
  materials?: Maybe<Array<Scalars['String']>>
  data?: Maybe<Scalars['JSON']>
}

export type HospitalInfo = {
  __typename?: 'HospitalInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  contact?: Maybe<Scalars['String']>
}

export type HospitalInfoPaginatedResponse = {
  __typename?: 'HospitalInfoPaginatedResponse'
  items: Array<DockingHospitalInfo>
  pager: PagerResult
}

export type HospitalInfoSearchQuery = {
  province?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['String']>
}

export type HospitalPaginationRequest = {
  query?: Maybe<HospitalSearchQuery>
  pager?: Maybe<PagerInput>
}

export type HospitalPaginationRes = {
  __typename?: 'HospitalPaginationRes'
  items: Array<HospitalInfo>
  pager: PagerResult
}

export type HospitalPaginationResult = {
  __typename?: 'HospitalPaginationResult'
  items: Array<HospitalInfo>
  pager: PagerResult
}

export type HospitalSearchQuery = {
  cityId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
}

export type HotelInfo = {
  __typename?: 'HotelInfo'
  id: Scalars['String']
  name: Scalars['String']
  type: Scalars['String']
  bizExt?: Maybe<BizExt>
  photos?: Maybe<Array<IPhoto>>
}

export type HotelSearchInput = {
  query?: Maybe<HotelSearchQuery>
  pager?: Maybe<PagerInput>
}

export type HotelSearchQuery = {
  keyword?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
}

export type HotelSearchResult = {
  __typename?: 'HotelSearchResult'
  items: Array<HotelInfo>
  pager: PagerResult
}

export type IesLocation = {
  __typename?: 'IESLocation'
  lat?: Maybe<Scalars['Float']>
  lon?: Maybe<Scalars['Float']>
}

export type IPhoto = {
  __typename?: 'IPhoto'
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type InterventionProjectParticipantBase = {
  __typename?: 'InterventionProjectParticipantBase'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  attenderId?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<Scalars['String']>
  topic?: Maybe<Scalars['String']>
  regionOwnerId?: Maybe<Scalars['String']>
}

export type InterventionProjectParticipantInfo = {
  __typename?: 'InterventionProjectParticipantInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  attenderId?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<Scalars['String']>
  topic?: Maybe<Scalars['String']>
  regionOwnerId?: Maybe<Scalars['String']>
  attender?: Maybe<AccountInfo>
  regionOwner?: Maybe<AccountInfo>
}

export type InterventionProjectParticipantInput = {
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
  attenderId?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
  roles?: Maybe<Array<Scalars['String']>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<Scalars['String']>
  topic?: Maybe<Scalars['String']>
  regionOwnerId?: Maybe<Scalars['String']>
}

export type InterventionalProjectInfo = {
  __typename?: 'InterventionalProjectInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>
  status?: Maybe<Scalars['String']>
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>
  purpose?: Maybe<Scalars['String']>
  categoryId?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['Timestamp']>
  fee?: Maybe<Scalars['JSONObject']>
  totalFee?: Maybe<Scalars['String']>
  isLiveSupport?: Maybe<Scalars['Int']>
  meetingType?: Maybe<Scalars['Int']>
  meetingData?: Maybe<Scalars['JSONObject']>
  participants: Array<InterventionProjectParticipantInfo>
  owner?: Maybe<AccountInfo>
}

export type InterventionalProjectInfoBase = {
  __typename?: 'InterventionalProjectInfoBase'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>
  status?: Maybe<Scalars['String']>
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>
  purpose?: Maybe<Scalars['String']>
  categoryId?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['Timestamp']>
  fee?: Maybe<Scalars['JSONObject']>
  totalFee?: Maybe<Scalars['String']>
  isLiveSupport?: Maybe<Scalars['Int']>
  meetingType?: Maybe<Scalars['Int']>
  meetingData?: Maybe<Scalars['JSONObject']>
}

export type InterventionalProjectInfoInput = {
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['Int']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  regionApplicationEnabled?: Maybe<Scalars['Boolean']>
  status?: Maybe<Scalars['String']>
  /** 参与人数 */
  participantsAllowed?: Maybe<Scalars['Int']>
  purpose?: Maybe<Scalars['String']>
  categoryId?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['Timestamp']>
  fee?: Maybe<Scalars['JSONObject']>
  totalFee?: Maybe<Scalars['String']>
  isLiveSupport?: Maybe<Scalars['Int']>
  meetingType?: Maybe<Scalars['Int']>
  meetingData?: Maybe<Scalars['JSONObject']>
}

export type InterventionalProjectParticipantsQueryParams = {
  nameKeyword?: Maybe<Scalars['String']>
  clientKeyword?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  productIds?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Array<Scalars['Int']>>
}

export type InterventionalProjectParticipantsQueryParamsWithPager = {
  query?: Maybe<InterventionalProjectParticipantsQueryParams>
  pager?: Maybe<PagerInput>
}

export type InterventionalProjectQueryParams = {
  keyword?: Maybe<Scalars['String']>
  searchFields?: Maybe<Array<Scalars['String']>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  departmentId?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  productIds?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Array<Scalars['Int']>>
  bizStatus?: Maybe<ProjectBizStatus>
  regionOwnerIds?: Maybe<Array<Scalars['String']>>
  approveStatus?: Maybe<ProjectApprovalStatus>
}

export type InterventionalProjectQueryParamsWithPager = {
  query?: Maybe<InterventionalProjectQueryParams>
  pager?: Maybe<PagerInput>
}

export enum InvitationButtonStatus {
  NeedPreregistered = 'NEED_PREREGISTERED',
  Preregistered = 'PREREGISTERED',
  CanInvited = 'CAN_INVITED'
}

export type LimitQueryResult = {
  __typename?: 'LimitQueryResult'
  limitReached: Scalars['Boolean']
  limitEnabled: Scalars['Boolean']
  message: Scalars['String']
}

export type LimitSwapPeople = {
  __typename?: 'LimitSwapPeople'
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  financeNo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type LocationInfo = {
  __typename?: 'LocationInfo'
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  landMark?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
}

export type LogData = {
  __typename?: 'LogData'
  logType?: Maybe<Scalars['String']>
  assginer?: Maybe<Scalars['String']>
  assginerInfo?: Maybe<AccountInfo>
  reason?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['Timestamp']>
}

export type MeetingACtivityCreateOrUpdateInput = {
  id?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  categoryCode: Scalars['String']
  processId: Scalars['String']
  activityCode: Scalars['String']
  enable: Scalars['Boolean']
  templateCode?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  start?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  instanceMin?: Maybe<Scalars['Int']>
  instanceMax?: Maybe<Scalars['Int']>
  autoComplete?: Maybe<Scalars['Boolean']>
  sphere?: Maybe<Scalars['String']>
}

export type MeetingActivity = {
  __typename?: 'MeetingActivity'
  id: Scalars['String']
  enable: Scalars['Boolean']
  config?: Maybe<MeetingConfigBase>
  companyId: Scalars['String']
  explain?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  autoComplete?: Maybe<Scalars['Boolean']>
  titleFilter?: Maybe<Array<Scalars['String']>>
  activityProperties?: Maybe<Scalars['String']>
  properties?: Maybe<Array<Scalars['JSON']>>
  activitiyTypes: Array<MeetingActivity>
  children: Array<MeetingCategory>
  myApplied: Scalars['Int']
  mySettled: Scalars['Int']
  titleFilterText?: Maybe<Scalars['String']>
  activitySphere?: Maybe<DocumentSphereInfo>
  categoryCode?: Maybe<Scalars['String']>
  processId?: Maybe<Scalars['String']>
  templateCode?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  start?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  instanceMin?: Maybe<Scalars['Int']>
  instanceMax?: Maybe<Scalars['Int']>
  sphere?: Maybe<Scalars['String']>
  templateInfo?: Maybe<MeetingActivityTemplate>
}

export type MeetingActivityTemplate = {
  __typename?: 'MeetingActivityTemplate'
  id?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  remark?: Maybe<Scalars['String']>
  unitPrice?: Maybe<Scalars['String']>
  templateName?: Maybe<Scalars['String']>
  templateCode?: Maybe<Scalars['String']>
  templateCategory?: Maybe<Scalars['String']>
  documentCategory?: Maybe<Scalars['String']>
  templateAttribute?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  instanceMax?: Maybe<Scalars['Int']>
  instanceMin?: Maybe<Scalars['Int']>
  documentCategoryInfo?: Maybe<DocumentArchiveCategory>
}

export type MeetingApprovalRecordData = {
  __typename?: 'MeetingApprovalRecordData'
  id?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  meetingType?: Maybe<Scalars['String']>
  meetingProperties?: Maybe<Scalars['String']>
  attenderProperties?: Maybe<Scalars['String']>
  inviatorProperties?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
}

export type MeetingAttenderTask = {
  __typename?: 'MeetingAttenderTask'
  id: Scalars['String']
  templateCode?: Maybe<Scalars['String']>
  taskAttribute?: Maybe<Scalars['String']>
  taskCategory?: Maybe<Scalars['String']>
  taskName?: Maybe<Scalars['String']>
  taskPrice?: Maybe<Scalars['String']>
  objectId?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Int']>
  statusText?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  isOpen?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  attenderId: Scalars['String']
  accountId: Scalars['String']
  accountInfo?: Maybe<AccountInfo>
  meetingId: Scalars['String']
  companyId: Scalars['String']
  rejectMsg?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['String']>
  gmtModified?: Maybe<Scalars['String']>
  templateInfo?: Maybe<MeetingActivityTemplate>
  documentInfo?: Maybe<DocumentInfo>
  meetingInfo?: Maybe<StandardMeetingInfo>
  attenderInfo?: Maybe<StandardMeetingBaseAttender>
}

export type MeetingCategory = {
  __typename?: 'MeetingCategory'
  id: Scalars['String']
  enable: Scalars['Boolean']
  config?: Maybe<MeetingConfigBase>
  companyId: Scalars['String']
  explain?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  autoComplete?: Maybe<Scalars['Boolean']>
  titleFilter?: Maybe<Array<Scalars['String']>>
  activityProperties?: Maybe<Scalars['String']>
  properties?: Maybe<Array<Scalars['JSON']>>
  activitiyTypes: Array<MeetingActivity>
  children: Array<MeetingCategory>
  myApplied: Scalars['Int']
  mySettled: Scalars['Int']
  titleFilterText?: Maybe<Scalars['String']>
  activitySphere?: Maybe<DocumentSphereInfo>
}

export type MeetingCategoryProps = {
  __typename?: 'MeetingCategoryProps'
  code?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
}

export type MeetingCategoryPropsInput = {
  code?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
}

export type MeetingChooseLocation = {
  __typename?: 'MeetingChooseLocation'
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
}

export type MeetingChooseLocationInput = {
  province?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
}

export type MeetingConfigAddOrUpdateInput = {
  id?: Maybe<Scalars['String']>
  title: Scalars['String']
  code: Scalars['String']
  icon: Scalars['String']
  enable: Scalars['Boolean']
  sort?: Maybe<Scalars['Int']>
  props: Array<MeetingCategoryPropsInput>
  titleFilter?: Maybe<Array<Scalars['String']>>
}

export type MeetingConfigBase = {
  __typename?: 'MeetingConfigBase'
  id: Scalars['String']
  code: Scalars['String']
  enable: Scalars['Boolean']
  sort: Scalars['Int']
  icon?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  desc?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  properties?: Maybe<Array<Scalars['JSON']>>
}

export enum MeetingDomainStatus {
  Anaesthesia = 'ANAESTHESIA',
  Severe = 'SEVERE'
}

export type MeetingDomainStatusOption = {
  __typename?: 'MeetingDomainStatusOption'
  value: MeetingDomainStatus
  label: Scalars['String']
}

export type MeetingInviator = {
  __typename?: 'MeetingInviator'
  id?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountInfo?: Maybe<AccountInfo>
  extraInfo?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
  videoLink?: Maybe<Scalars['String']>
  pictureLink?: Maybe<Scalars['String']>
}

export type MeetingInviatorInput = {
  meetingId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
}

export type MeetingPlatform = {
  __typename?: 'MeetingPlatform'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  enabled?: Maybe<Scalars['Boolean']>
}

export type MeetingPlatformInput = {
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  enabled?: Maybe<Scalars['Boolean']>
}

export type MeetingQueryInput = {
  name?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  status?: Maybe<UpMeetingStatus>
  subStatus?: Maybe<Scalars['String']>
  pager?: Maybe<PagerInput>
}

export type MeetingSettlement = {
  __typename?: 'MeetingSettlement'
  settlementId?: Maybe<Scalars['String']>
  attenders?: Maybe<StandardMeetingBaseAttender>
  state?: Maybe<Scalars['Int']>
  settlementStatus?: Maybe<Scalars['String']>
}

export type MeetingSettlementSearch = {
  keyword?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  meetingStartTimestamp?: Maybe<Scalars['String']>
  meetingEndTimestamp?: Maybe<Scalars['String']>
  settlementStatus?: Maybe<Scalars['Int']>
  region?: Maybe<Scalars['String']>
}

export enum MeetingSignChannel {
  Ptx = 'PTX',
  Fg = 'FG',
  Hnjc = 'HNJC',
  Axb = 'AXB',
  Hndx = 'HNDX',
  Hnmx = 'HNMX',
  Hnld = 'HNLD',
  Hnsm = 'HNSM',
  Shpt = 'SHPT',
  Sdtx = 'SDTX',
  Yhl = 'YHL'
}

export type MeetingUserAuthorityDetail = {
  __typename?: 'MeetingUserAuthorityDetail'
  id?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  activityCode?: Maybe<Array<Scalars['String']>>
  regionId?: Maybe<Array<Scalars['String']>>
}

export type MeetingUserAuthorityInfo = {
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  activityCode?: Maybe<Array<Scalars['String']>>
  regionId?: Maybe<Array<Scalars['String']>>
  userId?: Maybe<Array<Scalars['String']>>
}

export type MeetingUserAuthoritySearchQuery = {
  companyId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
}

export type MeetingWhiteInfo = {
  __typename?: 'MeetingWhiteInfo'
  id?: Maybe<Scalars['Int']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
}

export type MemberPaginatedResponse = {
  __typename?: 'MemberPaginatedResponse'
  items: Array<AccountInfo>
  pager: PagerResult
}

export type MemberQueryInput = {
  search?: Maybe<Scalars['String']>
  pager?: Maybe<PagerInput>
}

export type Mutation = {
  __typename?: 'Mutation'
  login: Scalars['String']
  sendVerifyCode: Scalars['String']
  loginWithVerifyCode: Scalars['String']
  register: Scalars['String']
  simpleRegisterDoctor: Scalars['Boolean']
  logout: Scalars['Boolean']
  updateAccount: Scalars['Boolean']
  updateAccountFinal?: Maybe<Scalars['Boolean']>
  updateAccountStatus: Scalars['Boolean']
  resetPassword: Scalars['Boolean']
  changeAccountDefaultChannel: Scalars['Boolean']
  unionLogin?: Maybe<Scalars['JSON']>
  uploadUserCertificationImages?: Maybe<BaseResponse>
  registerPtxUser?: Maybe<BaseResponse>
  bindAccountWithCode?: Maybe<BaseResponse>
  syncDoctorVerifyResult?: Maybe<BaseResponse>
  addMeetingWhite?: Maybe<Scalars['Boolean']>
  deleteMeetingWhite?: Maybe<Scalars['Boolean']>
  disableMeetingWhite?: Maybe<Scalars['Boolean']>
  updateCompanyGroup?: Maybe<BaseResponse>
  generateAlipayUrl?: Maybe<BaseResponse>
  saveArchive: Scalars['Boolean']
  bindArchivesToMeeting: BaseResponse
  unbindArchiveFromMeeting: BaseResponse
  disableArchive?: Maybe<BaseResponse>
  enableArchive?: Maybe<BaseResponse>
  hengrui_logBalanceRecord: Scalars['Boolean']
  preImportHansohBudget?: Maybe<Scalars['JSON']>
  importHansohBudget?: Maybe<BaseResponse>
  preImportProcessBudget?: Maybe<Scalars['JSON']>
  importProcessBudget?: Maybe<BaseResponse>
  adjustProcessBudget?: Maybe<BaseResponse>
  adjustPersonalBudget?: Maybe<BaseResponse>
  swapPersonalBudget?: Maybe<BaseResponse>
  preImportHansohInPlanBudget?: Maybe<Scalars['JSON']>
  importHansohInPlanBudget?: Maybe<BaseResponse>
  adjustInPlanBudget?: Maybe<BaseResponse>
  preImportHansohOutPlanBudget?: Maybe<Scalars['JSON']>
  importHansohOutPlanBudget?: Maybe<BaseResponse>
  adjustOutPlanBudget?: Maybe<BaseResponse>
  preImportHansohCostCenterBudget?: Maybe<Scalars['JSON']>
  importHansohCostCenterBudget?: Maybe<BaseResponse>
  adjustCostCenterBudget?: Maybe<BaseResponse>
  createContent: Scalars['Boolean']
  updateContent: Scalars['Boolean']
  deleteContent: Scalars['Boolean']
  shareContent: Scalars['Boolean']
  commentContent: Scalars['Boolean']
  readContent: Scalars['Boolean']
  likeContent: Scalars['Boolean']
  dislikeContent: Scalars['Boolean']
  upDoctorSendCode: Scalars['String']
  upDoctorSendCodeByAttenderId: Scalars['String']
  upDoctorLogin: BmsLoginResponse
  upDoctorLoginWithCode: Scalars['String']
  upDoctorLoginWithCodeAndAttenderId: Scalars['String']
  doctorCompleteTask?: Maybe<BaseResponse>
  removeDoctorTask?: Maybe<BaseResponse>
  saveDocumentReviewContents?: Maybe<BaseResponse>
  upDoctorLoginWithWechatCode?: Maybe<Scalars['String']>
  unBindAccountWechatRelation: Scalars['Boolean']
  hansohSalesCreatePlan?: Maybe<Scalars['String']>
  hansohSalesEditPlan?: Maybe<Scalars['String']>
  hansohSalesDeletePlan?: Maybe<BaseResponse>
  hansohSalesSubmitPlan?: Maybe<BaseResponse>
  hansohSalesSubmitOrder?: Maybe<BaseResponse>
  hansohSalesCreateOrder?: Maybe<Scalars['String']>
  hansohSalesEditOrder?: Maybe<Scalars['String']>
  hansohSalesDeleteOrder?: Maybe<BaseResponse>
  hansohSalesCancelOrder?: Maybe<BaseResponse>
  hansohSalesSelfPaymentOrder?: Maybe<BaseResponse>
  hansohSalesUpdateEmail?: Maybe<Scalars['Boolean']>
  hansohSalesConfirmOrder?: Maybe<BaseResponse>
  createHansohSalesClaim?: Maybe<Scalars['String']>
  removeHansohSalesClaim?: Maybe<BaseResponse>
  updateHansohSalesClaim?: Maybe<BaseResponse>
  relateOrdersToHansohSalesClaim?: Maybe<BaseResponse>
  removeOrdersFromHansohSalesClaim?: Maybe<BaseResponse>
  submitHansohSalesClaim?: Maybe<BaseResponse>
  hansohSalesAddRecommendVendor?: Maybe<Scalars['String']>
  hansohSalesPayOrderByAlipay?: Maybe<Scalars['String']>
  hansohSalesWithdraw?: Maybe<Scalars['Boolean']>
  recommendShopOcr?: Maybe<RecommendShopOcrResultInfo>
  shoppingReceiptOcr?: Maybe<ShoppingReceiptOcrResultInfo>
  paymentRecordOcr?: Maybe<PaymentRecordOcrResultInfo>
  terminateAlipayRule?: Maybe<BaseResponse>
  unbindAlipayRelationship?: Maybe<BaseResponse>
  hansohSalesCheckInvoices?: Maybe<Scalars['JSON']>
  hansohSalesUploadInvoicesAndTickets?: Maybe<Array<UploadInvoicesFailed>>
  hansohSalesUploadInvoicesAndTicketsNext?: Maybe<BaseResponse>
  hansohSalesUploadInvoicesAndTicketsNextNew?: Maybe<BaseResponse>
  hengruiImage_sendCode: Scalars['String']
  hengruiImage_login: BmsLoginResponse
  hengruiImage_loginWithCode: Scalars['String']
  hengruiImage_logOut: Scalars['String']
  hengruiImage_verifyDoctorBankInfo: Scalars['Boolean']
  hengruiImage_verifyDoctorBankInfoNew: VerifyDoctorBankInfoResponse
  hengruiImage_completeDoctorCerts: Scalars['Boolean']
  hengruiImage_batchMarkBillStatus?: Maybe<Scalars['String']>
  hengruiImage_exportMeeting: Scalars['String']
  hengruiImage_addTemporaryAttender: Scalars['Boolean']
  hengruiImage_startHRMeetingSettlement?: Maybe<Scalars['Boolean']>
  hengruiImage_updateMeeting?: Maybe<StandardMeetingInfo>
  hengruiImage_createMeeting?: Maybe<StandardMeetingInfo>
  hengruiImage_submitMeeting: Scalars['Boolean']
  hengruiImage_confirmMeeting: Scalars['Boolean']
  hengruiImage_retreatMeeting: Scalars['Boolean']
  meetingWithdraw?: Maybe<Scalars['Boolean']>
  hengruiImage_deleteMeeting?: Maybe<BaseResponse>
  hengruiImage_uploadSpeakerMaterials: Scalars['Boolean']
  hengruiImage_uploadSpeakerJSONMaterials: Scalars['Boolean']
  hengruiImage_uploadAttenderTaxReceipts: Scalars['Boolean']
  hengruiImage_uploadAttenderPaymentVoucher: Scalars['Boolean']
  hengruiImage_uploadAttenderInvoices: Scalars['Boolean']
  hengruiImage_uploadSchedulePics: Scalars['Boolean']
  hengruiImage_uploadActiveVideoMaterials: Scalars['Boolean']
  hengruiImage_uploadActiveCoursewareMaterials: Scalars['Boolean']
  hengruiImage_uploadMaterials: Scalars['Boolean']
  hengruiImage_addDoctors: Scalars['Boolean']
  hengruiImage_addDoctorsWithError: BaseResponse
  hengruiImage_removeDoctors: Scalars['Boolean']
  hengruiImage_removeSpeakers: Scalars['Boolean']
  hengruiImage_updateAttenderInfo?: Maybe<BaseResponse>
  hengruiImage_addCost: Scalars['Boolean']
  hengruiImage_addCheckReport: Scalars['Boolean']
  hengruiImage_setDoctorBeenInvited?: Maybe<Scalars['Boolean']>
  hengruiImage_doctorReceiveInvitation?: Maybe<Scalars['Boolean']>
  hengruiImage_doctorReceiveInvitationWithoutMeeting?: Maybe<Scalars['Boolean']>
  hengruiImage_generateContract?: Maybe<Scalars['String']>
  hengruiImage_generateJunziqianUrlNext?: Maybe<Scalars['JSON']>
  /** @deprecated use hengruiImage_generateJunziqianUrlNext */
  hengruiImage_generateJunziqianUrl?: Maybe<Scalars['String']>
  hengruiImage_editDoctor: Scalars['Boolean']
  hengruiImage_markHRMeetingPaymentFinished: Scalars['Boolean']
  hengruiImage_getSettlementZipUrl?: Maybe<Scalars['String']>
  sendTaskInvitationMessage?: Maybe<Scalars['String']>
  retreatAttenderTask?: Maybe<BaseResponse>
  sendMiniProgramTaskInvitationMessage?: Maybe<Scalars['String']>
  medicalLiteratureLoginWithPwd: Scalars['String']
  hengruiImage_forwardSignatureMeeting?: Maybe<Scalars['Boolean']>
  hengruiImage_verifyDoctorCardInfo: VerifyDoctorCardInfoResponse
  hengruiImage_checkDoctorIdCardImages: Scalars['String']
  hengruiImage_saveMeetingVerificationInfo?: Maybe<Scalars['Boolean']>
  operationConferencePlatform: BaseResponse
  deleteOrderByAccountId: Scalars['Boolean']
  resumeOrderByAccountId: Scalars['Boolean']
  operateOrder: Scalars['Boolean']
  updateBudgetWhiteListByAccountId: Scalars['Boolean']
  hengruiImage_addMeetingInviator: BaseResponse
  hengruiImage_removeInviators: Scalars['Boolean']
  hengruiImage_uploadMeetingInviatorMaterials: Scalars['Boolean']
  batchSyncPropagatorAndDoctors: Scalars['Boolean']
  medicalLiteratureSendCode: Scalars['String']
  medicalLiteratureLogin: Scalars['String']
  medicalLiteratureDoctorSendCode: Scalars['String']
  medicalLiteratureDoctorLogin: Scalars['String']
  addDocument: BaseResponse
  editDocument: BaseResponse
  removeDocument: BaseResponse
  submitReview?: Maybe<BaseResponse>
  upDoctorLoginWithCodeAndAttenderInWeb: Scalars['String']
  batchInviteDoctorsToReview?: Maybe<Array<BaseResponse>>
  ratingReview?: Maybe<BaseResponse>
  payReview?: Maybe<BaseResponse>
  setReviewToSettlement?: Maybe<BaseResponse>
  updateMeetingCategoryEnabled?: Maybe<BaseResponse>
  updateMeetingCategoryTitleBlkList?: Maybe<BaseResponse>
  updateMeetingCategoryProperties?: Maybe<BaseResponse>
  toggleMeetingCategoryAutoComplete?: Maybe<BaseResponse>
  updateMeetingActivityEnabled?: Maybe<BaseResponse>
  createOrupdateBaseMeetingCategory?: Maybe<BaseResponse>
  createOrupdateBaseMeetingActivity?: Maybe<BaseResponse>
  createOrUpdateMeetingActivity?: Maybe<BaseResponse>
  createMeetingActivityTemplate?: Maybe<BaseResponse>
  addDocumentSphere?: Maybe<BaseResponse>
  pingguang_sendCode: Scalars['String']
  pingguang_login: Scalars['String']
  pingguang_lectureLogin: Scalars['String']
  pingguang_attenderLogin: Scalars['String']
  pingguang_updateMeeting?: Maybe<StandardMeetingInfo>
  pingguang_createMeeting?: Maybe<StandardMeetingInfo>
  pingguang_approveMeeting: Scalars['Boolean']
  pingguang_rejectMeeting: Scalars['Boolean']
  sendAuthMessage: Scalars['Boolean']
  salesClaimFormApproved: Scalars['Boolean']
  salesClaimFormReturn: Scalars['Boolean']
  salesClaimFormReturnPreReview: Scalars['Boolean']
  sendClaimFormEmail: BaseResponse
  salesOrderBillApproved?: Maybe<Scalars['Boolean']>
  salesOrderBillReturn?: Maybe<Scalars['Boolean']>
  sendAcademicOrderEmail: BaseResponse
  salesOrderVoucherReject?: Maybe<Scalars['Boolean']>
  upadminDistributeSalesOrder: Scalars['Boolean']
  salesPlanApproved: Scalars['Boolean']
  salesPlanReturn: Scalars['Boolean']
  normalSendCode?: Maybe<Scalars['String']>
  normalValidateCode?: Maybe<Scalars['Boolean']>
  preregisterPTXUser: BaseResponse
  upadminEditOrAddDoctor: BaseResponse
  upadminPreImportTasks?: Maybe<Scalars['JSON']>
  upadminImportTasks?: Maybe<BaseResponse>
  upadminPreImportDoctors?: Maybe<Scalars['JSON']>
  upadminImportDoctors?: Maybe<BaseResponse>
  addMeetingUserAuthority: BaseResponse
  editMeetingUserAuthority: BaseResponse
  removeMeetingUserAuthority: BaseResponse
  upadminDistributeOrder: Scalars['Boolean']
  adminSaveApprovalFlowDetail?: Maybe<Scalars['Boolean']>
  adminCreateApprovalFlow?: Maybe<Scalars['Boolean']>
  upadminFinanceApprove: Scalars['Boolean']
  upadminFinancePayToHZBank: BaseResponse
  generateInvitationSignature?: Maybe<Scalars['String']>
}

export type MutationLoginArgs = {
  password: Scalars['String']
  mobile: Scalars['String']
}

export type MutationSendVerifyCodeArgs = {
  userType: QcsfUserType
  stage: QcsfSendVerifyCodeStage
  mobile: Scalars['String']
}

export type MutationLoginWithVerifyCodeArgs = {
  userType: QcsfUserType
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationRegisterArgs = {
  inviterId?: Maybe<Scalars['String']>
  userType: QcsfUserType
  userInfo: QcsfRegisterInput
}

export type MutationSimpleRegisterDoctorArgs = {
  userInfo: QcsfRegisterInput
}

export type MutationUpdateAccountArgs = {
  account: AccountBaseInfoInput
}

export type MutationUpdateAccountFinalArgs = {
  account: AccountInfoInput
}

export type MutationUpdateAccountStatusArgs = {
  status: Scalars['String']
  accountId: Scalars['String']
}

export type MutationResetPasswordArgs = {
  companyName?: Maybe<Scalars['String']>
  newPassword: Scalars['String']
  sessionId: Scalars['String']
  code: Scalars['String']
  mobile: Scalars['String']
}

export type MutationChangeAccountDefaultChannelArgs = {
  channel: Scalars['String']
  accountId: Scalars['String']
}

export type MutationUnionLoginArgs = {
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationUploadUserCertificationImagesArgs = {
  titleImages?: Maybe<Array<Scalars['String']>>
  doctorId: Scalars['String']
  images: Array<Scalars['String']>
  type: Scalars['String']
}

export type MutationRegisterPtxUserArgs = {
  accountId: Scalars['String']
}

export type MutationBindAccountWithCodeArgs = {
  code: Scalars['String']
}

export type MutationSyncDoctorVerifyResultArgs = {
  verifyImages?: Maybe<Scalars['String']>
  verifyResult?: Maybe<Scalars['String']>
  accountId: Scalars['String']
}

export type MutationAddMeetingWhiteArgs = {
  companyId: Scalars['String']
  accountId: Scalars['String']
}

export type MutationDeleteMeetingWhiteArgs = {
  companyId: Scalars['String']
  accountId: Scalars['String']
}

export type MutationDisableMeetingWhiteArgs = {
  companyId: Scalars['String']
  accountId: Scalars['String']
}

export type MutationUpdateCompanyGroupArgs = {
  group: AccountGroupInfoInput
}

export type MutationSaveArchiveArgs = {
  archiveInfo: ArchiveCreateParams
}

export type MutationBindArchivesToMeetingArgs = {
  meetingId: Scalars['String']
  archiveIds: Array<Scalars['String']>
}

export type MutationUnbindArchiveFromMeetingArgs = {
  meetingId: Scalars['String']
  archiveId: Scalars['String']
}

export type MutationDisableArchiveArgs = {
  archiveId: Scalars['String']
}

export type MutationEnableArchiveArgs = {
  archiveId: Scalars['String']
}

export type MutationHengrui_LogBalanceRecordArgs = {
  companyId?: Maybe<Scalars['String']>
  paymentTime: Scalars['String']
  type: Scalars['String']
  amount: Scalars['Float']
  regionId: Scalars['String']
}

export type MutationPreImportHansohBudgetArgs = {
  importId?: Maybe<Scalars['String']>
  data: Array<BudgetImportData>
}

export type MutationImportHansohBudgetArgs = {
  importId: Scalars['String']
  data?: Maybe<Array<BudgetImportData>>
}

export type MutationPreImportProcessBudgetArgs = {
  importId?: Maybe<Scalars['String']>
  data: Array<ProcessBudgetImportData>
}

export type MutationImportProcessBudgetArgs = {
  importId: Scalars['String']
  data: Array<ProcessBudgetImportData>
}

export type MutationAdjustProcessBudgetArgs = {
  description?: Maybe<Scalars['String']>
  changeValue: Scalars['String']
  id: Scalars['Int']
}

export type MutationAdjustPersonalBudgetArgs = {
  operatorRemark?: Maybe<Scalars['String']>
  amount: Scalars['String']
  id: Scalars['Int']
}

export type MutationSwapPersonalBudgetArgs = {
  operatorRemark?: Maybe<Scalars['String']>
  amount: Scalars['String']
  targetAccountId: Scalars['String']
  sourceAccountId: Scalars['String']
  id: Scalars['Int']
}

export type MutationPreImportHansohInPlanBudgetArgs = {
  importId?: Maybe<Scalars['String']>
  data: Array<PersonalPlanBudgetImportData>
}

export type MutationImportHansohInPlanBudgetArgs = {
  importId: Scalars['String']
  data: Array<PersonalPlanBudgetImportData>
}

export type MutationAdjustInPlanBudgetArgs = {
  operatorRemark?: Maybe<Scalars['String']>
  amount: Scalars['String']
  id: Scalars['Int']
}

export type MutationPreImportHansohOutPlanBudgetArgs = {
  importId?: Maybe<Scalars['String']>
  data: Array<PersonalPlanBudgetImportData>
}

export type MutationImportHansohOutPlanBudgetArgs = {
  importId: Scalars['String']
  data: Array<PersonalPlanBudgetImportData>
}

export type MutationAdjustOutPlanBudgetArgs = {
  operatorRemark?: Maybe<Scalars['String']>
  amount: Scalars['String']
  id: Scalars['Int']
}

export type MutationPreImportHansohCostCenterBudgetArgs = {
  importId?: Maybe<Scalars['String']>
  data: Array<CostCenterBudgetImportData>
}

export type MutationImportHansohCostCenterBudgetArgs = {
  importId: Scalars['String']
}

export type MutationAdjustCostCenterBudgetArgs = {
  operatorRemark?: Maybe<Scalars['String']>
  amount: Scalars['String']
  id: Scalars['Int']
}

export type MutationDeleteContentArgs = {
  id: Scalars['String']
}

export type MutationShareContentArgs = {
  doctorId: Scalars['String']
  contentId: Scalars['String']
}

export type MutationCommentContentArgs = {
  openId?: Maybe<Scalars['String']>
  commentorId?: Maybe<Scalars['String']>
  comment: Scalars['String']
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationReadContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationLikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  sharerId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationDislikeContentArgs = {
  doctorId?: Maybe<Scalars['String']>
  openId?: Maybe<Scalars['String']>
  contentId: Scalars['String']
}

export type MutationUpDoctorSendCodeArgs = {
  mobile: Scalars['String']
}

export type MutationUpDoctorSendCodeByAttenderIdArgs = {
  attenderId: Scalars['String']
}

export type MutationUpDoctorLoginArgs = {
  pwd: Scalars['String']
  mobile: Scalars['String']
}

export type MutationUpDoctorLoginWithCodeArgs = {
  code?: Maybe<Scalars['String']>
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationUpDoctorLoginWithCodeAndAttenderIdArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  attenderId: Scalars['String']
}

export type MutationDoctorCompleteTaskArgs = {
  answers: Scalars['String']
  taskId: Scalars['String']
}

export type MutationRemoveDoctorTaskArgs = {
  taskId: Scalars['String']
}

export type MutationSaveDocumentReviewContentsArgs = {
  review: SubmitReviewInput
  documentId: Scalars['String']
  taskId: Scalars['String']
}

export type MutationUpDoctorLoginWithWechatCodeArgs = {
  appName: Scalars['String']
  code: Scalars['String']
}

export type MutationUnBindAccountWechatRelationArgs = {
  appName: Scalars['String']
  accountId: Scalars['String']
}

export type MutationHansohSalesCreatePlanArgs = {
  plan: HansohSalesPlanCreateRequest
}

export type MutationHansohSalesEditPlanArgs = {
  plan: HansohSalesPlanEditRequest
}

export type MutationHansohSalesDeletePlanArgs = {
  planId: Scalars['String']
}

export type MutationHansohSalesSubmitPlanArgs = {
  planId: Scalars['String']
}

export type MutationHansohSalesSubmitOrderArgs = {
  orderId: Scalars['String']
}

export type MutationHansohSalesCreateOrderArgs = {
  order: CreateHansohSalesOrderInput
}

export type MutationHansohSalesEditOrderArgs = {
  order: CreateHansohSalesOrderInput
}

export type MutationHansohSalesDeleteOrderArgs = {
  orderId: Scalars['String']
}

export type MutationHansohSalesCancelOrderArgs = {
  orderId: Scalars['String']
}

export type MutationHansohSalesSelfPaymentOrderArgs = {
  orderId: Scalars['String']
}

export type MutationHansohSalesUpdateEmailArgs = {
  email: Scalars['String']
}

export type MutationHansohSalesConfirmOrderArgs = {
  targetAddress?: Maybe<Scalars['String']>
  resourceName: Scalars['String']
  amount: Scalars['Float']
  peopleNum: Scalars['Int']
  resourceId: Scalars['String']
  orderId: Scalars['String']
}

export type MutationCreateHansohSalesClaimArgs = {
  budgetChannel: Scalars['Int']
  name: Scalars['String']
}

export type MutationRemoveHansohSalesClaimArgs = {
  claimId: Scalars['String']
}

export type MutationUpdateHansohSalesClaimArgs = {
  name: Scalars['String']
  claimId: Scalars['String']
}

export type MutationRelateOrdersToHansohSalesClaimArgs = {
  orderIds: Array<Scalars['String']>
  claimId: Scalars['String']
}

export type MutationRemoveOrdersFromHansohSalesClaimArgs = {
  orderIds: Array<Scalars['String']>
  claimId: Scalars['String']
}

export type MutationSubmitHansohSalesClaimArgs = {
  claimId: Scalars['String']
}

export type MutationHansohSalesAddRecommendVendorArgs = {
  payload: UnsignedVendorAddInput
  type: Scalars['String']
}

export type MutationHansohSalesPayOrderByAlipayArgs = {
  orderId: Scalars['String']
}

export type MutationHansohSalesWithdrawArgs = {
  reason?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['Int']>
  id: Scalars['String']
}

export type MutationRecommendShopOcrArgs = {
  fileUrl: Scalars['String']
}

export type MutationShoppingReceiptOcrArgs = {
  fileUrl: Scalars['String']
}

export type MutationPaymentRecordOcrArgs = {
  fileUrl: Scalars['String']
}

export type MutationTerminateAlipayRuleArgs = {
  orderId: Scalars['String']
}

export type MutationUnbindAlipayRelationshipArgs = {
  accountId: Scalars['String']
}

export type MutationHansohSalesCheckInvoicesArgs = {
  invoices: Array<Scalars['String']>
  orderId: Scalars['String']
}

export type MutationHansohSalesUploadInvoicesAndTicketsArgs = {
  type: Scalars['Int']
  tickets: Array<Scalars['String']>
  invoices: Array<Scalars['String']>
  orderId: Scalars['String']
}

export type MutationHansohSalesUploadInvoicesAndTicketsNextArgs = {
  type: Scalars['Int']
  invoiceCertifications?: Maybe<Array<Scalars['String']>>
  bills: Array<Scalars['String']>
  tickets: Array<Scalars['String']>
  invoices: Scalars['JSON']
  orderId: Scalars['String']
}

export type MutationHansohSalesUploadInvoicesAndTicketsNextNewArgs = {
  type: Scalars['Int']
  invoiceCertifications?: Maybe<Array<Scalars['String']>>
  billsCheckRes?: Maybe<Scalars['JSON']>
  bills: Array<Scalars['String']>
  ticketsCheckRes?: Maybe<Scalars['JSON']>
  tickets: Array<Scalars['String']>
  invoices: Scalars['JSON']
  orderId: Scalars['String']
}

export type MutationHengruiImage_SendCodeArgs = {
  mobile: Scalars['String']
}

export type MutationHengruiImage_LoginArgs = {
  pwd: Scalars['String']
  mobile: Scalars['String']
}

export type MutationHengruiImage_LoginWithCodeArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationHengruiImage_VerifyDoctorBankInfoArgs = {
  mobile?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
  doctorIdCardImages?: Maybe<Array<Scalars['String']>>
  doctorAccountId?: Maybe<Scalars['String']>
  bankCardNo?: Maybe<Scalars['String']>
  name: Scalars['String']
  idCardNo: Scalars['String']
}

export type MutationHengruiImage_VerifyDoctorBankInfoNewArgs = {
  doctorAccountId: Scalars['String']
  channel?: Maybe<Scalars['String']>
  doctorIdCardImages?: Maybe<Array<Scalars['String']>>
  bankCardNo: Scalars['String']
  idCardNo?: Maybe<Scalars['String']>
  mobile: Scalars['String']
  name: Scalars['String']
}

export type MutationHengruiImage_CompleteDoctorCertsArgs = {
  needRefreshStatus?: Maybe<Scalars['Boolean']>
  meetingId: Scalars['String']
  doctorInfo: DoctorCompleteCertInfo
  doctorAccountId: Scalars['String']
}

export type MutationHengruiImage_BatchMarkBillStatusArgs = {
  data: Array<StandardMeetingBillMarkStatus>
}

export type MutationHengruiImage_ExportMeetingArgs = {
  pageQuery?: Maybe<PageSearchStandardMeetingInput>
}

export type MutationHengruiImage_AddTemporaryAttenderArgs = {
  attender: AddAttenderInput
}

export type MutationHengruiImage_StartHrMeetingSettlementArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UpdateMeetingArgs = {
  meetingInfo: UpdateHengruiMeeting
}

export type MutationHengruiImage_CreateMeetingArgs = {
  meetingInfo: CreateHengruiMeeting
}

export type MutationHengruiImage_SubmitMeetingArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_ConfirmMeetingArgs = {
  approveUser?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RetreatMeetingArgs = {
  approveUser?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationMeetingWithdrawArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_DeleteMeetingArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadSpeakerMaterialsArgs = {
  lectureCoursewares?: Maybe<Array<Scalars['String']>>
  frontVideoUrls?: Maybe<Array<Scalars['String']>>
  frontPicUrls?: Maybe<Array<Scalars['String']>>
  panoramicPicUrls?: Maybe<Array<Scalars['String']>>
  materials: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadSpeakerJsonMaterialsArgs = {
  materials: Array<Scalars['JSON']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderTaxReceiptsArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderPaymentVoucherArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadAttenderInvoicesArgs = {
  urls: Array<Scalars['String']>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadSchedulePicsArgs = {
  panoramicPicUrls?: Maybe<Array<Scalars['String']>>
  signPicUrls?: Maybe<Array<Scalars['String']>>
  schedulePicUrls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadActiveVideoMaterialsArgs = {
  activeEndVideos?: Maybe<Array<Scalars['String']>>
  activeStartVideos?: Maybe<Array<Scalars['String']>>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadActiveCoursewareMaterialsArgs = {
  activeCoursewares?: Maybe<Array<Scalars['String']>>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UploadMaterialsArgs = {
  materialUrls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddDoctorsArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  doctorInfo: HengruiMeetingSpeakerInput
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddDoctorsWithErrorArgs = {
  documentIds?: Maybe<Array<Scalars['String']>>
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  doctorInfo: HengruiMeetingSpeakerInput
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RemoveDoctorsArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RemoveSpeakersArgs = {
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_UpdateAttenderInfoArgs = {
  increasingDocumentIds?: Maybe<Array<Scalars['String']>>
  increasingAmount?: Maybe<Scalars['Int']>
  templateCode: Scalars['String']
  attenderId: Scalars['String']
  doctorAccountId: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddCostArgs = {
  costs: Array<SaveStandardMeetingCostInput>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_AddCheckReportArgs = {
  urls: Array<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_SetDoctorBeenInvitedArgs = {
  doctorType: StandardMeetingTypeAttenderType
  meetingId: Scalars['String']
  doctorId: Scalars['String']
}

export type MutationHengruiImage_DoctorReceiveInvitationArgs = {
  doctorType: StandardMeetingTypeAttenderType
  meetingId: Scalars['String']
  doctorId: Scalars['String']
}

export type MutationHengruiImage_DoctorReceiveInvitationWithoutMeetingArgs = {
  doctorId: Scalars['String']
}

export type MutationHengruiImage_GenerateContractArgs = {
  inviterId: Scalars['String']
  meetingId: Scalars['String']
  signerId: Scalars['String']
}

export type MutationHengruiImage_GenerateJunziqianUrlNextArgs = {
  meetingId?: Maybe<Scalars['String']>
  returnUrl?: Maybe<Scalars['String']>
  channel?: Maybe<MeetingSignChannel>
  accountId: Scalars['String']
}

export type MutationHengruiImage_GenerateJunziqianUrlArgs = {
  channel?: Maybe<MeetingSignChannel>
  accountId: Scalars['String']
}

export type MutationHengruiImage_EditDoctorArgs = {
  doctor: AddAttenderInput
}

export type MutationHengruiImage_MarkHrMeetingPaymentFinishedArgs = {
  meetingId: Scalars['String']
}

export type MutationHengruiImage_GetSettlementZipUrlArgs = {
  meetingId: Scalars['String']
}

export type MutationSendTaskInvitationMessageArgs = {
  hash?: Maybe<Scalars['String']>
  taskUrl: Scalars['String']
  activityName: Scalars['String']
  doctorAccountId: Scalars['String']
}

export type MutationRetreatAttenderTaskArgs = {
  reason?: Maybe<Scalars['String']>
  taskId: Scalars['String']
}

export type MutationSendMiniProgramTaskInvitationMessageArgs = {
  appName: Scalars['String']
  activityName: Scalars['String']
  doctorAccountId: Scalars['String']
}

export type MutationMedicalLiteratureLoginWithPwdArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>
  password: Scalars['String']
  mobile: Scalars['String']
}

export type MutationHengruiImage_ForwardSignatureMeetingArgs = {
  insertPosition?: Maybe<Scalars['String']>
  approveUser?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationHengruiImage_VerifyDoctorCardInfoArgs = {
  mobile: Scalars['String']
  name: Scalars['String']
  idCardNo: Scalars['String']
}

export type MutationHengruiImage_CheckDoctorIdCardImagesArgs = {
  channel: Scalars['String']
  doctorIdCardImages: Array<Scalars['String']>
  name: Scalars['String']
}

export type MutationHengruiImage_SaveMeetingVerificationInfoArgs = {
  approver?: Maybe<Scalars['String']>
  inviatorProperties?: Maybe<Scalars['String']>
  attenderProperties?: Maybe<Scalars['String']>
  meetingProperties?: Maybe<Scalars['String']>
  meetingType: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationOperationConferencePlatformArgs = {
  platForm: MeetingPlatformInput
}

export type MutationDeleteOrderByAccountIdArgs = {
  disableReason?: Maybe<Scalars['String']>
  accountId: Scalars['String']
}

export type MutationResumeOrderByAccountIdArgs = {
  accountId: Scalars['String']
}

export type MutationOperateOrderArgs = {
  input: OperateOrderInput
}

export type MutationUpdateBudgetWhiteListByAccountIdArgs = {
  budgetWhiteDetail: BudgetWhiteDetailInput
}

export type MutationHengruiImage_AddMeetingInviatorArgs = {
  doctorInfo: MeetingInviatorInput
  meetingId: Scalars['String']
}

export type MutationHengruiImage_RemoveInviatorsArgs = {
  id: Scalars['String']
}

export type MutationHengruiImage_UploadMeetingInviatorMaterialsArgs = {
  frontPicUrls?: Maybe<Array<Scalars['String']>>
  id: Scalars['String']
}

export type MutationBatchSyncPropagatorAndDoctorsArgs = {
  infos: Array<BatchInfo>
  companyId: Scalars['String']
}

export type MutationMedicalLiteratureSendCodeArgs = {
  isDoctor?: Maybe<Scalars['Boolean']>
  mobile: Scalars['String']
}

export type MutationMedicalLiteratureLoginArgs = {
  sessionId: Scalars['String']
  code: Scalars['String']
  mobile: Scalars['String']
}

export type MutationMedicalLiteratureDoctorSendCodeArgs = {
  mobile: Scalars['String']
}

export type MutationMedicalLiteratureDoctorLoginArgs = {
  sessionId: Scalars['String']
  code: Scalars['String']
  mobile: Scalars['String']
}

export type MutationAddDocumentArgs = {
  info: AddDocumentInput
}

export type MutationEditDocumentArgs = {
  info: AddDocumentInput
}

export type MutationRemoveDocumentArgs = {
  documentId: Scalars['String']
}

export type MutationSubmitReviewArgs = {
  review: SubmitReviewInput
}

export type MutationUpDoctorLoginWithCodeAndAttenderInWebArgs = {
  openId: Scalars['String']
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile?: Maybe<Scalars['String']>
  attenderId?: Maybe<Scalars['String']>
}

export type MutationBatchInviteDoctorsToReviewArgs = {
  documentIds: Array<Scalars['String']>
  doctorIds: Array<Scalars['String']>
}

export type MutationRatingReviewArgs = {
  remark?: Maybe<Scalars['String']>
  level: Scalars['Int']
  reviewId: Scalars['String']
}

export type MutationPayReviewArgs = {
  reviewId: Scalars['String']
}

export type MutationSetReviewToSettlementArgs = {
  reviewId: Scalars['String']
}

export type MutationUpdateMeetingCategoryEnabledArgs = {
  enabled: Scalars['Boolean']
  categoryId: Scalars['String']
}

export type MutationUpdateMeetingCategoryTitleBlkListArgs = {
  titleList: Array<Scalars['String']>
  categoryId: Scalars['String']
}

export type MutationUpdateMeetingCategoryPropertiesArgs = {
  properties?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  categoryId: Scalars['String']
}

export type MutationToggleMeetingCategoryAutoCompleteArgs = {
  categoryId: Scalars['String']
}

export type MutationUpdateMeetingActivityEnabledArgs = {
  enabled: Scalars['Boolean']
  activityId: Scalars['String']
}

export type MutationCreateOrupdateBaseMeetingCategoryArgs = {
  config: MeetingConfigAddOrUpdateInput
}

export type MutationCreateOrupdateBaseMeetingActivityArgs = {
  config: MeetingConfigAddOrUpdateInput
}

export type MutationCreateOrUpdateMeetingActivityArgs = {
  input: MeetingACtivityCreateOrUpdateInput
}

export type MutationCreateMeetingActivityTemplateArgs = {
  template: TemplateInput
}

export type MutationAddDocumentSphereArgs = {
  sphere: DocumentSphereInfoInput
}

export type MutationPingguang_SendCodeArgs = {
  stage: QcsfSendVerifyCodeStage
  mobile: Scalars['String']
}

export type MutationPingguang_LoginArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
}

export type MutationPingguang_LectureLoginArgs = {
  sessionId: Scalars['String']
  verifyCode: Scalars['String']
  mobile: Scalars['String']
  meetingId: Scalars['String']
}

export type MutationPingguang_AttenderLoginArgs = {
  titleId: Scalars['String']
  departmentId: Scalars['String']
  hospitalId: Scalars['String']
  name: Scalars['String']
  mobile: Scalars['String']
}

export type MutationPingguang_UpdateMeetingArgs = {
  meeting: CreateStandardMeetingInput
}

export type MutationPingguang_CreateMeetingArgs = {
  meeting: CreateStandardMeetingInput
}

export type MutationPingguang_ApproveMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationPingguang_RejectMeetingArgs = {
  reason?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type MutationSendAuthMessageArgs = {
  doctorId: Scalars['String']
}

export type MutationSalesClaimFormApprovedArgs = {
  orderParam?: Maybe<Array<ClaimFormRejectOrderParam>>
  reason?: Maybe<Scalars['String']>
  id: Scalars['String']
}

export type MutationSalesClaimFormReturnArgs = {
  rejectOrderParam?: Maybe<Array<ClaimFormRejectOrderParam>>
  reason?: Maybe<Scalars['String']>
  orderIds?: Maybe<Array<Scalars['String']>>
  formId: Scalars['String']
}

export type MutationSalesClaimFormReturnPreReviewArgs = {
  reason?: Maybe<Scalars['String']>
  formId: Scalars['String']
}

export type MutationSendClaimFormEmailArgs = {
  formId: Scalars['String']
}

export type MutationSalesOrderBillApprovedArgs = {
  billReviewResult?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  invoiceDesc?: Maybe<Scalars['String']>
  ticketDesc?: Maybe<Scalars['String']>
  id: Scalars['String']
}

export type MutationSalesOrderBillReturnArgs = {
  billReviewResult?: Maybe<Scalars['String']>
  reason: Scalars['String']
  id: Scalars['String']
}

export type MutationSendAcademicOrderEmailArgs = {
  orderId: Scalars['String']
}

export type MutationSalesOrderVoucherRejectArgs = {
  id: Scalars['String']
}

export type MutationUpadminDistributeSalesOrderArgs = {
  accountId?: Maybe<Scalars['String']>
}

export type MutationSalesPlanApprovedArgs = {
  planId: Scalars['String']
}

export type MutationSalesPlanReturnArgs = {
  reason?: Maybe<Scalars['String']>
  planId: Scalars['String']
}

export type MutationNormalSendCodeArgs = {
  mobile: Scalars['String']
}

export type MutationNormalValidateCodeArgs = {
  code: Scalars['String']
  sessionId: Scalars['String']
  mobile: Scalars['String']
}

export type MutationPreregisterPtxUserArgs = {
  idNo: Scalars['String']
  attenderId: Scalars['String']
}

export type MutationUpadminEditOrAddDoctorArgs = {
  doctor: EditDoctorInfo
}

export type MutationUpadminPreImportTasksArgs = {
  companyId: Scalars['String']
  importId?: Maybe<Scalars['String']>
  data: Array<HrTaskExcelImportInput>
}

export type MutationUpadminImportTasksArgs = {
  companyId: Scalars['String']
  importId?: Maybe<Scalars['String']>
  data: Array<HrTaskExcelImportInput>
}

export type MutationUpadminPreImportDoctorsArgs = {
  chanel?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  importId?: Maybe<Scalars['String']>
  data: Array<HrDoctorExcelImportInput>
}

export type MutationUpadminImportDoctorsArgs = {
  chanel?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  importId?: Maybe<Scalars['String']>
}

export type MutationAddMeetingUserAuthorityArgs = {
  userAuthorityInfo: MeetingUserAuthorityInfo
}

export type MutationEditMeetingUserAuthorityArgs = {
  userAuthorityInfo: MeetingUserAuthorityInfo
}

export type MutationRemoveMeetingUserAuthorityArgs = {
  id: Scalars['String']
}

export type MutationUpadminDistributeOrderArgs = {
  type?: Maybe<DistributeOrderType>
  accountId?: Maybe<Scalars['String']>
}

export type MutationAdminSaveApprovalFlowDetailArgs = {
  nodeGroups: Array<NodeGroupInfo>
  companyId: Scalars['String']
  targetId: Scalars['String']
}

export type MutationAdminCreateApprovalFlowArgs = {
  id: Scalars['String']
  desc: Scalars['String']
  key: Scalars['String']
  name: Scalars['String']
  companyId: Scalars['String']
}

export type MutationUpadminFinanceApproveArgs = {
  reason?: Maybe<Scalars['String']>
  result: FinanceApproveResult
  meetingId: Scalars['String']
}

export type MutationUpadminFinancePayToHzBankArgs = {
  meetingId: Scalars['String']
  attenderId: Scalars['String']
}

export type MutationGenerateInvitationSignatureArgs = {
  expire?: Maybe<Scalars['Int']>
  snapshot?: Maybe<Scalars['JSON']>
}

export type NodeGroupInfo = {
  nodeGroupId?: Maybe<Scalars['String']>
  nodeGroupName?: Maybe<Scalars['String']>
  nodeGroupTypeId?: Maybe<Scalars['String']>
  nodeGroupTypeName?: Maybe<Scalars['String']>
  nextGroupId?: Maybe<Scalars['String']>
  defNodes?: Maybe<Array<DefNodeInfo>>
}

export type NodeType = {
  __typename?: 'NodeType'
  name?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type OperateOrderInput = {
  operatorId?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
  relUserId?: Maybe<Scalars['String']>
  relMeetingId?: Maybe<Scalars['String']>
  category?: Maybe<OrderOperationCategory>
}

export type OperationLogSearchQuery = {
  meetingId?: Maybe<Scalars['String']>
}

export type OrderCategory = {
  __typename?: 'OrderCategory'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  businessCategoryCode?: Maybe<Scalars['String']>
  relResource?: Maybe<Scalars['Boolean']>
  orderConsumptionCategory?: Maybe<Array<ConsumptionCategory>>
}

export enum OrderOperationCategory {
  DeleteUser = 'DELETE_USER',
  SplitUser = 'SPLIT_USER',
  CancelOrder = 'CANCEL_ORDER'
}

export type OrderOperationLogInfo = {
  __typename?: 'OrderOperationLogInfo'
  id?: Maybe<Scalars['String']>
  operatorId?: Maybe<Scalars['String']>
  operationDate?: Maybe<Scalars['String']>
  category?: Maybe<OrderOperationCategory>
  beforeSnapshotStatus?: Maybe<Scalars['String']>
  afterSnapshotStatus?: Maybe<Scalars['String']>
  explain?: Maybe<Scalars['String']>
  operatorName?: Maybe<Scalars['String']>
}

export type OwnerLimitSearch = {
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  limitType?: Maybe<Scalars['Float']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type PageQueryArchivesRequest = {
  query?: Maybe<ArchiveSearchParams>
  pager?: Maybe<PagerInput>
}

export type PageQueryArchivesResult = {
  __typename?: 'PageQueryArchivesResult'
  items: Array<ArchiveInfo>
  pager: PagerResult
}

export type PageQueryMeetingAttenders = {
  query?: Maybe<QueryMeetingAttenders>
  pager?: Maybe<PagerInput>
}

export type PageQueryMeetingUserAuthorityInput = {
  query?: Maybe<MeetingUserAuthoritySearchQuery>
  pager?: Maybe<PagerInput>
}

export type PageQueryMeetingUserAuthorityResult = {
  __typename?: 'PageQueryMeetingUserAuthorityResult'
  items: Array<MeetingUserAuthorityDetail>
  pager: PagerResult
}

export type PageQueryOperationLogInput = {
  query?: Maybe<OperationLogSearchQuery>
  pager?: Maybe<PagerInput>
}

export type PageQueryOrderOperationLog = {
  __typename?: 'PageQueryOrderOperationLog'
  items: Array<OrderOperationLogInfo>
  pager: PagerResult
}

export type PageQueryStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchQuery>
  pager?: Maybe<PagerInput>
}

export type PageQueryStandardMeetingResult = {
  __typename?: 'PageQueryStandardMeetingResult'
  items: Array<StandardMeetingInfo>
  pager: PagerResult
}

export type PageResultMeetingAttenders = {
  __typename?: 'PageResultMeetingAttenders'
  items: Array<StandardMeetingBaseAttender>
  pager: PagerResult
}

export type PageSearchStandardMeetingInput = {
  query?: Maybe<StandardMeetingSearchInput>
  pager?: Maybe<PagerInput>
}

export type PagerInput = {
  currentPage?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type PagerResult = {
  __typename?: 'PagerResult'
  currentPage: Scalars['Int']
  pageSize: Scalars['Int']
  total: Scalars['Int']
  hasMore: Scalars['Boolean']
}

export type PaginatedAttenderTasks = {
  __typename?: 'PaginatedAttenderTasks'
  items: Array<MeetingAttenderTask>
  pager: PagerResult
}

export type PaginatedBasicInfoNode = {
  __typename?: 'PaginatedBasicInfoNode'
  items: Array<BasicInfoNode>
  pager: PagerResult
}

export type PaginatedBmsMeetingSearchH5Input = {
  query?: Maybe<BmsMeetingSearchH5Input>
  pager?: Maybe<PagerInput>
}

export type PaginatedBmsMeetingSearchInput = {
  query?: Maybe<BmsMeetingSearchInput>
  pager?: Maybe<PagerInput>
}

export type PaginatedBudgetsLog = {
  __typename?: 'PaginatedBudgetsLog'
  items: Array<ProcessBudgetLog>
  pager: PagerResult
}

export type PaginatedCarOrderResponse = {
  __typename?: 'PaginatedCarOrderResponse'
  items: Array<CarOrderInfo>
  pager: PagerResult
}

export type PaginatedCostCenterBudgetLogs = {
  __typename?: 'PaginatedCostCenterBudgetLogs'
  items: Array<CostCenterBudgetLog>
  pager: PagerResult
}

export type PaginatedCostCenterBudgetUsedDetails = {
  __typename?: 'PaginatedCostCenterBudgetUsedDetails'
  items: Array<CostCenterBudgetUsedDetail>
  pager: PagerResult
}

export type PaginatedCostCenterBudgets = {
  __typename?: 'PaginatedCostCenterBudgets'
  items: Array<CostCenterBudgetInfo>
  pager: PagerResult
}

export type PaginatedHansohBudgets = {
  __typename?: 'PaginatedHansohBudgets'
  items: Array<HansohBudget>
  pager: PagerResult
}

export type PaginatedHansohSalesOrder = {
  __typename?: 'PaginatedHansohSalesOrder'
  items: Array<HansohSalesOrder>
  pager: PagerResult
}

export type PaginatedHansohSalesPlan = {
  __typename?: 'PaginatedHansohSalesPlan'
  items: Array<HansohSalesPlan>
  pager: PagerResult
}

export type PaginatedInterventionalProjectInfo = {
  __typename?: 'PaginatedInterventionalProjectInfo'
  items: Array<InterventionalProjectInfo>
  pager: PagerResult
}

export type PaginatedInterventionalProjectParticipantsInfo = {
  __typename?: 'PaginatedInterventionalProjectParticipantsInfo'
  items: Array<InterventionProjectParticipantInfo>
  pager: PagerResult
}

export type PaginatedMeetingSettlementRequest = {
  query?: Maybe<MeetingSettlementSearch>
  pager?: Maybe<PagerInput>
}

export type PaginatedMeetingSettlementResult = {
  __typename?: 'PaginatedMeetingSettlementResult'
  items: Array<MeetingSettlement>
  pager: PagerResult
}

export type PaginatedPersonalBudgetUsedDetail = {
  __typename?: 'PaginatedPersonalBudgetUsedDetail'
  items: Array<PersonalBudgetUsedDetail>
  pager: PagerResult
}

export type PaginatedPersonalBudgetsLog = {
  __typename?: 'PaginatedPersonalBudgetsLog'
  items: Array<PersonalBudgetLog>
  pager: PagerResult
}

export type PaginatedPersonalPlanBudgetLogs = {
  __typename?: 'PaginatedPersonalPlanBudgetLogs'
  items: Array<PersonalPlanBudgetLog>
  pager: PagerResult
}

export type PaginatedPersonalPlanBudgetUsedDetails = {
  __typename?: 'PaginatedPersonalPlanBudgetUsedDetails'
  items: Array<PersonalPlanBudgetUsedDetail>
  pager: PagerResult
}

export type PaginatedPersonalPlanBudgets = {
  __typename?: 'PaginatedPersonalPlanBudgets'
  items: Array<PersonalPlanBudgetInfo>
  pager: PagerResult
}

export type PaginatedProcessBudget = {
  __typename?: 'PaginatedProcessBudget'
  items: Array<ProcessBudget>
  pager: PagerResult
}

export type PaginatedSalesClaimForm = {
  __typename?: 'PaginatedSalesClaimForm'
  items: Array<SalesClaimFormInfo>
  pager: PagerResult
}

export type PaginatedSalesOrderOperationLog = {
  __typename?: 'PaginatedSalesOrderOperationLog'
  items: Array<SalesOrderOperationLog>
  pager: PagerResult
}

export type PaginatedSalesOrderPage = {
  __typename?: 'PaginatedSalesOrderPage'
  items: Array<SalesOrderBaseInfo>
  pager: PagerResult
}

export type PaginatedSalesPlanDetail = {
  __typename?: 'PaginatedSalesPlanDetail'
  items: Array<SalesPlanDetail>
  pager: PagerResult
}

export type PaginatedUserManagementDetails = {
  __typename?: 'PaginatedUserManagementDetails'
  items: Array<UserManagementDetail>
  pager: PagerResult
}

export type PaymentRecordOcrResultInfo = {
  __typename?: 'PaymentRecordOcrResultInfo'
  shopName?: Maybe<Scalars['String']>
  consumptionDate?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  orderNumber?: Maybe<Scalars['String']>
  paymentMethod?: Maybe<Scalars['String']>
}

export type PersonalBudgetBusinessDepartment = {
  __typename?: 'PersonalBudgetBusinessDepartment'
  accountId: Scalars['String']
  businessId: Scalars['String']
  businessName: Scalars['String']
}

export type PersonalBudgetLog = {
  __typename?: 'PersonalBudgetLog'
  id: Scalars['String']
  operationDate: Scalars['String']
  BudgetAmountChanged: Scalars['String']
  BudgetAmountBefore: Scalars['String']
  increaseAmount: Scalars['String']
  usedAmount: Scalars['String']
  balanceAmountChanged: Scalars['String']
  balanceAmountBefore: Scalars['String']
  operatorRemark: Scalars['String']
  operator: Scalars['String']
  operatorAccountInfo?: Maybe<AccountInfo>
}

export type PersonalBudgetOwnerLimit = {
  __typename?: 'PersonalBudgetOwnerLimit'
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountInfo?: Maybe<AccountInfo>
  accountName?: Maybe<Scalars['String']>
  budgetType?: Maybe<Scalars['Float']>
  financeNo?: Maybe<Scalars['String']>
  budgetAmount?: Maybe<Scalars['String']>
  usedAmount?: Maybe<Scalars['String']>
  balanceAmount?: Maybe<Scalars['String']>
  deductionAmount?: Maybe<Scalars['String']>
  increaseAmount?: Maybe<Scalars['String']>
  initialBudget?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  startTimestamp?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
  month?: Maybe<Scalars['Int']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type PersonalBudgetSearch = {
  accountId?: Maybe<Scalars['String']>
  ownerLimitId?: Maybe<Scalars['String']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type PersonalBudgetSearchWithPager = {
  query?: Maybe<PersonalBudgetSearch>
  pager?: Maybe<PagerInput>
}

export type PersonalBudgetUsedDetail = {
  __typename?: 'PersonalBudgetUsedDetail'
  meetingId?: Maybe<Scalars['String']>
  meetingName?: Maybe<Scalars['String']>
  attenderName?: Maybe<Scalars['String']>
  attenderPhone?: Maybe<Scalars['String']>
  attenderFee?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountInfo?: Maybe<AccountInfo>
}

export type PersonalBudgetUsedDetailSearch = {
  type?: Maybe<Scalars['Float']>
  ownerId?: Maybe<Scalars['String']>
  budgetNumber?: Maybe<Scalars['String']>
  targetMonth?: Maybe<Scalars['String']>
}

export type PersonalBudgetUsedDetailSearchWithPager = {
  query?: Maybe<PersonalBudgetUsedDetailSearch>
  pager?: Maybe<PagerInput>
}

export type PersonalPersonalPlanBudgetInfo = {
  __typename?: 'PersonalPersonalPlanBudgetInfo'
  used?: Maybe<Scalars['Float']>
  left?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
}

export type PersonalPlanBudget = {
  __typename?: 'PersonalPlanBudget'
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['Float']>
  budgetAmount?: Maybe<Scalars['Float']>
  usedAmount?: Maybe<Scalars['Float']>
  balanceAmount?: Maybe<Scalars['Float']>
  deductionAmount?: Maybe<Scalars['Float']>
  increaseAmount?: Maybe<Scalars['Float']>
  initialBudget?: Maybe<Scalars['Float']>
  start?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['Int']>
  month?: Maybe<Scalars['Int']>
  end?: Maybe<Scalars['String']>
  budgetType?: Maybe<Scalars['Float']>
  isLock?: Maybe<Scalars['Boolean']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  creator?: Maybe<Scalars['String']>
  modifier?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  financeNo?: Maybe<Scalars['String']>
}

export type PersonalPlanBudgetAuthority = {
  __typename?: 'PersonalPlanBudgetAuthority'
  accountId: Scalars['String']
  businessId: Scalars['String']
  businessName: Scalars['String']
}

export type PersonalPlanBudgetAuthoritySearch = {
  type?: Maybe<PersonalPlanBudgetType>
}

export type PersonalPlanBudgetImportData = {
  accountName: Scalars['String']
  financeNo: Scalars['String']
  year: Scalars['String']
  monthBudget: Array<BudgetImportUnit>
  operationRemark?: Maybe<Scalars['String']>
}

export type PersonalPlanBudgetInfo = {
  __typename?: 'PersonalPlanBudgetInfo'
  budgetId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  financeNo?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  lastMonthAmount?: Maybe<Scalars['String']>
  lastMonthBalanceAmount?: Maybe<Scalars['String']>
  currentMonthAmount?: Maybe<Scalars['String']>
  currentMonthIncreaseAmount?: Maybe<Scalars['String']>
  currentMonthUsedAmount?: Maybe<Scalars['String']>
  nextMonthAmount?: Maybe<Scalars['String']>
}

export type PersonalPlanBudgetLog = {
  __typename?: 'PersonalPlanBudgetLog'
  id: Scalars['String']
  budgetId: Scalars['String']
  accountId: Scalars['String']
  budgetAmountChanged: Scalars['Float']
  budgetAmountBefore: Scalars['Float']
  increaseAmount: Scalars['Float']
  usedAmount: Scalars['Float']
  balanceAmountChanged: Scalars['Float']
  balanceAmountBefore: Scalars['Float']
  remark: Scalars['String']
  operator: Scalars['String']
  operatorAccountInfo?: Maybe<AccountInfo>
  operationTime: Scalars['String']
}

export type PersonalPlanBudgetLogSearch = {
  accountId?: Maybe<Scalars['String']>
  budgetId?: Maybe<Scalars['String']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type PersonalPlanBudgetLogSearchWithPager = {
  query?: Maybe<PersonalPlanBudgetLogSearch>
  pager?: Maybe<PagerInput>
}

export type PersonalPlanBudgetSearch = {
  businessIds?: Maybe<Array<Scalars['String']>>
  keyword?: Maybe<Scalars['String']>
}

export type PersonalPlanBudgetSearchWithPager = {
  query?: Maybe<PersonalPlanBudgetSearch>
  pager?: Maybe<PagerInput>
}

export enum PersonalPlanBudgetType {
  Personal = 'PERSONAL',
  ExtraPersonal = 'EXTRA_PERSONAL',
  Costcenter = 'COSTCENTER'
}

export type PersonalPlanBudgetUsedDetail = {
  __typename?: 'PersonalPlanBudgetUsedDetail'
  formCategory: Scalars['String']
  consumptionCategoryCode: Scalars['String']
  targetName: Scalars['String']
  accountId: Scalars['String']
  accountName: Scalars['String']
  accountMobile: Scalars['String']
  planAmount: Scalars['String']
  actualAmount: Scalars['String']
  claimAmount: Scalars['String']
  deductionAmount: Scalars['String']
  financialDeductionAmount: Scalars['String']
  submitTime: Scalars['String']
  orderId: Scalars['String']
  budgetUsedConsumptionCategory?: Maybe<OrderCategory>
}

export type PersonalPlanBudgetUsedDetailSearch = {
  companyId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  targetMonth?: Maybe<Scalars['String']>
  type?: Maybe<PersonalPlanBudgetType>
}

export type PersonalPlanBudgetUsedDetailSearchWithPager = {
  query?: Maybe<PersonalPlanBudgetUsedDetailSearch>
  pager?: Maybe<PagerInput>
}

export type PersonalPlanMonthBudgetSearch = {
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  budgetType?: Maybe<Scalars['Float']>
  startTimestamp?: Maybe<Scalars['String']>
  endTimestamp?: Maybe<Scalars['String']>
}

export type ProcessBudget = {
  __typename?: 'ProcessBudget'
  id: Scalars['String']
  budgetNumber: Scalars['String']
  budgetAmount: Scalars['String']
  usedFee: Scalars['String']
  balanceFee: Scalars['String']
}

export type ProcessBudgetImportData = {
  number: Scalars['String']
  amount: Scalars['String']
  operationRemark?: Maybe<Scalars['String']>
}

export type ProcessBudgetLog = {
  __typename?: 'ProcessBudgetLog'
  operationDate: Scalars['String']
  changeValue: Scalars['String']
  snapshotBudgetAmount: Scalars['String']
  snapshotUsedFee: Scalars['String']
  snapshotBalanceFee: Scalars['String']
  currentBalanceFee: Scalars['String']
  description: Scalars['String']
  operator: Scalars['String']
  operatorName: Scalars['String']
}

export type ProcessBudgetSearch = {
  number?: Maybe<Scalars['String']>
}

export type ProcessBudgetSearchWithPager = {
  query?: Maybe<ProcessBudgetSearch>
  pager?: Maybe<PagerInput>
}

export type ProductInfo = {
  __typename?: 'ProductInfo'
  id: Scalars['String']
  name: Scalars['String']
  isOtc?: Maybe<Scalars['Boolean']>
}

export type ProductInfoInput = {
  id: Scalars['String']
  name: Scalars['String']
  isOtc?: Maybe<Scalars['Boolean']>
}

export enum ProjectApprovalStatus {
  WaitForMyApproving = 'WAIT_FOR_MY_APPROVING',
  MyApproved = 'MY_APPROVED'
}

export enum ProjectBizStatus {
  Completed = 'COMPLETED',
  Incompleted = 'INCOMPLETED'
}

export type ProjectCategory = {
  __typename?: 'ProjectCategory'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  describe?: Maybe<Scalars['String']>
}

export type ProjectProduction = {
  __typename?: 'ProjectProduction'
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  children?: Maybe<Array<ProjectProduction>>
}

export type ProjectReviewBase = {
  __typename?: 'ProjectReviewBase'
  id?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  nextPlan?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  conceptChange?: Maybe<Scalars['String']>
  feedback?: Maybe<Scalars['String']>
  projectId: Scalars['String']
  projectScheduleId: Scalars['String']
}

export type ProjectReviewInfo = {
  __typename?: 'ProjectReviewInfo'
  id?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  nextPlan?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  conceptChange?: Maybe<Scalars['String']>
  feedback?: Maybe<Scalars['String']>
  projectId: Scalars['String']
  projectScheduleId: Scalars['String']
}

export type ProjectReviewInput = {
  id?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  nextPlan?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  conceptChange?: Maybe<Scalars['String']>
  feedback?: Maybe<Scalars['String']>
  projectId: Scalars['String']
  projectScheduleId: Scalars['String']
}

export type QcsfRegisterInput = {
  mobile: Scalars['String']
  cnName: Scalars['String']
  cityId?: Maybe<Scalars['String']>
  provinceId?: Maybe<Scalars['String']>
  hospitalId?: Maybe<Scalars['String']>
  departmentId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  certPictures?: Maybe<Array<Scalars['String']>>
  targetProvinceIds?: Maybe<Array<Scalars['String']>>
  targetCityIds?: Maybe<Array<Scalars['String']>>
}

/** login: 登录, register: 注册 */
export enum QcsfSendVerifyCodeStage {
  Login = 'LOGIN',
  Register = 'REGISTER'
}

/** doctor: 老师, propagator: 代表 */
export enum QcsfUserType {
  Doctor = 'DOCTOR',
  Propagator = 'PROPAGATOR'
}

export type Query = {
  __typename?: 'Query'
  insideAttenderList: MemberPaginatedResponse
  outsideAttenderList: MemberPaginatedResponse
  currentUser?: Maybe<AccountInfo>
  queryUser?: Maybe<AccountInfo>
  queryTargetRegionTree: Array<TargetRegionNodeInfo>
  queryTargetRegions: Array<TargetRegionNodeBaseInfo>
  queryDoctorAccountAttrConfigs: Array<AccountGroupInfo>
  queryCompanyGroup: Array<AccountGroupInfo>
  queryCompanyAccountsByKeyword: Array<AccountBaseInfo>
  queryCostCenterByCompany?: Maybe<Array<AccountGroupInfo>>
  queryArchiveList: PageQueryArchivesResult
  queryArchiveDetail?: Maybe<ArchiveInfo>
  getArchiveProductsList?: Maybe<Array<ArchiveTagInfo>>
  getArchiveSTSInfo?: Maybe<Scalars['JSON']>
  getArchiveSignedUrl?: Maybe<Scalars['String']>
  getUploadAdminPageUrl?: Maybe<Scalars['String']>
  searchDoctorAttenderTasks?: Maybe<DoctorAttenderTasks>
  hengrui_queryBalancePoolList: Array<BalancePoolDetail>
  pageSearchHansohBudget?: Maybe<PaginatedHansohBudgets>
  pageSearchProcessBudget?: Maybe<PaginatedProcessBudget>
  checkPersonalBudget?: Maybe<LimitQueryResult>
  queryPersonalBudgetBusinessDepartments?: Maybe<
    Array<PersonalBudgetBusinessDepartment>
  >
  searchBudgetBackStage?: Maybe<PaginatedHansohBudgets>
  queryProcessBudgetDetail?: Maybe<ProcessBudget>
  queryProcessBudgetLog?: Maybe<PaginatedBudgetsLog>
  queryPersonalBudgetDetail?: Maybe<PersonalBudgetOwnerLimit>
  queryPersonalBudgetLog?: Maybe<PaginatedPersonalBudgetsLog>
  queryLimitSwapPeople?: Maybe<Array<LimitSwapPeople>>
  queryUsedDetail?: Maybe<PaginatedPersonalBudgetUsedDetail>
  searchOwnerLimit?: Maybe<PersonalBudgetOwnerLimit>
  pageUserManagement?: Maybe<PaginatedUserManagementDetails>
  searchInPlanBudget?: Maybe<PaginatedPersonalPlanBudgets>
  queryInPlanBudgetDetail?: Maybe<PersonalPlanBudget>
  queryInPlanBudgetLog?: Maybe<PaginatedPersonalPlanBudgetLogs>
  queryPlanBudgetUsedDetail?: Maybe<PaginatedPersonalPlanBudgetUsedDetails>
  searchInPlanBudgetByMonth?: Maybe<PersonalPlanBudget>
  getBudgetAuthority?: Maybe<Array<PersonalPlanBudgetAuthority>>
  searchOutPlanBudget?: Maybe<PaginatedPersonalPlanBudgets>
  queryOutPlanBudgetDetail?: Maybe<PersonalPlanBudget>
  queryOutPlanBudgetLog?: Maybe<PaginatedPersonalPlanBudgetLogs>
  searchOutPlanBudgetByMonth?: Maybe<PersonalPlanBudget>
  searchCostCenterBudget?: Maybe<PaginatedCostCenterBudgets>
  queryCostCenterBudgetDetail?: Maybe<CostCenterBudget>
  queryCostCenterBudgetLog?: Maybe<PaginatedCostCenterBudgetLogs>
  queryCostCenterBudgetUsedDetail?: Maybe<PaginatedCostCenterBudgetUsedDetails>
  searchCostCenterBudgetByYear?: Maybe<CostCenterBudget>
  searchCostCenterYearBudgetsByGroupIds?: Maybe<Array<CostCenterBudget>>
  carOrder?: Maybe<CarOrderInfo>
  cityTree: Scalars['JSON']
  cities: Array<CityInfo>
  contentList?: Maybe<ContentListQueryResult>
  contentDetail?: Maybe<ContentInfo>
  contentActionList: Array<ContentActionInfo>
  queryUpDoctorMobileByAttenderId?: Maybe<Scalars['String']>
  doctorTasks?: Maybe<PaginatedAttenderTasks>
  doctorTask?: Maybe<MeetingAttenderTask>
  hasUnfinishedTasks?: Maybe<Scalars['Boolean']>
  queryTaskDocumentReviewContent: Array<DocumentReviewContentInfo>
  getRecordByTaskId: Array<AuditRecordResult>
  queryTaskLogByTaskId: Array<AttenderTaskLog>
  hansohSalesPlans?: Maybe<PaginatedHansohSalesPlan>
  hansohSalesPlanApprovals?: Maybe<Array<ApprovalUnion>>
  hansohSalesPlanDetail?: Maybe<HansohSalesPlan>
  hansohSalesOrders?: Maybe<PaginatedHansohSalesOrder>
  hansohSalesOrderDetail?: Maybe<HansohSalesOrder>
  hansohSalesClaims?: Maybe<PaginatedSalesClaimForm>
  hansohSalesClaimDetail?: Maybe<SalesClaimFormInfo>
  hansohSalesUnsignedRestaurant?: Maybe<UnsignedRestaurant>
  hansohSalesUnsignedStore?: Maybe<UnsignedStore>
  hansohSalesConsumptionCategories?: Maybe<Array<ConsumptionCategory>>
  hansohSalesConferenceCategories?: Maybe<Array<BaseCategoryType>>
  hansohSalesConsumptionCostItems?: Maybe<Array<BaseCategoryType>>
  hansohSalesCurrentMonthPersonalPlanBudgetBudget?: Maybe<PersonalPersonalPlanBudgetInfo>
  hansohSalesOrderInvoices?: Maybe<Array<HansohOrderInvoice>>
  hansohSalesBusinessCategorys?: Maybe<Array<BusinessCategory>>
  hansohSalesOrderCategorys?: Maybe<Array<OrderCategory>>
  hengruiImage_doctorHasCompeleteCert: Scalars['Boolean']
  hengruiImage_meetingList: PageQueryStandardMeetingResult
  hengruiImage_interProjectType: Array<Scalars['String']>
  hengruiImage_searchMeeting: PageQueryStandardMeetingResult
  hengruiImage_meetingDetail?: Maybe<StandardMeetingInfo>
  hengruiImage_doctors: MemberPaginatedResponse
  hengruiImage_myApprovals: PageQueryStandardMeetingResult
  hengruiImage_departments: GroupPaginationRes
  hengruiImage_titles: GroupPaginationRes
  hengruiImage_isUserJunziqianSigned?: Maybe<Scalars['Boolean']>
  hengruiImage_meetingCount: Scalars['JSON']
  hengruiImage_meetingTypes: Scalars['JSON']
  hengruiImage_approvalCount: Scalars['JSON']
  hengruiImage_statusMap: Array<HrMeetingStatusOption>
  hengruiImage_regions: Array<AccountGroupInfo>
  hengruiImage_attenderContract?: Maybe<Scalars['String']>
  hengruiImage_userCostCenter?: Maybe<Array<HrCostCenter>>
  hengruiImage_attenderPaymentDetail?: Maybe<Scalars['JSON']>
  hengruiImage_vendors?: Maybe<Array<CompanyInfo>>
  hengruiImage_checkOwnerLimit?: Maybe<LimitQueryResult>
  hengruiImage_checkAddDoctorLimit?: Maybe<LimitQueryResult>
  queryAttenderTasks?: Maybe<PaginatedAttenderTasks>
  getWechatUrlLink?: Maybe<Scalars['String']>
  queryMeetingApprovalFlows?: Maybe<Array<ApproveInfo>>
  hengruiImage_approveUser?: Maybe<Array<Scalars['String']>>
  hengruiImage_searchMeetingVerificationInfo?: Maybe<
    Array<MeetingApprovalRecordData>
  >
  hengruiImage_conferencePlatforms?: Maybe<Array<Scalars['String']>>
  queryAllConferencePlatforms?: Maybe<Array<MeetingPlatform>>
  doctorTitleRuleConfig?: Maybe<DoctorTitleRuleConfig>
  academicConferenceCategorys?: Maybe<Array<Scalars['String']>>
  orderOperationLog?: Maybe<PageQueryOrderOperationLog>
  hospitalList: Array<HospitalInfo>
  hospitalSearch: HospitalInfoPaginatedResponse
  queryArchiveTags: Array<ArchiveTagInfo>
  queryMedicalLiteratureTags: Array<ArchiveTagInfo>
  queryDocuments: DocumentArchivePagerSearchResult
  queryDocument: DocumentInfo
  queryDocumentCategories: Array<DocumentArchiveCategory>
  queryMedicalLiteratureAppUrl?: Maybe<Scalars['String']>
  queryMedicalLiteratureAdminUrl?: Maybe<Scalars['String']>
  translateDocumentContent?: Maybe<Scalars['String']>
  queryWebAccessTokenInfo?: Maybe<Scalars['String']>
  upDoctorLoginWithWechatOpenIdAndAttenderInWeb?: Maybe<Scalars['String']>
  queryDocumentReviews: DocumentReviewPagerSearchResult
  financeQueryDocumentReviews: DocumentReviewPagerSearchResult
  queryDocumentReview?: Maybe<DocumentReviewInfo>
  queryDocumentReviewContent: Array<DocumentReviewContentInfo>
  queryDocumentReviewContentTemplate: Array<ReviewContentTemplate>
  companyApprovelProcess?: Maybe<Array<BaseOptions>>
  companyUpProducts?: Maybe<Array<UpProduct>>
  queryMeetingActivityTemplates?: Maybe<Array<MeetingActivityTemplate>>
  queryDomains: Array<MeetingDomainStatusOption>
  queryDocumentSphere: Array<DocumentSphereInfo>
  signInLogs: Array<UpMeetingSignInInfo>
  queryBankCardInfo?: Maybe<BankCardInfo>
  pingguang_userInfo: AccountInfo
  pingguang_authLiveUrl?: Maybe<Scalars['String']>
  pingguang_meetingList: PageQueryStandardMeetingResult
  pingguang_meetingDetail: StandardMeetingInfo
  pingguang_lecturers: MemberPaginatedResponse
  pingguang_doctors: MemberPaginatedResponse
  pingguang_hospitals: HospitalPaginationRes
  pingguang_departments: GroupPaginationRes
  pingguang_titles: GroupPaginationRes
  pingguang_products: GroupPaginationRes
  pingguang_regions: GroupPaginationRes
  pingguang_pptList: Array<ResourceInfo>
  pingguang_approveList: Array<StandardMeetingInfo>
  salesPerformance: Array<SalesPerformanceData>
  salesPerformanceAll: Scalars['JSONObject']
  latestContents: Array<ContentInfo>
  relatedDoctors: MemberPaginatedResponse
  salesClaimForm_StatusMap: Scalars['JSON']
  searchSalesClaimForm?: Maybe<PaginatedSalesClaimForm>
  searchSalesClaimFormById?: Maybe<SalesClaimFormInfo>
  querySalesClaimForm?: Maybe<Array<SalesClaimFormInfo>>
  salesOrder_TypesMap: Scalars['JSON']
  salesOrder_StatusMap: Scalars['JSON']
  searchSalesOrder?: Maybe<Array<SalesOrderDetail>>
  searchSalesOrderById?: Maybe<SalesOrderDetail>
  searchSalesOrderPage?: Maybe<PaginatedSalesOrderPage>
  searchApproveSalesOrderPage?: Maybe<PaginatedSalesOrderPage>
  salesOrderOperationLog?: Maybe<PaginatedSalesOrderOperationLog>
  searchSalesOrderTaskNum?: Maybe<Scalars['Int']>
  salesPlan_StatusMap: Scalars['JSON']
  searchSalesPlan?: Maybe<PaginatedSalesPlanDetail>
  upadminSalesPlanMyApprovals?: Maybe<PaginatedSalesPlanDetail>
  searchSalesPlanByPlanId?: Maybe<SalesPlanDetail>
  querySalesPlanApprovalFlows?: Maybe<Array<ApproveInfo>>
  upadminCompanies: Array<CompanyInfo>
  upadminDoctorCompanies: Array<CompanyInfo>
  upadminRegions: Array<AccountGroupInfo>
  upadminFetchCNAPSCode: Array<BankCodeInfo>
  upadminSearchDoctors: MemberPaginatedResponse
  upadminSearchAttenders: PageResultMeetingAttenders
  upadminMyApprovals: PageQueryStandardMeetingResult
  availableChannels: Array<Scalars['JSONObject']>
  queryInvitationUrl?: Maybe<Scalars['String']>
  upadminQueryDoctorTitles?: Maybe<Array<AccountGroupInfo>>
  queryCompanyRegions?: Maybe<Array<AccountGroupInfo>>
  searchMeetingUserAuthority?: Maybe<PageQueryMeetingUserAuthorityResult>
  adminCompanyDetail?: Maybe<CompanyInfo>
  adminCompanyGroupTree?: Maybe<Scalars['JSON']>
  adminCompanyGroup?: Maybe<GroupNodeInfo>
  adminGroupMembers?: Maybe<MemberPaginatedResponse>
  adminApprovalFlowForms?: Maybe<Array<AthenaForm>>
  adminApprovalNodeTypes?: Maybe<Array<NodeType>>
  adminApprovalFlowDetail?: Maybe<Scalars['JSON']>
  searchMeetingTaskNum?: Maybe<Array<TaskDetail>>
  upadminFinancePageQuerySettlements: PaginatedMeetingSettlementResult
  appBanner: Array<BasicInfoNode>
  appInfos: Array<BasicInfoNode>
  appInfosWithPager: PaginatedBasicInfoNode
  appMeetingCategories: Array<MeetingCategory>
  appMeetingCategoryProps: Array<Scalars['JSON']>
  appMeetingActivityProps: Array<Scalars['JSON']>
  appMeetingActivityTypes?: Maybe<Array<MeetingActivity>>
  queryMeetingActivityById?: Maybe<MeetingActivity>
  fetchInvitationDataBySignature?: Maybe<Scalars['JSON']>
  upappDocumentList?: Maybe<DocumentArchivePagerSearchResult>
  meetingCategories?: Maybe<Array<MeetingCategory>>
  meetingCategorieByCompanyIdAndCode?: Maybe<MeetingCategory>
  fetchAllBaseMeetingTypes?: Maybe<Array<MeetingConfigBase>>
  fetchAllBaseMeetingActivities?: Maybe<Array<MeetingConfigBase>>
  meetingActivitiyTypes?: Maybe<Array<MeetingCategory>>
  restaurantSearch: RestaurantSearchResult
  hotelSearch: HotelSearchResult
  carTypeSearch: Array<CarInfo>
  carServiceSearch: Array<CarServiceInfo>
  queryCarSupportedCities: Array<Scalars['String']>
  queryHospitals: HospitalPaginationResult
}

export type QueryInsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryOutsideAttenderListArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryQueryUserArgs = {
  accountId: Scalars['String']
}

export type QueryQueryTargetRegionsArgs = {
  userType?: Maybe<QcsfUserType>
  pid?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
}

export type QueryQueryDoctorAccountAttrConfigsArgs = {
  search?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type QueryQueryCompanyGroupArgs = {
  companyId?: Maybe<Scalars['String']>
  pid?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type QueryQueryCompanyAccountsByKeywordArgs = {
  keyword?: Maybe<Scalars['String']>
  companyId: Scalars['String']
}

export type QueryQueryCostCenterByCompanyArgs = {
  pid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryArchiveListArgs = {
  params?: Maybe<PageQueryArchivesRequest>
}

export type QueryQueryArchiveDetailArgs = {
  archiveId: Scalars['String']
}

export type QueryGetArchiveProductsListArgs = {
  refId?: Maybe<Scalars['Int']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryGetArchiveSignedUrlArgs = {
  archiveId: Scalars['String']
}

export type QuerySearchDoctorAttenderTasksArgs = {
  search?: Maybe<DoctorTaskQueryInputWithPager>
}

export type QueryHengrui_QueryBalancePoolListArgs = {
  companyId?: Maybe<Scalars['String']>
  regionIds?: Maybe<Array<Scalars['String']>>
}

export type QueryPageSearchHansohBudgetArgs = {
  param?: Maybe<BudgetSearchWithPager>
}

export type QueryPageSearchProcessBudgetArgs = {
  param?: Maybe<ProcessBudgetSearchWithPager>
}

export type QueryCheckPersonalBudgetArgs = {
  meetingId: Scalars['String']
  amountToAdd?: Maybe<Scalars['Float']>
}

export type QuerySearchBudgetBackStageArgs = {
  param?: Maybe<BudgetSearchWithPager>
}

export type QueryQueryProcessBudgetDetailArgs = {
  id: Scalars['Int']
}

export type QueryQueryProcessBudgetLogArgs = {
  param?: Maybe<ProcessBudgetSearchWithPager>
}

export type QueryQueryPersonalBudgetDetailArgs = {
  id: Scalars['Int']
}

export type QueryQueryPersonalBudgetLogArgs = {
  param?: Maybe<PersonalBudgetSearchWithPager>
}

export type QueryQueryLimitSwapPeopleArgs = {
  keyword?: Maybe<Scalars['String']>
}

export type QueryQueryUsedDetailArgs = {
  param?: Maybe<PersonalBudgetUsedDetailSearchWithPager>
}

export type QuerySearchOwnerLimitArgs = {
  param?: Maybe<OwnerLimitSearch>
}

export type QueryPageUserManagementArgs = {
  param?: Maybe<BudgetSearchWithPager>
}

export type QuerySearchInPlanBudgetArgs = {
  param?: Maybe<PersonalPlanBudgetSearchWithPager>
}

export type QueryQueryInPlanBudgetDetailArgs = {
  id: Scalars['Int']
}

export type QueryQueryInPlanBudgetLogArgs = {
  param?: Maybe<PersonalPlanBudgetLogSearchWithPager>
}

export type QueryQueryPlanBudgetUsedDetailArgs = {
  param?: Maybe<PersonalPlanBudgetUsedDetailSearchWithPager>
}

export type QuerySearchInPlanBudgetByMonthArgs = {
  param?: Maybe<PersonalPlanMonthBudgetSearch>
}

export type QueryGetBudgetAuthorityArgs = {
  param?: Maybe<PersonalPlanBudgetAuthoritySearch>
}

export type QuerySearchOutPlanBudgetArgs = {
  param?: Maybe<PersonalPlanBudgetSearchWithPager>
}

export type QueryQueryOutPlanBudgetDetailArgs = {
  id: Scalars['Int']
}

export type QueryQueryOutPlanBudgetLogArgs = {
  param?: Maybe<PersonalPlanBudgetLogSearchWithPager>
}

export type QuerySearchOutPlanBudgetByMonthArgs = {
  param?: Maybe<PersonalPlanMonthBudgetSearch>
}

export type QuerySearchCostCenterBudgetArgs = {
  param?: Maybe<CostCenterBudgetSearchWithPager>
}

export type QueryQueryCostCenterBudgetDetailArgs = {
  id: Scalars['Int']
}

export type QueryQueryCostCenterBudgetLogArgs = {
  param?: Maybe<CostCenterBudgetLogSearchWithPager>
}

export type QueryQueryCostCenterBudgetUsedDetailArgs = {
  param?: Maybe<CostCenterBudgetUsedDetailSearchWithPager>
}

export type QuerySearchCostCenterBudgetByYearArgs = {
  param?: Maybe<CostCenterYearBudgetSearch>
}

export type QuerySearchCostCenterYearBudgetsByGroupIdsArgs = {
  param?: Maybe<CostCenterYearBudgetSearch>
}

export type QueryCarOrderArgs = {
  orderId: Scalars['String']
}

export type QueryCityTreeArgs = {
  rootId?: Maybe<Scalars['String']>
}

export type QueryCitiesArgs = {
  level?: Maybe<Scalars['String']>
}

export type QueryContentListArgs = {
  query?: Maybe<ContentQueryInput>
}

export type QueryContentDetailArgs = {
  contentId: Scalars['String']
}

export type QueryContentActionListArgs = {
  contentId?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
}

export type QueryQueryUpDoctorMobileByAttenderIdArgs = {
  attenderId: Scalars['String']
}

export type QueryDoctorTasksArgs = {
  search?: Maybe<DoctorTaskSearchInputWithPager>
}

export type QueryDoctorTaskArgs = {
  id: Scalars['String']
}

export type QueryHasUnfinishedTasksArgs = {
  category: Scalars['String']
}

export type QueryQueryTaskDocumentReviewContentArgs = {
  taskId: Scalars['String']
}

export type QueryGetRecordByTaskIdArgs = {
  taskId: Scalars['String']
}

export type QueryQueryTaskLogByTaskIdArgs = {
  taskId: Scalars['String']
}

export type QueryHansohSalesPlansArgs = {
  params?: Maybe<HansohSalesPlanSearchParamsWithPager>
}

export type QueryHansohSalesPlanApprovalsArgs = {
  isHome?: Maybe<Scalars['Boolean']>
  params?: Maybe<HansohSalesPlanSearchParamsWithPager>
  approveStatus: Scalars['String']
}

export type QueryHansohSalesPlanDetailArgs = {
  planId: Scalars['String']
}

export type QueryHansohSalesOrdersArgs = {
  pager?: Maybe<PagerInput>
  relatedClaimId?: Maybe<Scalars['String']>
  status: Array<SalesOrderStatus>
}

export type QueryHansohSalesOrderDetailArgs = {
  orderId: Scalars['String']
}

export type QueryHansohSalesClaimsArgs = {
  request?: Maybe<SalesClaimFormSearchWithPager>
}

export type QueryHansohSalesClaimDetailArgs = {
  claimId: Scalars['String']
}

export type QueryHansohSalesUnsignedRestaurantArgs = {
  restaurantId: Scalars['String']
}

export type QueryHansohSalesUnsignedStoreArgs = {
  supermarketId: Scalars['String']
}

export type QueryHansohSalesConsumptionCategoriesArgs = {
  types?: Maybe<Array<Scalars['Int']>>
}

export type QueryHansohSalesOrderInvoicesArgs = {
  orderId: Scalars['String']
}

export type QueryHansohSalesBusinessCategorysArgs = {
  code?: Maybe<Array<Scalars['String']>>
  title?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Array<Scalars['Int']>>
}

export type QueryHansohSalesOrderCategorysArgs = {
  code?: Maybe<Array<Scalars['String']>>
  title?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Array<Scalars['Int']>>
}

export type QueryHengruiImage_DoctorHasCompeleteCertArgs = {
  channel?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  mobile: Scalars['String']
}

export type QueryHengruiImage_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryHengruiImage_SearchMeetingArgs = {
  isFinanceAdmin?: Maybe<Scalars['Boolean']>
  pageQuery?: Maybe<PageSearchStandardMeetingInput>
}

export type QueryHengruiImage_MeetingDetailArgs = {
  meetingId?: Maybe<Scalars['String']>
}

export type QueryHengruiImage_DoctorsArgs = {
  isAsc?: Maybe<Scalars['Boolean']>
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_MyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryHengruiImage_DepartmentsArgs = {
  companyId?: Maybe<Scalars['String']>
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_TitlesArgs = {
  companyId?: Maybe<Scalars['String']>
  query?: Maybe<MemberQueryInput>
}

export type QueryHengruiImage_IsUserJunziqianSignedArgs = {
  channel?: Maybe<MeetingSignChannel>
  accountId: Scalars['String']
}

export type QueryHengruiImage_AttenderContractArgs = {
  attenderId: Scalars['String']
  meetingId: Scalars['String']
}

export type QueryHengruiImage_AttenderPaymentDetailArgs = {
  type?: Maybe<HadesReceiptType>
  channel?: Maybe<HadesPaymentChannel>
  paymentId?: Maybe<Scalars['String']>
}

export type QueryHengruiImage_CheckOwnerLimitArgs = {
  meetingId?: Maybe<Scalars['String']>
  meetingDate: Scalars['Timestamp']
}

export type QueryHengruiImage_CheckAddDoctorLimitArgs = {
  budget?: Maybe<Scalars['Float']>
  meetingId: Scalars['String']
}

export type QueryQueryAttenderTasksArgs = {
  search?: Maybe<DoctorTaskSearchInputWithPager>
}

export type QueryGetWechatUrlLinkArgs = {
  doctorAccountId?: Maybe<Scalars['String']>
  appName?: Maybe<Scalars['String']>
}

export type QueryQueryMeetingApprovalFlowsArgs = {
  meetingId: Scalars['String']
}

export type QueryHengruiImage_SearchMeetingVerificationInfoArgs = {
  meetingType?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type QueryDoctorTitleRuleConfigArgs = {
  searchInput: SearchDoctorTitleRuleConfigInput
}

export type QueryOrderOperationLogArgs = {
  input: PageQueryOperationLogInput
}

export type QueryHospitalListArgs = {
  city: Scalars['String']
}

export type QueryHospitalSearchArgs = {
  pageQuery?: Maybe<HospitalInfoSearchQuery>
}

export type QueryQueryArchiveTagsArgs = {
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryMedicalLiteratureTagsArgs = {
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryDocumentsArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Scalars['String']>
  search?: Maybe<DocumentArchiveSearchParamsWithPager>
}

export type QueryQueryDocumentArgs = {
  documentId: Scalars['String']
}

export type QueryQueryDocumentCategoriesArgs = {
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryMedicalLiteratureAppUrlArgs = {
  doctorAccountId?: Maybe<Scalars['String']>
  path: Scalars['String']
}

export type QueryQueryMedicalLiteratureAdminUrlArgs = {
  path: Scalars['String']
}

export type QueryTranslateDocumentContentArgs = {
  text: Scalars['String']
}

export type QueryQueryWebAccessTokenInfoArgs = {
  code: Scalars['String']
  appName: Scalars['String']
}

export type QueryUpDoctorLoginWithWechatOpenIdAndAttenderInWebArgs = {
  openId: Scalars['String']
  attenderId?: Maybe<Scalars['String']>
  appName: Scalars['String']
}

export type QueryQueryDocumentReviewsArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>
}

export type QueryFinanceQueryDocumentReviewsArgs = {
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>
}

export type QueryQueryDocumentReviewArgs = {
  reviewId: Scalars['String']
}

export type QueryQueryDocumentReviewContentArgs = {
  reviewId: Scalars['String']
}

export type QueryQueryDocumentReviewContentTemplateArgs = {
  documentId: Scalars['String']
}

export type QueryCompanyApprovelProcessArgs = {
  companyId: Scalars['String']
}

export type QueryCompanyUpProductsArgs = {
  currentUser?: Maybe<Scalars['Boolean']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryMeetingActivityTemplatesArgs = {
  documentCategory?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryDocumentSphereArgs = {
  params: DocumentSphereSearchParams
}

export type QuerySignInLogsArgs = {
  meetingId: Scalars['String']
}

export type QueryQueryBankCardInfoArgs = {
  bankCardNo: Scalars['String']
}

export type QueryPingguang_UserInfoArgs = {
  accountId?: Maybe<Scalars['String']>
}

export type QueryPingguang_AuthLiveUrlArgs = {
  meetingId: Scalars['String']
}

export type QueryPingguang_MeetingListArgs = {
  filter?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryPingguang_MeetingDetailArgs = {
  meetingId: Scalars['String']
}

export type QueryPingguang_LecturersArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_DoctorsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_HospitalsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_DepartmentsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_TitlesArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_ProductsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_RegionsArgs = {
  query?: Maybe<MemberQueryInput>
}

export type QueryPingguang_ApproveListArgs = {
  submitted?: Maybe<Scalars['Boolean']>
}

export type QueryRelatedDoctorsArgs = {
  pager?: Maybe<PagerInput>
  keyword?: Maybe<Scalars['String']>
}

export type QuerySearchSalesClaimFormArgs = {
  param?: Maybe<SalesClaimFormSearchWithPager>
}

export type QuerySearchSalesClaimFormByIdArgs = {
  id: Scalars['String']
}

export type QueryQuerySalesClaimFormArgs = {
  claimFormCodes?: Maybe<Array<Scalars['String']>>
  ids?: Maybe<Array<Scalars['String']>>
}

export type QuerySearchSalesOrderArgs = {
  param?: Maybe<SalesOrderSearch>
}

export type QuerySearchSalesOrderByIdArgs = {
  id: Scalars['String']
}

export type QuerySearchSalesOrderPageArgs = {
  param?: Maybe<SalesOrderPageSearchWithPager>
}

export type QuerySearchApproveSalesOrderPageArgs = {
  param?: Maybe<SalesOrderPageSearchWithPager>
}

export type QuerySalesOrderOperationLogArgs = {
  param?: Maybe<SalesOrderLogSearchWithPager>
}

export type QuerySearchSalesPlanArgs = {
  param?: Maybe<SalesPlanSearchWithPager>
}

export type QueryUpadminSalesPlanMyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>
  param?: Maybe<SalesPlanSearchWithPager>
}

export type QuerySearchSalesPlanByPlanIdArgs = {
  planId: Scalars['String']
}

export type QueryQuerySalesPlanApprovalFlowsArgs = {
  planId: Scalars['String']
}

export type QueryUpadminRegionsArgs = {
  companyId: Scalars['String']
}

export type QueryUpadminFetchCnapsCodeArgs = {
  bankBranchName?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
}

export type QueryUpadminSearchDoctorsArgs = {
  query?: Maybe<PageQueryMeetingAttenders>
}

export type QueryUpadminSearchAttendersArgs = {
  query?: Maybe<PageQueryMeetingAttenders>
}

export type QueryUpadminMyApprovalsArgs = {
  approveStatus?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}

export type QueryQueryInvitationUrlArgs = {
  channel?: Maybe<Scalars['String']>
  issuerId?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
  host: Scalars['String']
}

export type QueryUpadminQueryDoctorTitlesArgs = {
  search?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryQueryCompanyRegionsArgs = {
  level?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QuerySearchMeetingUserAuthorityArgs = {
  pageQuery?: Maybe<PageQueryMeetingUserAuthorityInput>
}

export type QueryAdminCompanyDetailArgs = {
  id: Scalars['String']
}

export type QueryAdminCompanyGroupTreeArgs = {
  groupType?: Maybe<Scalars['String']>
  companyId: Scalars['String']
}

export type QueryAdminCompanyGroupArgs = {
  groupId: Scalars['String']
}

export type QueryAdminGroupMembersArgs = {
  pager?: Maybe<PagerInput>
  groupId: Scalars['String']
}

export type QueryAdminApprovalFlowFormsArgs = {
  companyId: Scalars['String']
}

export type QueryAdminApprovalFlowDetailArgs = {
  targetId: Scalars['String']
}

export type QueryUpadminFinancePageQuerySettlementsArgs = {
  request?: Maybe<PaginatedMeetingSettlementRequest>
}

export type QueryAppInfosArgs = {
  type: AppInfoType
}

export type QueryAppInfosWithPagerArgs = {
  pager: PagerInput
  type: AppInfoType
}

export type QueryAppMeetingCategoryPropsArgs = {
  cid: Scalars['String']
}

export type QueryAppMeetingActivityPropsArgs = {
  aid: Scalars['String']
}

export type QueryAppMeetingActivityTypesArgs = {
  mine?: Maybe<Scalars['Boolean']>
  productType?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  meetingType?: Maybe<Scalars['String']>
}

export type QueryQueryMeetingActivityByIdArgs = {
  id: Scalars['String']
}

export type QueryFetchInvitationDataBySignatureArgs = {
  signature: Scalars['String']
}

export type QueryUpappDocumentListArgs = {
  query?: Maybe<DocumentArchiveSearchParamsWithPager>
}

export type QueryMeetingCategoriesArgs = {
  companyId?: Maybe<Scalars['String']>
}

export type QueryMeetingCategorieByCompanyIdAndCodeArgs = {
  code?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryMeetingActivitiyTypesArgs = {
  productType?: Maybe<Scalars['String']>
  categoryCode?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type QueryRestaurantSearchArgs = {
  search?: Maybe<RestaurantSearchInput>
}

export type QueryHotelSearchArgs = {
  search?: Maybe<HotelSearchInput>
}

export type QueryCarTypeSearchArgs = {
  city?: Maybe<Scalars['String']>
}

export type QueryCarServiceSearchArgs = {
  carType: Scalars['String']
}

export type QueryQueryHospitalsArgs = {
  request?: Maybe<HospitalPaginationRequest>
}

export type QueryMeetingAttenders = {
  companyId?: Maybe<Scalars['String']>
  meetingType?: Maybe<Scalars['String']>
  activityType?: Maybe<Array<Scalars['String']>>
  projectType?: Maybe<Array<Scalars['String']>>
  regionId?: Maybe<Scalars['String']>
  meetingStatus?: Maybe<Array<Scalars['String']>>
  meetingStartTime?: Maybe<Scalars['Timestamp']>
  meetingEndTime?: Maybe<Scalars['Timestamp']>
  providerId?: Maybe<Scalars['String']>
  settlementStatus?: Maybe<Scalars['String']>
  billStatus?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  channel?: Maybe<Scalars['String']>
}

export type RecommendShopOcrResultInfo = {
  __typename?: 'RecommendShopOcrResultInfo'
  name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  isGaoDe?: Maybe<Scalars['String']>
}

export type ResourceInfo = {
  __typename?: 'ResourceInfo'
  resourceId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  type?: Maybe<ResourceType>
}

export enum ResourceType {
  Ppt = 'PPT',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Image = 'IMAGE'
}

export type RestaurantInfo = {
  __typename?: 'RestaurantInfo'
  indexId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  restaurantId: Scalars['String']
  companys: Array<Scalars['String']>
  name?: Maybe<Scalars['String']>
  restaurantName: Scalars['String']
  district?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  areaCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  cityCode?: Maybe<Scalars['String']>
  businessAreaName?: Maybe<Scalars['String']>
  businessAreaCode?: Maybe<Scalars['String']>
  cityNameTag?: Maybe<Scalars['String']>
  areaNameTag?: Maybe<Scalars['String']>
  businessAreaNameTag?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  location?: Maybe<IesLocation>
  cookStyle?: Maybe<Array<Scalars['String']>>
  category?: Maybe<Array<Scalars['String']>>
  cookStyleTag?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['Float']>
  consume?: Maybe<Scalars['Float']>
  indoor?: Maybe<Array<Scalars['String']>>
  indoorUrls?: Maybe<Array<Scalars['String']>>
  outdoor?: Maybe<Array<Scalars['String']>>
  outdoorUrl?: Maybe<Array<Scalars['String']>>
  createTime?: Maybe<Scalars['Timestamp']>
  needAdvance?: Maybe<Scalars['Boolean']>
  advanceHour?: Maybe<Scalars['Float']>
  hitNum?: Maybe<Scalars['Float']>
  onLineTime: Scalars['Timestamp']
  settlementType?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  distance?: Maybe<Scalars['Float']>
  gaodeId?: Maybe<Scalars['String']>
  txType?: Maybe<Scalars['String']>
  telPhone?: Maybe<Scalars['String']>
  supportWaimai: Scalars['Boolean']
  supportYuding: Scalars['Boolean']
  shippingDis?: Maybe<Scalars['Float']>
  advanceTime?: Maybe<Scalars['Float']>
  deliveryDesc?: Maybe<Scalars['String']>
  shippingFeeMin?: Maybe<Scalars['Float']>
  cancelRule?: Maybe<Scalars['String']>
  maxItems?: Maybe<Scalars['Float']>
  maxType?: Maybe<Scalars['String']>
  openingHours?: Maybe<Scalars['String']>
  introduce?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Float']>
  frozenAmount?: Maybe<Scalars['Float']>
  availableAmount?: Maybe<Scalars['Float']>
  companyName?: Maybe<Scalars['String']>
  balanceAcountId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  clientBlacklists: Array<Scalars['String']>
  onlineStatus?: Maybe<Scalars['String']>
  settlementOwner?: Maybe<Scalars['String']>
  rebateId?: Maybe<Scalars['String']>
  rebateType?: Maybe<Scalars['String']>
  rebateRule?: Maybe<Scalars['String']>
  rebateSettlementType?: Maybe<Scalars['String']>
  effectiveTime: Scalars['Timestamp']
  rebateStatus?: Maybe<Scalars['String']>
  rebatePercent?: Maybe<Scalars['Float']>
  rebateBizType?: Maybe<Scalars['String']>
  companyNameTag?: Maybe<Scalars['String']>
  einvoice?: Maybe<Scalars['Boolean']>
  shippingFee?: Maybe<Scalars['Float']>
  packingBoxFee?: Maybe<Scalars['Float']>
  polygon?: Maybe<Scalars['String']>
  isPolygon?: Maybe<Scalars['Float']>
  haveBox?: Maybe<Scalars['Boolean']>
  boxNum?: Maybe<Scalars['Float']>
  boxMinConsume?: Maybe<Scalars['String']>
  boxMinimum?: Maybe<Scalars['String']>
}

export enum RestaurantPayType {
  None = 'NONE',
  Unionpay = 'UNIONPAY',
  AlipayCreditCard = 'ALIPAY_CREDIT_CARD'
}

export type RestaurantSearchInput = {
  query?: Maybe<RestaurantSearchQuery>
  pager?: Maybe<PagerInput>
}

export type RestaurantSearchQuery = {
  areaName?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  sortType?: Maybe<SortType>
  orderBy?: Maybe<Scalars['String']>
  keywords?: Maybe<Scalars['String']>
  radius?: Maybe<Scalars['String']>
}

export type RestaurantSearchResult = {
  __typename?: 'RestaurantSearchResult'
  items: Array<RestaurantInfo>
  pager: PagerResult
}

export type ReviewContentTemplate = {
  __typename?: 'ReviewContentTemplate'
  key?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Int']>
  placeholder?: Maybe<Scalars['String']>
  min?: Maybe<Scalars['Int']>
  max?: Maybe<Scalars['Int']>
}

export type RoleAuthority = {
  __typename?: 'RoleAuthority'
  appId?: Maybe<Scalars['String']>
  appName?: Maybe<Scalars['String']>
}

export type RoleInfo = {
  __typename?: 'RoleInfo'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  enable?: Maybe<Scalars['Boolean']>
}

export type SaleAccountInput = {
  cid: Scalars['String']
  uid: Scalars['String']
}

export type SalesClaimFormBaseInfo = {
  __typename?: 'SalesClaimFormBaseInfo'
  id?: Maybe<Scalars['String']>
  claimFormName?: Maybe<Scalars['String']>
  claimFormCode?: Maybe<Scalars['String']>
  qrCodeImage?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountInfo?: Maybe<AccountInfo>
  accountName?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  status?: Maybe<SalesClaimStatus>
  reason?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['String']>
  claimAmount?: Maybe<Scalars['String']>
  approvedTime?: Maybe<Scalars['String']>
  preReviewedTime?: Maybe<Scalars['String']>
  reviewedTime?: Maybe<Scalars['String']>
  budgetChannel?: Maybe<Scalars['Float']>
  /** 报销编号 */
  budgetNumber?: Maybe<Scalars['String']>
}

export type SalesClaimFormInfo = {
  __typename?: 'SalesClaimFormInfo'
  claimForm?: Maybe<SalesClaimFormBaseInfo>
  relations?: Maybe<Array<SalesClaimFormRelationInfo>>
  properties?: Maybe<Array<SalesClaimFormProperties>>
  salesClaimFormOrders?: Maybe<Array<SalesOrderDetail>>
  isOwner: Scalars['Boolean']
  isCurrentApprover: Scalars['Boolean']
  statusText?: Maybe<Scalars['String']>
  salesClaimFormApprovalFlows?: Maybe<Array<ApproveInfo>>
  salesClaimFormLogs?: Maybe<Array<SalesOrderOperationLog>>
  salesClaimFormPreReviewedUser?: Maybe<AccountInfo>
  salesClaimFormReviewedUser?: Maybe<AccountInfo>
}

export type SalesClaimFormProperties = {
  __typename?: 'SalesClaimFormProperties'
  id?: Maybe<Scalars['String']>
  claimFormCode?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  gmtCreated?: Maybe<Scalars['String']>
  gmtModified?: Maybe<Scalars['String']>
}

export type SalesClaimFormRelationInfo = {
  __typename?: 'SalesClaimFormRelationInfo'
  id?: Maybe<Scalars['String']>
  claimFormCode?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  salesOrder?: Maybe<HansohSalesOrder>
}

export type SalesClaimFormSearch = {
  id?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  status?: Maybe<Array<Scalars['Float']>>
  startTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  preReviewedStartTime?: Maybe<Scalars['String']>
  preReviewedEndTime?: Maybe<Scalars['String']>
  reviewedStartTime?: Maybe<Scalars['String']>
  reviewedEndTime?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
}

export type SalesClaimFormSearchWithPager = {
  query?: Maybe<SalesClaimFormSearch>
  pager?: Maybe<PagerInput>
}

export enum SalesClaimStatus {
  Init = 'INIT',
  Approving = 'APPROVING',
  ApproveReturn = 'APPROVE_RETURN',
  Reviewing = 'REVIEWING',
  Reviewed = 'REVIEWED',
  Return = 'RETURN',
  Prereviewing = 'PREREVIEWING'
}

export type SalesOrderAuditRecord = {
  __typename?: 'SalesOrderAuditRecord'
  id?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  receiptType?: Maybe<Scalars['String']>
  verifyType?: Maybe<Scalars['String']>
  verifyDimension?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Float']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
  properties?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Float']>
  isEnable?: Maybe<Scalars['Boolean']>
}

export type SalesOrderBaseInfo = {
  __typename?: 'SalesOrderBaseInfo'
  id?: Maybe<Scalars['String']>
  conferenceCategoryCode?: Maybe<Scalars['String']>
  consumptionCategoryCode?: Maybe<Scalars['String']>
  budgetChannel?: Maybe<Scalars['Float']>
  budgetNumber?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  accountInfo?: Maybe<AccountInfo>
  formCategory?: Maybe<Scalars['String']>
  targetHospital?: Maybe<Scalars['String']>
  targetDepartment?: Maybe<Scalars['String']>
  applyReason?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  targetName?: Maybe<Scalars['String']>
  targetAddress?: Maybe<Scalars['String']>
  targetOccurTime?: Maybe<Scalars['String']>
  peopleNumber?: Maybe<Scalars['Float']>
  actualPeopleNumber?: Maybe<Scalars['Float']>
  appointmentTime?: Maybe<Scalars['String']>
  consumptionAmount?: Maybe<Scalars['String']>
  actualConsumptionAmount?: Maybe<Scalars['String']>
  actualConsumptionTime?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  occurCity?: Maybe<Scalars['String']>
  status?: Maybe<SalesOrderStatus>
  reason?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['Float']>
  paymentTime?: Maybe<Scalars['String']>
  invoiceQuantity?: Maybe<Scalars['Float']>
  invoiceVerification?: Maybe<Scalars['Float']>
  companyId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  costcenterId?: Maybe<Scalars['String']>
  costcenterName?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
  claimAmount?: Maybe<Scalars['String']>
  preVoucherAuditTime?: Maybe<Scalars['String']>
  /** 核减金额 */
  deductionAmount?: Maybe<Scalars['String']>
  /** 核减报销金额 */
  financialDeductionAmount?: Maybe<Scalars['String']>
  /** 核减金额操作人员 */
  deductionOperator?: Maybe<Scalars['String']>
  /** 核减报销金额操作人员 */
  financialDeductionOperator?: Maybe<Scalars['String']>
  /** 核减报销金额原因 */
  deductionReason?: Maybe<Scalars['String']>
  /** 平台审核人 */
  qcOwner?: Maybe<Scalars['String']>
  qcOwnerAccountInfo?: Maybe<AccountInfo>
  finalCorrection?: Maybe<Scalars['String']>
  finalCorrectionReason?: Maybe<Scalars['String']>
  usageScenario?: Maybe<Scalars['String']>
  oaNumber?: Maybe<Scalars['String']>
}

export type SalesOrderDetail = {
  __typename?: 'SalesOrderDetail'
  salesOrder?: Maybe<SalesOrderBaseInfo>
  properties?: Maybe<Array<SalesOrderProperties>>
  salesOrderConsumptionCategory?: Maybe<BaseCategoryType>
  salesOrderBusinessCategory?: Maybe<BusinessCategory>
  salesOrderLogs?: Maybe<Array<SalesOrderOperationLog>>
  salesOrderInvoices: Array<HansohOrderInvoice>
  salesOrderReceiptTemplate?: Maybe<Array<SalesOrderReceiptTemplate>>
  salesOrderApprovalFlows?: Maybe<Array<ApproveInfo>>
  formCategoryInfo?: Maybe<OrderCategory>
  isSalesOrderCurrentApproverQcOwner?: Maybe<Scalars['Boolean']>
  searchSalesOrderAuditRecordByOrderId?: Maybe<Array<SalesOrderAuditRecord>>
  resource?: Maybe<UnsignedVendor>
}

export type SalesOrderLogSearch = {
  log?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  businessType?: Maybe<Scalars['String']>
  bizType?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  extraInfo?: Maybe<Scalars['String']>
  markType?: Maybe<Scalars['String']>
  markAction?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  status?: Maybe<Array<Scalars['Float']>>
  createTime?: Maybe<Scalars['String']>
}

export type SalesOrderLogSearchWithPager = {
  query?: Maybe<SalesOrderLogSearch>
  pager?: Maybe<PagerInput>
}

export type SalesOrderOperationLog = {
  __typename?: 'SalesOrderOperationLog'
  log?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  businessType?: Maybe<Scalars['String']>
  bizType?: Maybe<Scalars['String']>
  extraInfo?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['String']>
  recordId?: Maybe<Scalars['String']>
  markType?: Maybe<Scalars['String']>
  markAction?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['String']>
}

export type SalesOrderPageSearch = {
  consumptionCategoryCode?: Maybe<Array<Scalars['String']>>
  formCategory?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Array<Scalars['Float']>>
  start?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  preAuditStart?: Maybe<Scalars['String']>
  preAuditEnd?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  budgetChannel?: Maybe<Array<Scalars['Float']>>
  field?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['Float']>
}

export type SalesOrderPageSearchWithPager = {
  query?: Maybe<SalesOrderPageSearch>
  pager?: Maybe<PagerInput>
}

export type SalesOrderProperties = {
  __typename?: 'SalesOrderProperties'
  id?: Maybe<Scalars['String']>
  orderId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type SalesOrderReceiptTemplate = {
  __typename?: 'SalesOrderReceiptTemplate'
  id?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  resourceName?: Maybe<Scalars['String']>
  receiptUrl?: Maybe<Scalars['String']>
  receiptType?: Maybe<Scalars['String']>
}

export type SalesOrderSearch = {
  id?: Maybe<Scalars['String']>
  conferenceCategoryCode?: Maybe<Array<Scalars['String']>>
  consumptionCategoryCode?: Maybe<Array<Scalars['String']>>
  formCategory?: Maybe<Array<Scalars['String']>>
  targetName?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Array<Scalars['Float']>>
  accountId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export enum SalesOrderStatus {
  BookingProgress = 'BOOKING_PROGRESS',
  BookingReturn = 'BOOKING_RETURN',
  BookingSuccess = 'BOOKING_SUCCESS',
  PrePaid = 'PRE_PAID',
  PreVoucherUpload = 'PRE_VOUCHER_UPLOAD',
  VoucherUploadCompleted = 'VOUCHER_UPLOAD_COMPLETED',
  PreVoucherAudit = 'PRE_VOUCHER_AUDIT',
  AuditCompleted = 'AUDIT_COMPLETED',
  AuditFailed = 'AUDIT_FAILED',
  FormCancel = 'FORM_CANCEL',
  FormReviewing = 'FORM_REVIEWING',
  ReviewedCompleted = 'REVIEWED_COMPLETED',
  FinancialReturn = 'FINANCIAL_RETURN'
}

export type SalesPerformance = {
  __typename?: 'SalesPerformance'
  share: ContentActionStatInfo
  unshare: ContentActionStatInfo
  up: ContentActionStatInfo
  read: ContentActionStatInfo
}

export type SalesPerformanceData = {
  __typename?: 'SalesPerformanceData'
  period: StatPeriod
  data: Scalars['JSONObject']
}

export type SalesPlanBaseInfo = {
  __typename?: 'SalesPlanBaseInfo'
  id?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  budgetChannel?: Maybe<Scalars['Float']>
  budgetNumber?: Maybe<Scalars['String']>
  planAmount?: Maybe<Scalars['String']>
  actualAmount?: Maybe<Scalars['String']>
  orderNumber?: Maybe<Scalars['Float']>
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  costcenterId?: Maybe<Scalars['String']>
  costcenterName?: Maybe<Scalars['String']>
  applyTime?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  serviceBusinessStatus?: Maybe<SalesPlanStatus>
  reason?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type SalesPlanDetail = {
  __typename?: 'SalesPlanDetail'
  salesPlan?: Maybe<SalesPlanBaseInfo>
  properties?: Maybe<Array<SalesPlanProperties>>
  salesPlanOrders?: Maybe<Array<SalesOrderDetail>>
  salesPlanApprovalFlows?: Maybe<Array<ApproveInfo>>
  isSalesPlanCurrentApprover: Scalars['Boolean']
}

export type SalesPlanProperties = {
  __typename?: 'SalesPlanProperties'
  id?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type SalesPlanSearch = {
  id?: Maybe<Array<Scalars['String']>>
  companyId?: Maybe<Array<Scalars['String']>>
  businessId?: Maybe<Array<Scalars['String']>>
  regionId?: Maybe<Array<Scalars['String']>>
  areaId?: Maybe<Array<Scalars['String']>>
  costcenterId?: Maybe<Array<Scalars['String']>>
  serviceBusinessStatus?: Maybe<Array<Scalars['Float']>>
  accountId?: Maybe<Array<Scalars['String']>>
  keyword?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  sort?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['String']>
}

export type SalesPlanSearchWithPager = {
  query?: Maybe<SalesPlanSearch>
  pager?: Maybe<PagerInput>
}

export enum SalesPlanStatus {
  PlanProgress = 'PLAN_PROGRESS',
  PlanApproving = 'PLAN_APPROVING',
  PlanConsumeing = 'PLAN_CONSUMEING',
  PlanCompleted = 'PLAN_COMPLETED',
  PlanReturn = 'PLAN_RETURN'
}

export type SaveStandardMeetingCostInput = {
  amount: Scalars['String']
  type: Scalars['String']
  note?: Maybe<Scalars['String']>
}

export type SearchDoctorTitleRuleConfigInput = {
  id?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Int']>
  categoryCode?: Maybe<Scalars['String']>
}

export type ShoppingReceiptOcrResultInfo = {
  __typename?: 'ShoppingReceiptOcrResultInfo'
  shopName?: Maybe<Scalars['String']>
  consumptionDate?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['String']>
}

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StandardMeetingApprovalStatus {
  Approving = 'APPROVING',
  NotApproval = 'NOT_APPROVAL',
  Success = 'SUCCESS',
  Fail = 'FAIL'
}

export type StandardMeetingBaseAttender = {
  __typename?: 'StandardMeetingBaseAttender'
  id?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  attenderData?: Maybe<Scalars['JSON']>
  attenderId?: Maybe<Scalars['String']>
  attenderName?: Maybe<Scalars['String']>
  attenderPhone?: Maybe<Scalars['String']>
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
  /** 费用 */
  attenderFee?: Maybe<Scalars['String']>
  /** 总费用 */
  actualFee?: Maybe<Scalars['String']>
  /** 企业费率 */
  rate?: Maybe<Scalars['String']>
  /** 服务费用 */
  serviceFee?: Maybe<Scalars['String']>
  attenderStatus?: Maybe<Scalars['String']>
  billStatus?: Maybe<AttenderBillStatus>
  billTime?: Maybe<Scalars['String']>
  attenderInfo?: Maybe<AccountInfo>
  /** origin签约渠道 */
  channel?: Maybe<Scalars['String']>
  /** 签约渠道 */
  attenderSignChannel?: Maybe<Scalars['String']>
  attenderSettlementTime?: Maybe<Scalars['Timestamp']>
  meetingAttending?: Maybe<StandardMeetingInfo>
  taskIsOpen?: Maybe<Scalars['Boolean']>
  qkkStudioOpenStatus?: Maybe<Scalars['String']>
  invitationButtonStatus: InvitationButtonStatus
  ptxUserRegistered: Scalars['Boolean']
  settlementRecord?: Maybe<Scalars['JSON']>
  taskListInfo?: Maybe<Array<MeetingAttenderTask>>
}

export type StandardMeetingBaseData = {
  __typename?: 'StandardMeetingBaseData'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  applyTime?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['String']>
  submitTime?: Maybe<Scalars['String']>
  approvalTime?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  cityId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  company?: Maybe<CompanyInfo>
  ownerId?: Maybe<Scalars['String']>
  owner?: Maybe<AccountInfo>
  topic?: Maybe<Scalars['String']>
  desc?: Maybe<Scalars['String']>
  status?: Maybe<StandardMeetingStatus>
  type?: Maybe<Scalars['String']>
  peopleNum?: Maybe<Scalars['Int']>
  channel?: Maybe<StandardMeetingChannel>
  needBudget?: Maybe<Scalars['Boolean']>
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>
  budgetNumber?: Maybe<Scalars['String']>
  activityType?: Maybe<Scalars['String']>
  serviceBusinessStatus?: Maybe<Scalars['String']>
  belongProduct?: Maybe<UpProduct>
  productType?: Maybe<Scalars['String']>
  therapyArea?: Maybe<Scalars['String']>
  drugName?: Maybe<Scalars['String']>
  activityPurpose?: Maybe<Scalars['String']>
  activityConfig?: Maybe<Scalars['JSON']>
  activityConfigModified?: Maybe<MeetingActivity>
}

export type StandardMeetingBillMarkStatus = {
  meetingId?: Maybe<Scalars['String']>
  billStatus?: Maybe<AttenderBillStatus>
  attenderId?: Maybe<Scalars['String']>
  billTime?: Maybe<Scalars['String']>
}

export type StandardMeetingBudgetInfo = {
  __typename?: 'StandardMeetingBudgetInfo'
  LectureFee?: Maybe<Scalars['Float']>
  otherFee?: Maybe<Scalars['Float']>
}

export enum StandardMeetingChannel {
  Default = 'DEFAULT',
  HrVideo = 'HR_VIDEO',
  PingGuang = 'PING_GUANG',
  HrNarcosis = 'HR_NARCOSIS',
  All = 'ALL',
  Hansoh = 'HANSOH',
  Cttq = 'CTTQ',
  Qc = 'QC',
  Qilu = 'QILU',
  Zmhd = 'ZMHD',
  HrncsMarket = 'HRNCS_MARKET'
}

export type StandardMeetingCost = {
  __typename?: 'StandardMeetingCost'
  amount: Scalars['String']
  type: Scalars['String']
  note?: Maybe<Scalars['String']>
}

export type StandardMeetingInfo = {
  __typename?: 'StandardMeetingInfo'
  meetingBaseData?: Maybe<StandardMeetingBaseData>
  properties?: Maybe<StandardMeetingProperties>
  city?: Maybe<AccountGroupInfo>
  ppts: Array<ResourceInfo>
  speakerInfo?: Maybe<AccountInfo>
  authLiveUrl?: Maybe<Scalars['String']>
  isOwner: Scalars['Boolean']
  isCurrentApprover: Scalars['Boolean']
  otherProperties?: Maybe<Array<StandardMeetingOtherProperties>>
  hengruiImage_approveStatus?: Maybe<Scalars['String']>
  hengruiImage_approveStatusText?: Maybe<Scalars['String']>
  hengruiImage_approveReason?: Maybe<Scalars['String']>
  hasUnfinishedTasks?: Maybe<Scalars['Boolean']>
  isCurrentApproverOrYHLXS: Scalars['Boolean']
  hengruiSpearks: Array<HengruiMeetingSpeaker>
  meetingTotalFee: Scalars['Float']
  hengruiImage_costs: Array<StandardMeetingCost>
  hengruiImage_allAttenders?: Maybe<Array<StandardMeetingBaseAttender>>
  hengruiImage_spearkers?: Maybe<Array<StandardMeetingBaseAttender>>
  hengruiImage_attendDoctors?: Maybe<Array<StandardMeetingBaseAttender>>
  hengruiImage_projectType?: Maybe<Scalars['String']>
  hengruiImage_activityType?: Maybe<Scalars['String']>
  hengruiImage_categoryName?: Maybe<Scalars['String']>
  hengruiImage_costcenter?: Maybe<HrCostCenter>
  hengruiImage_meetingRegion?: Maybe<AccountGroupInfo>
  hengruiImage_vendor?: Maybe<CompanyInfo>
  hengruiImage_address?: Maybe<Scalars['String']>
  hengruiImage_addressLocation?: Maybe<Scalars['String']>
  hengruiImage_hospital?: Maybe<Scalars['String']>
  hengruiImage_materials?: Maybe<Array<Scalars['String']>>
  hengruiImage_schedulePics?: Maybe<Array<Scalars['String']>>
  hengruiImage_signPics?: Maybe<Array<Scalars['String']>>
  hengruiImage_panoramicPics?: Maybe<Array<Scalars['String']>>
  hengruiImage_activeStartVideos?: Maybe<Array<Scalars['String']>>
  hengruiImage_activeEndVideos?: Maybe<Array<Scalars['String']>>
  hengruiImage_activeCoursewares?: Maybe<Array<Scalars['String']>>
  hengruiImage_checkReports?: Maybe<Array<Scalars['String']>>
  hengruiImage_ApproverOwner?: Maybe<Scalars['String']>
  hengruiImage_status?: Maybe<Scalars['String']>
  hengruiImage_statusExtra?: Maybe<Scalars['String']>
  hengruiImage_meetingApproveUser?: Maybe<Scalars['String']>
  hengruiImage_documentComposeInfo?: Maybe<Scalars['String']>
  hengruiImage_academicConferencePlatformInfo?: Maybe<Scalars['String']>
  academicConference_meetingCategory?: Maybe<Scalars['String']>
  hengruiImage_statusText?: Maybe<Scalars['String']>
  hengruiImage_city?: Maybe<CityInfo>
  hengruiImage_activityTypeSphere?: Maybe<Scalars['String']>
  meetingAuditRecord?: Maybe<Array<AuditRecordResult>>
  hengruiImage_forwardSignatureOwner?: Maybe<Scalars['String']>
  hengruiImage_meetingInviators?: Maybe<Array<MeetingInviator>>
}

export type StandardMeetingInfoHengruiImage_AllAttendersArgs = {
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
}

export type StandardMeetingOtherProperties = {
  __typename?: 'StandardMeetingOtherProperties'
  type: StandardMeetingPropType
  value?: Maybe<Scalars['String']>
  diyType?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
}

export enum StandardMeetingPropType {
  Diy = 'DIY',
  QuizUrl = 'QUIZ_URL',
  LiveUrl = 'LIVE_URL',
  MeetingProduct = 'MEETING_PRODUCT',
  MeetingSpeaker = 'MEETING_SPEAKER',
  MeetingChairman = 'MEETING_CHAIRMAN',
  MeetingRedenvelopeTotalmoney = 'MEETING_REDENVELOPE_TOTALMONEY',
  LiveId = 'LIVE_ID',
  NeedQuiz = 'NEED_QUIZ',
  PlayerId = 'PLAYER_ID',
  ThumbUrl = 'THUMB_URL',
  TopicPicUrl = 'TOPIC_PIC_URL',
  SourceMeetingInfo = 'SOURCE_MEETING_INFO',
  QuizStartTime = 'QUIZ_START_TIME',
  LiveAuthoKey = 'LIVE_AUTHO_KEY',
  Hospital = 'HOSPITAL',
  OpenRegistration = 'OPEN_REGISTRATION',
  Location = 'LOCATION',
  Area = 'AREA',
  BudgetCycle = 'BUDGET_CYCLE',
  PptUrl = 'PPT_URL',
  ApprovalStatus = 'APPROVAL_STATUS',
  Budget = 'BUDGET',
  NeedAssistance = 'NEED_ASSISTANCE',
  WebLiveTool = 'WEB_LIVE_TOOL',
  MuduLiveExcelBill = 'MUDU_LIVE_EXCEL_BILL',
  MuduLiveExcelDetail = 'MUDU_LIVE_EXCEL_DETAIL',
  ProjectType = 'PROJECT_TYPE',
  ContractTemplateId = 'CONTRACT_TEMPLATE_ID',
  MeetingLiveId = 'MEETING_LIVE_ID',
  ServiceBusinessStatus = 'SERVICE_BUSINESS_STATUS',
  City = 'CITY',
  Province = 'PROVINCE',
  AdvertisingActivitiesType = 'ADVERTISING_ACTIVITIES_TYPE',
  CostCenterForMeeting = 'COST_CENTER_FOR_MEETING',
  HrMeetingOwnerCostCenter = 'HR_MEETING_OWNER_COST_CENTER',
  SettlementVendor = 'SETTLEMENT_VENDOR',
  QcApprovalStatus = 'QC_APPROVAL_STATUS',
  QcOwner = 'QC_OWNER'
}

export type StandardMeetingProperties = {
  __typename?: 'StandardMeetingProperties'
  topicPictureUrls: Array<Scalars['String']>
  speaker?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  pptUrl: Array<Scalars['String']>
  liveUrl?: Maybe<WatchUrlInfo>
  needQuiz?: Maybe<Scalars['Boolean']>
  needAssistance?: Maybe<Scalars['Boolean']>
  budget?: Maybe<StandardMeetingBudgetInfo>
  approvalStatus?: Maybe<StandardMeetingApprovalStatus>
  products: Array<ProductInfo>
  webLiveTool?: Maybe<WebLiveToolInfo>
}

export type StandardMeetingSearchInput = {
  region?: Maybe<Scalars['String']>
  keyword?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  activityType?: Maybe<Scalars['String']>
  projectTypes?: Maybe<Array<Scalars['String']>>
  activityTypes?: Maybe<Array<Scalars['String']>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  approvalStartTime?: Maybe<Scalars['Timestamp']>
  approvalEndTime?: Maybe<Scalars['Timestamp']>
  meetingType?: Maybe<Scalars['String']>
  meetingTypes?: Maybe<Array<Scalars['String']>>
  productIds?: Maybe<Array<Scalars['String']>>
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>
  vendor?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}

export type StandardMeetingSearchQuery = {
  keyword?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  meetingStatus?: Maybe<Array<StandardMeetingStatus>>
  productIds?: Maybe<Array<Scalars['String']>>
  meetingType?: Maybe<Scalars['String']>
  meetingTypes?: Maybe<Array<Scalars['String']>>
  serviceBizStatus?: Maybe<Array<StandardMeetingServiceBizStatus>>
  startTime?: Maybe<Scalars['Timestamp']>
  endTime?: Maybe<Scalars['Timestamp']>
  sort?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['String']>
  approveUsers?: Maybe<Array<Scalars['String']>>
  meetingRegion?: Maybe<Array<Scalars['String']>>
  timeField?: Maybe<Scalars['String']>
  meetingCostCenter?: Maybe<Array<Scalars['String']>>
}

export enum StandardMeetingServiceBizStatus {
  MeetingInProgress = 'MEETING_IN_PROGRESS',
  MeetingInApproving = 'MEETING_IN_APPROVING',
  MeetingRetreated = 'MEETING_RETREATED',
  MeetingSettlementConfirming = 'MEETING_SETTLEMENT_CONFIRMING',
  MeetingInSettlement = 'MEETING_IN_SETTLEMENT',
  MeetingFinished = 'MEETING_FINISHED',
  MeetingSettlementWithoutTax = 'MEETING_SETTLEMENT_WITHOUT_TAX',
  MeetingSettlementNotPass = 'MEETING_SETTLEMENT_NOT_PASS'
}

export enum StandardMeetingStatus {
  NotStart = 'NOT_START',
  Ongoing = 'ONGOING',
  Finish = 'FINISH',
  Exception = 'EXCEPTION',
  Deleted = 'DELETED'
}

export enum StandardMeetingTypeAttenderType {
  None = 'NONE',
  Creator = 'CREATOR',
  Speaker = 'SPEAKER',
  Attender = 'ATTENDER',
  Guest = 'GUEST',
  Subscriber = 'SUBSCRIBER'
}

export enum StatPeriod {
  All = 'ALL',
  Day = 'DAY',
  CurrentWeek = 'CURRENT_WEEK',
  CurrentMonth = 'CURRENT_MONTH',
  RecentWeek = 'RECENT_WEEK',
  RecentMonth = 'RECENT_MONTH'
}

export type SubmitReviewContentInput = {
  id?: Maybe<Scalars['String']>
  key: Scalars['String']
  value: Scalars['String']
}

export type SubmitReviewInput = {
  reviewId?: Maybe<Scalars['String']>
  contents: Array<SubmitReviewContentInput>
  canOpen?: Maybe<Scalars['Boolean']>
}

export type SystemBusinessRelation = {
  __typename?: 'SystemBusinessRelation'
  id?: Maybe<Scalars['String']>
  businessCategoryCode?: Maybe<Scalars['String']>
  orderCategoryCode?: Maybe<Scalars['String']>
  consumptionCategoryCode?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  remark?: Maybe<Scalars['String']>
}

export type TargetRegionNodeBaseInfo = {
  __typename?: 'TargetRegionNodeBaseInfo'
  groupId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type TargetRegionNodeInfo = {
  __typename?: 'TargetRegionNodeInfo'
  groupId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  children: Array<TargetRegionNodeInfo>
}

export type TaskDetail = {
  __typename?: 'TaskDetail'
  type?: Maybe<DistributeOrderType>
  num?: Maybe<Scalars['Int']>
}

export type TaskInfo = {
  __typename?: 'TaskInfo'
  taskId?: Maybe<Scalars['String']>
  approver?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  nodeSort?: Maybe<Scalars['Int']>
  opinion?: Maybe<Scalars['String']>
  createTime?: Maybe<Scalars['Timestamp']>
  approverInfo?: Maybe<AccountInfo>
  logDatas?: Maybe<Array<LogData>>
}

export type TemplateInput = {
  id?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  remark?: Maybe<Scalars['String']>
  unitPrice?: Maybe<Scalars['String']>
  templateName?: Maybe<Scalars['String']>
  templateCode?: Maybe<Scalars['String']>
  templateCategory?: Maybe<Scalars['String']>
  documentCategory?: Maybe<Scalars['String']>
  templateAttribute?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  instanceMax?: Maybe<Scalars['Int']>
  instanceMin?: Maybe<Scalars['Int']>
}

/** u平台报价阶段状态 */
export enum UpMeetingQuotationStatus {
  UAwaitOrder = 'U_AWAIT_ORDER',
  UAwaitQuotation = 'U_AWAIT_QUOTATION',
  UAwaitConfirm = 'U_AWAIT_CONFIRM',
  UAwaitApproval = 'U_AWAIT_APPROVAL'
}

export type UpMeetingQuotationStatusOption = {
  __typename?: 'UPMeetingQuotationStatusOption'
  value: UpMeetingQuotationStatus
  label: Scalars['String']
}

export type UpMeetingSignInInfo = {
  __typename?: 'UPMeetingSignInInfo'
  accountId: Scalars['String']
  meetingId: Scalars['String']
  address: Scalars['String']
  time: Scalars['String']
  userInfo?: Maybe<Scalars['JSONObject']>
}

/** u平台会议状态 */
export enum UpMeetingStatus {
  UMeetingDraft = 'U_MEETING_DRAFT',
  UMeetingWithdraw = 'U_MEETING_WITHDRAW',
  UMeetingApproving = 'U_MEETING_APPROVING',
  UMeetingQuotation = 'U_MEETING_QUOTATION',
  UMeetingInProgress = 'U_MEETING_IN_PROGRESS',
  UMeetingInSettlement = 'U_MEETING_IN_SETTLEMENT',
  UMeetingFinished = 'U_MEETING_FINISHED',
  UMeetingForbidden = 'U_MEETING_FORBIDDEN',
  UMeetingCanceled = 'U_MEETING_CANCELED'
}

export type UpMeetingStatusOption = {
  __typename?: 'UPMeetingStatusOption'
  value: UpMeetingStatus
  label: Scalars['String']
}

export type UnsignedBase = {
  __typename?: 'UnsignedBase'
  amapId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  categoryList?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  openingTime?: Maybe<Scalars['String']>
  mealType?: Maybe<Scalars['String']>
  payType?: Maybe<Array<RestaurantPayType>>
  phone?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  isAlipay?: Maybe<Scalars['Int']>
  outdoorUrl?: Maybe<Scalars['String']>
}

export type UnsignedBaseInput = {
  amapId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  categoryList?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  openingTime?: Maybe<Scalars['String']>
  mealType?: Maybe<Scalars['String']>
  payType?: Maybe<Array<RestaurantPayType>>
  phone?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  isAlipay?: Maybe<Scalars['Int']>
  outdoorUrl?: Maybe<Scalars['String']>
}

export type UnsignedRestaurant = {
  __typename?: 'UnsignedRestaurant'
  amapId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  categoryList?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  openingTime?: Maybe<Scalars['String']>
  mealType?: Maybe<Scalars['String']>
  payType?: Maybe<Array<RestaurantPayType>>
  phone?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  isAlipay?: Maybe<Scalars['Int']>
  outdoorUrl?: Maybe<Scalars['String']>
  restaurantId?: Maybe<Scalars['String']>
  restaurantName?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  indoorUrlList?: Maybe<Array<Scalars['String']>>
}

export type UnsignedStore = {
  __typename?: 'UnsignedStore'
  amapId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  categoryList?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  openingTime?: Maybe<Scalars['String']>
  mealType?: Maybe<Scalars['String']>
  payType?: Maybe<Array<RestaurantPayType>>
  phone?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  isAlipay?: Maybe<Scalars['Int']>
  outdoorUrl?: Maybe<Scalars['String']>
  supermarketId?: Maybe<Scalars['String']>
  supermarketName?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  lng?: Maybe<Scalars['String']>
  indoorUrlList?: Maybe<Array<Scalars['String']>>
}

export type UnsignedVendor = UnsignedStore | UnsignedRestaurant

export type UnsignedVendorAddInput = {
  amapId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  categoryList?: Maybe<Array<Scalars['String']>>
  avg?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['String']>
  openingTime?: Maybe<Scalars['String']>
  mealType?: Maybe<Scalars['String']>
  payType?: Maybe<Array<RestaurantPayType>>
  phone?: Maybe<Scalars['String']>
  district?: Maybe<Scalars['String']>
  province?: Maybe<Scalars['String']>
  isAlipay?: Maybe<Scalars['Int']>
  outdoorUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  indoors?: Maybe<Array<Scalars['String']>>
  lng?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  recommendType?: Maybe<Scalars['Int']>
}

export type UpProduct = {
  __typename?: 'UpProduct'
  id: Scalars['String']
  companyId: Scalars['String']
  productName?: Maybe<Scalars['String']>
  isEnable?: Maybe<Scalars['Boolean']>
  productCategory?: Maybe<Scalars['String']>
  productCode?: Maybe<Scalars['String']>
  remark?: Maybe<Scalars['String']>
}

export type UpdateHengruiMeeting = {
  type: Scalars['String']
  name?: Maybe<Scalars['String']>
  cityId: Scalars['String']
  address?: Maybe<Scalars['String']>
  startTime: Scalars['String']
  endTime: Scalars['String']
  peopleNum: Scalars['Float']
  desc?: Maybe<Scalars['String']>
  projectType?: Maybe<Scalars['String']>
  activityType?: Maybe<Scalars['String']>
  costcenter?: Maybe<Scalars['String']>
  speakers?: Maybe<Array<HengruiMeetingSpeakerInput>>
  hospital?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  needBudget?: Maybe<Scalars['Boolean']>
  /** 0: 默认，1：个人，2：流程 */
  budgetChannel?: Maybe<Scalars['Int']>
  budgetNumber?: Maybe<Scalars['String']>
  productType?: Maybe<Scalars['String']>
  activityPurpose?: Maybe<Scalars['String']>
  activityRequire?: Maybe<Scalars['String']>
  conferencePlatform?: Maybe<Scalars['String']>
  conferenceNo?: Maybe<Scalars['String']>
  /** 会议种类 */
  category?: Maybe<Scalars['String']>
  location?: Maybe<MeetingChooseLocationInput>
  therapyArea?: Maybe<Scalars['String']>
  drugName?: Maybe<Scalars['String']>
  meetingId: Scalars['String']
}

export type UploadInvoicesFailed = {
  __typename?: 'UploadInvoicesFailed'
  index: Scalars['Int']
  errorMessage?: Maybe<Scalars['String']>
}

export type UploadInvoicesFailedInfo = {
  __typename?: 'UploadInvoicesFailedInfo'
  invoices: Array<UploadInvoicesFailed>
  tickets: Array<UploadInvoicesFailed>
}

export type UserManagementDetail = {
  __typename?: 'UserManagementDetail'
  budgetId?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  business?: Maybe<Scalars['String']>
  regionId?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  areaId?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  financeNo?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountMobile?: Maybe<Scalars['String']>
  accountEmail?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  accountStatus?: Maybe<Scalars['String']>
}

export type VerifyDoctorBankInfoResponse = {
  __typename?: 'VerifyDoctorBankInfoResponse'
  idCardNo?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type VerifyDoctorCardInfoResponse = {
  __typename?: 'VerifyDoctorCardInfoResponse'
  resultMsg?: Maybe<Scalars['String']>
  pass?: Maybe<Scalars['Boolean']>
}

export type WatchUrlInfo = {
  __typename?: 'WatchUrlInfo'
  pc?: Maybe<Scalars['String']>
  mobile?: Maybe<Scalars['String']>
}

export type WebLiveToolInfo = {
  __typename?: 'WebLiveToolInfo'
  hostLoginAddr?: Maybe<Scalars['String']>
  hostLoginToken?: Maybe<Scalars['String']>
  guestLoginAddr?: Maybe<Scalars['String']>
  guestLoginToken?: Maybe<Scalars['String']>
}

export type AdminCompanyDetailQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type AdminCompanyDetailQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'companyId' | 'cnName'>
  >
}

export type AdminCompanyGroupTreeQueryVariables = Exact<{
  companyId: Scalars['String']
  groupType?: Maybe<Scalars['String']>
}>

export type AdminCompanyGroupTreeQuery = { __typename?: 'Query' } & {
  groupTree: Query['adminCompanyGroupTree']
}

export type AdminCompanyGroupQueryVariables = Exact<{
  groupId: Scalars['String']
}>

export type AdminCompanyGroupQuery = { __typename?: 'Query' } & {
  group?: Maybe<
    { __typename?: 'GroupNodeInfo' } & Pick<
      GroupNodeInfo,
      'name' | 'groupId' | 'code'
    > & {
        owner?: Maybe<
          { __typename?: 'AccountInfo' } & Pick<
            AccountInfo,
            'cnName' | 'companyMobile'
          >
        >
      }
  >
}

export type AdminGroupMembersQueryVariables = Exact<{
  groupId: Scalars['String']
  pager?: Maybe<PagerInput>
}>

export type AdminGroupMembersQuery = { __typename?: 'Query' } & {
  members?: Maybe<
    { __typename?: 'MemberPaginatedResponse' } & {
      items: Array<
        { __typename?: 'AccountInfo' } & Pick<
          AccountInfo,
          'cnName' | 'companyMobile'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'total' | 'pageSize'
      >
    }
  >
}

export type AdminCompanyApprovalFormsQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type AdminCompanyApprovalFormsQuery = { __typename?: 'Query' } & {
  forms?: Maybe<
    Array<
      { __typename?: 'AthenaForm' } & Pick<
        AthenaForm,
        'name' | 'desc' | 'id' | 'key'
      >
    >
  >
}

export type AdminCompanyApprovalDetailQueryVariables = Exact<{
  formId: Scalars['String']
}>

export type AdminCompanyApprovalDetailQuery = { __typename?: 'Query' } & {
  detail: Query['adminApprovalFlowDetail']
}

export type AdminApprovalNodeTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type AdminApprovalNodeTypesQuery = { __typename?: 'Query' } & {
  types?: Maybe<
    Array<{ __typename?: 'NodeType' } & Pick<NodeType, 'name' | 'code' | 'id'>>
  >
}

export type AdminApprovalFlowSaveMutationVariables = Exact<{
  formId: Scalars['String']
  companyId: Scalars['String']
  nodeGroups: Array<NodeGroupInfo> | NodeGroupInfo
}>

export type AdminApprovalFlowSaveMutation = { __typename?: 'Mutation' } & {
  result: Mutation['adminSaveApprovalFlowDetail']
}

export type AdminCreateApprovalFlowMutationVariables = Exact<{
  name: Scalars['String']
  companyId: Scalars['String']
  key: Scalars['String']
  desc: Scalars['String']
  id: Scalars['String']
}>

export type AdminCreateApprovalFlowMutation = { __typename?: 'Mutation' } & {
  result: Mutation['adminCreateApprovalFlow']
}

export type ArchiveListQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<ArchiveSearchParams>
}>

export type ArchiveListQuery = { __typename?: 'Query' } & {
  queryArchiveList: { __typename?: 'PageQueryArchivesResult' } & {
    items: Array<
      { __typename?: 'ArchiveInfo' } & Pick<
        ArchiveInfo,
        'id' | 'describe' | 'createTimestamp' | 'isEnable'
      > & {
          url: ArchiveInfo['archiveUrl']
          title: ArchiveInfo['archiveTitle']
          type: ArchiveInfo['archiveType']
        } & {
          creator?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
          >
          products?: Maybe<
            Array<
              { __typename?: 'ArchiveTagInfo' } & Pick<
                ArchiveTagInfo,
                'tag' | 'id'
              >
            >
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'pageSize' | 'currentPage' | 'total'
    >
  }
}

export type ProductsListQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
}>

export type ProductsListQuery = { __typename?: 'Query' } & {
  getArchiveProductsList?: Maybe<
    Array<
      { __typename?: 'ArchiveTagInfo' } & Pick<ArchiveTagInfo, 'tag' | 'id'>
    >
  >
}

export type ArchiveSignedUrlQueryVariables = Exact<{
  archiveId: Scalars['String']
}>

export type ArchiveSignedUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getArchiveSignedUrl'
>

export type DisableArchiveMutationVariables = Exact<{
  archiveId: Scalars['String']
}>

export type DisableArchiveMutation = { __typename?: 'Mutation' } & {
  disableArchive?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<BaseResponse, 'success' | 'message'>
  >
}

export type EnableArchiveMutationVariables = Exact<{
  archiveId: Scalars['String']
}>

export type EnableArchiveMutation = { __typename?: 'Mutation' } & {
  enableArchive?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<BaseResponse, 'success' | 'message'>
  >
}

export type SaveArchiveMutationVariables = Exact<{
  params: ArchiveCreateParams
}>

export type SaveArchiveMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveArchive'
>

export type HansohBudgetListQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<BudgetSearch>
}>

export type HansohBudgetListQuery = { __typename?: 'Query' } & {
  budget?: Maybe<
    { __typename?: 'PaginatedHansohBudgets' } & {
      items: Array<
        { __typename?: 'HansohBudget' } & Pick<
          HansohBudget,
          | 'accountId'
          | 'area'
          | 'region'
          | 'business'
          | 'financeNo'
          | 'accountName'
        > & {
            budget?: Maybe<
              Array<
                { __typename?: 'BudgetUnit' } & Pick<
                  BudgetUnit,
                  | 'id'
                  | 'month'
                  | 'year'
                  | 'budgetAmount'
                  | 'deductionAmount'
                  | 'usedAmount'
                  | 'balanceAmount'
                  | 'increaseAmount'
                >
              >
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type PreImportHansohBudgetMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  data: Array<BudgetImportData> | BudgetImportData
}>

export type PreImportHansohBudgetMutation = { __typename?: 'Mutation' } & {
  result: Mutation['preImportHansohBudget']
}

export type ImportHansohBudgetMutationVariables = Exact<{
  importId: Scalars['String']
  data?: Maybe<Array<BudgetImportData> | BudgetImportData>
}>

export type ImportHansohBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CompanyGroupQueryVariables = Exact<{
  type: Scalars['String']
  level?: Maybe<Scalars['String']>
  pid?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}>

export type CompanyGroupQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'AccountGroupInfo' } & Pick<
      AccountGroupInfo,
      'groupId' | 'name' | 'pid'
    >
  >
}

export type PreImportProcessBudgetMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  data: Array<ProcessBudgetImportData> | ProcessBudgetImportData
}>

export type PreImportProcessBudgetMutation = { __typename?: 'Mutation' } & {
  result: Mutation['preImportProcessBudget']
}

export type ImportProcessBudgetMutationVariables = Exact<{
  importId: Scalars['String']
  data: Array<ProcessBudgetImportData> | ProcessBudgetImportData
}>

export type ImportProcessBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type ProcessBudgetListQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<ProcessBudgetSearch>
}>

export type ProcessBudgetListQuery = { __typename?: 'Query' } & {
  budget?: Maybe<
    { __typename?: 'PaginatedProcessBudget' } & {
      items: Array<
        { __typename?: 'ProcessBudget' } & Pick<
          ProcessBudget,
          'id' | 'budgetNumber' | 'budgetAmount' | 'usedFee' | 'balanceFee'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type PersonalBudgetBusinessDepartmentQueryVariables = Exact<{
  [key: string]: never
}>

export type PersonalBudgetBusinessDepartmentQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'PersonalBudgetBusinessDepartment' } & Pick<
        PersonalBudgetBusinessDepartment,
        'accountId' | 'businessId' | 'businessName'
      >
    >
  >
}

export type ProcessBudgetDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type ProcessBudgetDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'ProcessBudget' } & Pick<
      ProcessBudget,
      'id' | 'budgetNumber' | 'budgetAmount' | 'usedFee' | 'balanceFee'
    >
  >
}

export type ProcessBudgetLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<ProcessBudgetSearch>
}>

export type ProcessBudgetLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedBudgetsLog' } & {
      items: Array<
        { __typename?: 'ProcessBudgetLog' } & Pick<
          ProcessBudgetLog,
          | 'operationDate'
          | 'changeValue'
          | 'snapshotBudgetAmount'
          | 'snapshotUsedFee'
          | 'snapshotBalanceFee'
          | 'currentBalanceFee'
          | 'description'
          | 'operator'
          | 'operatorName'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type AdjustProcessBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  changeValue: Scalars['String']
  description?: Maybe<Scalars['String']>
}>

export type AdjustProcessBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type PersonalBudgetDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type PersonalBudgetDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalBudgetOwnerLimit' } & Pick<
      PersonalBudgetOwnerLimit,
      | 'id'
      | 'companyId'
      | 'accountId'
      | 'accountName'
      | 'budgetType'
      | 'financeNo'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'businessId'
      | 'business'
      | 'region'
      | 'area'
      | 'startTimestamp'
      | 'endTimestamp'
      | 'year'
      | 'month'
    > & {
        accountInfo?: Maybe<
          { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'companyMobile'>
        >
      }
  >
}

export type PersonalBudgetLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<PersonalBudgetSearch>
}>

export type PersonalBudgetLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalBudgetsLog' } & {
      items: Array<
        { __typename?: 'PersonalBudgetLog' } & Pick<
          PersonalBudgetLog,
          | 'id'
          | 'operationDate'
          | 'BudgetAmountChanged'
          | 'BudgetAmountBefore'
          | 'increaseAmount'
          | 'usedAmount'
          | 'balanceAmountChanged'
          | 'balanceAmountBefore'
          | 'operatorRemark'
          | 'operator'
        > & {
            operatorAccountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type LimitSwapPeopleQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>
}>

export type LimitSwapPeopleQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'LimitSwapPeople' } & Pick<
        LimitSwapPeople,
        'accountId' | 'companyId' | 'financeNo' | 'name' | 'phone'
      >
    >
  >
}

export type AdjustPersonalBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  amount: Scalars['String']
  operatorRemark?: Maybe<Scalars['String']>
}>

export type AdjustPersonalBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type SwapPersonalBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  sourceAccountId: Scalars['String']
  targetAccountId: Scalars['String']
  amount: Scalars['String']
  operatorRemark?: Maybe<Scalars['String']>
}>

export type SwapPersonalBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type SearchBudgetUsedDetailQueryVariables = Exact<{
  param?: Maybe<PersonalBudgetUsedDetailSearchWithPager>
}>

export type SearchBudgetUsedDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalBudgetUsedDetail' } & {
      items: Array<
        { __typename?: 'PersonalBudgetUsedDetail' } & Pick<
          PersonalBudgetUsedDetail,
          | 'meetingId'
          | 'meetingName'
          | 'attenderName'
          | 'attenderPhone'
          | 'attenderFee'
          | 'submitTime'
          | 'accountId'
        > & {
            accountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<
                AccountInfo,
                'cnName' | 'staffId'
              > & {
                  department?: Maybe<
                    { __typename?: 'AccountGroupInfo' } & Pick<
                      AccountGroupInfo,
                      'name'
                    >
                  >
                }
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type SearchPersonalBudgetByMonthQueryVariables = Exact<{
  param?: Maybe<OwnerLimitSearch>
}>

export type SearchPersonalBudgetByMonthQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalBudgetOwnerLimit' } & Pick<
      PersonalBudgetOwnerLimit,
      | 'id'
      | 'companyId'
      | 'accountId'
      | 'accountName'
      | 'budgetType'
      | 'financeNo'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'businessId'
      | 'business'
      | 'region'
      | 'area'
      | 'startTimestamp'
      | 'endTimestamp'
      | 'month'
    > & {
        accountInfo?: Maybe<
          { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'companyMobile'>
        >
      }
  >
}

export type PreImportHansohInPlanBudgetMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  data: Array<PersonalPlanBudgetImportData> | PersonalPlanBudgetImportData
}>

export type PreImportHansohInPlanBudgetMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['preImportHansohInPlanBudget'] }

export type ImportHansohInPlanBudgetMutationVariables = Exact<{
  importId: Scalars['String']
  data: Array<PersonalPlanBudgetImportData> | PersonalPlanBudgetImportData
}>

export type ImportHansohInPlanBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type InPlanBudgetQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<PersonalPlanBudgetSearch>
}>

export type InPlanBudgetQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalPlanBudgets' } & {
      items: Array<
        { __typename?: 'PersonalPlanBudgetInfo' } & Pick<
          PersonalPlanBudgetInfo,
          | 'budgetId'
          | 'businessId'
          | 'business'
          | 'accountId'
          | 'accountName'
          | 'financeNo'
          | 'year'
          | 'lastMonthAmount'
          | 'lastMonthBalanceAmount'
          | 'currentMonthAmount'
          | 'currentMonthIncreaseAmount'
          | 'currentMonthUsedAmount'
          | 'nextMonthAmount'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type InPlanBudgetDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type InPlanBudgetDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalPlanBudget' } & Pick<
      PersonalPlanBudget,
      | 'id'
      | 'companyId'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'end'
      | 'year'
      | 'month'
      | 'budgetType'
      | 'isLock'
      | 'isEnable'
      | 'remark'
      | 'creator'
      | 'modifier'
      | 'accountId'
      | 'accountName'
      | 'accountMobile'
      | 'business'
      | 'financeNo'
    >
  >
}

export type AdjustInPlanBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  amount: Scalars['String']
  operatorRemark?: Maybe<Scalars['String']>
}>

export type AdjustInPlanBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type PersonalPlanBudgetUsedDetailQueryVariables = Exact<{
  param?: Maybe<PersonalPlanBudgetUsedDetailSearchWithPager>
}>

export type PersonalPlanBudgetUsedDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalPlanBudgetUsedDetails' } & {
      items: Array<
        { __typename?: 'PersonalPlanBudgetUsedDetail' } & Pick<
          PersonalPlanBudgetUsedDetail,
          | 'formCategory'
          | 'consumptionCategoryCode'
          | 'targetName'
          | 'accountId'
          | 'accountName'
          | 'accountMobile'
          | 'planAmount'
          | 'actualAmount'
          | 'submitTime'
          | 'orderId'
          | 'claimAmount'
          | 'deductionAmount'
          | 'financialDeductionAmount'
        > & {
            budgetUsedConsumptionCategory?: Maybe<
              { __typename?: 'OrderCategory' } & Pick<
                OrderCategory,
                'id' | 'title' | 'describe' | 'code'
              >
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type InPlanBudgetLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<PersonalPlanBudgetLogSearch>
}>

export type InPlanBudgetLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalPlanBudgetLogs' } & {
      items: Array<
        { __typename?: 'PersonalPlanBudgetLog' } & Pick<
          PersonalPlanBudgetLog,
          | 'id'
          | 'budgetId'
          | 'accountId'
          | 'budgetAmountChanged'
          | 'budgetAmountBefore'
          | 'increaseAmount'
          | 'usedAmount'
          | 'balanceAmountChanged'
          | 'balanceAmountBefore'
          | 'remark'
          | 'operator'
          | 'operationTime'
        > & {
            operatorAccountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type InPlanBudgetByMonthQueryVariables = Exact<{
  param?: Maybe<PersonalPlanMonthBudgetSearch>
}>

export type InPlanBudgetByMonthQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalPlanBudget' } & Pick<
      PersonalPlanBudget,
      | 'id'
      | 'companyId'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'end'
      | 'year'
      | 'month'
      | 'budgetType'
      | 'isLock'
      | 'isEnable'
      | 'remark'
      | 'creator'
      | 'modifier'
      | 'accountId'
      | 'accountName'
      | 'accountMobile'
      | 'business'
      | 'financeNo'
    >
  >
}

export type PageUserManagementQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<BudgetSearch>
}>

export type PageUserManagementQuery = { __typename?: 'Query' } & {
  budget?: Maybe<
    { __typename?: 'PaginatedUserManagementDetails' } & {
      items: Array<
        { __typename?: 'UserManagementDetail' } & Pick<
          UserManagementDetail,
          | 'budgetId'
          | 'companyId'
          | 'businessId'
          | 'business'
          | 'regionId'
          | 'region'
          | 'areaId'
          | 'area'
          | 'financeNo'
          | 'accountName'
          | 'accountId'
          | 'accountMobile'
          | 'accountEmail'
          | 'year'
          | 'month'
          | 'accountStatus'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type PersonalPlanBudgetBusinessAuthorityQueryVariables = Exact<{
  param?: Maybe<PersonalPlanBudgetAuthoritySearch>
}>

export type PersonalPlanBudgetBusinessAuthorityQuery = {
  __typename?: 'Query'
} & {
  result?: Maybe<
    Array<
      { __typename?: 'PersonalPlanBudgetAuthority' } & Pick<
        PersonalPlanBudgetAuthority,
        'accountId' | 'businessId' | 'businessName'
      >
    >
  >
}

export type PreImportHansohOutPlanBudgetMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  data: Array<PersonalPlanBudgetImportData> | PersonalPlanBudgetImportData
}>

export type PreImportHansohOutPlanBudgetMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['preImportHansohOutPlanBudget'] }

export type ImportHansohOutPlanBudgetMutationVariables = Exact<{
  importId: Scalars['String']
  data: Array<PersonalPlanBudgetImportData> | PersonalPlanBudgetImportData
}>

export type ImportHansohOutPlanBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type OutPlanBudgetQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<PersonalPlanBudgetSearch>
}>

export type OutPlanBudgetQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalPlanBudgets' } & {
      items: Array<
        { __typename?: 'PersonalPlanBudgetInfo' } & Pick<
          PersonalPlanBudgetInfo,
          | 'budgetId'
          | 'businessId'
          | 'business'
          | 'accountId'
          | 'accountName'
          | 'financeNo'
          | 'year'
          | 'lastMonthAmount'
          | 'lastMonthBalanceAmount'
          | 'currentMonthAmount'
          | 'currentMonthIncreaseAmount'
          | 'currentMonthUsedAmount'
          | 'nextMonthAmount'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type OutPlanBudgetDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type OutPlanBudgetDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalPlanBudget' } & Pick<
      PersonalPlanBudget,
      | 'id'
      | 'companyId'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'end'
      | 'year'
      | 'month'
      | 'budgetType'
      | 'isLock'
      | 'isEnable'
      | 'remark'
      | 'creator'
      | 'modifier'
      | 'accountId'
      | 'accountName'
      | 'accountMobile'
      | 'business'
      | 'financeNo'
    >
  >
}

export type AdjustOutPlanBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  amount: Scalars['String']
  operatorRemark?: Maybe<Scalars['String']>
}>

export type AdjustOutPlanBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type OutPlanBudgetLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<PersonalPlanBudgetLogSearch>
}>

export type OutPlanBudgetLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedPersonalPlanBudgetLogs' } & {
      items: Array<
        { __typename?: 'PersonalPlanBudgetLog' } & Pick<
          PersonalPlanBudgetLog,
          | 'id'
          | 'budgetId'
          | 'accountId'
          | 'budgetAmountChanged'
          | 'budgetAmountBefore'
          | 'increaseAmount'
          | 'usedAmount'
          | 'balanceAmountChanged'
          | 'balanceAmountBefore'
          | 'remark'
          | 'operator'
          | 'operationTime'
        > & {
            operatorAccountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type OutPlanBudgetByMonthQueryVariables = Exact<{
  param?: Maybe<PersonalPlanMonthBudgetSearch>
}>

export type OutPlanBudgetByMonthQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PersonalPlanBudget' } & Pick<
      PersonalPlanBudget,
      | 'id'
      | 'companyId'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'end'
      | 'year'
      | 'month'
      | 'budgetType'
      | 'isLock'
      | 'isEnable'
      | 'remark'
      | 'creator'
      | 'modifier'
      | 'accountId'
      | 'accountName'
      | 'accountMobile'
    >
  >
}

export type PreImportHansohCostCenterBudgetMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  data: Array<CostCenterBudgetImportData> | CostCenterBudgetImportData
}>

export type PreImportHansohCostCenterBudgetMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['preImportHansohCostCenterBudget'] }

export type ImportHansohCostCenterBudgetMutationVariables = Exact<{
  importId: Scalars['String']
}>

export type ImportHansohCostCenterBudgetMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CostCenterBudgetQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<CostCenterBudgetSearch>
}>

export type CostCenterBudgetQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedCostCenterBudgets' } & {
      items: Array<
        { __typename?: 'CostCenterBudgetInfo' } & Pick<
          CostCenterBudgetInfo,
          | 'id'
          | 'groupId'
          | 'groupName'
          | 'year'
          | 'lastMonthAmount'
          | 'lastMonthBalanceAmount'
          | 'currentMonthAmount'
          | 'currentMonthIncreaseAmount'
          | 'currentMonthUsedAmount'
          | 'nextMonthAmount'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type CostCenterBudgetDetailQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type CostCenterBudgetDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'CostCenterBudget' } & Pick<
      CostCenterBudget,
      | 'id'
      | 'companyId'
      | 'groupId'
      | 'groupName'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'year'
      | 'month'
      | 'end'
    >
  >
}

export type AdjustCostCenterBudgetMutationVariables = Exact<{
  id: Scalars['Int']
  amount: Scalars['String']
  operatorRemark?: Maybe<Scalars['String']>
}>

export type AdjustCostCenterBudgetMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CostCenterBudgetUsedDetailQueryVariables = Exact<{
  param?: Maybe<CostCenterBudgetUsedDetailSearchWithPager>
}>

export type CostCenterBudgetUsedDetailQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedCostCenterBudgetUsedDetails' } & {
      items: Array<
        { __typename?: 'CostCenterBudgetUsedDetail' } & Pick<
          CostCenterBudgetUsedDetail,
          | 'formCategory'
          | 'consumptionCategoryCode'
          | 'targetName'
          | 'accountId'
          | 'accountName'
          | 'accountMobile'
          | 'planAmount'
          | 'actualAmount'
          | 'submitTime'
          | 'orderId'
          | 'claimAmount'
          | 'deductionAmount'
          | 'financialDeductionAmount'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type CostCenterBudgetLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<CostCenterBudgetLogSearch>
}>

export type CostCenterBudgetLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedCostCenterBudgetLogs' } & {
      items: Array<
        { __typename?: 'CostCenterBudgetLog' } & Pick<
          CostCenterBudgetLog,
          | 'id'
          | 'budgetId'
          | 'groupId'
          | 'budgetAmountChanged'
          | 'budgetAmountBefore'
          | 'increaseAmount'
          | 'usedAmount'
          | 'balanceAmountChanged'
          | 'balanceAmountBefore'
          | 'remark'
          | 'operator'
          | 'operationTime'
        > & {
            operatorAccountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type CostCenterBudgetByYearQueryVariables = Exact<{
  param?: Maybe<CostCenterYearBudgetSearch>
}>

export type CostCenterBudgetByYearQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'CostCenterBudget' } & Pick<
      CostCenterBudget,
      | 'id'
      | 'companyId'
      | 'groupId'
      | 'groupName'
      | 'channel'
      | 'budgetAmount'
      | 'usedAmount'
      | 'balanceAmount'
      | 'deductionAmount'
      | 'increaseAmount'
      | 'initialBudget'
      | 'start'
      | 'year'
      | 'month'
      | 'end'
    >
  >
}

export type UpadminCostCentersQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  pid?: Maybe<Scalars['String']>
}>

export type UpadminCostCentersQuery = { __typename?: 'Query' } & {
  items?: Maybe<
    Array<
      { __typename?: 'AccountGroupInfo' } & Pick<
        AccountGroupInfo,
        'name' | 'pid'
      > & { id: AccountGroupInfo['groupId'] }
    >
  >
}

export type CityListQueryVariables = Exact<{ [key: string]: never }>

export type CityListQuery = { __typename?: 'Query' } & {
  cities: Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name' | 'id'>>
}

export type ProvinceListQueryVariables = Exact<{ [key: string]: never }>

export type ProvinceListQuery = { __typename?: 'Query' } & {
  cities: Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name' | 'id'>>
}

export type QueryDocumentsQueryVariables = Exact<{
  isAdmin?: Maybe<Scalars['Boolean']>
  search?: Maybe<DocumentArchiveSearchParamsWithPager>
}>

export type QueryDocumentsQuery = { __typename?: 'Query' } & {
  documents: { __typename?: 'DocumentArchivePagerSearchResult' } & {
    items: Array<
      { __typename?: 'DocumentInfo' } & {
        archive?: Maybe<
          Array<
            { __typename?: 'ArchiveInfo' } & Pick<
              ArchiveInfo,
              'id' | 'signedUrl'
            > & {
                tags?: Maybe<
                  Array<
                    { __typename?: 'ArchiveTag' } & Pick<
                      ArchiveTag,
                      'typeName' | 'id'
                    >
                  >
                >
                archiveTags?: Maybe<
                  Array<
                    { __typename?: 'ArchiveTagInfo' } & Pick<
                      ArchiveTagInfo,
                      'id' | 'tag'
                    >
                  >
                >
                creator?: Maybe<
                  { __typename?: 'AccountInfo' } & {
                    id: AccountInfo['accountId']
                    name: AccountInfo['cnName']
                  }
                >
              }
          >
        >
        document?: Maybe<
          { __typename?: 'Document' } & Pick<
            Document,
            | 'remark'
            | 'language'
            | 'title'
            | 'id'
            | 'author'
            | 'describe'
            | 'source'
            | 'createdTimestamp'
            | 'releaseTimestamp'
            | 'sphere'
            | 'enabled'
          > & {
              category?: Maybe<
                { __typename?: 'DocumentArchiveCategory' } & Pick<
                  DocumentArchiveCategory,
                  'title' | 'id'
                >
              >
              productInfo?: Maybe<
                { __typename?: 'UpProduct' } & Pick<
                  UpProduct,
                  'id' | 'productName'
                >
              >
              company?: Maybe<
                { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'cnName'>
              >
              documentSphere?: Maybe<
                { __typename?: 'DocumentSphereInfo' } & Pick<
                  DocumentSphereInfo,
                  'sphereCode' | 'sphereName'
                >
              >
            }
        >
      }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type AddDocumentMutationVariables = Exact<{
  info: AddDocumentInput
}>

export type AddDocumentMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type EditDocumentMutationVariables = Exact<{
  info: AddDocumentInput
}>

export type EditDocumentMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type QueryArchiveTagsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
}>

export type QueryArchiveTagsQuery = { __typename?: 'Query' } & {
  tags: Array<
    { __typename?: 'ArchiveTagInfo' } & Pick<
      ArchiveTagInfo,
      'id' | 'refId' | 'tag'
    >
  >
}

export type QueryDocumentCategoriesQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
}>

export type QueryDocumentCategoriesQuery = { __typename?: 'Query' } & {
  categories: Array<
    { __typename?: 'DocumentArchiveCategory' } & Pick<
      DocumentArchiveCategory,
      'id' | 'title' | 'describe' | 'remark' | 'enabled'
    >
  >
}

export type RemoveDocumentMutationVariables = Exact<{
  documentId: Scalars['String']
}>

export type RemoveDocumentMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type QueryDocumentSphereQueryVariables = Exact<{
  params: DocumentSphereSearchParams
}>

export type QueryDocumentSphereQuery = { __typename?: 'Query' } & {
  spheres: Array<
    { __typename?: 'DocumentSphereInfo' } & Pick<
      DocumentSphereInfo,
      'id' | 'sphereName' | 'sphereCode' | 'describe' | 'enabled' | 'remark'
    >
  >
}

export type CreateCompanySphereMutationVariables = Exact<{
  sphere: DocumentSphereInfoInput
}>

export type CreateCompanySphereMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type AttenderInfoFragment = {
  __typename?: 'StandardMeetingBaseAttender'
} & Pick<
  StandardMeetingBaseAttender,
  | 'id'
  | 'attenderId'
  | 'attenderData'
  | 'channel'
  | 'attenderStatus'
  | 'attenderFee'
> & {
    attenderInfo?: Maybe<
      { __typename?: 'AccountInfo' } & Pick<
        AccountInfo,
        | 'cnName'
        | 'companyId'
        | 'companyMobile'
        | 'verifyResult'
        | 'verifyImages'
      > & {
          hospital: AccountInfo['custom1']
          province: AccountInfo['custom2']
        } & {
          userCertificationImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages' | 'cnName'
            >
          >
          userTitleCertificationImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages'
            >
          >
          userComplexMaterialCertImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages'
            >
          >
          titleInfo?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          bankInfo?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certNo' | 'ssqBankName' | 'qkkBankName'
            > & { bankName: AccountCertInfo['custom1'] }
          >
          minPointLimitConfig?: Maybe<
            { __typename?: 'DoctorTitleRuleConfig' } & Pick<
              DoctorTitleRuleConfig,
              'pointLimit'
            >
          >
        }
    >
  }

export type ExportMemberInfoFragment = {
  __typename?: 'StandardMeetingBaseAttender'
} & Pick<
  StandardMeetingBaseAttender,
  | 'id'
  | 'attenderId'
  | 'serviceFee'
  | 'attenderSettlementTime'
  | 'billTime'
  | 'billStatus'
  | 'attenderData'
  | 'attenderFee'
  | 'attenderStatus'
> & {
    totalFee: StandardMeetingBaseAttender['actualFee']
    cnName: StandardMeetingBaseAttender['attenderName']
    companyMobile: StandardMeetingBaseAttender['attenderPhone']
  } & {
    attenderInfo?: Maybe<
      { __typename?: 'AccountInfo' } & { hospital: AccountInfo['custom1'] }
    >
  }

export type MeetingDetailFragment = { __typename?: 'StandardMeetingInfo' } & {
  meetingType: StandardMeetingInfo['hengruiImage_categoryName']
  activityType: StandardMeetingInfo['hengruiImage_activityType']
  hospital: StandardMeetingInfo['hengruiImage_hospital']
  projectType: StandardMeetingInfo['hengruiImage_projectType']
  status: StandardMeetingInfo['hengruiImage_status']
  statusText: StandardMeetingInfo['hengruiImage_statusText']
  statusExtra: StandardMeetingInfo['hengruiImage_statusExtra']
  approveStatus: StandardMeetingInfo['hengruiImage_approveStatus']
  isCurrentApprover: StandardMeetingInfo['isCurrentApproverOrYHLXS']
  forwardSignatureOwner: StandardMeetingInfo['hengruiImage_forwardSignatureOwner']
  approveUser: StandardMeetingInfo['hengruiImage_meetingApproveUser']
} & {
  base?: Maybe<
    { __typename?: 'StandardMeetingBaseData' } & Pick<
      StandardMeetingBaseData,
      | 'type'
      | 'activityType'
      | 'name'
      | 'cityId'
      | 'id'
      | 'status'
      | 'startTime'
      | 'createTime'
      | 'endTime'
      | 'applyTime'
      | 'approvalTime'
      | 'desc'
      | 'code'
      | 'budgetNumber'
      | 'budgetChannel'
      | 'needBudget'
    > & {
        belongProduct?: Maybe<
          { __typename?: 'UpProduct' } & Pick<UpProduct, 'id' | 'productName'>
        >
        owner?: Maybe<
          { __typename?: 'AccountInfo' } & Pick<
            AccountInfo,
            'cnName' | 'companyMobile' | 'verifyResult'
          > & {
              region?: Maybe<
                { __typename?: 'AccountGroupInfo' } & Pick<
                  AccountGroupInfo,
                  'name'
                >
              >
              company?: Maybe<
                { __typename?: 'CompanyInfo' } & Pick<
                  CompanyInfo,
                  'cnName' | 'name'
                >
              >
            }
        >
      }
  >
  speakers?: Maybe<
    Array<{ __typename?: 'StandardMeetingBaseAttender' } & AttenderInfoFragment>
  >
  doctors?: Maybe<
    Array<{ __typename?: 'StandardMeetingBaseAttender' } & AttenderInfoFragment>
  >
  city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
  costcenter?: Maybe<
    { __typename?: 'HRCostCenter' } & Pick<HrCostCenter, 'name'>
  >
  mettingRegion?: Maybe<
    { __typename?: 'AccountGroupInfo' } & Pick<AccountGroupInfo, 'name'> & {
        province?: Maybe<
          Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'id' | 'name'>>
        >
      }
  >
  vendor?: Maybe<
    { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'companyId' | 'cnName'>
  >
}

export type YhlMeetingDetailFragment = {
  __typename?: 'StandardMeetingInfo'
} & {
  meetingType: StandardMeetingInfo['hengruiImage_categoryName']
  activityType: StandardMeetingInfo['hengruiImage_activityType']
  hospital: StandardMeetingInfo['hengruiImage_hospital']
  projectType: StandardMeetingInfo['hengruiImage_projectType']
  status: StandardMeetingInfo['hengruiImage_status']
  statusText: StandardMeetingInfo['hengruiImage_statusText']
  statusExtra: StandardMeetingInfo['hengruiImage_statusExtra']
  approveStatus: StandardMeetingInfo['hengruiImage_approveStatus']
  isCurrentApprover: StandardMeetingInfo['isCurrentApproverOrYHLXS']
  forwardSignatureOwner: StandardMeetingInfo['hengruiImage_forwardSignatureOwner']
  approveUser: StandardMeetingInfo['hengruiImage_meetingApproveUser']
} & {
  base?: Maybe<
    { __typename?: 'StandardMeetingBaseData' } & Pick<
      StandardMeetingBaseData,
      | 'type'
      | 'activityType'
      | 'name'
      | 'cityId'
      | 'id'
      | 'status'
      | 'startTime'
      | 'createTime'
      | 'endTime'
      | 'applyTime'
      | 'approvalTime'
      | 'desc'
      | 'code'
      | 'budgetNumber'
      | 'budgetChannel'
      | 'needBudget'
    > & {
        belongProduct?: Maybe<
          { __typename?: 'UpProduct' } & Pick<UpProduct, 'id' | 'productName'>
        >
        owner?: Maybe<
          { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'> & {
              company?: Maybe<
                { __typename?: 'CompanyInfo' } & Pick<
                  CompanyInfo,
                  'cnName' | 'name'
                >
              >
            }
        >
      }
  >
  speakers?: Maybe<
    Array<{ __typename?: 'StandardMeetingBaseAttender' } & AttenderInfoFragment>
  >
  doctors?: Maybe<
    Array<{ __typename?: 'StandardMeetingBaseAttender' } & AttenderInfoFragment>
  >
  city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
  costcenter?: Maybe<
    { __typename?: 'HRCostCenter' } & Pick<HrCostCenter, 'name'>
  >
  mettingRegion?: Maybe<
    { __typename?: 'AccountGroupInfo' } & Pick<AccountGroupInfo, 'name'> & {
        province?: Maybe<
          Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'id' | 'name'>>
        >
      }
  >
  vendor?: Maybe<
    { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'companyId' | 'cnName'>
  >
}

export type HrMeetingListQueryVariables = Exact<{
  isFinanceAdmin?: Maybe<Scalars['Boolean']>
  pageQuery?: Maybe<PageSearchStandardMeetingInput>
}>

export type HrMeetingListQuery = { __typename?: 'Query' } & {
  meetings: { __typename?: 'PageQueryStandardMeetingResult' } & {
    items: Array<{ __typename?: 'StandardMeetingInfo' } & MeetingDetailFragment>
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type HrDoctorListQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  search?: Maybe<Scalars['String']>
}>

export type HrDoctorListQuery = { __typename?: 'Query' } & {
  doctors: { __typename?: 'MemberPaginatedResponse' } & {
    items: Array<
      { __typename?: 'AccountInfo' } & Pick<
        AccountInfo,
        'accountId' | 'cnName' | 'status' | 'companyMobile' | 'defaultChannel'
      > & { hospital: AccountInfo['custom1'] } & {
          department?: Maybe<
            { __typename?: 'DepartmentInfo' } & Pick<DepartmentInfo, 'name'>
          >
          groupInfo?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<AccountGroupInfo, 'name'>
          >
          bankInfo?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'qkkBankName' | 'ssqBankName' | 'custom1' | 'certNo'
            >
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type ChangeChannelMutationVariables = Exact<{
  channel: Scalars['String']
  accountId: Scalars['String']
}>

export type ChangeChannelMutation = { __typename?: 'Mutation' } & {
  result: Mutation['changeAccountDefaultChannel']
}

export type HrMeetingDetailQueryVariables = Exact<{
  meetingId?: Maybe<Scalars['String']>
}>

export type HrMeetingDetailQuery = { __typename?: 'Query' } & {
  detail?: Maybe<
    { __typename?: 'StandardMeetingInfo' } & {
      activityType: StandardMeetingInfo['hengruiImage_activityType']
      meetingType: StandardMeetingInfo['hengruiImage_categoryName']
      schedulePics: StandardMeetingInfo['hengruiImage_schedulePics']
      signPics: StandardMeetingInfo['hengruiImage_signPics']
      checkReports: StandardMeetingInfo['hengruiImage_checkReports']
      conferencePlatformInfo: StandardMeetingInfo['hengruiImage_academicConferencePlatformInfo']
      meetingCategory: StandardMeetingInfo['academicConference_meetingCategory']
    } & {
      costs: Array<
        { __typename?: 'StandardMeetingCost' } & Pick<
          StandardMeetingCost,
          'amount' | 'type' | 'note'
        >
      >
      meetingAuditRecord?: Maybe<
        Array<
          { __typename?: 'AuditRecordResult' } & Pick<
            AuditRecordResult,
            | 'taskId'
            | 'meetingId'
            | 'attenderId'
            | 'accountId'
            | 'verifyType'
            | 'verifyDimension'
            | 'status'
            | 'key'
            | 'value'
            | 'remark'
            | 'properties'
          >
        >
      >
    } & MeetingDetailFragment
  >
}

export type YhlMeetingDetailQueryVariables = Exact<{
  meetingId?: Maybe<Scalars['String']>
}>

export type YhlMeetingDetailQuery = { __typename?: 'Query' } & {
  detail?: Maybe<
    { __typename?: 'StandardMeetingInfo' } & {
      activityType: StandardMeetingInfo['hengruiImage_activityType']
      meetingType: StandardMeetingInfo['hengruiImage_categoryName']
      schedulePics: StandardMeetingInfo['hengruiImage_schedulePics']
      signPics: StandardMeetingInfo['hengruiImage_signPics']
      checkReports: StandardMeetingInfo['hengruiImage_checkReports']
      conferencePlatformInfo: StandardMeetingInfo['hengruiImage_academicConferencePlatformInfo']
      meetingCategory: StandardMeetingInfo['academicConference_meetingCategory']
    } & {
      costs: Array<
        { __typename?: 'StandardMeetingCost' } & Pick<
          StandardMeetingCost,
          'amount' | 'type' | 'note'
        >
      >
      meetingAuditRecord?: Maybe<
        Array<
          { __typename?: 'AuditRecordResult' } & Pick<
            AuditRecordResult,
            | 'taskId'
            | 'meetingId'
            | 'attenderId'
            | 'accountId'
            | 'verifyType'
            | 'verifyDimension'
            | 'status'
          >
        >
      >
    } & YhlMeetingDetailFragment
  >
}

export type HrRegionListQueryVariables = Exact<{ [key: string]: never }>

export type HrRegionListQuery = { __typename?: 'Query' } & {
  regions: Array<
    { __typename?: 'AccountGroupInfo' } & Pick<AccountGroupInfo, 'name'> & {
        id: AccountGroupInfo['groupId']
      }
  >
}

export type HrMeetingBizStatusQueryVariables = Exact<{ [key: string]: never }>

export type HrMeetingBizStatusQuery = { __typename?: 'Query' } & {
  status: Array<
    { __typename?: 'HRMeetingStatusOption' } & Pick<
      HrMeetingStatusOption,
      'label' | 'value'
    >
  >
}

export type HrProjectTypesQueryVariables = Exact<{ [key: string]: never }>

export type HrProjectTypesQuery = { __typename?: 'Query' } & {
  types: Query['hengruiImage_meetingTypes']
}

export type HrAttenderContractQueryVariables = Exact<{
  meetingId: Scalars['String']
  attenderId: Scalars['String']
}>

export type HrAttenderContractQuery = { __typename?: 'Query' } & {
  contract: Query['hengruiImage_attenderContract']
}

export type HrUploadAttenderInvoicesMutationVariables = Exact<{
  meetingId: Scalars['String']
  attenderId: Scalars['String']
  urls: Array<Scalars['String']> | Scalars['String']
}>

export type HrUploadAttenderInvoicesMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_uploadAttenderInvoices']
}

export type HrUploadAttenderTaxReceiptsMutationVariables = Exact<{
  meetingId: Scalars['String']
  attenderId: Scalars['String']
  urls: Array<Scalars['String']> | Scalars['String']
}>

export type HrUploadAttenderTaxReceiptsMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['hengruiImage_uploadAttenderTaxReceipts'] }

export type HrUploadAttenderPaymentVoucherMutationVariables = Exact<{
  meetingId: Scalars['String']
  attenderId: Scalars['String']
  urls: Array<Scalars['String']> | Scalars['String']
}>

export type HrUploadAttenderPaymentVoucherMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['hengruiImage_uploadAttenderPaymentVoucher'] }

export type MarkHrMeetingPaymentFinishedMutationVariables = Exact<{
  meetingId: Scalars['String']
}>

export type MarkHrMeetingPaymentFinishedMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['hengruiImage_markHRMeetingPaymentFinished'] }

export type StartHrMeetingSettlementMutationVariables = Exact<{
  meetingId: Scalars['String']
}>

export type StartHrMeetingSettlementMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_startHRMeetingSettlement']
}

export type HrGetSettlementZipUrlMutationVariables = Exact<{
  meetingId: Scalars['String']
}>

export type HrGetSettlementZipUrlMutation = { __typename?: 'Mutation' } & {
  url: Mutation['hengruiImage_getSettlementZipUrl']
}

export type HrQueryAttenderPaymentDetailQueryVariables = Exact<{
  paymentId?: Maybe<Scalars['String']>
  channel?: Maybe<HadesPaymentChannel>
  type?: Maybe<HadesReceiptType>
}>

export type HrQueryAttenderPaymentDetailQuery = { __typename?: 'Query' } & {
  detail: Query['hengruiImage_attenderPaymentDetail']
}

export type BalanceRecordMutationVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  paymentTime: Scalars['String']
  type: Scalars['String']
  amount: Scalars['Float']
  regionId: Scalars['String']
}>

export type BalanceRecordMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengrui_logBalanceRecord']
}

export type BalanceListQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  regionIds?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type BalanceListQuery = { __typename?: 'Query' } & {
  lists: Array<
    { __typename?: 'BalancePoolDetail' } & Pick<
      BalancePoolDetail,
      'regionId' | 'totalPrepaid' | 'frozenFee' | 'usedFee' | 'balance'
    > & {
        regionInfo?: Maybe<
          { __typename?: 'AccountGroupInfo' } & Pick<
            AccountGroupInfo,
            'groupId' | 'name' | 'pid'
          >
        >
        recordInfo?: Maybe<
          Array<
            { __typename?: 'BalancePoolRecord' } & Pick<
              BalancePoolRecord,
              'paymentTime' | 'amount'
            >
          >
        >
      }
  >
}

export type HrMeetingExportQueryVariables = Exact<{
  isFinanceAdmin?: Maybe<Scalars['Boolean']>
  pageQuery?: Maybe<PageSearchStandardMeetingInput>
}>

export type HrMeetingExportQuery = { __typename?: 'Query' } & {
  meetings: { __typename?: 'PageQueryStandardMeetingResult' } & {
    items: Array<
      { __typename?: 'StandardMeetingInfo' } & {
        activityType: StandardMeetingInfo['hengruiImage_activityType']
        meetingType: StandardMeetingInfo['hengruiImage_categoryName']
        statusText: StandardMeetingInfo['hengruiImage_statusText']
        conferencePlatformInfo: StandardMeetingInfo['hengruiImage_academicConferencePlatformInfo']
      } & {
        region?: Maybe<
          { __typename?: 'AccountGroupInfo' } & Pick<AccountGroupInfo, 'name'>
        >
        speakers?: Maybe<
          Array<
            {
              __typename?: 'StandardMeetingBaseAttender'
            } & ExportMemberInfoFragment
          >
        >
        attenders?: Maybe<
          Array<
            {
              __typename?: 'StandardMeetingBaseAttender'
            } & ExportMemberInfoFragment
          >
        >
        baseData?: Maybe<
          { __typename?: 'StandardMeetingBaseData' } & Pick<
            StandardMeetingBaseData,
            | 'type'
            | 'activityType'
            | 'id'
            | 'budgetNumber'
            | 'budgetChannel'
            | 'name'
            | 'code'
            | 'startTime'
            | 'endTime'
            | 'approvalTime'
          > & {
              owner?: Maybe<
                { __typename?: 'AccountInfo' } & Pick<
                  AccountInfo,
                  'cnName' | 'staffId' | 'companyMobile'
                > & {
                    legalEntity?: Maybe<
                      { __typename?: 'CompanyInfo' } & Pick<
                        CompanyInfo,
                        'cnName'
                      >
                    >
                  }
              >
            }
        >
        costCenter?: Maybe<
          { __typename?: 'HRCostCenter' } & Pick<HrCostCenter, 'name'>
        >
        vendor?: Maybe<
          { __typename?: 'CompanyInfo' } & Pick<
            CompanyInfo,
            'companyId' | 'cnName'
          >
        >
        city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
      }
    >
  }
}

export type BatchMarkBillStatusMutationVariables = Exact<{
  data: Array<StandardMeetingBillMarkStatus> | StandardMeetingBillMarkStatus
}>

export type BatchMarkBillStatusMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_batchMarkBillStatus']
}

export type HrVendorsListQueryVariables = Exact<{ [key: string]: never }>

export type HrVendorsListQuery = { __typename?: 'Query' } & {
  vendors?: Maybe<
    Array<
      { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'companyId' | 'cnName'>
    >
  >
}

export type HrApprovalListQueryVariables = Exact<{
  approveStatus?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}>

export type HrApprovalListQuery = { __typename?: 'Query' } & {
  approval: { __typename?: 'PageQueryStandardMeetingResult' } & {
    items: Array<
      { __typename?: 'StandardMeetingInfo' } & {
        activityType: StandardMeetingInfo['hengruiImage_activityType']
        meetingType: StandardMeetingInfo['hengruiImage_categoryName']
        approverOwner: StandardMeetingInfo['hengruiImage_ApproverOwner']
      } & {
        city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
        attenders?: Maybe<
          Array<
            { __typename?: 'StandardMeetingBaseAttender' } & Pick<
              StandardMeetingBaseAttender,
              'attenderFee'
            >
          >
        >
        vendor?: Maybe<
          { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'cnName'>
        >
        meetingInfo?: Maybe<
          { __typename?: 'StandardMeetingBaseData' } & Pick<
            StandardMeetingBaseData,
            | 'id'
            | 'needBudget'
            | 'budgetNumber'
            | 'budgetChannel'
            | 'type'
            | 'code'
            | 'peopleNum'
            | 'createTime'
            | 'startTime'
            | 'endTime'
            | 'applyTime'
            | 'submitTime'
            | 'approvalTime'
            | 'name'
          > & {
              belongProduct?: Maybe<
                { __typename?: 'UpProduct' } & Pick<
                  UpProduct,
                  'id' | 'productName'
                >
              >
              owner?: Maybe<
                { __typename?: 'AccountInfo' } & Pick<
                  AccountInfo,
                  'cnName' | 'companyMobile'
                > & {
                    region?: Maybe<
                      { __typename?: 'AccountGroupInfo' } & Pick<
                        AccountGroupInfo,
                        'name'
                      >
                    >
                    structure?: Maybe<
                      { __typename?: 'AccountGroupInfo' } & Pick<
                        AccountGroupInfo,
                        'name'
                      >
                    >
                  }
              >
            }
        >
        otherProperties?: Maybe<
          Array<
            { __typename?: 'StandardMeetingOtherProperties' } & Pick<
              StandardMeetingOtherProperties,
              'type' | 'key' | 'value' | 'diyType'
            >
          >
        >
      }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type HrMeetingForwardSignatureMutationVariables = Exact<{
  meetingId: Scalars['String']
  reason?: Maybe<Scalars['String']>
  approveUser?: Maybe<Scalars['String']>
  insertPosition?: Maybe<Scalars['String']>
}>

export type HrMeetingForwardSignatureMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_forwardSignatureMeeting']
}

export type HrMeetingRetreatMutationVariables = Exact<{
  meetingId: Scalars['String']
  reason?: Maybe<Scalars['String']>
  approveUser?: Maybe<Scalars['String']>
}>

export type HrMeetingRetreatMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_retreatMeeting']
}

export type HrMeetingConfirmMutationVariables = Exact<{
  meetingId: Scalars['String']
  approveUser?: Maybe<Scalars['String']>
}>

export type HrMeetingConfirmMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_confirmMeeting']
}

export type ApprovalExportQueryVariables = Exact<{
  approveStatus?: Maybe<Scalars['String']>
  pageQuery?: Maybe<PageQueryStandardMeetingInput>
}>

export type ApprovalExportQuery = { __typename?: 'Query' } & {
  approval: { __typename?: 'PageQueryStandardMeetingResult' } & {
    items: Array<
      { __typename?: 'StandardMeetingInfo' } & {
        activityType: StandardMeetingInfo['hengruiImage_activityType']
        meetingType: StandardMeetingInfo['hengruiImage_categoryName']
      } & {
        city?: Maybe<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'name'>>
        attenders?: Maybe<
          Array<
            { __typename?: 'StandardMeetingBaseAttender' } & Pick<
              StandardMeetingBaseAttender,
              'attenderFee'
            >
          >
        >
        vendor?: Maybe<
          { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'cnName'>
        >
        meetingInfo?: Maybe<
          { __typename?: 'StandardMeetingBaseData' } & Pick<
            StandardMeetingBaseData,
            | 'id'
            | 'needBudget'
            | 'budgetChannel'
            | 'budgetNumber'
            | 'type'
            | 'peopleNum'
            | 'createTime'
            | 'startTime'
            | 'endTime'
            | 'approvalTime'
            | 'code'
            | 'name'
          > & {
              belongProduct?: Maybe<
                { __typename?: 'UpProduct' } & Pick<
                  UpProduct,
                  'id' | 'productName'
                >
              >
              owner?: Maybe<
                { __typename?: 'AccountInfo' } & Pick<
                  AccountInfo,
                  'cnName' | 'companyMobile'
                > & {
                    region?: Maybe<
                      { __typename?: 'AccountGroupInfo' } & Pick<
                        AccountGroupInfo,
                        'name'
                      >
                    >
                    structure?: Maybe<
                      { __typename?: 'AccountGroupInfo' } & Pick<
                        AccountGroupInfo,
                        'name'
                      >
                    >
                  }
              >
            }
        >
      }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type AvailableChannelsQueryVariables = Exact<{ [key: string]: never }>

export type AvailableChannelsQuery = { __typename?: 'Query' } & {
  result: Query['availableChannels']
}

export type CompanyUpProductsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  currentUser?: Maybe<Scalars['Boolean']>
}>

export type CompanyUpProductsQuery = { __typename?: 'Query' } & {
  companyUpProducts?: Maybe<
    Array<
      { __typename?: 'UpProduct' } & Pick<
        UpProduct,
        'id' | 'productCode' | 'productName'
      >
    >
  >
}

export type RemoveDoctorsMutationVariables = Exact<{
  attenderId: Scalars['String']
  meetingId: Scalars['String']
  attenderType?: Maybe<StandardMeetingTypeAttenderType>
}>

export type RemoveDoctorsMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_removeDoctors']
}

export type UpadminQueryDoctorTitlesQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
}>

export type UpadminQueryDoctorTitlesQuery = { __typename?: 'Query' } & {
  upadminQueryDoctorTitles?: Maybe<
    Array<
      { __typename?: 'AccountGroupInfo' } & Pick<
        AccountGroupInfo,
        'name' | 'groupId'
      >
    >
  >
}

export type UpdateMeetingCategoryTitleBlkListMutationVariables = Exact<{
  categoryId: Scalars['String']
  titleList: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateMeetingCategoryTitleBlkListMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<BaseResponse, 'success' | 'message'>
  >
}

export type UpdateMeetingCategoryPropertiesMutationVariables = Exact<{
  categoryId: Scalars['String']
  sort?: Maybe<Scalars['Int']>
  properties?: Maybe<Scalars['String']>
}>

export type UpdateMeetingCategoryPropertiesMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<BaseResponse, 'success' | 'message'>
  >
}

export type VerifyDoctorBankInfoMutationVariables = Exact<{
  name: Scalars['String']
  idCardNo: Scalars['String']
  mobile?: Maybe<Scalars['String']>
  bankCardNo?: Maybe<Scalars['String']>
}>

export type VerifyDoctorBankInfoMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_verifyDoctorBankInfo']
}

export type VerifyDoctorCardInfoMutationVariables = Exact<{
  idCardNo: Scalars['String']
  name: Scalars['String']
  mobile: Scalars['String']
}>

export type VerifyDoctorCardInfoMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'VerifyDoctorCardInfoResponse' } & Pick<
    VerifyDoctorCardInfoResponse,
    'pass' | 'resultMsg'
  >
}

export type CheckDoctorIdCardImagesMutationVariables = Exact<{
  name: Scalars['String']
  doctorIdCardImages: Array<Scalars['String']> | Scalars['String']
  channel: Scalars['String']
}>

export type CheckDoctorIdCardImagesMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_checkDoctorIdCardImages']
}

export type QueryCompanyRegionsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  level?: Maybe<Scalars['String']>
}>

export type QueryCompanyRegionsQuery = { __typename?: 'Query' } & {
  regions?: Maybe<
    Array<
      { __typename?: 'AccountGroupInfo' } & Pick<
        AccountGroupInfo,
        'groupId' | 'name' | 'pid' | 'level' | 'status'
      > & { provinceId: AccountGroupInfo['custom4'] } & {
          province?: Maybe<
            Array<{ __typename?: 'CityInfo' } & Pick<CityInfo, 'id' | 'name'>>
          >
        }
    >
  >
}

export type UpdateCompanyGroupMutationVariables = Exact<{
  group: AccountGroupInfoInput
}>

export type UpdateCompanyGroupMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type QueryCompanyAccountsByKeywordQueryVariables = Exact<{
  companyId: Scalars['String']
  keyword?: Maybe<Scalars['String']>
}>

export type QueryCompanyAccountsByKeywordQuery = { __typename?: 'Query' } & {
  users: Array<
    { __typename?: 'AccountBaseInfo' } & Pick<
      AccountBaseInfo,
      'accountId' | 'cnName' | 'companyMobile'
    >
  >
}

export type SearchMeetingUserAuthorityQueryVariables = Exact<{
  pageQuery: PageQueryMeetingUserAuthorityInput
}>

export type SearchMeetingUserAuthorityQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PageQueryMeetingUserAuthorityResult' } & {
      items: Array<
        { __typename?: 'MeetingUserAuthorityDetail' } & Pick<
          MeetingUserAuthorityDetail,
          | 'id'
          | 'accountId'
          | 'companyId'
          | 'name'
          | 'phone'
          | 'activityCode'
          | 'regionId'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type AddMeetingUserAuthorityMutationVariables = Exact<{
  auth: MeetingUserAuthorityInfo
}>

export type AddMeetingUserAuthorityMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type EditMeetingUserAuthorityMutationVariables = Exact<{
  auth: MeetingUserAuthorityInfo
}>

export type EditMeetingUserAuthorityMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type RemoveMeetingUserAuthorityMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RemoveMeetingUserAuthorityMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type ApproveUserQueryVariables = Exact<{ [key: string]: never }>

export type ApproveUserQuery = { __typename?: 'Query' } & {
  result: Query['hengruiImage_approveUser']
}

export type SaveMeetingVerificationInfoMutationVariables = Exact<{
  meetingId: Scalars['String']
  meetingType: Scalars['String']
  meetingProperties?: Maybe<Scalars['String']>
  attenderProperties?: Maybe<Scalars['String']>
  approver?: Maybe<Scalars['String']>
}>

export type SaveMeetingVerificationInfoMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['hengruiImage_saveMeetingVerificationInfo'] }

export type SyncDoctorVerifyResultMutationVariables = Exact<{
  accountId: Scalars['String']
  verifyResult?: Maybe<Scalars['String']>
  verifyImages?: Maybe<Scalars['String']>
}>

export type SyncDoctorVerifyResultMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type DoctorTitleRuleConfigQueryVariables = Exact<{
  searchInput: SearchDoctorTitleRuleConfigInput
}>

export type DoctorTitleRuleConfigQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'DoctorTitleRuleConfig' } & Pick<
      DoctorTitleRuleConfig,
      | 'id'
      | 'companyId'
      | 'categoryCode'
      | 'titleGrade'
      | 'role'
      | 'durationStart'
      | 'durationEnd'
      | 'pointLimit'
      | 'remark'
    >
  >
}

export type UpadminDistributeOrderMutationVariables = Exact<{
  type: DistributeOrderType
}>

export type UpadminDistributeOrderMutation = { __typename?: 'Mutation' } & {
  result: Mutation['upadminDistributeOrder']
}

export type MeetingVerificationInfoQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>
  meetingId?: Maybe<Scalars['String']>
  meetingType?: Maybe<Scalars['String']>
}>

export type MeetingVerificationInfoQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingApprovalRecordData' } & Pick<
        MeetingApprovalRecordData,
        | 'id'
        | 'meetingId'
        | 'meetingType'
        | 'meetingProperties'
        | 'attenderProperties'
      >
    >
  >
}

export type UpadminCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type UpadminCompaniesQuery = { __typename?: 'Query' } & {
  companies: Array<
    { __typename?: 'CompanyInfo' } & Pick<
      CompanyInfo,
      'name' | 'cnName' | 'ownerId' | 'status'
    > & { id: CompanyInfo['companyId'] }
  >
}

export type UpadminDoctorCompaniesQueryVariables = Exact<{
  [key: string]: never
}>

export type UpadminDoctorCompaniesQuery = { __typename?: 'Query' } & {
  companies: Array<
    { __typename?: 'CompanyInfo' } & Pick<
      CompanyInfo,
      'name' | 'cnName' | 'ownerId' | 'status'
    > & { id: CompanyInfo['companyId'] }
  >
}

export type UpadminRegionsQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type UpadminRegionsQuery = { __typename?: 'Query' } & {
  regions: Array<
    { __typename?: 'AccountGroupInfo' } & Pick<
      AccountGroupInfo,
      'name' | 'pid'
    > & { id: AccountGroupInfo['groupId'] }
  >
}

export type BankCardInfoQueryVariables = Exact<{
  bankCardNo: Scalars['String']
}>

export type BankCardInfoQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'BankCardInfo' } & Pick<
      BankCardInfo,
      'cardBin' | 'cardDesc' | 'cardName' | 'cardType' | 'bankName' | 'bankId'
    >
  >
}

export type FetchCnapsCodeQueryVariables = Exact<{
  bankBranchName?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
}>

export type FetchCnapsCodeQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'BankCodeInfo' } & Pick<
      BankCodeInfo,
      'bankName' | 'bankCode'
    >
  >
}

export type DepartmentsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  query?: Maybe<MemberQueryInput>
}>

export type DepartmentsQuery = { __typename?: 'Query' } & {
  result: { __typename?: 'GroupPaginationRes' } & {
    items: Array<
      { __typename?: 'AccountGroupInfo' } & Pick<
        AccountGroupInfo,
        'groupId' | 'name' | 'pid'
      >
    >
  }
}

export type DoctorTitlesQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  query?: Maybe<MemberQueryInput>
}>

export type DoctorTitlesQuery = { __typename?: 'Query' } & {
  result: { __typename?: 'GroupPaginationRes' } & {
    items: Array<
      { __typename?: 'AccountGroupInfo' } & Pick<
        AccountGroupInfo,
        'groupId' | 'name' | 'pid'
      >
    >
  }
}

export type UpadminEditOrAddDoctorMutationVariables = Exact<{
  doctor: EditDoctorInfo
}>

export type UpadminEditOrAddDoctorMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'message' | 'success'
  >
}

export type UpadminSearchAttendersQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<QueryMeetingAttenders>
}>

export type UpadminSearchAttendersQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'PageResultMeetingAttenders' } & {
    items: Array<
      { __typename?: 'StandardMeetingBaseAttender' } & Pick<
        StandardMeetingBaseAttender,
        | 'id'
        | 'settlementRecord'
        | 'attenderId'
        | 'attenderName'
        | 'qkkStudioOpenStatus'
        | 'attenderFee'
        | 'rate'
        | 'serviceFee'
        | 'meetingId'
        | 'attenderData'
        | 'attenderStatus'
        | 'billStatus'
        | 'billTime'
        | 'attenderSettlementTime'
      > & { totalFee: StandardMeetingBaseAttender['actualFee'] } & {
          attenderInfo?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'custom1'>
          >
          meetingInfo?: Maybe<
            { __typename?: 'StandardMeetingInfo' } & {
              activityType: StandardMeetingInfo['hengruiImage_activityType']
              meetingType: StandardMeetingInfo['hengruiImage_categoryName']
              hospital: StandardMeetingInfo['hengruiImage_hospital']
              status: StandardMeetingInfo['hengruiImage_status']
              statusExtra: StandardMeetingInfo['hengruiImage_statusExtra']
              statusText: StandardMeetingInfo['hengruiImage_statusText']
            } & {
              speakers?: Maybe<
                Array<
                  { __typename?: 'StandardMeetingBaseAttender' } & Pick<
                    StandardMeetingBaseAttender,
                    'id' | 'attenderId'
                  >
                >
              >
              metingRegion?: Maybe<
                { __typename?: 'AccountGroupInfo' } & Pick<
                  AccountGroupInfo,
                  'name'
                >
              >
              costCenter?: Maybe<
                { __typename?: 'HRCostCenter' } & Pick<HrCostCenter, 'name'>
              >
              vendor?: Maybe<
                { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'cnName'>
              >
              base?: Maybe<
                { __typename?: 'StandardMeetingBaseData' } & Pick<
                  StandardMeetingBaseData,
                  'type' | 'name' | 'startTime' | 'endTime' | 'createTime'
                > & {
                    owner?: Maybe<
                      { __typename?: 'AccountInfo' } & Pick<
                        AccountInfo,
                        'cnName'
                      > & {
                          company?: Maybe<
                            { __typename?: 'CompanyInfo' } & Pick<
                              CompanyInfo,
                              'cnName'
                            >
                          >
                        }
                    >
                  }
              >
            }
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type UpadminFinanceApproveMutationVariables = Exact<{
  meetingId: Scalars['String']
  reason?: Maybe<Scalars['String']>
  result: FinanceApproveResult
}>

export type UpadminFinanceApproveMutation = { __typename?: 'Mutation' } & {
  result: Mutation['upadminFinanceApprove']
}

export type UpadminDoctorListQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<QueryMeetingAttenders>
}>

export type UpadminDoctorListQuery = { __typename?: 'Query' } & {
  doctors: { __typename?: 'MemberPaginatedResponse' } & {
    items: Array<
      { __typename?: 'AccountInfo' } & Pick<
        AccountInfo,
        | 'accountId'
        | 'cnName'
        | 'status'
        | 'disableReason'
        | 'companyMobile'
        | 'companyId'
        | 'defaultChannel'
        | 'verifyResult'
        | 'verifyImages'
        | 'companyEmail'
        | 'staffId'
        | 'qkkStudioOpenStatus'
      > & {
          hospital: AccountInfo['custom1']
          openId: AccountInfo['docMicroAppBinded']
        } & {
          structure?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          costcenter?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          department?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          groupInfo?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          hospitalInfo?: Maybe<
            { __typename?: 'AccountGroupInfo' } & Pick<
              AccountGroupInfo,
              'name' | 'groupId'
            >
          >
          bankInfo?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              | 'qkkBankName'
              | 'ssqBankName'
              | 'custom1'
              | 'rawCertNo'
              | 'cnFirstPinyin'
            >
          >
          idCardInfo?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'rawCertNo' | 'certImages'
            >
          >
          userCertificationImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages' | 'cnName'
            >
          >
          userTitleCertificationImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages'
            >
          >
          userComplexMaterialCertImages?: Maybe<
            { __typename?: 'AccountCertInfo' } & Pick<
              AccountCertInfo,
              'certImages'
            >
          >
          company?: Maybe<
            { __typename?: 'CompanyInfo' } & Pick<CompanyInfo, 'cnName'>
          >
          budgetWhite?: Maybe<
            { __typename?: 'BudgetWhiteDetail' } & Pick<
              BudgetWhiteDetail,
              | 'id'
              | 'accountId'
              | 'companyId'
              | 'name'
              | 'phone'
              | 'enabled'
              | 'remark'
            >
          >
          doctorMeetingWhite?: Maybe<
            { __typename?: 'MeetingWhiteInfo' } & Pick<
              MeetingWhiteInfo,
              | 'id'
              | 'accountId'
              | 'companyId'
              | 'name'
              | 'phone'
              | 'enabled'
              | 'remark'
            >
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type UpadminFinancePayToHzBankMutationVariables = Exact<{
  meetingId: Scalars['String']
  attenderId: Scalars['String']
}>

export type UpadminFinancePayToHzBankMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'message' | 'success'
  >
}

export type UpadminFinancePageQuerySettlementsQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<MeetingSettlementSearch>
}>

export type UpadminFinancePageQuerySettlementsQuery = {
  __typename?: 'Query'
} & {
  settlements: { __typename?: 'PaginatedMeetingSettlementResult' } & {
    items: Array<
      { __typename?: 'MeetingSettlement' } & Pick<
        MeetingSettlement,
        'settlementId' | 'state' | 'settlementStatus'
      > & {
          attenders?: Maybe<
            { __typename?: 'StandardMeetingBaseAttender' } & Pick<
              StandardMeetingBaseAttender,
              | 'meetingId'
              | 'attenderId'
              | 'attenderFee'
              | 'rate'
              | 'serviceFee'
              | 'attenderData'
              | 'settlementRecord'
            > & { totalFee: StandardMeetingBaseAttender['actualFee'] } & {
                attenderInfo?: Maybe<
                  { __typename?: 'AccountInfo' } & Pick<
                    AccountInfo,
                    'custom1' | 'cnName' | 'companyMobile'
                  >
                >
                meetingInfo?: Maybe<
                  { __typename?: 'StandardMeetingInfo' } & {
                    metingRegion?: Maybe<
                      { __typename?: 'AccountGroupInfo' } & Pick<
                        AccountGroupInfo,
                        'name'
                      >
                    >
                    base?: Maybe<
                      { __typename?: 'StandardMeetingBaseData' } & Pick<
                        StandardMeetingBaseData,
                        'name'
                      > & {
                          owner?: Maybe<
                            { __typename?: 'AccountInfo' } & {
                              company?: Maybe<
                                { __typename?: 'CompanyInfo' } & Pick<
                                  CompanyInfo,
                                  'cnName'
                                >
                              >
                            }
                          >
                        }
                    >
                  }
                >
              }
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type ActivityTypesByMeetingTypeQueryVariables = Exact<{
  meetingType?: Maybe<Scalars['String']>
  companyId?: Maybe<Scalars['String']>
}>

export type ActivityTypesByMeetingTypeQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingActivity' } & Pick<MeetingActivity, 'id'> & {
          config?: Maybe<
            { __typename?: 'MeetingConfigBase' } & Pick<
              MeetingConfigBase,
              'title' | 'code'
            >
          >
        }
    >
  >
}

export type InterProjectTypeQueryVariables = Exact<{ [key: string]: never }>

export type InterProjectTypeQuery = { __typename?: 'Query' } & {
  result: Query['hengruiImage_interProjectType']
}

export type FetchAllBaseMeetingTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchAllBaseMeetingTypesQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingConfigBase' } & Pick<
        MeetingConfigBase,
        'id' | 'code' | 'enable' | 'sort' | 'icon' | 'title' | 'desc' | 'remark'
      >
    >
  >
}

export type FetchAllBaseActivityTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchAllBaseActivityTypesQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingConfigBase' } & Pick<
        MeetingConfigBase,
        | 'id'
        | 'code'
        | 'enable'
        | 'sort'
        | 'icon'
        | 'title'
        | 'desc'
        | 'remark'
        | 'properties'
      >
    >
  >
}

export type MeetingTypesByCompanyQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
}>

export type MeetingTypesByCompanyQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingCategory' } & Pick<
        MeetingCategory,
        | 'id'
        | 'enable'
        | 'autoComplete'
        | 'companyId'
        | 'titleFilter'
        | 'titleFilterText'
        | 'sort'
        | 'activityProperties'
      > & {
          config?: Maybe<
            { __typename?: 'MeetingConfigBase' } & Pick<
              MeetingConfigBase,
              'id' | 'code' | 'icon' | 'title'
            >
          >
          activitiyTypes: Array<
            { __typename?: 'MeetingActivity' } & Pick<
              MeetingActivity,
              | 'id'
              | 'enable'
              | 'instanceMax'
              | 'instanceMin'
              | 'templateCode'
              | 'productType'
              | 'start'
              | 'end'
              | 'sphere'
            > & {
                config?: Maybe<
                  { __typename?: 'MeetingConfigBase' } & Pick<
                    MeetingConfigBase,
                    'id' | 'code' | 'icon' | 'title'
                  >
                >
                templateInfo?: Maybe<
                  { __typename?: 'MeetingActivityTemplate' } & Pick<
                    MeetingActivityTemplate,
                    'templateCode' | 'templateName'
                  >
                >
                activitySphere?: Maybe<
                  { __typename?: 'DocumentSphereInfo' } & Pick<
                    DocumentSphereInfo,
                    'sphereCode' | 'sphereName'
                  >
                >
              }
          >
        }
    >
  >
}

export type UpdateMeetingCategoryEnabledMutationVariables = Exact<{
  enabled: Scalars['Boolean']
  categoryId: Scalars['String']
}>

export type UpdateMeetingCategoryEnabledMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type ToggleMeetingCategoryAutoCompleteMutationVariables = Exact<{
  categoryId: Scalars['String']
}>

export type ToggleMeetingCategoryAutoCompleteMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CreateOrUpdateBaseMeetingTypeMutationVariables = Exact<{
  config: MeetingConfigAddOrUpdateInput
}>

export type CreateOrUpdateBaseMeetingTypeMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CreateOrUpdateBaseActivityTypeMutationVariables = Exact<{
  config: MeetingConfigAddOrUpdateInput
}>

export type CreateOrUpdateBaseActivityTypeMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type UpdateActivityEnabledMutationVariables = Exact<{
  enabled: Scalars['Boolean']
  activityId: Scalars['String']
}>

export type UpdateActivityEnabledMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type BindActivityToMeetingCategoryMutationVariables = Exact<{
  input: MeetingACtivityCreateOrUpdateInput
}>

export type BindActivityToMeetingCategoryMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CompanyApprovalProcessIdQueryVariables = Exact<{
  companyId: Scalars['String']
}>

export type CompanyApprovalProcessIdQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<{ __typename?: 'BaseOptions' } & Pick<BaseOptions, 'id' | 'name'>>
  >
}

export type QueryActivityTemplateQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  documentCategory?: Maybe<Scalars['String']>
}>

export type QueryActivityTemplateQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'MeetingActivityTemplate' } & Pick<
        MeetingActivityTemplate,
        'id' | 'unitPrice' | 'templateCode' | 'templateName'
      > & { enable: MeetingActivityTemplate['isEnable'] } & {
          documentCategoryInfo?: Maybe<
            { __typename?: 'DocumentArchiveCategory' } & Pick<
              DocumentArchiveCategory,
              'title'
            >
          >
        }
    >
  >
}

export type CreateMeetingActivityTemplateMutationVariables = Exact<{
  template: TemplateInput
}>

export type CreateMeetingActivityTemplateMutation = {
  __typename?: 'Mutation'
} & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type CompanyProductsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['String']>
  currentUser?: Maybe<Scalars['Boolean']>
}>

export type CompanyProductsQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'UpProduct' } & Pick<
        UpProduct,
        'id' | 'productName' | 'productCode' | 'productCategory' | 'companyId'
      >
    >
  >
}

export type AttenderTasksQueryVariables = Exact<{
  attenderId?: Maybe<Array<Scalars['String']> | Scalars['String']>
  pager?: Maybe<PagerInput>
}>

export type AttenderTasksQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedAttenderTasks' } & {
      items: Array<
        { __typename?: 'MeetingAttenderTask' } & Pick<
          MeetingAttenderTask,
          | 'id'
          | 'status'
          | 'statusText'
          | 'rejectMsg'
          | 'taskAttribute'
          | 'taskCategory'
          | 'taskName'
          | 'taskPrice'
          | 'templateCode'
          | 'accountId'
          | 'attenderId'
        > & {
            templateInfo?: Maybe<
              { __typename?: 'MeetingActivityTemplate' } & Pick<
                MeetingActivityTemplate,
                | 'unitPrice'
                | 'templateAttribute'
                | 'templateName'
                | 'templateCategory'
              >
            >
            documentInfo?: Maybe<
              { __typename?: 'DocumentInfo' } & {
                document?: Maybe<
                  { __typename?: 'Document' } & Pick<Document, 'id' | 'title'>
                >
                archive?: Maybe<
                  Array<
                    { __typename?: 'ArchiveInfo' } & Pick<
                      ArchiveInfo,
                      'id' | 'signedUrl'
                    >
                  >
                >
                properties?: Maybe<
                  Array<
                    { __typename?: 'DocumentArchiveProperty' } & Pick<
                      DocumentArchiveProperty,
                      'id' | 'value' | 'key'
                    >
                  >
                >
              }
            >
            meetingInfo?: Maybe<
              { __typename?: 'StandardMeetingInfo' } & {
                meetingType: StandardMeetingInfo['hengruiImage_categoryName']
                documentComposeInfo: StandardMeetingInfo['hengruiImage_documentComposeInfo']
              } & {
                meetingBaseData?: Maybe<
                  { __typename?: 'StandardMeetingBaseData' } & Pick<
                    StandardMeetingBaseData,
                    'type' | 'startTime' | 'endTime'
                  > & {
                      belongProduct?: Maybe<
                        { __typename?: 'UpProduct' } & Pick<
                          UpProduct,
                          'productName'
                        >
                      >
                    }
                >
              }
            >
            accountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'cnName'>
            >
            attenderInfo?: Maybe<
              { __typename?: 'StandardMeetingBaseAttender' } & Pick<
                StandardMeetingBaseAttender,
                'attenderFee'
              >
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type TaskDocumentReviewContentQueryVariables = Exact<{
  taskId: Scalars['String']
}>

export type TaskDocumentReviewContentQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'DocumentReviewContentInfo' } & Pick<
      DocumentReviewContentInfo,
      'id' | 'value' | 'key'
    >
  >
}

export type PreImportDoctorsMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  chanel: Scalars['String']
  data: Array<HrDoctorExcelImportInput> | HrDoctorExcelImportInput
}>

export type PreImportDoctorsMutation = { __typename?: 'Mutation' } & {
  result: Mutation['upadminPreImportDoctors']
}

export type ImportDoctorsMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  chanel: Scalars['String']
}>

export type ImportDoctorsMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type UnBindAccountWechatRelationMutationVariables = Exact<{
  accountId: Scalars['String']
  appName: Scalars['String']
}>

export type UnBindAccountWechatRelationMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['unBindAccountWechatRelation'] }

export type MeetingApprovalFlowsQueryVariables = Exact<{
  meetingId: Scalars['String']
}>

export type MeetingApprovalFlowsQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'ApproveInfo' } & Pick<
        ApproveInfo,
        'instanceId' | 'status' | 'reason'
      > & {
          tasks?: Maybe<
            Array<
              { __typename?: 'TaskInfo' } & Pick<
                TaskInfo,
                'createTime' | 'status' | 'opinion'
              > & {
                  approverInfo?: Maybe<
                    { __typename?: 'AccountInfo' } & Pick<
                      AccountInfo,
                      'cnName' | 'accountId' | 'roleAuthoritiesIncludeQcxs'
                    >
                  >
                  logDatas?: Maybe<
                    Array<
                      { __typename?: 'LogData' } & Pick<
                        LogData,
                        'createTime' | 'logType' | 'assginer' | 'reason'
                      > & {
                          assginerInfo?: Maybe<
                            { __typename?: 'AccountInfo' } & Pick<
                              AccountInfo,
                              'cnName'
                            >
                          >
                        }
                    >
                  >
                }
            >
          >
        }
    >
  >
}

export type UpdateAccountStatusMutationVariables = Exact<{
  accountId: Scalars['String']
  status: Scalars['String']
}>

export type UpdateAccountStatusMutation = { __typename?: 'Mutation' } & {
  result: Mutation['updateAccountStatus']
}

export type DeleteOrderByAccountIdMutationVariables = Exact<{
  accountId: Scalars['String']
  disableReason?: Maybe<Scalars['String']>
}>

export type DeleteOrderByAccountIdMutation = { __typename?: 'Mutation' } & {
  result: Mutation['deleteOrderByAccountId']
}

export type ResumeOrderByAccountIdMutationVariables = Exact<{
  accountId: Scalars['String']
}>

export type ResumeOrderByAccountIdMutation = { __typename?: 'Mutation' } & {
  result: Mutation['resumeOrderByAccountId']
}

export type OperateOrderMutationVariables = Exact<{
  input: OperateOrderInput
}>

export type OperateOrderMutation = { __typename?: 'Mutation' } & {
  result: Mutation['operateOrder']
}

export type OrderOperationLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<OperationLogSearchQuery>
}>

export type OrderOperationLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PageQueryOrderOperationLog' } & {
      items: Array<
        { __typename?: 'OrderOperationLogInfo' } & Pick<
          OrderOperationLogInfo,
          | 'id'
          | 'operatorId'
          | 'operationDate'
          | 'category'
          | 'beforeSnapshotStatus'
          | 'afterSnapshotStatus'
          | 'explain'
          | 'operatorName'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type UpdateBudgetWhiteListByAccountIdMutationVariables = Exact<{
  budgetWhiteDetail: BudgetWhiteDetailInput
}>

export type UpdateBudgetWhiteListByAccountIdMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['updateBudgetWhiteListByAccountId'] }

export type TaskDocumentAuditRecordByTaskIdQueryVariables = Exact<{
  taskId: Scalars['String']
}>

export type TaskDocumentAuditRecordByTaskIdQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'AuditRecordResult' } & Pick<
      AuditRecordResult,
      'taskId' | 'verifyType' | 'verifyDimension' | 'status'
    >
  >
}

export type TaskLogByTaskIdQueryVariables = Exact<{
  taskId: Scalars['String']
}>

export type TaskLogByTaskIdQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'AttenderTaskLog' } & Pick<
      AttenderTaskLog,
      'taskId' | 'rejectMsg' | 'gmtCreated'
    >
  >
}

export type AllConferencePlatformsQueryVariables = Exact<{
  [key: string]: never
}>

export type AllConferencePlatformsQuery = { __typename?: 'Query' } & {
  platformList?: Maybe<
    Array<
      { __typename?: 'MeetingPlatform' } & Pick<
        MeetingPlatform,
        'id' | 'name' | 'code' | 'remark' | 'sort' | 'enabled'
      >
    >
  >
}

export type OperationConferencePlatformMutationVariables = Exact<{
  platForm: MeetingPlatformInput
}>

export type OperationConferencePlatformMutation = {
  __typename?: 'Mutation'
} & {
  result: { __typename?: 'BaseResponse' } & Pick<
    BaseResponse,
    'errorCode' | 'success' | 'message'
  >
}

export type UpdateAccountFinalMutationVariables = Exact<{
  account: AccountInfoInput
}>

export type UpdateAccountFinalMutation = { __typename?: 'Mutation' } & {
  result: Mutation['updateAccountFinal']
}

export type AddMeetingWhiteMutationVariables = Exact<{
  accountId: Scalars['String']
  companyId: Scalars['String']
}>

export type AddMeetingWhiteMutation = { __typename?: 'Mutation' } & {
  result: Mutation['addMeetingWhite']
}

export type DeleteMeetingWhiteMutationVariables = Exact<{
  accountId: Scalars['String']
  companyId: Scalars['String']
}>

export type DeleteMeetingWhiteMutation = { __typename?: 'Mutation' } & {
  result: Mutation['deleteMeetingWhite']
}

export type DisableMeetingWhiteMutationVariables = Exact<{
  accountId: Scalars['String']
  companyId: Scalars['String']
}>

export type DisableMeetingWhiteMutation = { __typename?: 'Mutation' } & {
  result: Mutation['disableMeetingWhite']
}

export type MeetingTaskNumQueryVariables = Exact<{ [key: string]: never }>

export type MeetingTaskNumQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<{ __typename?: 'TaskDetail' } & Pick<TaskDetail, 'type' | 'num'>>
  >
}

export type QueryDocumentsReviewsQueryVariables = Exact<{
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>
}>

export type QueryDocumentsReviewsQuery = { __typename?: 'Query' } & {
  reviews: { __typename?: 'DocumentReviewPagerSearchResult' } & {
    items: Array<
      { __typename?: 'DocumentReviewInfo' } & Pick<
        DocumentReviewInfo,
        | 'id'
        | 'documentId'
        | 'accountId'
        | 'name'
        | 'inviterName'
        | 'stateText'
        | 'createdTimestamp'
        | 'level'
        | 'fee'
        | 'state'
        | 'phone'
      > & {
          documentInfo?: Maybe<
            { __typename?: 'DocumentInfo' } & {
              document?: Maybe<
                { __typename?: 'Document' } & Pick<Document, 'id' | 'title'> & {
                    category?: Maybe<
                      { __typename?: 'DocumentArchiveCategory' } & Pick<
                        DocumentArchiveCategory,
                        'title' | 'id'
                      >
                    >
                  }
              >
              archive?: Maybe<
                Array<
                  { __typename?: 'ArchiveInfo' } & Pick<
                    ArchiveInfo,
                    'signedUrl'
                  > & {
                      archiveTags?: Maybe<
                        Array<
                          { __typename?: 'ArchiveTagInfo' } & Pick<
                            ArchiveTagInfo,
                            'id' | 'tag'
                          >
                        >
                      >
                    }
                >
              >
            }
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type ReviewContentQueryVariables = Exact<{
  reviewId: Scalars['String']
}>

export type ReviewContentQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'DocumentReviewContentInfo' } & Pick<
      DocumentReviewContentInfo,
      'id' | 'value' | 'key'
    >
  >
}

export type StartSettlementMutationVariables = Exact<{
  reviewId: Scalars['String']
}>

export type StartSettlementMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type DocumentReviewsQueryVariables = Exact<{
  search?: Maybe<DocumentArchiveReviewsSearchParamsWithPager>
}>

export type DocumentReviewsQuery = { __typename?: 'Query' } & {
  reviews: { __typename?: 'DocumentReviewPagerSearchResult' } & {
    items: Array<
      { __typename?: 'DocumentReviewInfo' } & Pick<
        DocumentReviewInfo,
        | 'id'
        | 'channel'
        | 'accountId'
        | 'companyId'
        | 'createdTimestamp'
        | 'inviterId'
        | 'inviterRegionId'
        | 'documentId'
        | 'level'
        | 'state'
        | 'stateText'
        | 'fee'
        | 'enabled'
        | 'isOpen'
      > & {
          inviterName: DocumentReviewInfo['inviterName']
          name: DocumentReviewInfo['name']
          encryptedName: DocumentReviewInfo['name']
          phone: DocumentReviewInfo['phone']
        } & {
          documentInfo?: Maybe<
            { __typename?: 'DocumentInfo' } & {
              archive?: Maybe<
                Array<
                  { __typename?: 'ArchiveInfo' } & Pick<
                    ArchiveInfo,
                    'id' | 'signedUrl'
                  > & {
                      tags?: Maybe<
                        Array<
                          { __typename?: 'ArchiveTag' } & Pick<
                            ArchiveTag,
                            'typeName' | 'id'
                          >
                        >
                      >
                      archiveTags?: Maybe<
                        Array<
                          { __typename?: 'ArchiveTagInfo' } & Pick<
                            ArchiveTagInfo,
                            'id' | 'tag'
                          >
                        >
                      >
                      creator?: Maybe<
                        { __typename?: 'AccountInfo' } & {
                          id: AccountInfo['accountId']
                          name: AccountInfo['cnName']
                        }
                      >
                    }
                >
              >
              document?: Maybe<
                { __typename?: 'Document' } & Pick<
                  Document,
                  | 'remark'
                  | 'title'
                  | 'id'
                  | 'author'
                  | 'describe'
                  | 'source'
                  | 'createdTimestamp'
                  | 'releaseTimestamp'
                > & {
                    category?: Maybe<
                      { __typename?: 'DocumentArchiveCategory' } & Pick<
                        DocumentArchiveCategory,
                        'title' | 'id'
                      >
                    >
                  }
              >
            }
          >
          account?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'ptxOpenStatus' | 'ptxOpenStatusOriginal'
            >
          >
          inviter?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<AccountInfo, 'staffId'>
          >
        }
    >
    pager: { __typename?: 'PagerResult' } & Pick<
      PagerResult,
      'currentPage' | 'pageSize' | 'total'
    >
  }
}

export type PreviewContentQueryVariables = Exact<{
  reviewId: Scalars['String']
}>

export type PreviewContentQuery = { __typename?: 'Query' } & {
  result: Array<
    { __typename?: 'DocumentReviewContentInfo' } & Pick<
      DocumentReviewContentInfo,
      'id' | 'value' | 'key'
    >
  >
}

export type BatchInviteDoctorsToReviewMutationVariables = Exact<{
  documentIds: Array<Scalars['String']> | Scalars['String']
  doctorIds: Array<Scalars['String']> | Scalars['String']
}>

export type BatchInviteDoctorsToReviewMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    Array<
      { __typename?: 'BaseResponse' } & Pick<
        BaseResponse,
        'errorCode' | 'message' | 'success'
      >
    >
  >
}

export type RatingReviewMutationVariables = Exact<{
  remark?: Maybe<Scalars['String']>
  level: Scalars['Int']
  reviewId: Scalars['String']
}>

export type RatingReviewMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type PayReviewMutationVariables = Exact<{
  reviewId: Scalars['String']
}>

export type PayReviewMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type GetMedicalLiteratureUrlQueryVariables = Exact<{
  path: Scalars['String']
  doctorAccountId: Scalars['String']
}>

export type GetMedicalLiteratureUrlQuery = { __typename?: 'Query' } & {
  result: Query['queryMedicalLiteratureAppUrl']
}

export type GetArchiveSignedUrlQueryVariables = Exact<{
  archiveId: Scalars['String']
}>

export type GetArchiveSignedUrlQuery = { __typename?: 'Query' } & {
  result: Query['getArchiveSignedUrl']
}

export type TranslateDocumentContentQueryVariables = Exact<{
  text: Scalars['String']
}>

export type TranslateDocumentContentQuery = { __typename?: 'Query' } & {
  result: Query['translateDocumentContent']
}

export type SalesOrderInfoFragment = {
  __typename?: 'SalesOrderBaseInfo'
} & Pick<
  SalesOrderBaseInfo,
  | 'id'
  | 'conferenceCategoryCode'
  | 'consumptionCategoryCode'
  | 'budgetChannel'
  | 'budgetNumber'
  | 'accountId'
  | 'accountName'
  | 'accountMobile'
  | 'formCategory'
  | 'targetHospital'
  | 'targetDepartment'
  | 'applyReason'
  | 'resourceId'
  | 'targetName'
  | 'targetAddress'
  | 'targetOccurTime'
  | 'peopleNumber'
  | 'actualPeopleNumber'
  | 'appointmentTime'
  | 'consumptionAmount'
  | 'actualConsumptionAmount'
  | 'actualConsumptionTime'
  | 'submitTime'
  | 'occurCity'
  | 'status'
  | 'reason'
  | 'paymentType'
  | 'paymentTime'
  | 'invoiceQuantity'
  | 'invoiceVerification'
  | 'companyId'
  | 'businessId'
  | 'businessName'
  | 'regionId'
  | 'regionName'
  | 'areaId'
  | 'areaName'
  | 'costcenterId'
  | 'costcenterName'
  | 'isEnable'
  | 'remark'
  | 'claimAmount'
  | 'preVoucherAuditTime'
  | 'deductionAmount'
  | 'financialDeductionAmount'
  | 'deductionOperator'
  | 'financialDeductionOperator'
  | 'deductionReason'
  | 'qcOwner'
  | 'finalCorrection'
  | 'finalCorrectionReason'
  | 'usageScenario'
  | 'oaNumber'
>

export type SalesOrderPropertieInfoFragment = {
  __typename?: 'SalesOrderProperties'
} & Pick<SalesOrderProperties, 'id' | 'orderId' | 'type' | 'key' | 'value'>

export type SalesClaimFormPropertieInfoFragment = {
  __typename?: 'SalesClaimFormProperties'
} & Pick<
  SalesClaimFormProperties,
  'id' | 'claimFormCode' | 'type' | 'key' | 'value'
>

export type SalesClaimFormInfoFragment = {
  __typename?: 'SalesClaimFormBaseInfo'
} & Pick<
  SalesClaimFormBaseInfo,
  | 'id'
  | 'claimFormName'
  | 'claimFormCode'
  | 'qrCodeImage'
  | 'amount'
  | 'accountId'
  | 'accountName'
  | 'accountMobile'
  | 'companyId'
  | 'submitTime'
  | 'businessId'
  | 'business'
  | 'regionId'
  | 'region'
  | 'areaId'
  | 'area'
  | 'status'
  | 'reason'
  | 'isEnable'
  | 'remark'
  | 'gmtCreated'
  | 'claimAmount'
  | 'approvedTime'
  | 'preReviewedTime'
  | 'reviewedTime'
  | 'budgetChannel'
  | 'budgetNumber'
>

export type SalesOrderTypesQueryVariables = Exact<{ [key: string]: never }>

export type SalesOrderTypesQuery = { __typename?: 'Query' } & {
  types: Query['salesOrder_TypesMap']
}

export type SalesOrderStatusQueryVariables = Exact<{ [key: string]: never }>

export type SalesOrderStatusQuery = { __typename?: 'Query' } & {
  status: Query['salesOrder_StatusMap']
}

export type SalesOrderByIdQueryVariables = Exact<{
  id: Scalars['String']
}>

export type SalesOrderByIdQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'SalesOrderDetail' } & {
      isCurrentApprover: SalesOrderDetail['isSalesOrderCurrentApproverQcOwner']
    } & {
      salesOrder?: Maybe<
        { __typename?: 'SalesOrderBaseInfo' } & {
          accountInfo?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'cnName' | 'staffId'
            >
          >
        } & SalesOrderInfoFragment
      >
      properties?: Maybe<
        Array<
          {
            __typename?: 'SalesOrderProperties'
          } & SalesOrderPropertieInfoFragment
        >
      >
      formCategoryInfo?: Maybe<
        { __typename?: 'OrderCategory' } & Pick<
          OrderCategory,
          'id' | 'title' | 'describe' | 'code'
        >
      >
      salesOrderBusinessCategory?: Maybe<
        { __typename?: 'BusinessCategory' } & Pick<
          BusinessCategory,
          'id' | 'title' | 'code'
        >
      >
      auditRecord?: Maybe<
        Array<
          { __typename?: 'SalesOrderAuditRecord' } & Pick<
            SalesOrderAuditRecord,
            | 'id'
            | 'orderId'
            | 'receiptType'
            | 'verifyType'
            | 'verifyDimension'
            | 'status'
            | 'key'
            | 'value'
            | 'remark'
            | 'properties'
            | 'sort'
            | 'isEnable'
          >
        >
      >
      invoices: Array<
        { __typename?: 'HansohOrderInvoice' } & Pick<
          HansohOrderInvoice,
          | 'formId'
          | 'invoiceUrl'
          | 'invoiceNumber'
          | 'remark'
          | 'isEnable'
          | 'checkOcr'
          | 'checkPassed'
          | 'checkRepeat'
          | 'checkTitle'
        > & {
            salesInvoiceDetail?: Maybe<
              { __typename?: 'HansohSalesOrderInvoiceDetail' } & Pick<
                HansohSalesOrderInvoiceDetail,
                'purchaserName' | 'purchaserTaxIdentNumber'
              >
            >
          }
      >
      receiptTemplate?: Maybe<
        Array<
          { __typename?: 'SalesOrderReceiptTemplate' } & Pick<
            SalesOrderReceiptTemplate,
            'id' | 'resourceId' | 'resourceName' | 'receiptUrl' | 'receiptType'
          >
        >
      >
      logs?: Maybe<
        Array<
          { __typename?: 'SalesOrderOperationLog' } & Pick<
            SalesOrderOperationLog,
            'log' | 'businessId' | 'createTime'
          >
        >
      >
      consumptionCategory?: Maybe<
        { __typename?: 'BaseCategoryType' } & Pick<
          BaseCategoryType,
          'id' | 'title' | 'describe' | 'code'
        >
      >
      approvalFlows?: Maybe<
        Array<
          { __typename?: 'ApproveInfo' } & Pick<
            ApproveInfo,
            'instanceId' | 'status' | 'reason'
          > & {
              tasks?: Maybe<
                Array<
                  { __typename?: 'TaskInfo' } & Pick<
                    TaskInfo,
                    'createTime' | 'status' | 'opinion'
                  > & {
                      approverInfo?: Maybe<
                        { __typename?: 'AccountInfo' } & Pick<
                          AccountInfo,
                          'cnName' | 'accountId'
                        >
                      >
                      logDatas?: Maybe<
                        Array<
                          { __typename?: 'LogData' } & Pick<
                            LogData,
                            'createTime' | 'logType' | 'assginer' | 'reason'
                          > & {
                              assginerInfo?: Maybe<
                                { __typename?: 'AccountInfo' } & Pick<
                                  AccountInfo,
                                  'cnName'
                                >
                              >
                            }
                        >
                      >
                    }
                >
              >
            }
        >
      >
    }
  >
}

export type SalesOrderPageQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<SalesOrderPageSearch>
}>

export type SalesOrderPageQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedSalesOrderPage' } & {
      items: Array<
        { __typename?: 'SalesOrderBaseInfo' } & {
          qcOwnerAccountInfo?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'cnName' | 'accountId'
            >
          >
        } & SalesOrderInfoFragment
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type ApproveSalesOrderPageQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<SalesOrderPageSearch>
}>

export type ApproveSalesOrderPageQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedSalesOrderPage' } & {
      items: Array<
        { __typename?: 'SalesOrderBaseInfo' } & {
          qcOwnerAccountInfo?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'cnName' | 'accountId'
            >
          >
        } & SalesOrderInfoFragment
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type SalesOrderOperationLogQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<SalesOrderLogSearch>
}>

export type SalesOrderOperationLogQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedSalesOrderOperationLog' } & {
      items: Array<
        { __typename?: 'SalesOrderOperationLog' } & Pick<
          SalesOrderOperationLog,
          'log' | 'businessId' | 'createTime'
        >
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type SalesOrderBillApprovedMutationVariables = Exact<{
  id: Scalars['String']
  ticketDesc?: Maybe<Scalars['String']>
  invoiceDesc?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  billReviewResult?: Maybe<Scalars['String']>
}>

export type SalesOrderBillApprovedMutation = { __typename?: 'Mutation' } & {
  result: Mutation['salesOrderBillApproved']
}

export type SalesOrderBillReturnMutationVariables = Exact<{
  id: Scalars['String']
  reason: Scalars['String']
  billReviewResult?: Maybe<Scalars['String']>
}>

export type SalesOrderBillReturnMutation = { __typename?: 'Mutation' } & {
  result: Mutation['salesOrderBillReturn']
}

export type SalesOrderVoucherRejectMutationVariables = Exact<{
  id: Scalars['String']
}>

export type SalesOrderVoucherRejectMutation = { __typename?: 'Mutation' } & {
  result: Mutation['salesOrderVoucherReject']
}

export type SalesClaimFormStatusQueryVariables = Exact<{ [key: string]: never }>

export type SalesClaimFormStatusQuery = { __typename?: 'Query' } & {
  status: Query['salesClaimForm_StatusMap']
}

export type SalesClaimFormQueryVariables = Exact<{
  pager?: Maybe<PagerInput>
  query?: Maybe<SalesClaimFormSearch>
}>

export type SalesClaimFormQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'PaginatedSalesClaimForm' } & {
      items: Array<
        { __typename?: 'SalesClaimFormInfo' } & {
          claimForm?: Maybe<
            { __typename?: 'SalesClaimFormBaseInfo' } & {
              accountInfo?: Maybe<
                { __typename?: 'AccountInfo' } & Pick<
                  AccountInfo,
                  'cnName' | 'staffId'
                >
              >
            } & SalesClaimFormInfoFragment
          >
          relations?: Maybe<
            Array<
              { __typename?: 'SalesClaimFormRelationInfo' } & Pick<
                SalesClaimFormRelationInfo,
                'id' | 'claimFormCode' | 'orderId' | 'isEnable' | 'remark'
              >
            >
          >
          orders?: Maybe<
            Array<
              { __typename?: 'SalesOrderDetail' } & {
                salesOrder?: Maybe<
                  { __typename?: 'SalesOrderBaseInfo' } & SalesOrderInfoFragment
                >
              }
            >
          >
          preReviewedUser?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'cnName' | 'staffId'
            >
          >
          reviewedUser?: Maybe<
            { __typename?: 'AccountInfo' } & Pick<
              AccountInfo,
              'cnName' | 'staffId'
            >
          >
        }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total'
      >
    }
  >
}

export type SalesClaimFormByClaimFormCodeQueryVariables = Exact<{
  claimFormCode: Scalars['String']
}>

export type SalesClaimFormByClaimFormCodeQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    Array<
      { __typename?: 'SalesClaimFormInfo' } & {
        claimForm?: Maybe<
          { __typename?: 'SalesClaimFormBaseInfo' } & {
            accountInfo?: Maybe<
              { __typename?: 'AccountInfo' } & Pick<
                AccountInfo,
                'cnName' | 'staffId'
              >
            >
          } & SalesClaimFormInfoFragment
        >
        approvalFlows?: Maybe<
          Array<
            { __typename?: 'ApproveInfo' } & Pick<
              ApproveInfo,
              'instanceId' | 'status' | 'reason'
            > & {
                tasks?: Maybe<
                  Array<
                    { __typename?: 'TaskInfo' } & Pick<
                      TaskInfo,
                      'createTime' | 'status' | 'opinion'
                    > & {
                        approverInfo?: Maybe<
                          { __typename?: 'AccountInfo' } & Pick<
                            AccountInfo,
                            'cnName' | 'accountId'
                          >
                        >
                        logDatas?: Maybe<
                          Array<
                            { __typename?: 'LogData' } & Pick<
                              LogData,
                              'createTime' | 'logType' | 'assginer' | 'reason'
                            > & {
                                assginerInfo?: Maybe<
                                  { __typename?: 'AccountInfo' } & Pick<
                                    AccountInfo,
                                    'cnName'
                                  >
                                >
                              }
                          >
                        >
                      }
                  >
                >
              }
          >
        >
        relations?: Maybe<
          Array<
            { __typename?: 'SalesClaimFormRelationInfo' } & Pick<
              SalesClaimFormRelationInfo,
              'id' | 'claimFormCode' | 'orderId' | 'isEnable' | 'remark'
            >
          >
        >
        properties?: Maybe<
          Array<
            {
              __typename?: 'SalesClaimFormProperties'
            } & SalesClaimFormPropertieInfoFragment
          >
        >
        orders?: Maybe<
          Array<
            { __typename?: 'SalesOrderDetail' } & {
              salesOrder?: Maybe<
                { __typename?: 'SalesOrderBaseInfo' } & {
                  accountInfo?: Maybe<
                    { __typename?: 'AccountInfo' } & Pick<
                      AccountInfo,
                      'cnName' | 'staffId'
                    >
                  >
                } & SalesOrderInfoFragment
              >
              properties?: Maybe<
                Array<
                  {
                    __typename?: 'SalesOrderProperties'
                  } & SalesOrderPropertieInfoFragment
                >
              >
              formCategoryInfo?: Maybe<
                { __typename?: 'OrderCategory' } & Pick<
                  OrderCategory,
                  'id' | 'title' | 'describe' | 'code'
                >
              >
              consumptionCategory?: Maybe<
                { __typename?: 'BaseCategoryType' } & Pick<
                  BaseCategoryType,
                  'id' | 'title' | 'describe' | 'code'
                >
              >
              salesOrderBusinessCategory?: Maybe<
                { __typename?: 'BusinessCategory' } & Pick<
                  BusinessCategory,
                  'id' | 'title' | 'code'
                >
              >
              invoices: Array<
                { __typename?: 'HansohOrderInvoice' } & Pick<
                  HansohOrderInvoice,
                  | 'formId'
                  | 'invoiceUrl'
                  | 'invoiceNumber'
                  | 'remark'
                  | 'isEnable'
                  | 'checkOcr'
                  | 'checkPassed'
                  | 'checkRepeat'
                  | 'checkTitle'
                >
              >
              approvalFlows?: Maybe<
                Array<
                  { __typename?: 'ApproveInfo' } & Pick<
                    ApproveInfo,
                    'instanceId' | 'status' | 'reason'
                  > & {
                      tasks?: Maybe<
                        Array<
                          { __typename?: 'TaskInfo' } & Pick<
                            TaskInfo,
                            'createTime' | 'status' | 'opinion'
                          > & {
                              approverInfo?: Maybe<
                                { __typename?: 'AccountInfo' } & Pick<
                                  AccountInfo,
                                  'cnName' | 'accountId'
                                >
                              >
                              logDatas?: Maybe<
                                Array<
                                  { __typename?: 'LogData' } & Pick<
                                    LogData,
                                    | 'createTime'
                                    | 'logType'
                                    | 'assginer'
                                    | 'reason'
                                  > & {
                                      assginerInfo?: Maybe<
                                        { __typename?: 'AccountInfo' } & Pick<
                                          AccountInfo,
                                          'cnName'
                                        >
                                      >
                                    }
                                >
                              >
                            }
                        >
                      >
                    }
                >
              >
            }
          >
        >
        logs?: Maybe<
          Array<
            { __typename?: 'SalesOrderOperationLog' } & Pick<
              SalesOrderOperationLog,
              'log' | 'businessId' | 'createTime'
            >
          >
        >
      }
    >
  >
}

export type SalesClaimFormApprovedMutationVariables = Exact<{
  id: Scalars['String']
  reason?: Maybe<Scalars['String']>
  orderParam?: Maybe<
    Array<ClaimFormRejectOrderParam> | ClaimFormRejectOrderParam
  >
}>

export type SalesClaimFormApprovedMutation = { __typename?: 'Mutation' } & {
  result: Mutation['salesClaimFormApproved']
}

export type SalesClaimFormReturnMutationVariables = Exact<{
  formId: Scalars['String']
  orderIds?: Maybe<Array<Scalars['String']> | Scalars['String']>
  reason?: Maybe<Scalars['String']>
  rejectOrderParam?: Maybe<
    Array<ClaimFormRejectOrderParam> | ClaimFormRejectOrderParam
  >
}>

export type SalesClaimFormReturnMutation = { __typename?: 'Mutation' } & {
  result: Mutation['salesClaimFormReturn']
}

export type SalesClaimFormReturnPreReviewMutationVariables = Exact<{
  formId: Scalars['String']
  reason?: Maybe<Scalars['String']>
}>

export type SalesClaimFormReturnPreReviewMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['salesClaimFormReturnPreReview'] }

export type TerminateAlipayRuleMutationVariables = Exact<{
  orderId: Scalars['String']
}>

export type TerminateAlipayRuleMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'success' | 'message'
    >
  >
}

export type UnbindAlipayRelationshipMutationVariables = Exact<{
  accountId: Scalars['String']
}>

export type UnbindAlipayRelationshipMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'success' | 'message'
    >
  >
}

export type HansohSalesEditOrderMutationVariables = Exact<{
  order: CreateHansohSalesOrderInput
}>

export type HansohSalesEditOrderMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hansohSalesEditOrder']
}

export type UpadminDistributeSalesOrderMutationVariables = Exact<{
  accountId?: Maybe<Scalars['String']>
}>

export type UpadminDistributeSalesOrderMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['upadminDistributeSalesOrder'] }

export type SalesOrderTaskNumQueryVariables = Exact<{ [key: string]: never }>

export type SalesOrderTaskNumQuery = { __typename?: 'Query' } & {
  result: Query['searchSalesOrderTaskNum']
}

export type PreImportTasksMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  data: Array<HrTaskExcelImportInput> | HrTaskExcelImportInput
}>

export type PreImportTasksMutation = { __typename?: 'Mutation' } & {
  result: Mutation['upadminPreImportTasks']
}

export type ImportTasksMutationVariables = Exact<{
  importId?: Maybe<Scalars['String']>
  companyId: Scalars['String']
  data: Array<HrTaskExcelImportInput> | HrTaskExcelImportInput
}>

export type ImportTasksMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type SearchDoctorAttenderTasksQueryVariables = Exact<{
  search?: Maybe<DoctorTaskQueryInputWithPager>
}>

export type SearchDoctorAttenderTasksQuery = { __typename?: 'Query' } & {
  result?: Maybe<
    { __typename?: 'DoctorAttenderTasks' } & {
      items: Array<
        { __typename?: 'StandardMeetingBaseAttender' } & Pick<
          StandardMeetingBaseAttender,
          | 'id'
          | 'meetingId'
          | 'attenderId'
          | 'attenderStatus'
          | 'attenderName'
          | 'attenderPhone'
          | 'attenderFee'
          | 'attenderType'
          | 'taskIsOpen'
        > & {
            taskListInfo?: Maybe<
              Array<
                { __typename?: 'MeetingAttenderTask' } & Pick<
                  MeetingAttenderTask,
                  'status' | 'gmtCreated'
                >
              >
            >
            meetingAttending?: Maybe<
              { __typename?: 'StandardMeetingInfo' } & Pick<
                StandardMeetingInfo,
                | 'hengruiImage_activityTypeSphere'
                | 'hengruiImage_activityType'
                | 'hengruiImage_categoryName'
              > & {
                  status: StandardMeetingInfo['hengruiImage_status']
                  statusText: StandardMeetingInfo['hengruiImage_statusText']
                } & {
                  meetingBaseData?: Maybe<
                    { __typename?: 'StandardMeetingBaseData' } & Pick<
                      StandardMeetingBaseData,
                      'activityType' | 'createTime' | 'type'
                    > & {
                        belongProduct?: Maybe<
                          { __typename?: 'UpProduct' } & Pick<
                            UpProduct,
                            'productName'
                          >
                        >
                        owner?: Maybe<
                          { __typename?: 'AccountInfo' } & {
                            company?: Maybe<
                              { __typename?: 'CompanyInfo' } & Pick<
                                CompanyInfo,
                                'companyId' | 'cnName'
                              >
                            >
                          }
                        >
                      }
                  >
                  meetingRegion?: Maybe<
                    { __typename?: 'AccountGroupInfo' } & Pick<
                      AccountGroupInfo,
                      'name'
                    >
                  >
                }
            >
          }
      >
      pager: { __typename?: 'PagerResult' } & Pick<
        PagerResult,
        'currentPage' | 'pageSize' | 'total' | 'hasMore'
      >
    }
  >
}

export type SendTaskInviteMessageMutationVariables = Exact<{
  taskUrl: Scalars['String']
  activityName: Scalars['String']
  doctorAccountId: Scalars['String']
  hash?: Maybe<Scalars['String']>
}>

export type SendTaskInviteMessageMutation = { __typename?: 'Mutation' } & {
  result: Mutation['sendTaskInvitationMessage']
}

export type SendMiniProgramTaskInviteMessageMutationVariables = Exact<{
  appName: Scalars['String']
  activityName: Scalars['String']
  doctorAccountId: Scalars['String']
}>

export type SendMiniProgramTaskInviteMessageMutation = {
  __typename?: 'Mutation'
} & { result: Mutation['sendMiniProgramTaskInvitationMessage'] }

export type GetUrlLinkByAppNameQueryVariables = Exact<{
  appName: Scalars['String']
  doctorAccountId: Scalars['String']
}>

export type GetUrlLinkByAppNameQuery = { __typename?: 'Query' } & {
  result: Query['getWechatUrlLink']
}

export type RetreatAttenderTaskMutationVariables = Exact<{
  reason?: Maybe<Scalars['String']>
  taskId: Scalars['String']
}>

export type RetreatAttenderTaskMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'BaseResponse' } & Pick<
      BaseResponse,
      'errorCode' | 'message' | 'success'
    >
  >
}

export type AccountFieldFragment = { __typename?: 'AccountInfo' } & Pick<
  AccountInfo,
  'accountId' | 'companyId' | 'hrProduction'
> & { name: AccountInfo['cnName']; mobile: AccountInfo['companyMobile'] }

export type MenuFieldFragment = { __typename?: 'AppMenu' } & Pick<
  AppMenu,
  'id' | 'name' | 'url' | 'access' | 'orderNum' | 'code'
>

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'AccountInfo' } & {
      roles: Array<
        { __typename?: 'RoleInfo' } & Pick<
          RoleInfo,
          'id' | 'name' | 'type' | 'enable'
        >
      >
      appMenus?: Maybe<Array<{ __typename?: 'AppMenu' } & MenuFieldFragment>>
      roleAuthorities?: Maybe<
        Array<
          { __typename?: 'RoleAuthority' } & Pick<
            RoleAuthority,
            'appId' | 'appName'
          >
        >
      >
    } & AccountFieldFragment
  >
}

export type UserInfoQueryVariables = Exact<{
  accountId: Scalars['String']
}>

export type UserInfoQuery = { __typename?: 'Query' } & {
  user?: Maybe<{ __typename?: 'AccountInfo' } & AccountFieldFragment>
}

export type UserLoginMutationVariables = Exact<{
  password: Scalars['String']
  mobile: Scalars['String']
}>

export type UserLoginMutation = { __typename?: 'Mutation' } & {
  result: { __typename?: 'BmsLoginResponse' } & Pick<
    BmsLoginResponse,
    'token'
  > & {
      account: { __typename?: 'AccountInfo' } & {
        roles: Array<
          { __typename?: 'RoleInfo' } & Pick<
            RoleInfo,
            'id' | 'name' | 'type' | 'enable'
          >
        >
        appMenus?: Maybe<Array<{ __typename?: 'AppMenu' } & MenuFieldFragment>>
        roleAuthorities?: Maybe<
          Array<
            { __typename?: 'RoleAuthority' } & Pick<
              RoleAuthority,
              'appId' | 'appName'
            >
          >
        >
      } & AccountFieldFragment
    }
}

export type SendCodeMutationVariables = Exact<{
  mobile: Scalars['String']
}>

export type SendCodeMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_sendCode']
}

export type GetSessionIdMutationVariables = Exact<{
  mobile: Scalars['String']
}>

export type GetSessionIdMutation = { __typename?: 'Mutation' } & {
  sessionId: Mutation['hengruiImage_sendCode']
}

export type EditPassMutationVariables = Exact<{
  companyName: Scalars['String']
  newPassword: Scalars['String']
  sessionId: Scalars['String']
  code: Scalars['String']
  mobile: Scalars['String']
}>

export type EditPassMutation = { __typename?: 'Mutation' } & {
  result: Mutation['resetPassword']
}

export type PwdLoginMutationVariables = Exact<{
  mobile: Scalars['String']
  password: Scalars['String']
}>

export type PwdLoginMutation = { __typename?: 'Mutation' } & {
  result: Mutation['medicalLiteratureLoginWithPwd']
}

export type UserLoginWithCodeMutationVariables = Exact<{
  mobile: Scalars['String']
  code: Scalars['String']
  sessionId: Scalars['String']
}>

export type UserLoginWithCodeMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_loginWithCode']
}

export type UserLogOutMutationVariables = Exact<{ [key: string]: never }>

export type UserLogOutMutation = { __typename?: 'Mutation' } & {
  result: Mutation['hengruiImage_logOut']
}

export const ExportMemberInfoFragmentDoc = gql`
  fragment ExportMemberInfo on StandardMeetingBaseAttender {
    id
    attenderId
    serviceFee
    totalFee: actualFee
    attenderSettlementTime
    billTime
    billStatus
    attenderData
    attenderFee
    attenderStatus
    cnName: attenderName
    companyMobile: attenderPhone
    attenderInfo {
      hospital: custom1
    }
  }
`
export const AttenderInfoFragmentDoc = gql`
  fragment AttenderInfo on StandardMeetingBaseAttender {
    id
    attenderId
    attenderData
    channel
    attenderStatus
    attenderFee
    channel
    attenderInfo {
      userCertificationImages {
        certImages
        cnName
      }
      userTitleCertificationImages {
        certImages
      }
      userComplexMaterialCertImages {
        certImages
      }
      titleInfo: group(groupType: "title") {
        name
        groupId
      }
      cnName
      companyId
      companyMobile
      hospital: custom1
      bankInfo {
        certNo
        bankName: custom1
        ssqBankName
        qkkBankName
      }
      province: custom2
      verifyResult
      verifyImages
      minPointLimitConfig: attenderTitleRuleConfig {
        pointLimit
      }
    }
  }
`
export const MeetingDetailFragmentDoc = gql`
  fragment MeetingDetail on StandardMeetingInfo {
    meetingType: hengruiImage_categoryName
    activityType: hengruiImage_activityType
    base: meetingBaseData {
      belongProduct {
        id
        productName
      }
      type
      activityType
      name
      cityId
      id
      status
      startTime
      createTime
      endTime
      applyTime
      approvalTime
      desc
      code
      owner {
        cnName
        companyMobile
        region: group(groupType: "region") {
          name
        }
        company {
          cnName
          name
        }
        verifyResult
      }
      budgetNumber
      budgetChannel
      needBudget
    }
    hospital: hengruiImage_hospital
    projectType: hengruiImage_projectType
    speakers: hengruiImage_spearkers {
      ...AttenderInfo
    }
    doctors: hengruiImage_attendDoctors {
      ...AttenderInfo
    }
    city: hengruiImage_city {
      name
    }
    costcenter: hengruiImage_costcenter {
      name
    }
    mettingRegion: hengruiImage_meetingRegion {
      name
      province {
        id
        name
      }
    }
    status: hengruiImage_status
    statusText: hengruiImage_statusText
    statusExtra: hengruiImage_statusExtra
    vendor: hengruiImage_vendor {
      companyId
      cnName
    }
    approveStatus: hengruiImage_approveStatus
    isCurrentApprover: isCurrentApproverOrYHLXS
    forwardSignatureOwner: hengruiImage_forwardSignatureOwner
    approveUser: hengruiImage_meetingApproveUser
  }
  ${AttenderInfoFragmentDoc}
`
export const YhlMeetingDetailFragmentDoc = gql`
  fragment YHLMeetingDetail on StandardMeetingInfo {
    meetingType: hengruiImage_categoryName
    activityType: hengruiImage_activityType
    base: meetingBaseData {
      belongProduct {
        id
        productName
      }
      type
      activityType
      name
      cityId
      id
      status
      startTime
      createTime
      endTime
      applyTime
      approvalTime
      desc
      code
      owner {
        cnName
        company {
          cnName
          name
        }
      }
      budgetNumber
      budgetChannel
      needBudget
    }
    hospital: hengruiImage_hospital
    projectType: hengruiImage_projectType
    speakers: hengruiImage_spearkers {
      ...AttenderInfo
    }
    doctors: hengruiImage_attendDoctors {
      ...AttenderInfo
    }
    city: hengruiImage_city {
      name
    }
    costcenter: hengruiImage_costcenter {
      name
    }
    mettingRegion: hengruiImage_meetingRegion {
      name
      province {
        id
        name
      }
    }
    status: hengruiImage_status
    statusText: hengruiImage_statusText
    statusExtra: hengruiImage_statusExtra
    vendor: hengruiImage_vendor {
      companyId
      cnName
    }
    approveStatus: hengruiImage_approveStatus
    isCurrentApprover: isCurrentApproverOrYHLXS
    forwardSignatureOwner: hengruiImage_forwardSignatureOwner
    approveUser: hengruiImage_meetingApproveUser
  }
  ${AttenderInfoFragmentDoc}
`
export const SalesOrderInfoFragmentDoc = gql`
  fragment SalesOrderInfo on SalesOrderBaseInfo {
    id
    conferenceCategoryCode
    consumptionCategoryCode
    budgetChannel
    budgetNumber
    accountId
    accountName
    accountMobile
    formCategory
    targetHospital
    targetDepartment
    applyReason
    resourceId
    targetName
    targetAddress
    targetOccurTime
    peopleNumber
    actualPeopleNumber
    appointmentTime
    consumptionAmount
    actualConsumptionAmount
    actualConsumptionTime
    submitTime
    occurCity
    status
    reason
    paymentType
    paymentTime
    invoiceQuantity
    invoiceVerification
    companyId
    businessId
    businessName
    regionId
    regionName
    areaId
    areaName
    costcenterId
    costcenterName
    isEnable
    remark
    claimAmount
    preVoucherAuditTime
    deductionAmount
    financialDeductionAmount
    deductionOperator
    financialDeductionOperator
    deductionReason
    qcOwner
    finalCorrection
    finalCorrectionReason
    usageScenario
    oaNumber
  }
`
export const SalesOrderPropertieInfoFragmentDoc = gql`
  fragment SalesOrderPropertieInfo on SalesOrderProperties {
    id
    orderId
    type
    key
    value
  }
`
export const SalesClaimFormPropertieInfoFragmentDoc = gql`
  fragment SalesClaimFormPropertieInfo on SalesClaimFormProperties {
    id
    claimFormCode
    type
    key
    value
  }
`
export const SalesClaimFormInfoFragmentDoc = gql`
  fragment SalesClaimFormInfo on SalesClaimFormBaseInfo {
    id
    claimFormName
    claimFormCode
    qrCodeImage
    amount
    accountId
    accountName
    accountMobile
    companyId
    submitTime
    businessId
    business
    regionId
    region
    areaId
    area
    status
    reason
    isEnable
    remark
    gmtCreated
    claimAmount
    approvedTime
    preReviewedTime
    reviewedTime
    budgetChannel
    budgetNumber
  }
`
export const AccountFieldFragmentDoc = gql`
  fragment accountField on AccountInfo {
    name: cnName
    accountId
    companyId
    mobile: companyMobile
    hrProduction
  }
`
export const MenuFieldFragmentDoc = gql`
  fragment menuField on AppMenu {
    id
    name
    url
    access
    orderNum
    code
  }
`
export const AdminCompanyDetailDocument = gql`
  query AdminCompanyDetail($companyId: String!) {
    company: adminCompanyDetail(id: $companyId) {
      companyId
      cnName
    }
  }
`

export function useAdminCompanyDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminCompanyDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminCompanyDetailQuery>({
    query: AdminCompanyDetailDocument,
    ...options
  })
}
export const AdminCompanyGroupTreeDocument = gql`
  query AdminCompanyGroupTree($companyId: String!, $groupType: String) {
    groupTree: adminCompanyGroupTree(
      companyId: $companyId
      groupType: $groupType
    )
  }
`

export function useAdminCompanyGroupTreeQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminCompanyGroupTreeQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminCompanyGroupTreeQuery>({
    query: AdminCompanyGroupTreeDocument,
    ...options
  })
}
export const AdminCompanyGroupDocument = gql`
  query AdminCompanyGroup($groupId: String!) {
    group: adminCompanyGroup(groupId: $groupId) {
      name
      groupId
      code
      owner {
        cnName
        companyMobile
      }
    }
  }
`

export function useAdminCompanyGroupQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminCompanyGroupQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminCompanyGroupQuery>({
    query: AdminCompanyGroupDocument,
    ...options
  })
}
export const AdminGroupMembersDocument = gql`
  query AdminGroupMembers($groupId: String!, $pager: PagerInput) {
    members: adminGroupMembers(groupId: $groupId, pager: $pager) {
      items {
        cnName
        companyMobile
      }
      pager {
        currentPage
        total
        pageSize
      }
    }
  }
`

export function useAdminGroupMembersQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminGroupMembersQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminGroupMembersQuery>({
    query: AdminGroupMembersDocument,
    ...options
  })
}
export const AdminCompanyApprovalFormsDocument = gql`
  query AdminCompanyApprovalForms($companyId: String!) {
    forms: adminApprovalFlowForms(companyId: $companyId) {
      name
      desc
      id
      key
    }
  }
`

export function useAdminCompanyApprovalFormsQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminCompanyApprovalFormsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminCompanyApprovalFormsQuery>({
    query: AdminCompanyApprovalFormsDocument,
    ...options
  })
}
export const AdminCompanyApprovalDetailDocument = gql`
  query AdminCompanyApprovalDetail($formId: String!) {
    detail: adminApprovalFlowDetail(targetId: $formId)
  }
`

export function useAdminCompanyApprovalDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminCompanyApprovalDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminCompanyApprovalDetailQuery>({
    query: AdminCompanyApprovalDetailDocument,
    ...options
  })
}
export const AdminApprovalNodeTypesDocument = gql`
  query AdminApprovalNodeTypes {
    types: adminApprovalNodeTypes {
      name
      code
      id
    }
  }
`

export function useAdminApprovalNodeTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<AdminApprovalNodeTypesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AdminApprovalNodeTypesQuery>({
    query: AdminApprovalNodeTypesDocument,
    ...options
  })
}
export const AdminApprovalFlowSaveDocument = gql`
  mutation AdminApprovalFlowSave(
    $formId: String!
    $companyId: String!
    $nodeGroups: [NodeGroupInfo!]!
  ) {
    result: adminSaveApprovalFlowDetail(
      targetId: $formId
      companyId: $companyId
      nodeGroups: $nodeGroups
    )
  }
`

export function useAdminApprovalFlowSaveMutation() {
  return Urql.useMutation<
    AdminApprovalFlowSaveMutation,
    AdminApprovalFlowSaveMutationVariables
  >(AdminApprovalFlowSaveDocument)
}
export const AdminCreateApprovalFlowDocument = gql`
  mutation AdminCreateApprovalFlow(
    $name: String!
    $companyId: String!
    $key: String!
    $desc: String!
    $id: String!
  ) {
    result: adminCreateApprovalFlow(
      id: $id
      name: $name
      companyId: $companyId
      key: $key
      desc: $desc
    )
  }
`

export function useAdminCreateApprovalFlowMutation() {
  return Urql.useMutation<
    AdminCreateApprovalFlowMutation,
    AdminCreateApprovalFlowMutationVariables
  >(AdminCreateApprovalFlowDocument)
}
export const ArchiveListDocument = gql`
  query ArchiveList($pager: PagerInput, $query: ArchiveSearchParams) {
    queryArchiveList(params: { pager: $pager, query: $query }) {
      items {
        id
        describe
        url: archiveUrl
        title: archiveTitle
        type: archiveType
        creator {
          cnName
        }
        products: archiveTags {
          tag
          id
        }
        createTimestamp
        isEnable
      }
      pager {
        pageSize
        currentPage
        total
      }
    }
  }
`

export function useArchiveListQuery(
  options: Omit<Urql.UseQueryArgs<ArchiveListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ArchiveListQuery>({
    query: ArchiveListDocument,
    ...options
  })
}
export const ProductsListDocument = gql`
  query ProductsList($companyId: String) {
    getArchiveProductsList(companyId: $companyId) {
      tag
      id
    }
  }
`

export function useProductsListQuery(
  options: Omit<Urql.UseQueryArgs<ProductsListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ProductsListQuery>({
    query: ProductsListDocument,
    ...options
  })
}
export const ArchiveSignedUrlDocument = gql`
  query ArchiveSignedUrl($archiveId: String!) {
    getArchiveSignedUrl(archiveId: $archiveId)
  }
`

export function useArchiveSignedUrlQuery(
  options: Omit<Urql.UseQueryArgs<ArchiveSignedUrlQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ArchiveSignedUrlQuery>({
    query: ArchiveSignedUrlDocument,
    ...options
  })
}
export const DisableArchiveDocument = gql`
  mutation DisableArchive($archiveId: String!) {
    disableArchive(archiveId: $archiveId) {
      success
      message
    }
  }
`

export function useDisableArchiveMutation() {
  return Urql.useMutation<
    DisableArchiveMutation,
    DisableArchiveMutationVariables
  >(DisableArchiveDocument)
}
export const EnableArchiveDocument = gql`
  mutation EnableArchive($archiveId: String!) {
    enableArchive(archiveId: $archiveId) {
      success
      message
    }
  }
`

export function useEnableArchiveMutation() {
  return Urql.useMutation<
    EnableArchiveMutation,
    EnableArchiveMutationVariables
  >(EnableArchiveDocument)
}
export const SaveArchiveDocument = gql`
  mutation SaveArchive($params: ArchiveCreateParams!) {
    saveArchive(archiveInfo: $params)
  }
`

export function useSaveArchiveMutation() {
  return Urql.useMutation<SaveArchiveMutation, SaveArchiveMutationVariables>(
    SaveArchiveDocument
  )
}
export const HansohBudgetListDocument = gql`
  query HansohBudgetList($pager: PagerInput, $query: BudgetSearch) {
    budget: searchBudgetBackStage(param: { pager: $pager, query: $query }) {
      items {
        accountId
        area
        region
        business
        financeNo
        accountName
        budget {
          id
          month
          year
          budgetAmount
          deductionAmount
          usedAmount
          balanceAmount
          increaseAmount
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useHansohBudgetListQuery(
  options: Omit<Urql.UseQueryArgs<HansohBudgetListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HansohBudgetListQuery>({
    query: HansohBudgetListDocument,
    ...options
  })
}
export const PreImportHansohBudgetDocument = gql`
  mutation PreImportHansohBudget(
    $importId: String
    $data: [BudgetImportData!]!
  ) {
    result: preImportHansohBudget(importId: $importId, data: $data)
  }
`

export function usePreImportHansohBudgetMutation() {
  return Urql.useMutation<
    PreImportHansohBudgetMutation,
    PreImportHansohBudgetMutationVariables
  >(PreImportHansohBudgetDocument)
}
export const ImportHansohBudgetDocument = gql`
  mutation ImportHansohBudget($importId: String!, $data: [BudgetImportData!]) {
    result: importHansohBudget(importId: $importId, data: $data) {
      errorCode
      message
      success
    }
  }
`

export function useImportHansohBudgetMutation() {
  return Urql.useMutation<
    ImportHansohBudgetMutation,
    ImportHansohBudgetMutationVariables
  >(ImportHansohBudgetDocument)
}
export const CompanyGroupDocument = gql`
  query CompanyGroup(
    $type: String!
    $level: String
    $pid: String
    $companyId: String
  ) {
    result: queryCompanyGroup(
      type: $type
      level: $level
      pid: $pid
      companyId: $companyId
    ) {
      groupId
      name
      pid
    }
  }
`

export function useCompanyGroupQuery(
  options: Omit<Urql.UseQueryArgs<CompanyGroupQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CompanyGroupQuery>({
    query: CompanyGroupDocument,
    ...options
  })
}
export const PreImportProcessBudgetDocument = gql`
  mutation PreImportProcessBudget(
    $importId: String
    $data: [ProcessBudgetImportData!]!
  ) {
    result: preImportProcessBudget(importId: $importId, data: $data)
  }
`

export function usePreImportProcessBudgetMutation() {
  return Urql.useMutation<
    PreImportProcessBudgetMutation,
    PreImportProcessBudgetMutationVariables
  >(PreImportProcessBudgetDocument)
}
export const ImportProcessBudgetDocument = gql`
  mutation ImportProcessBudget(
    $importId: String!
    $data: [ProcessBudgetImportData!]!
  ) {
    result: importProcessBudget(importId: $importId, data: $data) {
      errorCode
      message
      success
    }
  }
`

export function useImportProcessBudgetMutation() {
  return Urql.useMutation<
    ImportProcessBudgetMutation,
    ImportProcessBudgetMutationVariables
  >(ImportProcessBudgetDocument)
}
export const ProcessBudgetListDocument = gql`
  query ProcessBudgetList($pager: PagerInput, $query: ProcessBudgetSearch) {
    budget: pageSearchProcessBudget(param: { pager: $pager, query: $query }) {
      items {
        id
        budgetNumber
        budgetAmount
        usedFee
        balanceFee
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useProcessBudgetListQuery(
  options: Omit<
    Urql.UseQueryArgs<ProcessBudgetListQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<ProcessBudgetListQuery>({
    query: ProcessBudgetListDocument,
    ...options
  })
}
export const PersonalBudgetBusinessDepartmentDocument = gql`
  query PersonalBudgetBusinessDepartment {
    result: queryPersonalBudgetBusinessDepartments {
      accountId
      businessId
      businessName
    }
  }
`

export function usePersonalBudgetBusinessDepartmentQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalBudgetBusinessDepartmentQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PersonalBudgetBusinessDepartmentQuery>({
    query: PersonalBudgetBusinessDepartmentDocument,
    ...options
  })
}
export const ProcessBudgetDetailDocument = gql`
  query ProcessBudgetDetail($id: Int!) {
    result: queryProcessBudgetDetail(id: $id) {
      id
      budgetNumber
      budgetAmount
      usedFee
      balanceFee
    }
  }
`

export function useProcessBudgetDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<ProcessBudgetDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<ProcessBudgetDetailQuery>({
    query: ProcessBudgetDetailDocument,
    ...options
  })
}
export const ProcessBudgetLogDocument = gql`
  query ProcessBudgetLog($pager: PagerInput, $query: ProcessBudgetSearch) {
    result: queryProcessBudgetLog(param: { pager: $pager, query: $query }) {
      items {
        operationDate
        changeValue
        snapshotBudgetAmount
        snapshotUsedFee
        snapshotBalanceFee
        currentBalanceFee
        description
        operator
        operatorName
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useProcessBudgetLogQuery(
  options: Omit<Urql.UseQueryArgs<ProcessBudgetLogQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ProcessBudgetLogQuery>({
    query: ProcessBudgetLogDocument,
    ...options
  })
}
export const AdjustProcessBudgetDocument = gql`
  mutation AdjustProcessBudget(
    $id: Int!
    $changeValue: String!
    $description: String
  ) {
    result: adjustProcessBudget(
      id: $id
      changeValue: $changeValue
      description: $description
    ) {
      errorCode
      message
      success
    }
  }
`

export function useAdjustProcessBudgetMutation() {
  return Urql.useMutation<
    AdjustProcessBudgetMutation,
    AdjustProcessBudgetMutationVariables
  >(AdjustProcessBudgetDocument)
}
export const PersonalBudgetDetailDocument = gql`
  query PersonalBudgetDetail($id: Int!) {
    result: queryPersonalBudgetDetail(id: $id) {
      id
      companyId
      accountId
      accountName
      budgetType
      financeNo
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      businessId
      business
      region
      area
      startTimestamp
      endTimestamp
      year
      month
      accountInfo {
        companyMobile
      }
    }
  }
`

export function usePersonalBudgetDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalBudgetDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PersonalBudgetDetailQuery>({
    query: PersonalBudgetDetailDocument,
    ...options
  })
}
export const PersonalBudgetLogDocument = gql`
  query PersonalBudgetLog($pager: PagerInput, $query: PersonalBudgetSearch) {
    result: queryPersonalBudgetLog(param: { pager: $pager, query: $query }) {
      items {
        id
        operationDate
        BudgetAmountChanged
        BudgetAmountBefore
        increaseAmount
        usedAmount
        balanceAmountChanged
        balanceAmountBefore
        operatorRemark
        operator
        operatorAccountInfo {
          cnName
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function usePersonalBudgetLogQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalBudgetLogQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PersonalBudgetLogQuery>({
    query: PersonalBudgetLogDocument,
    ...options
  })
}
export const LimitSwapPeopleDocument = gql`
  query LimitSwapPeople($keyword: String) {
    result: queryLimitSwapPeople(keyword: $keyword) {
      accountId
      companyId
      financeNo
      name
      phone
    }
  }
`

export function useLimitSwapPeopleQuery(
  options: Omit<Urql.UseQueryArgs<LimitSwapPeopleQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<LimitSwapPeopleQuery>({
    query: LimitSwapPeopleDocument,
    ...options
  })
}
export const AdjustPersonalBudgetDocument = gql`
  mutation AdjustPersonalBudget(
    $id: Int!
    $amount: String!
    $operatorRemark: String
  ) {
    result: adjustPersonalBudget(
      id: $id
      amount: $amount
      operatorRemark: $operatorRemark
    ) {
      errorCode
      message
      success
    }
  }
`

export function useAdjustPersonalBudgetMutation() {
  return Urql.useMutation<
    AdjustPersonalBudgetMutation,
    AdjustPersonalBudgetMutationVariables
  >(AdjustPersonalBudgetDocument)
}
export const SwapPersonalBudgetDocument = gql`
  mutation SwapPersonalBudget(
    $id: Int!
    $sourceAccountId: String!
    $targetAccountId: String!
    $amount: String!
    $operatorRemark: String
  ) {
    result: swapPersonalBudget(
      id: $id
      sourceAccountId: $sourceAccountId
      targetAccountId: $targetAccountId
      amount: $amount
      operatorRemark: $operatorRemark
    ) {
      errorCode
      message
      success
    }
  }
`

export function useSwapPersonalBudgetMutation() {
  return Urql.useMutation<
    SwapPersonalBudgetMutation,
    SwapPersonalBudgetMutationVariables
  >(SwapPersonalBudgetDocument)
}
export const SearchBudgetUsedDetailDocument = gql`
  query SearchBudgetUsedDetail(
    $param: PersonalBudgetUsedDetailSearchWithPager
  ) {
    result: queryUsedDetail(param: $param) {
      items {
        meetingId
        meetingName
        attenderName
        attenderPhone
        attenderFee
        submitTime
        accountId
        accountInfo {
          cnName
          staffId
          department: group(groupType: "department") {
            name
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useSearchBudgetUsedDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<SearchBudgetUsedDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SearchBudgetUsedDetailQuery>({
    query: SearchBudgetUsedDetailDocument,
    ...options
  })
}
export const SearchPersonalBudgetByMonthDocument = gql`
  query SearchPersonalBudgetByMonth($param: OwnerLimitSearch) {
    result: searchOwnerLimit(param: $param) {
      id
      companyId
      accountId
      accountName
      budgetType
      financeNo
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      businessId
      business
      region
      area
      startTimestamp
      endTimestamp
      month
      accountInfo {
        companyMobile
      }
    }
  }
`

export function useSearchPersonalBudgetByMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<SearchPersonalBudgetByMonthQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SearchPersonalBudgetByMonthQuery>({
    query: SearchPersonalBudgetByMonthDocument,
    ...options
  })
}
export const PreImportHansohInPlanBudgetDocument = gql`
  mutation PreImportHansohInPlanBudget(
    $importId: String
    $data: [PersonalPlanBudgetImportData!]!
  ) {
    result: preImportHansohInPlanBudget(importId: $importId, data: $data)
  }
`

export function usePreImportHansohInPlanBudgetMutation() {
  return Urql.useMutation<
    PreImportHansohInPlanBudgetMutation,
    PreImportHansohInPlanBudgetMutationVariables
  >(PreImportHansohInPlanBudgetDocument)
}
export const ImportHansohInPlanBudgetDocument = gql`
  mutation ImportHansohInPlanBudget(
    $importId: String!
    $data: [PersonalPlanBudgetImportData!]!
  ) {
    result: importHansohInPlanBudget(importId: $importId, data: $data) {
      errorCode
      message
      success
    }
  }
`

export function useImportHansohInPlanBudgetMutation() {
  return Urql.useMutation<
    ImportHansohInPlanBudgetMutation,
    ImportHansohInPlanBudgetMutationVariables
  >(ImportHansohInPlanBudgetDocument)
}
export const InPlanBudgetDocument = gql`
  query InPlanBudget($pager: PagerInput, $query: PersonalPlanBudgetSearch) {
    result: searchInPlanBudget(param: { pager: $pager, query: $query }) {
      items {
        budgetId
        businessId
        business
        accountId
        accountName
        financeNo
        year
        lastMonthAmount
        lastMonthBalanceAmount
        currentMonthAmount
        currentMonthIncreaseAmount
        currentMonthUsedAmount
        nextMonthAmount
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useInPlanBudgetQuery(
  options: Omit<Urql.UseQueryArgs<InPlanBudgetQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<InPlanBudgetQuery>({
    query: InPlanBudgetDocument,
    ...options
  })
}
export const InPlanBudgetDetailDocument = gql`
  query InPlanBudgetDetail($id: Int!) {
    result: queryInPlanBudgetDetail(id: $id) {
      id
      companyId
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      end
      year
      month
      budgetType
      isLock
      isEnable
      remark
      creator
      modifier
      accountId
      accountName
      accountMobile
      business
      financeNo
    }
  }
`

export function useInPlanBudgetDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<InPlanBudgetDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<InPlanBudgetDetailQuery>({
    query: InPlanBudgetDetailDocument,
    ...options
  })
}
export const AdjustInPlanBudgetDocument = gql`
  mutation AdjustInPlanBudget(
    $id: Int!
    $amount: String!
    $operatorRemark: String
  ) {
    result: adjustInPlanBudget(
      id: $id
      amount: $amount
      operatorRemark: $operatorRemark
    ) {
      errorCode
      message
      success
    }
  }
`

export function useAdjustInPlanBudgetMutation() {
  return Urql.useMutation<
    AdjustInPlanBudgetMutation,
    AdjustInPlanBudgetMutationVariables
  >(AdjustInPlanBudgetDocument)
}
export const PersonalPlanBudgetUsedDetailDocument = gql`
  query PersonalPlanBudgetUsedDetail(
    $param: PersonalPlanBudgetUsedDetailSearchWithPager
  ) {
    result: queryPlanBudgetUsedDetail(param: $param) {
      items {
        formCategory
        budgetUsedConsumptionCategory {
          id
          title
          describe
          code
        }
        consumptionCategoryCode
        targetName
        accountId
        accountName
        accountMobile
        planAmount
        actualAmount
        submitTime
        orderId
        claimAmount
        deductionAmount
        financialDeductionAmount
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function usePersonalPlanBudgetUsedDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalPlanBudgetUsedDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PersonalPlanBudgetUsedDetailQuery>({
    query: PersonalPlanBudgetUsedDetailDocument,
    ...options
  })
}
export const InPlanBudgetLogDocument = gql`
  query InPlanBudgetLog(
    $pager: PagerInput
    $query: PersonalPlanBudgetLogSearch
  ) {
    result: queryInPlanBudgetLog(param: { pager: $pager, query: $query }) {
      items {
        id
        budgetId
        accountId
        budgetAmountChanged
        budgetAmountBefore
        increaseAmount
        usedAmount
        balanceAmountChanged
        balanceAmountBefore
        remark
        operator
        operationTime
        operatorAccountInfo {
          cnName
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useInPlanBudgetLogQuery(
  options: Omit<Urql.UseQueryArgs<InPlanBudgetLogQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<InPlanBudgetLogQuery>({
    query: InPlanBudgetLogDocument,
    ...options
  })
}
export const InPlanBudgetByMonthDocument = gql`
  query InPlanBudgetByMonth($param: PersonalPlanMonthBudgetSearch) {
    result: searchInPlanBudgetByMonth(param: $param) {
      id
      companyId
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      end
      year
      month
      budgetType
      isLock
      isEnable
      remark
      creator
      modifier
      accountId
      accountName
      accountMobile
      business
      financeNo
    }
  }
`

export function useInPlanBudgetByMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<InPlanBudgetByMonthQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<InPlanBudgetByMonthQuery>({
    query: InPlanBudgetByMonthDocument,
    ...options
  })
}
export const PageUserManagementDocument = gql`
  query PageUserManagement($pager: PagerInput, $query: BudgetSearch) {
    budget: pageUserManagement(param: { pager: $pager, query: $query }) {
      items {
        budgetId
        companyId
        businessId
        business
        regionId
        region
        areaId
        area
        financeNo
        accountName
        accountId
        accountMobile
        accountEmail
        year
        month
        accountStatus
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function usePageUserManagementQuery(
  options: Omit<
    Urql.UseQueryArgs<PageUserManagementQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PageUserManagementQuery>({
    query: PageUserManagementDocument,
    ...options
  })
}
export const PersonalPlanBudgetBusinessAuthorityDocument = gql`
  query PersonalPlanBudgetBusinessAuthority(
    $param: PersonalPlanBudgetAuthoritySearch
  ) {
    result: getBudgetAuthority(param: $param) {
      accountId
      businessId
      businessName
    }
  }
`

export function usePersonalPlanBudgetBusinessAuthorityQuery(
  options: Omit<
    Urql.UseQueryArgs<PersonalPlanBudgetBusinessAuthorityQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<PersonalPlanBudgetBusinessAuthorityQuery>({
    query: PersonalPlanBudgetBusinessAuthorityDocument,
    ...options
  })
}
export const PreImportHansohOutPlanBudgetDocument = gql`
  mutation PreImportHansohOutPlanBudget(
    $importId: String
    $data: [PersonalPlanBudgetImportData!]!
  ) {
    result: preImportHansohOutPlanBudget(importId: $importId, data: $data)
  }
`

export function usePreImportHansohOutPlanBudgetMutation() {
  return Urql.useMutation<
    PreImportHansohOutPlanBudgetMutation,
    PreImportHansohOutPlanBudgetMutationVariables
  >(PreImportHansohOutPlanBudgetDocument)
}
export const ImportHansohOutPlanBudgetDocument = gql`
  mutation ImportHansohOutPlanBudget(
    $importId: String!
    $data: [PersonalPlanBudgetImportData!]!
  ) {
    result: importHansohOutPlanBudget(importId: $importId, data: $data) {
      errorCode
      message
      success
    }
  }
`

export function useImportHansohOutPlanBudgetMutation() {
  return Urql.useMutation<
    ImportHansohOutPlanBudgetMutation,
    ImportHansohOutPlanBudgetMutationVariables
  >(ImportHansohOutPlanBudgetDocument)
}
export const OutPlanBudgetDocument = gql`
  query OutPlanBudget($pager: PagerInput, $query: PersonalPlanBudgetSearch) {
    result: searchOutPlanBudget(param: { pager: $pager, query: $query }) {
      items {
        budgetId
        businessId
        business
        accountId
        accountName
        financeNo
        year
        lastMonthAmount
        lastMonthBalanceAmount
        currentMonthAmount
        currentMonthIncreaseAmount
        currentMonthUsedAmount
        nextMonthAmount
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useOutPlanBudgetQuery(
  options: Omit<Urql.UseQueryArgs<OutPlanBudgetQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<OutPlanBudgetQuery>({
    query: OutPlanBudgetDocument,
    ...options
  })
}
export const OutPlanBudgetDetailDocument = gql`
  query OutPlanBudgetDetail($id: Int!) {
    result: queryOutPlanBudgetDetail(id: $id) {
      id
      companyId
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      end
      year
      month
      budgetType
      isLock
      isEnable
      remark
      creator
      modifier
      accountId
      accountName
      accountMobile
      business
      financeNo
    }
  }
`

export function useOutPlanBudgetDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<OutPlanBudgetDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<OutPlanBudgetDetailQuery>({
    query: OutPlanBudgetDetailDocument,
    ...options
  })
}
export const AdjustOutPlanBudgetDocument = gql`
  mutation AdjustOutPlanBudget(
    $id: Int!
    $amount: String!
    $operatorRemark: String
  ) {
    result: adjustOutPlanBudget(
      id: $id
      amount: $amount
      operatorRemark: $operatorRemark
    ) {
      errorCode
      message
      success
    }
  }
`

export function useAdjustOutPlanBudgetMutation() {
  return Urql.useMutation<
    AdjustOutPlanBudgetMutation,
    AdjustOutPlanBudgetMutationVariables
  >(AdjustOutPlanBudgetDocument)
}
export const OutPlanBudgetLogDocument = gql`
  query OutPlanBudgetLog(
    $pager: PagerInput
    $query: PersonalPlanBudgetLogSearch
  ) {
    result: queryOutPlanBudgetLog(param: { pager: $pager, query: $query }) {
      items {
        id
        budgetId
        accountId
        budgetAmountChanged
        budgetAmountBefore
        increaseAmount
        usedAmount
        balanceAmountChanged
        balanceAmountBefore
        remark
        operator
        operationTime
        operatorAccountInfo {
          cnName
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useOutPlanBudgetLogQuery(
  options: Omit<Urql.UseQueryArgs<OutPlanBudgetLogQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<OutPlanBudgetLogQuery>({
    query: OutPlanBudgetLogDocument,
    ...options
  })
}
export const OutPlanBudgetByMonthDocument = gql`
  query OutPlanBudgetByMonth($param: PersonalPlanMonthBudgetSearch) {
    result: searchOutPlanBudgetByMonth(param: $param) {
      id
      companyId
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      end
      year
      month
      budgetType
      isLock
      isEnable
      remark
      creator
      modifier
      accountId
      accountName
      accountMobile
    }
  }
`

export function useOutPlanBudgetByMonthQuery(
  options: Omit<
    Urql.UseQueryArgs<OutPlanBudgetByMonthQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<OutPlanBudgetByMonthQuery>({
    query: OutPlanBudgetByMonthDocument,
    ...options
  })
}
export const PreImportHansohCostCenterBudgetDocument = gql`
  mutation PreImportHansohCostCenterBudget(
    $importId: String
    $data: [CostCenterBudgetImportData!]!
  ) {
    result: preImportHansohCostCenterBudget(importId: $importId, data: $data)
  }
`

export function usePreImportHansohCostCenterBudgetMutation() {
  return Urql.useMutation<
    PreImportHansohCostCenterBudgetMutation,
    PreImportHansohCostCenterBudgetMutationVariables
  >(PreImportHansohCostCenterBudgetDocument)
}
export const ImportHansohCostCenterBudgetDocument = gql`
  mutation ImportHansohCostCenterBudget($importId: String!) {
    result: importHansohCostCenterBudget(importId: $importId) {
      errorCode
      message
      success
    }
  }
`

export function useImportHansohCostCenterBudgetMutation() {
  return Urql.useMutation<
    ImportHansohCostCenterBudgetMutation,
    ImportHansohCostCenterBudgetMutationVariables
  >(ImportHansohCostCenterBudgetDocument)
}
export const CostCenterBudgetDocument = gql`
  query CostCenterBudget($pager: PagerInput, $query: CostCenterBudgetSearch) {
    result: searchCostCenterBudget(param: { pager: $pager, query: $query }) {
      items {
        id
        groupId
        groupName
        year
        lastMonthAmount
        lastMonthBalanceAmount
        currentMonthAmount
        currentMonthIncreaseAmount
        currentMonthUsedAmount
        nextMonthAmount
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useCostCenterBudgetQuery(
  options: Omit<Urql.UseQueryArgs<CostCenterBudgetQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CostCenterBudgetQuery>({
    query: CostCenterBudgetDocument,
    ...options
  })
}
export const CostCenterBudgetDetailDocument = gql`
  query CostCenterBudgetDetail($id: Int!) {
    result: queryCostCenterBudgetDetail(id: $id) {
      id
      companyId
      groupId
      groupName
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      year
      month
      end
    }
  }
`

export function useCostCenterBudgetDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<CostCenterBudgetDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CostCenterBudgetDetailQuery>({
    query: CostCenterBudgetDetailDocument,
    ...options
  })
}
export const AdjustCostCenterBudgetDocument = gql`
  mutation AdjustCostCenterBudget(
    $id: Int!
    $amount: String!
    $operatorRemark: String
  ) {
    result: adjustCostCenterBudget(
      id: $id
      amount: $amount
      operatorRemark: $operatorRemark
    ) {
      errorCode
      message
      success
    }
  }
`

export function useAdjustCostCenterBudgetMutation() {
  return Urql.useMutation<
    AdjustCostCenterBudgetMutation,
    AdjustCostCenterBudgetMutationVariables
  >(AdjustCostCenterBudgetDocument)
}
export const CostCenterBudgetUsedDetailDocument = gql`
  query CostCenterBudgetUsedDetail(
    $param: CostCenterBudgetUsedDetailSearchWithPager
  ) {
    result: queryCostCenterBudgetUsedDetail(param: $param) {
      items {
        formCategory
        consumptionCategoryCode
        targetName
        accountId
        accountName
        accountMobile
        planAmount
        actualAmount
        submitTime
        orderId
        claimAmount
        deductionAmount
        financialDeductionAmount
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useCostCenterBudgetUsedDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<CostCenterBudgetUsedDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CostCenterBudgetUsedDetailQuery>({
    query: CostCenterBudgetUsedDetailDocument,
    ...options
  })
}
export const CostCenterBudgetLogDocument = gql`
  query CostCenterBudgetLog(
    $pager: PagerInput
    $query: CostCenterBudgetLogSearch
  ) {
    result: queryCostCenterBudgetLog(param: { pager: $pager, query: $query }) {
      items {
        id
        budgetId
        groupId
        budgetAmountChanged
        budgetAmountBefore
        increaseAmount
        usedAmount
        balanceAmountChanged
        balanceAmountBefore
        remark
        operator
        operationTime
        operatorAccountInfo {
          cnName
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useCostCenterBudgetLogQuery(
  options: Omit<
    Urql.UseQueryArgs<CostCenterBudgetLogQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CostCenterBudgetLogQuery>({
    query: CostCenterBudgetLogDocument,
    ...options
  })
}
export const CostCenterBudgetByYearDocument = gql`
  query CostCenterBudgetByYear($param: CostCenterYearBudgetSearch) {
    result: searchCostCenterBudgetByYear(param: $param) {
      id
      companyId
      groupId
      groupName
      channel
      budgetAmount
      usedAmount
      balanceAmount
      deductionAmount
      increaseAmount
      initialBudget
      start
      year
      month
      end
    }
  }
`

export function useCostCenterBudgetByYearQuery(
  options: Omit<
    Urql.UseQueryArgs<CostCenterBudgetByYearQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CostCenterBudgetByYearQuery>({
    query: CostCenterBudgetByYearDocument,
    ...options
  })
}
export const UpadminCostCentersDocument = gql`
  query UpadminCostCenters($companyId: String, $name: String, $pid: String) {
    items: queryCostCenterByCompany(
      companyId: $companyId
      name: $name
      pid: $pid
    ) {
      id: groupId
      name
      pid
    }
  }
`

export function useUpadminCostCentersQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminCostCentersQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminCostCentersQuery>({
    query: UpadminCostCentersDocument,
    ...options
  })
}
export const CityListDocument = gql`
  query CityList {
    cities(level: "2") {
      name
      id
    }
  }
`

export function useCityListQuery(
  options: Omit<Urql.UseQueryArgs<CityListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CityListQuery>({ query: CityListDocument, ...options })
}
export const ProvinceListDocument = gql`
  query ProvinceList {
    cities(level: "1") {
      name
      id
    }
  }
`

export function useProvinceListQuery(
  options: Omit<Urql.UseQueryArgs<ProvinceListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ProvinceListQuery>({
    query: ProvinceListDocument,
    ...options
  })
}
export const QueryDocumentsDocument = gql`
  query queryDocuments(
    $isAdmin: Boolean
    $search: DocumentArchiveSearchParamsWithPager
  ) {
    documents: queryDocuments(isAdmin: $isAdmin, search: $search) {
      items {
        archive {
          id
          signedUrl
          tags {
            typeName
            id
          }
          archiveTags {
            id
            tag
          }
          creator {
            id: accountId
            name: cnName(encrypted: true)
          }
        }
        document {
          remark
          language
          title
          id
          author
          describe
          source
          createdTimestamp
          releaseTimestamp
          category {
            title
            id
          }
          productInfo {
            id
            productName
          }
          company {
            cnName
          }
          sphere
          enabled
          documentSphere {
            sphereCode
            sphereName
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useQueryDocumentsQuery(
  options: Omit<Urql.UseQueryArgs<QueryDocumentsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<QueryDocumentsQuery>({
    query: QueryDocumentsDocument,
    ...options
  })
}
export const AddDocumentDocument = gql`
  mutation addDocument($info: AddDocumentInput!) {
    result: addDocument(info: $info) {
      errorCode
      message
      success
    }
  }
`

export function useAddDocumentMutation() {
  return Urql.useMutation<AddDocumentMutation, AddDocumentMutationVariables>(
    AddDocumentDocument
  )
}
export const EditDocumentDocument = gql`
  mutation editDocument($info: AddDocumentInput!) {
    result: editDocument(info: $info) {
      errorCode
      message
      success
    }
  }
`

export function useEditDocumentMutation() {
  return Urql.useMutation<EditDocumentMutation, EditDocumentMutationVariables>(
    EditDocumentDocument
  )
}
export const QueryArchiveTagsDocument = gql`
  query queryArchiveTags($companyId: String) {
    tags: queryMedicalLiteratureTags(companyId: $companyId) {
      id
      refId
      tag
    }
  }
`

export function useQueryArchiveTagsQuery(
  options: Omit<Urql.UseQueryArgs<QueryArchiveTagsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<QueryArchiveTagsQuery>({
    query: QueryArchiveTagsDocument,
    ...options
  })
}
export const QueryDocumentCategoriesDocument = gql`
  query queryDocumentCategories($companyId: String) {
    categories: queryDocumentCategories(companyId: $companyId) {
      id
      title
      describe
      remark
      enabled
    }
  }
`

export function useQueryDocumentCategoriesQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryDocumentCategoriesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryDocumentCategoriesQuery>({
    query: QueryDocumentCategoriesDocument,
    ...options
  })
}
export const RemoveDocumentDocument = gql`
  mutation removeDocument($documentId: String!) {
    result: removeDocument(documentId: $documentId) {
      errorCode
      message
      success
    }
  }
`

export function useRemoveDocumentMutation() {
  return Urql.useMutation<
    RemoveDocumentMutation,
    RemoveDocumentMutationVariables
  >(RemoveDocumentDocument)
}
export const QueryDocumentSphereDocument = gql`
  query queryDocumentSphere($params: DocumentSphereSearchParams!) {
    spheres: queryDocumentSphere(params: $params) {
      id
      sphereName
      sphereCode
      describe
      enabled
      remark
    }
  }
`

export function useQueryDocumentSphereQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryDocumentSphereQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryDocumentSphereQuery>({
    query: QueryDocumentSphereDocument,
    ...options
  })
}
export const CreateCompanySphereDocument = gql`
  mutation createCompanySphere($sphere: DocumentSphereInfoInput!) {
    result: addDocumentSphere(sphere: $sphere) {
      errorCode
      message
      success
    }
  }
`

export function useCreateCompanySphereMutation() {
  return Urql.useMutation<
    CreateCompanySphereMutation,
    CreateCompanySphereMutationVariables
  >(CreateCompanySphereDocument)
}
export const HrMeetingListDocument = gql`
  query HRMeetingList(
    $isFinanceAdmin: Boolean
    $pageQuery: PageSearchStandardMeetingInput
  ) {
    meetings: hengruiImage_searchMeeting(
      isFinanceAdmin: $isFinanceAdmin
      pageQuery: $pageQuery
    ) {
      items {
        ...MeetingDetail
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
  ${MeetingDetailFragmentDoc}
`

export function useHrMeetingListQuery(
  options: Omit<Urql.UseQueryArgs<HrMeetingListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrMeetingListQuery>({
    query: HrMeetingListDocument,
    ...options
  })
}
export const HrDoctorListDocument = gql`
  query HRDoctorList($pager: PagerInput, $search: String) {
    doctors: hengruiImage_doctors(query: { search: $search, pager: $pager }) {
      items {
        accountId
        cnName
        status
        companyMobile
        defaultChannel
        hospital: custom1
        department {
          name
        }
        groupInfo: group(groupType: "title") {
          name
        }
        bankInfo {
          qkkBankName
          ssqBankName
          custom1
          certNo
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useHrDoctorListQuery(
  options: Omit<Urql.UseQueryArgs<HrDoctorListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrDoctorListQuery>({
    query: HrDoctorListDocument,
    ...options
  })
}
export const ChangeChannelDocument = gql`
  mutation ChangeChannel($channel: String!, $accountId: String!) {
    result: changeAccountDefaultChannel(
      channel: $channel
      accountId: $accountId
    )
  }
`

export function useChangeChannelMutation() {
  return Urql.useMutation<
    ChangeChannelMutation,
    ChangeChannelMutationVariables
  >(ChangeChannelDocument)
}
export const HrMeetingDetailDocument = gql`
  query HRMeetingDetail($meetingId: String) {
    detail: hengruiImage_meetingDetail(meetingId: $meetingId) {
      activityType: hengruiImage_activityType
      meetingType: hengruiImage_categoryName
      ...MeetingDetail
      schedulePics: hengruiImage_schedulePics
      signPics: hengruiImage_signPics
      checkReports: hengruiImage_checkReports
      costs: hengruiImage_costs {
        amount
        type
        note
      }
      conferencePlatformInfo: hengruiImage_academicConferencePlatformInfo
      meetingCategory: academicConference_meetingCategory
      meetingAuditRecord {
        taskId
        meetingId
        attenderId
        accountId
        verifyType
        verifyDimension
        status
        key
        value
        remark
        properties
      }
    }
  }
  ${MeetingDetailFragmentDoc}
`

export function useHrMeetingDetailQuery(
  options: Omit<Urql.UseQueryArgs<HrMeetingDetailQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrMeetingDetailQuery>({
    query: HrMeetingDetailDocument,
    ...options
  })
}
export const YhlMeetingDetailDocument = gql`
  query YHLMeetingDetail($meetingId: String) {
    detail: hengruiImage_meetingDetail(meetingId: $meetingId) {
      activityType: hengruiImage_activityType
      meetingType: hengruiImage_categoryName
      ...YHLMeetingDetail
      schedulePics: hengruiImage_schedulePics
      signPics: hengruiImage_signPics
      checkReports: hengruiImage_checkReports
      costs: hengruiImage_costs {
        amount
        type
        note
      }
      conferencePlatformInfo: hengruiImage_academicConferencePlatformInfo
      meetingCategory: academicConference_meetingCategory
      meetingAuditRecord {
        taskId
        meetingId
        attenderId
        accountId
        verifyType
        verifyDimension
        status
      }
    }
  }
  ${YhlMeetingDetailFragmentDoc}
`

export function useYhlMeetingDetailQuery(
  options: Omit<Urql.UseQueryArgs<YhlMeetingDetailQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<YhlMeetingDetailQuery>({
    query: YhlMeetingDetailDocument,
    ...options
  })
}
export const HrRegionListDocument = gql`
  query HRRegionList {
    regions: hengruiImage_regions {
      id: groupId
      name
    }
  }
`

export function useHrRegionListQuery(
  options: Omit<Urql.UseQueryArgs<HrRegionListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrRegionListQuery>({
    query: HrRegionListDocument,
    ...options
  })
}
export const HrMeetingBizStatusDocument = gql`
  query HRMeetingBizStatus {
    status: hengruiImage_statusMap {
      label
      value
    }
  }
`

export function useHrMeetingBizStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<HrMeetingBizStatusQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<HrMeetingBizStatusQuery>({
    query: HrMeetingBizStatusDocument,
    ...options
  })
}
export const HrProjectTypesDocument = gql`
  query HRProjectTypes {
    types: hengruiImage_meetingTypes
  }
`

export function useHrProjectTypesQuery(
  options: Omit<Urql.UseQueryArgs<HrProjectTypesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrProjectTypesQuery>({
    query: HrProjectTypesDocument,
    ...options
  })
}
export const HrAttenderContractDocument = gql`
  query HRAttenderContract($meetingId: String!, $attenderId: String!) {
    contract: hengruiImage_attenderContract(
      meetingId: $meetingId
      attenderId: $attenderId
    )
  }
`

export function useHrAttenderContractQuery(
  options: Omit<
    Urql.UseQueryArgs<HrAttenderContractQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<HrAttenderContractQuery>({
    query: HrAttenderContractDocument,
    ...options
  })
}
export const HrUploadAttenderInvoicesDocument = gql`
  mutation HRUploadAttenderInvoices(
    $meetingId: String!
    $attenderId: String!
    $urls: [String!]!
  ) {
    result: hengruiImage_uploadAttenderInvoices(
      meetingId: $meetingId
      attenderId: $attenderId
      urls: $urls
    )
  }
`

export function useHrUploadAttenderInvoicesMutation() {
  return Urql.useMutation<
    HrUploadAttenderInvoicesMutation,
    HrUploadAttenderInvoicesMutationVariables
  >(HrUploadAttenderInvoicesDocument)
}
export const HrUploadAttenderTaxReceiptsDocument = gql`
  mutation HRUploadAttenderTaxReceipts(
    $meetingId: String!
    $attenderId: String!
    $urls: [String!]!
  ) {
    result: hengruiImage_uploadAttenderTaxReceipts(
      meetingId: $meetingId
      attenderId: $attenderId
      urls: $urls
    )
  }
`

export function useHrUploadAttenderTaxReceiptsMutation() {
  return Urql.useMutation<
    HrUploadAttenderTaxReceiptsMutation,
    HrUploadAttenderTaxReceiptsMutationVariables
  >(HrUploadAttenderTaxReceiptsDocument)
}
export const HrUploadAttenderPaymentVoucherDocument = gql`
  mutation HRUploadAttenderPaymentVoucher(
    $meetingId: String!
    $attenderId: String!
    $urls: [String!]!
  ) {
    result: hengruiImage_uploadAttenderPaymentVoucher(
      meetingId: $meetingId
      attenderId: $attenderId
      urls: $urls
    )
  }
`

export function useHrUploadAttenderPaymentVoucherMutation() {
  return Urql.useMutation<
    HrUploadAttenderPaymentVoucherMutation,
    HrUploadAttenderPaymentVoucherMutationVariables
  >(HrUploadAttenderPaymentVoucherDocument)
}
export const MarkHrMeetingPaymentFinishedDocument = gql`
  mutation MarkHRMeetingPaymentFinished($meetingId: String!) {
    result: hengruiImage_markHRMeetingPaymentFinished(meetingId: $meetingId)
  }
`

export function useMarkHrMeetingPaymentFinishedMutation() {
  return Urql.useMutation<
    MarkHrMeetingPaymentFinishedMutation,
    MarkHrMeetingPaymentFinishedMutationVariables
  >(MarkHrMeetingPaymentFinishedDocument)
}
export const StartHrMeetingSettlementDocument = gql`
  mutation StartHRMeetingSettlement($meetingId: String!) {
    result: hengruiImage_startHRMeetingSettlement(meetingId: $meetingId)
  }
`

export function useStartHrMeetingSettlementMutation() {
  return Urql.useMutation<
    StartHrMeetingSettlementMutation,
    StartHrMeetingSettlementMutationVariables
  >(StartHrMeetingSettlementDocument)
}
export const HrGetSettlementZipUrlDocument = gql`
  mutation HRGetSettlementZipUrl($meetingId: String!) {
    url: hengruiImage_getSettlementZipUrl(meetingId: $meetingId)
  }
`

export function useHrGetSettlementZipUrlMutation() {
  return Urql.useMutation<
    HrGetSettlementZipUrlMutation,
    HrGetSettlementZipUrlMutationVariables
  >(HrGetSettlementZipUrlDocument)
}
export const HrQueryAttenderPaymentDetailDocument = gql`
  query HRQueryAttenderPaymentDetail(
    $paymentId: String
    $channel: HadesPaymentChannel
    $type: HadesReceiptType
  ) {
    detail: hengruiImage_attenderPaymentDetail(
      paymentId: $paymentId
      channel: $channel
      type: $type
    )
  }
`

export function useHrQueryAttenderPaymentDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<HrQueryAttenderPaymentDetailQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<HrQueryAttenderPaymentDetailQuery>({
    query: HrQueryAttenderPaymentDetailDocument,
    ...options
  })
}
export const BalanceRecordDocument = gql`
  mutation balanceRecord(
    $companyId: String
    $paymentTime: String!
    $type: String!
    $amount: Float!
    $regionId: String!
  ) {
    result: hengrui_logBalanceRecord(
      paymentTime: $paymentTime
      type: $type
      amount: $amount
      regionId: $regionId
      companyId: $companyId
    )
  }
`

export function useBalanceRecordMutation() {
  return Urql.useMutation<
    BalanceRecordMutation,
    BalanceRecordMutationVariables
  >(BalanceRecordDocument)
}
export const BalanceListDocument = gql`
  query BalanceList($companyId: String, $regionIds: [String!]) {
    lists: hengrui_queryBalancePoolList(
      companyId: $companyId
      regionIds: $regionIds
    ) {
      regionId
      totalPrepaid
      frozenFee
      usedFee
      balance
      regionInfo: region {
        groupId
        name
        pid
      }
      recordInfo: records(companyId: $companyId) {
        paymentTime
        amount
      }
    }
  }
`

export function useBalanceListQuery(
  options: Omit<Urql.UseQueryArgs<BalanceListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<BalanceListQuery>({
    query: BalanceListDocument,
    ...options
  })
}
export const HrMeetingExportDocument = gql`
  query HRMeetingExport(
    $isFinanceAdmin: Boolean
    $pageQuery: PageSearchStandardMeetingInput
  ) {
    meetings: hengruiImage_searchMeeting(
      isFinanceAdmin: $isFinanceAdmin
      pageQuery: $pageQuery
    ) {
      items {
        activityType: hengruiImage_activityType
        meetingType: hengruiImage_categoryName
        region: hengruiImage_meetingRegion {
          name
        }
        speakers: hengruiImage_spearkers {
          ...ExportMemberInfo
        }
        attenders: hengruiImage_attendDoctors {
          ...ExportMemberInfo
        }
        baseData: meetingBaseData {
          type
          activityType
          id
          budgetNumber
          budgetChannel
          name
          code
          owner {
            cnName
            staffId
            legalEntity: ou {
              cnName
            }
            companyMobile
          }
          startTime
          endTime
          approvalTime
        }
        statusText: hengruiImage_statusText
        costCenter: hengruiImage_costcenter {
          name
        }
        vendor: hengruiImage_vendor {
          companyId
          cnName
        }
        city: hengruiImage_city {
          name
        }
        conferencePlatformInfo: hengruiImage_academicConferencePlatformInfo
      }
    }
  }
  ${ExportMemberInfoFragmentDoc}
`

export function useHrMeetingExportQuery(
  options: Omit<Urql.UseQueryArgs<HrMeetingExportQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrMeetingExportQuery>({
    query: HrMeetingExportDocument,
    ...options
  })
}
export const BatchMarkBillStatusDocument = gql`
  mutation batchMarkBillStatus($data: [StandardMeetingBillMarkStatus!]!) {
    result: hengruiImage_batchMarkBillStatus(data: $data)
  }
`

export function useBatchMarkBillStatusMutation() {
  return Urql.useMutation<
    BatchMarkBillStatusMutation,
    BatchMarkBillStatusMutationVariables
  >(BatchMarkBillStatusDocument)
}
export const HrVendorsListDocument = gql`
  query HRVendorsList {
    vendors: hengruiImage_vendors {
      companyId
      cnName
    }
  }
`

export function useHrVendorsListQuery(
  options: Omit<Urql.UseQueryArgs<HrVendorsListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrVendorsListQuery>({
    query: HrVendorsListDocument,
    ...options
  })
}
export const HrApprovalListDocument = gql`
  query HRApprovalList(
    $approveStatus: String
    $pageQuery: PageQueryStandardMeetingInput
  ) {
    approval: upadminMyApprovals(
      approveStatus: $approveStatus
      pageQuery: $pageQuery
    ) {
      items {
        activityType: hengruiImage_activityType
        meetingType: hengruiImage_categoryName
        approverOwner: hengruiImage_ApproverOwner
        city: hengruiImage_city {
          name
        }
        attenders: hengruiImage_allAttenders {
          attenderFee
        }
        vendor: hengruiImage_vendor {
          cnName
        }
        meetingInfo: meetingBaseData {
          belongProduct {
            id
            productName
          }
          id
          needBudget
          budgetNumber
          budgetChannel
          type
          code
          peopleNum
          createTime
          startTime
          endTime
          applyTime
          submitTime
          approvalTime
          name
          owner {
            cnName
            companyMobile
            region: group(groupType: "region") {
              name
            }
            structure: group(groupType: "structure") {
              name
            }
          }
        }
        otherProperties {
          type
          key
          value
          diyType
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useHrApprovalListQuery(
  options: Omit<Urql.UseQueryArgs<HrApprovalListQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<HrApprovalListQuery>({
    query: HrApprovalListDocument,
    ...options
  })
}
export const HrMeetingForwardSignatureDocument = gql`
  mutation HRMeetingForwardSignature(
    $meetingId: String!
    $reason: String
    $approveUser: String
    $insertPosition: String
  ) {
    result: hengruiImage_forwardSignatureMeeting(
      meetingId: $meetingId
      reason: $reason
      approveUser: $approveUser
      insertPosition: $insertPosition
    )
  }
`

export function useHrMeetingForwardSignatureMutation() {
  return Urql.useMutation<
    HrMeetingForwardSignatureMutation,
    HrMeetingForwardSignatureMutationVariables
  >(HrMeetingForwardSignatureDocument)
}
export const HrMeetingRetreatDocument = gql`
  mutation HRMeetingRetreat(
    $meetingId: String!
    $reason: String
    $approveUser: String
  ) {
    result: hengruiImage_retreatMeeting(
      meetingId: $meetingId
      reason: $reason
      approveUser: $approveUser
    )
  }
`

export function useHrMeetingRetreatMutation() {
  return Urql.useMutation<
    HrMeetingRetreatMutation,
    HrMeetingRetreatMutationVariables
  >(HrMeetingRetreatDocument)
}
export const HrMeetingConfirmDocument = gql`
  mutation HRMeetingConfirm($meetingId: String!, $approveUser: String) {
    result: hengruiImage_confirmMeeting(
      meetingId: $meetingId
      approveUser: $approveUser
    )
  }
`

export function useHrMeetingConfirmMutation() {
  return Urql.useMutation<
    HrMeetingConfirmMutation,
    HrMeetingConfirmMutationVariables
  >(HrMeetingConfirmDocument)
}
export const ApprovalExportDocument = gql`
  query ApprovalExport(
    $approveStatus: String
    $pageQuery: PageQueryStandardMeetingInput
  ) {
    approval: upadminMyApprovals(
      approveStatus: $approveStatus
      pageQuery: $pageQuery
    ) {
      items {
        activityType: hengruiImage_activityType
        meetingType: hengruiImage_categoryName
        city: hengruiImage_city {
          name
        }
        attenders: hengruiImage_allAttenders {
          attenderFee
        }
        vendor: hengruiImage_vendor {
          cnName
        }
        meetingInfo: meetingBaseData {
          id
          belongProduct {
            id
            productName
          }
          needBudget
          budgetChannel
          budgetNumber
          type
          peopleNum
          createTime
          startTime
          endTime
          approvalTime
          code
          name
          owner {
            cnName
            companyMobile
            region: group(groupType: "region") {
              name
            }
            structure: group(groupType: "structure") {
              name
            }
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useApprovalExportQuery(
  options: Omit<Urql.UseQueryArgs<ApprovalExportQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ApprovalExportQuery>({
    query: ApprovalExportDocument,
    ...options
  })
}
export const AvailableChannelsDocument = gql`
  query AvailableChannels {
    result: availableChannels
  }
`

export function useAvailableChannelsQuery(
  options: Omit<
    Urql.UseQueryArgs<AvailableChannelsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AvailableChannelsQuery>({
    query: AvailableChannelsDocument,
    ...options
  })
}
export const CompanyUpProductsDocument = gql`
  query companyUpProducts($companyId: String, $currentUser: Boolean) {
    companyUpProducts(companyId: $companyId, currentUser: $currentUser) {
      id
      productCode
      productName
    }
  }
`

export function useCompanyUpProductsQuery(
  options: Omit<
    Urql.UseQueryArgs<CompanyUpProductsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CompanyUpProductsQuery>({
    query: CompanyUpProductsDocument,
    ...options
  })
}
export const RemoveDoctorsDocument = gql`
  mutation removeDoctors(
    $attenderId: String!
    $meetingId: String!
    $attenderType: StandardMeetingTypeAttenderType
  ) {
    result: hengruiImage_removeDoctors(
      attenderId: $attenderId
      meetingId: $meetingId
      attenderType: $attenderType
    )
  }
`

export function useRemoveDoctorsMutation() {
  return Urql.useMutation<
    RemoveDoctorsMutation,
    RemoveDoctorsMutationVariables
  >(RemoveDoctorsDocument)
}
export const UpadminQueryDoctorTitlesDocument = gql`
  query UpadminQueryDoctorTitles($companyId: String) {
    upadminQueryDoctorTitles(companyId: $companyId) {
      name
      groupId
    }
  }
`

export function useUpadminQueryDoctorTitlesQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminQueryDoctorTitlesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminQueryDoctorTitlesQuery>({
    query: UpadminQueryDoctorTitlesDocument,
    ...options
  })
}
export const UpdateMeetingCategoryTitleBlkListDocument = gql`
  mutation UpdateMeetingCategoryTitleBlkList(
    $categoryId: String!
    $titleList: [String!]!
  ) {
    result: updateMeetingCategoryTitleBlkList(
      categoryId: $categoryId
      titleList: $titleList
    ) {
      success
      message
    }
  }
`

export function useUpdateMeetingCategoryTitleBlkListMutation() {
  return Urql.useMutation<
    UpdateMeetingCategoryTitleBlkListMutation,
    UpdateMeetingCategoryTitleBlkListMutationVariables
  >(UpdateMeetingCategoryTitleBlkListDocument)
}
export const UpdateMeetingCategoryPropertiesDocument = gql`
  mutation UpdateMeetingCategoryProperties(
    $categoryId: String!
    $sort: Int
    $properties: String
  ) {
    result: updateMeetingCategoryProperties(
      categoryId: $categoryId
      sort: $sort
      properties: $properties
    ) {
      success
      message
    }
  }
`

export function useUpdateMeetingCategoryPropertiesMutation() {
  return Urql.useMutation<
    UpdateMeetingCategoryPropertiesMutation,
    UpdateMeetingCategoryPropertiesMutationVariables
  >(UpdateMeetingCategoryPropertiesDocument)
}
export const VerifyDoctorBankInfoDocument = gql`
  mutation verifyDoctorBankInfo(
    $name: String!
    $idCardNo: String!
    $mobile: String
    $bankCardNo: String
  ) {
    result: hengruiImage_verifyDoctorBankInfo(
      name: $name
      idCardNo: $idCardNo
      mobile: $mobile
      bankCardNo: $bankCardNo
    )
  }
`

export function useVerifyDoctorBankInfoMutation() {
  return Urql.useMutation<
    VerifyDoctorBankInfoMutation,
    VerifyDoctorBankInfoMutationVariables
  >(VerifyDoctorBankInfoDocument)
}
export const VerifyDoctorCardInfoDocument = gql`
  mutation verifyDoctorCardInfo(
    $idCardNo: String!
    $name: String!
    $mobile: String!
  ) {
    result: hengruiImage_verifyDoctorCardInfo(
      idCardNo: $idCardNo
      name: $name
      mobile: $mobile
    ) {
      pass
      resultMsg
    }
  }
`

export function useVerifyDoctorCardInfoMutation() {
  return Urql.useMutation<
    VerifyDoctorCardInfoMutation,
    VerifyDoctorCardInfoMutationVariables
  >(VerifyDoctorCardInfoDocument)
}
export const CheckDoctorIdCardImagesDocument = gql`
  mutation checkDoctorIdCardImages(
    $name: String!
    $doctorIdCardImages: [String!]!
    $channel: String!
  ) {
    result: hengruiImage_checkDoctorIdCardImages(
      name: $name
      doctorIdCardImages: $doctorIdCardImages
      channel: $channel
    )
  }
`

export function useCheckDoctorIdCardImagesMutation() {
  return Urql.useMutation<
    CheckDoctorIdCardImagesMutation,
    CheckDoctorIdCardImagesMutationVariables
  >(CheckDoctorIdCardImagesDocument)
}
export const QueryCompanyRegionsDocument = gql`
  query queryCompanyRegions($companyId: String, $type: String, $level: String) {
    regions: queryCompanyRegions(
      companyId: $companyId
      type: $type
      level: $level
    ) {
      groupId
      name
      pid
      level
      status
      provinceId: custom4
      province {
        id
        name
      }
    }
  }
`

export function useQueryCompanyRegionsQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryCompanyRegionsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryCompanyRegionsQuery>({
    query: QueryCompanyRegionsDocument,
    ...options
  })
}
export const UpdateCompanyGroupDocument = gql`
  mutation updateCompanyGroup($group: AccountGroupInfoInput!) {
    result: updateCompanyGroup(group: $group) {
      errorCode
      message
      success
    }
  }
`

export function useUpdateCompanyGroupMutation() {
  return Urql.useMutation<
    UpdateCompanyGroupMutation,
    UpdateCompanyGroupMutationVariables
  >(UpdateCompanyGroupDocument)
}
export const QueryCompanyAccountsByKeywordDocument = gql`
  query queryCompanyAccountsByKeyword($companyId: String!, $keyword: String) {
    users: queryCompanyAccountsByKeyword(
      companyId: $companyId
      keyword: $keyword
    ) {
      accountId
      cnName
      companyMobile
    }
  }
`

export function useQueryCompanyAccountsByKeywordQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryCompanyAccountsByKeywordQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryCompanyAccountsByKeywordQuery>({
    query: QueryCompanyAccountsByKeywordDocument,
    ...options
  })
}
export const SearchMeetingUserAuthorityDocument = gql`
  query searchMeetingUserAuthority(
    $pageQuery: PageQueryMeetingUserAuthorityInput!
  ) {
    result: searchMeetingUserAuthority(pageQuery: $pageQuery) {
      items {
        id
        accountId
        companyId
        name
        phone
        activityCode
        regionId
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useSearchMeetingUserAuthorityQuery(
  options: Omit<
    Urql.UseQueryArgs<SearchMeetingUserAuthorityQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SearchMeetingUserAuthorityQuery>({
    query: SearchMeetingUserAuthorityDocument,
    ...options
  })
}
export const AddMeetingUserAuthorityDocument = gql`
  mutation addMeetingUserAuthority($auth: MeetingUserAuthorityInfo!) {
    result: addMeetingUserAuthority(userAuthorityInfo: $auth) {
      errorCode
      message
      success
    }
  }
`

export function useAddMeetingUserAuthorityMutation() {
  return Urql.useMutation<
    AddMeetingUserAuthorityMutation,
    AddMeetingUserAuthorityMutationVariables
  >(AddMeetingUserAuthorityDocument)
}
export const EditMeetingUserAuthorityDocument = gql`
  mutation editMeetingUserAuthority($auth: MeetingUserAuthorityInfo!) {
    result: editMeetingUserAuthority(userAuthorityInfo: $auth) {
      errorCode
      message
      success
    }
  }
`

export function useEditMeetingUserAuthorityMutation() {
  return Urql.useMutation<
    EditMeetingUserAuthorityMutation,
    EditMeetingUserAuthorityMutationVariables
  >(EditMeetingUserAuthorityDocument)
}
export const RemoveMeetingUserAuthorityDocument = gql`
  mutation removeMeetingUserAuthority($id: String!) {
    result: removeMeetingUserAuthority(id: $id) {
      errorCode
      message
      success
    }
  }
`

export function useRemoveMeetingUserAuthorityMutation() {
  return Urql.useMutation<
    RemoveMeetingUserAuthorityMutation,
    RemoveMeetingUserAuthorityMutationVariables
  >(RemoveMeetingUserAuthorityDocument)
}
export const ApproveUserDocument = gql`
  query ApproveUser {
    result: hengruiImage_approveUser
  }
`

export function useApproveUserQuery(
  options: Omit<Urql.UseQueryArgs<ApproveUserQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ApproveUserQuery>({
    query: ApproveUserDocument,
    ...options
  })
}
export const SaveMeetingVerificationInfoDocument = gql`
  mutation SaveMeetingVerificationInfo(
    $meetingId: String!
    $meetingType: String!
    $meetingProperties: String
    $attenderProperties: String
    $approver: String
  ) {
    result: hengruiImage_saveMeetingVerificationInfo(
      meetingId: $meetingId
      meetingType: $meetingType
      meetingProperties: $meetingProperties
      attenderProperties: $attenderProperties
      approver: $approver
    )
  }
`

export function useSaveMeetingVerificationInfoMutation() {
  return Urql.useMutation<
    SaveMeetingVerificationInfoMutation,
    SaveMeetingVerificationInfoMutationVariables
  >(SaveMeetingVerificationInfoDocument)
}
export const SyncDoctorVerifyResultDocument = gql`
  mutation syncDoctorVerifyResult(
    $accountId: String!
    $verifyResult: String
    $verifyImages: String
  ) {
    result: syncDoctorVerifyResult(
      accountId: $accountId
      verifyResult: $verifyResult
      verifyImages: $verifyImages
    ) {
      errorCode
      message
      success
    }
  }
`

export function useSyncDoctorVerifyResultMutation() {
  return Urql.useMutation<
    SyncDoctorVerifyResultMutation,
    SyncDoctorVerifyResultMutationVariables
  >(SyncDoctorVerifyResultDocument)
}
export const DoctorTitleRuleConfigDocument = gql`
  query doctorTitleRuleConfig($searchInput: SearchDoctorTitleRuleConfigInput!) {
    result: doctorTitleRuleConfig(searchInput: $searchInput) {
      id
      companyId
      categoryCode
      titleGrade
      role
      durationStart
      durationEnd
      pointLimit
      remark
    }
  }
`

export function useDoctorTitleRuleConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<DoctorTitleRuleConfigQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<DoctorTitleRuleConfigQuery>({
    query: DoctorTitleRuleConfigDocument,
    ...options
  })
}
export const UpadminDistributeOrderDocument = gql`
  mutation upadminDistributeOrder($type: DistributeOrderType!) {
    result: upadminDistributeOrder(type: $type)
  }
`

export function useUpadminDistributeOrderMutation() {
  return Urql.useMutation<
    UpadminDistributeOrderMutation,
    UpadminDistributeOrderMutationVariables
  >(UpadminDistributeOrderDocument)
}
export const MeetingVerificationInfoDocument = gql`
  query meetingVerificationInfo(
    $id: String
    $meetingId: String
    $meetingType: String
  ) {
    result: hengruiImage_searchMeetingVerificationInfo(
      id: $id
      meetingId: $meetingId
      meetingType: $meetingType
    ) {
      id
      meetingId
      meetingType
      meetingProperties
      attenderProperties
    }
  }
`

export function useMeetingVerificationInfoQuery(
  options: Omit<
    Urql.UseQueryArgs<MeetingVerificationInfoQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<MeetingVerificationInfoQuery>({
    query: MeetingVerificationInfoDocument,
    ...options
  })
}
export const UpadminCompaniesDocument = gql`
  query UpadminCompanies {
    companies: upadminCompanies {
      id: companyId
      name
      cnName
      ownerId
      status
    }
  }
`

export function useUpadminCompaniesQuery(
  options: Omit<Urql.UseQueryArgs<UpadminCompaniesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<UpadminCompaniesQuery>({
    query: UpadminCompaniesDocument,
    ...options
  })
}
export const UpadminDoctorCompaniesDocument = gql`
  query UpadminDoctorCompanies {
    companies: upadminDoctorCompanies {
      id: companyId
      name
      cnName
      ownerId
      status
    }
  }
`

export function useUpadminDoctorCompaniesQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminDoctorCompaniesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminDoctorCompaniesQuery>({
    query: UpadminDoctorCompaniesDocument,
    ...options
  })
}
export const UpadminRegionsDocument = gql`
  query UpadminRegions($companyId: String!) {
    regions: upadminRegions(companyId: $companyId) {
      id: groupId
      name
      pid
    }
  }
`

export function useUpadminRegionsQuery(
  options: Omit<Urql.UseQueryArgs<UpadminRegionsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<UpadminRegionsQuery>({
    query: UpadminRegionsDocument,
    ...options
  })
}
export const BankCardInfoDocument = gql`
  query BankCardInfo($bankCardNo: String!) {
    result: queryBankCardInfo(bankCardNo: $bankCardNo) {
      cardBin
      cardDesc
      cardName
      cardType
      bankName
      bankId
    }
  }
`

export function useBankCardInfoQuery(
  options: Omit<Urql.UseQueryArgs<BankCardInfoQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<BankCardInfoQuery>({
    query: BankCardInfoDocument,
    ...options
  })
}
export const FetchCnapsCodeDocument = gql`
  query FetchCNAPSCode($bankBranchName: String, $bankName: String) {
    result: upadminFetchCNAPSCode(
      bankBranchName: $bankBranchName
      bankName: $bankName
    ) {
      bankName
      bankCode
    }
  }
`

export function useFetchCnapsCodeQuery(
  options: Omit<Urql.UseQueryArgs<FetchCnapsCodeQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<FetchCnapsCodeQuery>({
    query: FetchCnapsCodeDocument,
    ...options
  })
}
export const DepartmentsDocument = gql`
  query Departments($companyId: String, $query: MemberQueryInput) {
    result: hengruiImage_departments(companyId: $companyId, query: $query) {
      items {
        groupId
        name
        pid
      }
    }
  }
`

export function useDepartmentsQuery(
  options: Omit<Urql.UseQueryArgs<DepartmentsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<DepartmentsQuery>({
    query: DepartmentsDocument,
    ...options
  })
}
export const DoctorTitlesDocument = gql`
  query DoctorTitles($companyId: String, $query: MemberQueryInput) {
    result: hengruiImage_titles(companyId: $companyId, query: $query) {
      items {
        groupId
        name
        pid
      }
    }
  }
`

export function useDoctorTitlesQuery(
  options: Omit<Urql.UseQueryArgs<DoctorTitlesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<DoctorTitlesQuery>({
    query: DoctorTitlesDocument,
    ...options
  })
}
export const UpadminEditOrAddDoctorDocument = gql`
  mutation UpadminEditOrAddDoctor($doctor: EditDoctorInfo!) {
    result: upadminEditOrAddDoctor(doctor: $doctor) {
      message
      success
    }
  }
`

export function useUpadminEditOrAddDoctorMutation() {
  return Urql.useMutation<
    UpadminEditOrAddDoctorMutation,
    UpadminEditOrAddDoctorMutationVariables
  >(UpadminEditOrAddDoctorDocument)
}
export const UpadminSearchAttendersDocument = gql`
  query UpadminSearchAttenders(
    $pager: PagerInput
    $query: QueryMeetingAttenders
  ) {
    orders: upadminSearchAttenders(query: { query: $query, pager: $pager }) {
      items {
        id
        settlementRecord
        attenderId
        attenderName
        qkkStudioOpenStatus
        attenderFee
        rate
        serviceFee
        totalFee: actualFee
        meetingId
        attenderData
        attenderStatus
        attenderInfo {
          custom1
        }
        billStatus
        billTime
        attenderSettlementTime
        meetingInfo: meetingAttending {
          speakers: hengruiImage_spearkers {
            id
            attenderId
          }
          activityType: hengruiImage_activityType
          meetingType: hengruiImage_categoryName
          hospital: hengruiImage_hospital
          status: hengruiImage_status
          statusExtra: hengruiImage_statusExtra
          statusText: hengruiImage_statusText
          metingRegion: hengruiImage_meetingRegion {
            name
          }
          costCenter: hengruiImage_costcenter {
            name
          }
          vendor: hengruiImage_vendor {
            cnName
          }
          base: meetingBaseData {
            type
            name
            owner {
              cnName
              company {
                cnName
              }
            }
            startTime
            endTime
            createTime
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useUpadminSearchAttendersQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminSearchAttendersQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminSearchAttendersQuery>({
    query: UpadminSearchAttendersDocument,
    ...options
  })
}
export const UpadminFinanceApproveDocument = gql`
  mutation upadminFinanceApprove(
    $meetingId: String!
    $reason: String
    $result: FinanceApproveResult!
  ) {
    result: upadminFinanceApprove(
      meetingId: $meetingId
      reason: $reason
      result: $result
    )
  }
`

export function useUpadminFinanceApproveMutation() {
  return Urql.useMutation<
    UpadminFinanceApproveMutation,
    UpadminFinanceApproveMutationVariables
  >(UpadminFinanceApproveDocument)
}
export const UpadminDoctorListDocument = gql`
  query upadminDoctorList($pager: PagerInput, $query: QueryMeetingAttenders) {
    doctors: upadminSearchDoctors(query: { query: $query, pager: $pager }) {
      items {
        accountId
        cnName
        status
        disableReason
        companyMobile
        companyId
        defaultChannel
        hospital: custom1
        verifyResult
        verifyImages
        companyEmail
        staffId
        structure: group(groupType: "structure") {
          name
          groupId
        }
        costcenter: group(groupType: "costcenter") {
          name
          groupId
        }
        department: group(groupType: "department") {
          name
          groupId
        }
        groupInfo: group(groupType: "title") {
          name
          groupId
        }
        hospitalInfo: group(groupType: "hospital") {
          name
          groupId
        }
        bankInfo {
          qkkBankName
          ssqBankName
          custom1
          rawCertNo
          cnFirstPinyin
        }
        idCardInfo {
          rawCertNo
          certImages
        }
        userCertificationImages {
          certImages
          cnName
        }
        userTitleCertificationImages {
          certImages
        }
        userComplexMaterialCertImages {
          certImages
        }
        company {
          cnName
        }
        qkkStudioOpenStatus
        openId: docMicroAppBinded
        budgetWhite {
          id
          accountId
          companyId
          name
          phone
          enabled
          remark
        }
        doctorMeetingWhite {
          id
          accountId
          companyId
          name
          phone
          enabled
          remark
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useUpadminDoctorListQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminDoctorListQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminDoctorListQuery>({
    query: UpadminDoctorListDocument,
    ...options
  })
}
export const UpadminFinancePayToHzBankDocument = gql`
  mutation upadminFinancePayToHZBank(
    $meetingId: String!
    $attenderId: String!
  ) {
    result: upadminFinancePayToHZBank(
      meetingId: $meetingId
      attenderId: $attenderId
    ) {
      errorCode
      message
      success
    }
  }
`

export function useUpadminFinancePayToHzBankMutation() {
  return Urql.useMutation<
    UpadminFinancePayToHzBankMutation,
    UpadminFinancePayToHzBankMutationVariables
  >(UpadminFinancePayToHzBankDocument)
}
export const UpadminFinancePageQuerySettlementsDocument = gql`
  query upadminFinancePageQuerySettlements(
    $pager: PagerInput
    $query: MeetingSettlementSearch
  ) {
    settlements: upadminFinancePageQuerySettlements(
      request: { query: $query, pager: $pager }
    ) {
      items {
        settlementId
        state
        settlementStatus
        attenders {
          meetingId
          attenderId
          attenderFee
          rate
          serviceFee
          totalFee: actualFee
          attenderData
          attenderInfo {
            custom1
            cnName
            companyMobile
          }
          meetingInfo: meetingAttending {
            metingRegion: hengruiImage_meetingRegion {
              name
            }
            base: meetingBaseData {
              name
              owner {
                company {
                  cnName
                }
              }
            }
          }
          settlementRecord
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useUpadminFinancePageQuerySettlementsQuery(
  options: Omit<
    Urql.UseQueryArgs<UpadminFinancePageQuerySettlementsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<UpadminFinancePageQuerySettlementsQuery>({
    query: UpadminFinancePageQuerySettlementsDocument,
    ...options
  })
}
export const ActivityTypesByMeetingTypeDocument = gql`
  query ActivityTypesByMeetingType($meetingType: String, $companyId: String) {
    result: appMeetingActivityTypes(
      meetingType: $meetingType
      companyId: $companyId
    ) {
      id
      config {
        title
        code
      }
    }
  }
`

export function useActivityTypesByMeetingTypeQuery(
  options: Omit<
    Urql.UseQueryArgs<ActivityTypesByMeetingTypeQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<ActivityTypesByMeetingTypeQuery>({
    query: ActivityTypesByMeetingTypeDocument,
    ...options
  })
}
export const InterProjectTypeDocument = gql`
  query InterProjectType {
    result: hengruiImage_interProjectType
  }
`

export function useInterProjectTypeQuery(
  options: Omit<Urql.UseQueryArgs<InterProjectTypeQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<InterProjectTypeQuery>({
    query: InterProjectTypeDocument,
    ...options
  })
}
export const FetchAllBaseMeetingTypesDocument = gql`
  query FetchAllBaseMeetingTypes {
    result: fetchAllBaseMeetingTypes {
      id
      code
      enable
      sort
      icon
      title
      desc
      remark
    }
  }
`

export function useFetchAllBaseMeetingTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<FetchAllBaseMeetingTypesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<FetchAllBaseMeetingTypesQuery>({
    query: FetchAllBaseMeetingTypesDocument,
    ...options
  })
}
export const FetchAllBaseActivityTypesDocument = gql`
  query FetchAllBaseActivityTypes {
    result: fetchAllBaseMeetingActivities {
      id
      code
      enable
      sort
      icon
      title
      desc
      remark
      properties
    }
  }
`

export function useFetchAllBaseActivityTypesQuery(
  options: Omit<
    Urql.UseQueryArgs<FetchAllBaseActivityTypesQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<FetchAllBaseActivityTypesQuery>({
    query: FetchAllBaseActivityTypesDocument,
    ...options
  })
}
export const MeetingTypesByCompanyDocument = gql`
  query MeetingTypesByCompany($companyId: String) {
    result: meetingCategories(companyId: $companyId) {
      id
      enable
      autoComplete
      config {
        id
        code
        icon
        title
      }
      companyId
      titleFilter
      activitiyTypes {
        id
        enable
        config {
          id
          code
          icon
          title
        }
        instanceMax
        instanceMin
        templateCode
        templateInfo {
          templateCode
          templateName
        }
        productType
        start
        end
        sphere
        activitySphere {
          sphereCode
          sphereName
        }
      }
      titleFilterText
      sort
      activityProperties
    }
  }
`

export function useMeetingTypesByCompanyQuery(
  options: Omit<
    Urql.UseQueryArgs<MeetingTypesByCompanyQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<MeetingTypesByCompanyQuery>({
    query: MeetingTypesByCompanyDocument,
    ...options
  })
}
export const UpdateMeetingCategoryEnabledDocument = gql`
  mutation updateMeetingCategoryEnabled(
    $enabled: Boolean!
    $categoryId: String!
  ) {
    result: updateMeetingCategoryEnabled(
      enabled: $enabled
      categoryId: $categoryId
    ) {
      errorCode
      message
      success
    }
  }
`

export function useUpdateMeetingCategoryEnabledMutation() {
  return Urql.useMutation<
    UpdateMeetingCategoryEnabledMutation,
    UpdateMeetingCategoryEnabledMutationVariables
  >(UpdateMeetingCategoryEnabledDocument)
}
export const ToggleMeetingCategoryAutoCompleteDocument = gql`
  mutation toggleMeetingCategoryAutoComplete($categoryId: String!) {
    result: toggleMeetingCategoryAutoComplete(categoryId: $categoryId) {
      errorCode
      message
      success
    }
  }
`

export function useToggleMeetingCategoryAutoCompleteMutation() {
  return Urql.useMutation<
    ToggleMeetingCategoryAutoCompleteMutation,
    ToggleMeetingCategoryAutoCompleteMutationVariables
  >(ToggleMeetingCategoryAutoCompleteDocument)
}
export const CreateOrUpdateBaseMeetingTypeDocument = gql`
  mutation createOrUpdateBaseMeetingType(
    $config: MeetingConfigAddOrUpdateInput!
  ) {
    result: createOrupdateBaseMeetingCategory(config: $config) {
      errorCode
      message
      success
    }
  }
`

export function useCreateOrUpdateBaseMeetingTypeMutation() {
  return Urql.useMutation<
    CreateOrUpdateBaseMeetingTypeMutation,
    CreateOrUpdateBaseMeetingTypeMutationVariables
  >(CreateOrUpdateBaseMeetingTypeDocument)
}
export const CreateOrUpdateBaseActivityTypeDocument = gql`
  mutation createOrUpdateBaseActivityType(
    $config: MeetingConfigAddOrUpdateInput!
  ) {
    result: createOrupdateBaseMeetingActivity(config: $config) {
      errorCode
      message
      success
    }
  }
`

export function useCreateOrUpdateBaseActivityTypeMutation() {
  return Urql.useMutation<
    CreateOrUpdateBaseActivityTypeMutation,
    CreateOrUpdateBaseActivityTypeMutationVariables
  >(CreateOrUpdateBaseActivityTypeDocument)
}
export const UpdateActivityEnabledDocument = gql`
  mutation updateActivityEnabled($enabled: Boolean!, $activityId: String!) {
    result: updateMeetingActivityEnabled(
      enabled: $enabled
      activityId: $activityId
    ) {
      errorCode
      message
      success
    }
  }
`

export function useUpdateActivityEnabledMutation() {
  return Urql.useMutation<
    UpdateActivityEnabledMutation,
    UpdateActivityEnabledMutationVariables
  >(UpdateActivityEnabledDocument)
}
export const BindActivityToMeetingCategoryDocument = gql`
  mutation bindActivityToMeetingCategory(
    $input: MeetingACtivityCreateOrUpdateInput!
  ) {
    result: createOrUpdateMeetingActivity(input: $input) {
      errorCode
      message
      success
    }
  }
`

export function useBindActivityToMeetingCategoryMutation() {
  return Urql.useMutation<
    BindActivityToMeetingCategoryMutation,
    BindActivityToMeetingCategoryMutationVariables
  >(BindActivityToMeetingCategoryDocument)
}
export const CompanyApprovalProcessIdDocument = gql`
  query companyApprovalProcessId($companyId: String!) {
    result: companyApprovelProcess(companyId: $companyId) {
      id
      name
    }
  }
`

export function useCompanyApprovalProcessIdQuery(
  options: Omit<
    Urql.UseQueryArgs<CompanyApprovalProcessIdQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<CompanyApprovalProcessIdQuery>({
    query: CompanyApprovalProcessIdDocument,
    ...options
  })
}
export const QueryActivityTemplateDocument = gql`
  query queryActivityTemplate($companyId: String, $documentCategory: String) {
    result: queryMeetingActivityTemplates(
      companyId: $companyId
      documentCategory: $documentCategory
    ) {
      id
      unitPrice
      templateCode
      documentCategoryInfo {
        title
      }
      templateName
      enable: isEnable
    }
  }
`

export function useQueryActivityTemplateQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryActivityTemplateQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryActivityTemplateQuery>({
    query: QueryActivityTemplateDocument,
    ...options
  })
}
export const CreateMeetingActivityTemplateDocument = gql`
  mutation createMeetingActivityTemplate($template: TemplateInput!) {
    result: createMeetingActivityTemplate(template: $template) {
      errorCode
      message
      success
    }
  }
`

export function useCreateMeetingActivityTemplateMutation() {
  return Urql.useMutation<
    CreateMeetingActivityTemplateMutation,
    CreateMeetingActivityTemplateMutationVariables
  >(CreateMeetingActivityTemplateDocument)
}
export const CompanyProductsDocument = gql`
  query companyProducts($companyId: String, $currentUser: Boolean) {
    result: companyUpProducts(
      companyId: $companyId
      currentUser: $currentUser
    ) {
      id
      productName
      productCode
      productCategory
      companyId
    }
  }
`

export function useCompanyProductsQuery(
  options: Omit<Urql.UseQueryArgs<CompanyProductsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CompanyProductsQuery>({
    query: CompanyProductsDocument,
    ...options
  })
}
export const AttenderTasksDocument = gql`
  query attenderTasks($attenderId: [String!], $pager: PagerInput) {
    result: queryAttenderTasks(
      search: { query: { attenderId: $attenderId }, pager: $pager }
    ) {
      items {
        id
        status
        statusText
        rejectMsg
        taskAttribute
        taskCategory
        taskName
        taskPrice
        templateCode
        templateInfo {
          unitPrice
          templateAttribute
          templateName
          templateCategory
        }
        documentInfo {
          document {
            id
            title
          }
          archive {
            id
            signedUrl
          }
          properties {
            id
            value
            key
          }
        }
        meetingInfo {
          meetingType: hengruiImage_categoryName
          meetingBaseData {
            type
            belongProduct {
              productName
            }
            startTime
            endTime
          }
          documentComposeInfo: hengruiImage_documentComposeInfo
        }
        accountId
        accountInfo {
          cnName
        }
        attenderId
        attenderInfo {
          attenderFee
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useAttenderTasksQuery(
  options: Omit<Urql.UseQueryArgs<AttenderTasksQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<AttenderTasksQuery>({
    query: AttenderTasksDocument,
    ...options
  })
}
export const TaskDocumentReviewContentDocument = gql`
  query taskDocumentReviewContent($taskId: String!) {
    result: queryTaskDocumentReviewContent(taskId: $taskId) {
      id
      value
      key
    }
  }
`

export function useTaskDocumentReviewContentQuery(
  options: Omit<
    Urql.UseQueryArgs<TaskDocumentReviewContentQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<TaskDocumentReviewContentQuery>({
    query: TaskDocumentReviewContentDocument,
    ...options
  })
}
export const PreImportDoctorsDocument = gql`
  mutation PreImportDoctors(
    $importId: String
    $companyId: String!
    $chanel: String!
    $data: [HRDoctorExcelImportInput!]!
  ) {
    result: upadminPreImportDoctors(
      importId: $importId
      companyId: $companyId
      chanel: $chanel
      data: $data
    )
  }
`

export function usePreImportDoctorsMutation() {
  return Urql.useMutation<
    PreImportDoctorsMutation,
    PreImportDoctorsMutationVariables
  >(PreImportDoctorsDocument)
}
export const ImportDoctorsDocument = gql`
  mutation ImportDoctors(
    $importId: String
    $companyId: String!
    $chanel: String!
  ) {
    result: upadminImportDoctors(
      importId: $importId
      companyId: $companyId
      chanel: $chanel
    ) {
      errorCode
      message
      success
    }
  }
`

export function useImportDoctorsMutation() {
  return Urql.useMutation<
    ImportDoctorsMutation,
    ImportDoctorsMutationVariables
  >(ImportDoctorsDocument)
}
export const UnBindAccountWechatRelationDocument = gql`
  mutation UnBindAccountWechatRelation($accountId: String!, $appName: String!) {
    result: unBindAccountWechatRelation(
      accountId: $accountId
      appName: $appName
    )
  }
`

export function useUnBindAccountWechatRelationMutation() {
  return Urql.useMutation<
    UnBindAccountWechatRelationMutation,
    UnBindAccountWechatRelationMutationVariables
  >(UnBindAccountWechatRelationDocument)
}
export const MeetingApprovalFlowsDocument = gql`
  query meetingApprovalFlows($meetingId: String!) {
    result: queryMeetingApprovalFlows(meetingId: $meetingId) {
      instanceId
      status
      reason
      tasks {
        createTime
        status
        opinion
        approverInfo {
          cnName
          accountId
          roleAuthoritiesIncludeQcxs
        }
        logDatas {
          createTime
          logType
          assginer
          assginerInfo {
            cnName
          }
          reason
        }
      }
    }
  }
`

export function useMeetingApprovalFlowsQuery(
  options: Omit<
    Urql.UseQueryArgs<MeetingApprovalFlowsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<MeetingApprovalFlowsQuery>({
    query: MeetingApprovalFlowsDocument,
    ...options
  })
}
export const UpdateAccountStatusDocument = gql`
  mutation updateAccountStatus($accountId: String!, $status: String!) {
    result: updateAccountStatus(accountId: $accountId, status: $status)
  }
`

export function useUpdateAccountStatusMutation() {
  return Urql.useMutation<
    UpdateAccountStatusMutation,
    UpdateAccountStatusMutationVariables
  >(UpdateAccountStatusDocument)
}
export const DeleteOrderByAccountIdDocument = gql`
  mutation deleteOrderByAccountId($accountId: String!, $disableReason: String) {
    result: deleteOrderByAccountId(
      accountId: $accountId
      disableReason: $disableReason
    )
  }
`

export function useDeleteOrderByAccountIdMutation() {
  return Urql.useMutation<
    DeleteOrderByAccountIdMutation,
    DeleteOrderByAccountIdMutationVariables
  >(DeleteOrderByAccountIdDocument)
}
export const ResumeOrderByAccountIdDocument = gql`
  mutation resumeOrderByAccountId($accountId: String!) {
    result: resumeOrderByAccountId(accountId: $accountId)
  }
`

export function useResumeOrderByAccountIdMutation() {
  return Urql.useMutation<
    ResumeOrderByAccountIdMutation,
    ResumeOrderByAccountIdMutationVariables
  >(ResumeOrderByAccountIdDocument)
}
export const OperateOrderDocument = gql`
  mutation operateOrder($input: OperateOrderInput!) {
    result: operateOrder(input: $input)
  }
`

export function useOperateOrderMutation() {
  return Urql.useMutation<OperateOrderMutation, OperateOrderMutationVariables>(
    OperateOrderDocument
  )
}
export const OrderOperationLogDocument = gql`
  query orderOperationLog($pager: PagerInput, $query: OperationLogSearchQuery) {
    result: orderOperationLog(input: { query: $query, pager: $pager }) {
      items {
        id
        operatorId
        operationDate
        category
        beforeSnapshotStatus
        afterSnapshotStatus
        explain
        operatorName
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useOrderOperationLogQuery(
  options: Omit<
    Urql.UseQueryArgs<OrderOperationLogQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<OrderOperationLogQuery>({
    query: OrderOperationLogDocument,
    ...options
  })
}
export const UpdateBudgetWhiteListByAccountIdDocument = gql`
  mutation updateBudgetWhiteListByAccountId(
    $budgetWhiteDetail: BudgetWhiteDetailInput!
  ) {
    result: updateBudgetWhiteListByAccountId(
      budgetWhiteDetail: $budgetWhiteDetail
    )
  }
`

export function useUpdateBudgetWhiteListByAccountIdMutation() {
  return Urql.useMutation<
    UpdateBudgetWhiteListByAccountIdMutation,
    UpdateBudgetWhiteListByAccountIdMutationVariables
  >(UpdateBudgetWhiteListByAccountIdDocument)
}
export const TaskDocumentAuditRecordByTaskIdDocument = gql`
  query taskDocumentAuditRecordByTaskId($taskId: String!) {
    result: getRecordByTaskId(taskId: $taskId) {
      taskId
      verifyType
      verifyDimension
      status
    }
  }
`

export function useTaskDocumentAuditRecordByTaskIdQuery(
  options: Omit<
    Urql.UseQueryArgs<TaskDocumentAuditRecordByTaskIdQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<TaskDocumentAuditRecordByTaskIdQuery>({
    query: TaskDocumentAuditRecordByTaskIdDocument,
    ...options
  })
}
export const TaskLogByTaskIdDocument = gql`
  query taskLogByTaskId($taskId: String!) {
    result: queryTaskLogByTaskId(taskId: $taskId) {
      taskId
      rejectMsg
      gmtCreated
    }
  }
`

export function useTaskLogByTaskIdQuery(
  options: Omit<Urql.UseQueryArgs<TaskLogByTaskIdQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<TaskLogByTaskIdQuery>({
    query: TaskLogByTaskIdDocument,
    ...options
  })
}
export const AllConferencePlatformsDocument = gql`
  query allConferencePlatforms {
    platformList: queryAllConferencePlatforms {
      id
      name
      code
      remark
      sort
      enabled
    }
  }
`

export function useAllConferencePlatformsQuery(
  options: Omit<
    Urql.UseQueryArgs<AllConferencePlatformsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<AllConferencePlatformsQuery>({
    query: AllConferencePlatformsDocument,
    ...options
  })
}
export const OperationConferencePlatformDocument = gql`
  mutation operationConferencePlatform($platForm: MeetingPlatformInput!) {
    result: operationConferencePlatform(platForm: $platForm) {
      errorCode
      success
      message
    }
  }
`

export function useOperationConferencePlatformMutation() {
  return Urql.useMutation<
    OperationConferencePlatformMutation,
    OperationConferencePlatformMutationVariables
  >(OperationConferencePlatformDocument)
}
export const UpdateAccountFinalDocument = gql`
  mutation UpdateAccountFinal($account: AccountInfoInput!) {
    result: updateAccountFinal(account: $account)
  }
`

export function useUpdateAccountFinalMutation() {
  return Urql.useMutation<
    UpdateAccountFinalMutation,
    UpdateAccountFinalMutationVariables
  >(UpdateAccountFinalDocument)
}
export const AddMeetingWhiteDocument = gql`
  mutation addMeetingWhite($accountId: String!, $companyId: String!) {
    result: addMeetingWhite(accountId: $accountId, companyId: $companyId)
  }
`

export function useAddMeetingWhiteMutation() {
  return Urql.useMutation<
    AddMeetingWhiteMutation,
    AddMeetingWhiteMutationVariables
  >(AddMeetingWhiteDocument)
}
export const DeleteMeetingWhiteDocument = gql`
  mutation deleteMeetingWhite($accountId: String!, $companyId: String!) {
    result: deleteMeetingWhite(accountId: $accountId, companyId: $companyId)
  }
`

export function useDeleteMeetingWhiteMutation() {
  return Urql.useMutation<
    DeleteMeetingWhiteMutation,
    DeleteMeetingWhiteMutationVariables
  >(DeleteMeetingWhiteDocument)
}
export const DisableMeetingWhiteDocument = gql`
  mutation disableMeetingWhite($accountId: String!, $companyId: String!) {
    result: disableMeetingWhite(accountId: $accountId, companyId: $companyId)
  }
`

export function useDisableMeetingWhiteMutation() {
  return Urql.useMutation<
    DisableMeetingWhiteMutation,
    DisableMeetingWhiteMutationVariables
  >(DisableMeetingWhiteDocument)
}
export const MeetingTaskNumDocument = gql`
  query MeetingTaskNum {
    result: searchMeetingTaskNum {
      type
      num
    }
  }
`

export function useMeetingTaskNumQuery(
  options: Omit<Urql.UseQueryArgs<MeetingTaskNumQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<MeetingTaskNumQuery>({
    query: MeetingTaskNumDocument,
    ...options
  })
}
export const QueryDocumentsReviewsDocument = gql`
  query queryDocumentsReviews(
    $search: DocumentArchiveReviewsSearchParamsWithPager
  ) {
    reviews: financeQueryDocumentReviews(search: $search) {
      items {
        id
        documentId
        accountId
        name
        inviterName
        stateText
        createdTimestamp
        level
        fee
        state
        phone
        documentInfo {
          document {
            id
            title
            category {
              title
              id
            }
          }
          archive {
            archiveTags {
              id
              tag
            }
            signedUrl
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useQueryDocumentsReviewsQuery(
  options: Omit<
    Urql.UseQueryArgs<QueryDocumentsReviewsQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<QueryDocumentsReviewsQuery>({
    query: QueryDocumentsReviewsDocument,
    ...options
  })
}
export const ReviewContentDocument = gql`
  query reviewContent($reviewId: String!) {
    result: queryDocumentReviewContent(reviewId: $reviewId) {
      id
      value
      key
    }
  }
`

export function useReviewContentQuery(
  options: Omit<Urql.UseQueryArgs<ReviewContentQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<ReviewContentQuery>({
    query: ReviewContentDocument,
    ...options
  })
}
export const StartSettlementDocument = gql`
  mutation startSettlement($reviewId: String!) {
    result: setReviewToSettlement(reviewId: $reviewId) {
      errorCode
      message
      success
    }
  }
`

export function useStartSettlementMutation() {
  return Urql.useMutation<
    StartSettlementMutation,
    StartSettlementMutationVariables
  >(StartSettlementDocument)
}
export const DocumentReviewsDocument = gql`
  query documentReviews($search: DocumentArchiveReviewsSearchParamsWithPager) {
    reviews: queryDocumentReviews(isAdmin: true, search: $search) {
      items {
        id
        channel
        accountId
        companyId
        createdTimestamp
        inviterId
        inviterName: inviterName(encrypted: true)
        inviterRegionId
        documentId
        name: name(encrypted: false)
        encryptedName: name(encrypted: true)
        phone: phone(encrypted: true)
        level
        state
        stateText
        fee
        enabled
        documentInfo {
          archive {
            id
            signedUrl
            tags {
              typeName
              id
            }
            archiveTags {
              id
              tag
            }
            creator {
              id: accountId
              name: cnName
            }
          }
          document {
            remark
            title
            id
            author
            describe
            source
            createdTimestamp
            releaseTimestamp
            category {
              title
              id
            }
          }
        }
        account {
          ptxOpenStatus
          ptxOpenStatusOriginal
        }
        isOpen
        inviter {
          staffId
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useDocumentReviewsQuery(
  options: Omit<Urql.UseQueryArgs<DocumentReviewsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<DocumentReviewsQuery>({
    query: DocumentReviewsDocument,
    ...options
  })
}
export const PreviewContentDocument = gql`
  query previewContent($reviewId: String!) {
    result: queryDocumentReviewContent(reviewId: $reviewId) {
      id
      value
      key
    }
  }
`

export function usePreviewContentQuery(
  options: Omit<Urql.UseQueryArgs<PreviewContentQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<PreviewContentQuery>({
    query: PreviewContentDocument,
    ...options
  })
}
export const BatchInviteDoctorsToReviewDocument = gql`
  mutation batchInviteDoctorsToReview(
    $documentIds: [String!]!
    $doctorIds: [String!]!
  ) {
    result: batchInviteDoctorsToReview(
      documentIds: $documentIds
      doctorIds: $doctorIds
    ) {
      errorCode
      message
      success
    }
  }
`

export function useBatchInviteDoctorsToReviewMutation() {
  return Urql.useMutation<
    BatchInviteDoctorsToReviewMutation,
    BatchInviteDoctorsToReviewMutationVariables
  >(BatchInviteDoctorsToReviewDocument)
}
export const RatingReviewDocument = gql`
  mutation ratingReview($remark: String, $level: Int!, $reviewId: String!) {
    result: ratingReview(remark: $remark, level: $level, reviewId: $reviewId) {
      errorCode
      message
      success
    }
  }
`

export function useRatingReviewMutation() {
  return Urql.useMutation<RatingReviewMutation, RatingReviewMutationVariables>(
    RatingReviewDocument
  )
}
export const PayReviewDocument = gql`
  mutation payReview($reviewId: String!) {
    result: payReview(reviewId: $reviewId) {
      errorCode
      message
      success
    }
  }
`

export function usePayReviewMutation() {
  return Urql.useMutation<PayReviewMutation, PayReviewMutationVariables>(
    PayReviewDocument
  )
}
export const GetMedicalLiteratureUrlDocument = gql`
  query getMedicalLiteratureUrl($path: String!, $doctorAccountId: String!) {
    result: queryMedicalLiteratureAppUrl(
      path: $path
      doctorAccountId: $doctorAccountId
    )
  }
`

export function useGetMedicalLiteratureUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GetMedicalLiteratureUrlQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetMedicalLiteratureUrlQuery>({
    query: GetMedicalLiteratureUrlDocument,
    ...options
  })
}
export const GetArchiveSignedUrlDocument = gql`
  query getArchiveSignedUrl($archiveId: String!) {
    result: getArchiveSignedUrl(archiveId: $archiveId)
  }
`

export function useGetArchiveSignedUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GetArchiveSignedUrlQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetArchiveSignedUrlQuery>({
    query: GetArchiveSignedUrlDocument,
    ...options
  })
}
export const TranslateDocumentContentDocument = gql`
  query translateDocumentContent($text: String!) {
    result: translateDocumentContent(text: $text)
  }
`

export function useTranslateDocumentContentQuery(
  options: Omit<
    Urql.UseQueryArgs<TranslateDocumentContentQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<TranslateDocumentContentQuery>({
    query: TranslateDocumentContentDocument,
    ...options
  })
}
export const SalesOrderTypesDocument = gql`
  query SalesOrderTypes {
    types: salesOrder_TypesMap
  }
`

export function useSalesOrderTypesQuery(
  options: Omit<Urql.UseQueryArgs<SalesOrderTypesQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<SalesOrderTypesQuery>({
    query: SalesOrderTypesDocument,
    ...options
  })
}
export const SalesOrderStatusDocument = gql`
  query SalesOrderStatus {
    status: salesOrder_StatusMap
  }
`

export function useSalesOrderStatusQuery(
  options: Omit<Urql.UseQueryArgs<SalesOrderStatusQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<SalesOrderStatusQuery>({
    query: SalesOrderStatusDocument,
    ...options
  })
}
export const SalesOrderByIdDocument = gql`
  query SalesOrderById($id: String!) {
    result: searchSalesOrderById(id: $id) {
      salesOrder {
        ...SalesOrderInfo
        accountInfo {
          cnName
          staffId
        }
      }
      properties {
        ...SalesOrderPropertieInfo
      }
      formCategoryInfo {
        id
        title
        describe
        code
      }
      salesOrderBusinessCategory {
        id
        title
        code
      }
      isCurrentApprover: isSalesOrderCurrentApproverQcOwner
      auditRecord: searchSalesOrderAuditRecordByOrderId {
        id
        orderId
        receiptType
        verifyType
        verifyDimension
        status
        key
        value
        remark
        properties
        sort
        isEnable
      }
      invoices: salesOrderInvoices {
        formId
        invoiceUrl
        invoiceNumber
        remark
        isEnable
        checkOcr
        checkPassed
        checkRepeat
        checkTitle
        salesInvoiceDetail {
          purchaserName
          purchaserTaxIdentNumber
        }
      }
      receiptTemplate: salesOrderReceiptTemplate {
        id
        resourceId
        resourceName
        receiptUrl
        receiptType
      }
      logs: salesOrderLogs {
        log
        businessId
        createTime
      }
      consumptionCategory: salesOrderConsumptionCategory {
        id
        title
        describe
        code
      }
      approvalFlows: salesOrderApprovalFlows {
        instanceId
        status
        reason
        tasks {
          createTime
          status
          opinion
          approverInfo {
            cnName
            accountId
          }
          logDatas {
            createTime
            logType
            assginer
            assginerInfo {
              cnName
            }
            reason
          }
        }
      }
    }
  }
  ${SalesOrderInfoFragmentDoc}
  ${SalesOrderPropertieInfoFragmentDoc}
`

export function useSalesOrderByIdQuery(
  options: Omit<Urql.UseQueryArgs<SalesOrderByIdQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<SalesOrderByIdQuery>({
    query: SalesOrderByIdDocument,
    ...options
  })
}
export const SalesOrderPageDocument = gql`
  query SalesOrderPage($pager: PagerInput, $query: SalesOrderPageSearch) {
    result: searchSalesOrderPage(param: { pager: $pager, query: $query }) {
      items {
        ...SalesOrderInfo
        qcOwnerAccountInfo {
          cnName
          accountId
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
  ${SalesOrderInfoFragmentDoc}
`

export function useSalesOrderPageQuery(
  options: Omit<Urql.UseQueryArgs<SalesOrderPageQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<SalesOrderPageQuery>({
    query: SalesOrderPageDocument,
    ...options
  })
}
export const ApproveSalesOrderPageDocument = gql`
  query ApproveSalesOrderPage(
    $pager: PagerInput
    $query: SalesOrderPageSearch
  ) {
    result: searchApproveSalesOrderPage(
      param: { pager: $pager, query: $query }
    ) {
      items {
        ...SalesOrderInfo
        qcOwnerAccountInfo {
          cnName
          accountId
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
  ${SalesOrderInfoFragmentDoc}
`

export function useApproveSalesOrderPageQuery(
  options: Omit<
    Urql.UseQueryArgs<ApproveSalesOrderPageQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<ApproveSalesOrderPageQuery>({
    query: ApproveSalesOrderPageDocument,
    ...options
  })
}
export const SalesOrderOperationLogDocument = gql`
  query SalesOrderOperationLog(
    $pager: PagerInput
    $query: SalesOrderLogSearch
  ) {
    result: salesOrderOperationLog(param: { pager: $pager, query: $query }) {
      items {
        log
        businessId
        createTime
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
`

export function useSalesOrderOperationLogQuery(
  options: Omit<
    Urql.UseQueryArgs<SalesOrderOperationLogQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SalesOrderOperationLogQuery>({
    query: SalesOrderOperationLogDocument,
    ...options
  })
}
export const SalesOrderBillApprovedDocument = gql`
  mutation SalesOrderBillApproved(
    $id: String!
    $ticketDesc: String
    $invoiceDesc: String
    $reason: String
    $billReviewResult: String
  ) {
    result: salesOrderBillApproved(
      id: $id
      ticketDesc: $ticketDesc
      invoiceDesc: $invoiceDesc
      reason: $reason
      billReviewResult: $billReviewResult
    )
  }
`

export function useSalesOrderBillApprovedMutation() {
  return Urql.useMutation<
    SalesOrderBillApprovedMutation,
    SalesOrderBillApprovedMutationVariables
  >(SalesOrderBillApprovedDocument)
}
export const SalesOrderBillReturnDocument = gql`
  mutation SalesOrderBillReturn(
    $id: String!
    $reason: String!
    $billReviewResult: String
  ) {
    result: salesOrderBillReturn(
      id: $id
      reason: $reason
      billReviewResult: $billReviewResult
    )
  }
`

export function useSalesOrderBillReturnMutation() {
  return Urql.useMutation<
    SalesOrderBillReturnMutation,
    SalesOrderBillReturnMutationVariables
  >(SalesOrderBillReturnDocument)
}
export const SalesOrderVoucherRejectDocument = gql`
  mutation SalesOrderVoucherReject($id: String!) {
    result: salesOrderVoucherReject(id: $id)
  }
`

export function useSalesOrderVoucherRejectMutation() {
  return Urql.useMutation<
    SalesOrderVoucherRejectMutation,
    SalesOrderVoucherRejectMutationVariables
  >(SalesOrderVoucherRejectDocument)
}
export const SalesClaimFormStatusDocument = gql`
  query SalesClaimFormStatus {
    status: salesClaimForm_StatusMap
  }
`

export function useSalesClaimFormStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<SalesClaimFormStatusQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SalesClaimFormStatusQuery>({
    query: SalesClaimFormStatusDocument,
    ...options
  })
}
export const SalesClaimFormDocument = gql`
  query SalesClaimForm($pager: PagerInput, $query: SalesClaimFormSearch) {
    result: searchSalesClaimForm(param: { pager: $pager, query: $query }) {
      items {
        claimForm {
          ...SalesClaimFormInfo
          accountInfo {
            cnName
            staffId
          }
        }
        relations {
          id
          claimFormCode
          orderId
          isEnable
          remark
        }
        orders: salesClaimFormOrders {
          salesOrder {
            ...SalesOrderInfo
          }
        }
        preReviewedUser: salesClaimFormPreReviewedUser {
          cnName
          staffId
        }
        reviewedUser: salesClaimFormReviewedUser {
          cnName
          staffId
        }
      }
      pager {
        currentPage
        pageSize
        total
      }
    }
  }
  ${SalesClaimFormInfoFragmentDoc}
  ${SalesOrderInfoFragmentDoc}
`

export function useSalesClaimFormQuery(
  options: Omit<Urql.UseQueryArgs<SalesClaimFormQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<SalesClaimFormQuery>({
    query: SalesClaimFormDocument,
    ...options
  })
}
export const SalesClaimFormByClaimFormCodeDocument = gql`
  query SalesClaimFormByClaimFormCode($claimFormCode: String!) {
    result: querySalesClaimForm(claimFormCodes: [$claimFormCode]) {
      claimForm {
        ...SalesClaimFormInfo
        accountInfo {
          cnName
          staffId
        }
      }
      approvalFlows: salesClaimFormApprovalFlows {
        instanceId
        status
        reason
        tasks {
          createTime
          status
          opinion
          approverInfo {
            cnName
            accountId
          }
          logDatas {
            createTime
            logType
            assginer
            assginerInfo {
              cnName
            }
            reason
          }
        }
      }
      relations {
        id
        claimFormCode
        orderId
        isEnable
        remark
      }
      properties {
        ...SalesClaimFormPropertieInfo
      }
      orders: salesClaimFormOrders {
        salesOrder {
          ...SalesOrderInfo
          accountInfo {
            cnName
            staffId
          }
        }
        properties {
          ...SalesOrderPropertieInfo
        }
        formCategoryInfo {
          id
          title
          describe
          code
        }
        consumptionCategory: salesOrderConsumptionCategory {
          id
          title
          describe
          code
        }
        salesOrderBusinessCategory {
          id
          title
          code
        }
        invoices: salesOrderInvoices {
          formId
          invoiceUrl
          invoiceNumber
          remark
          isEnable
          checkOcr
          checkPassed
          checkRepeat
          checkTitle
        }
        approvalFlows: salesOrderApprovalFlows {
          instanceId
          status
          reason
          tasks {
            createTime
            status
            opinion
            approverInfo {
              cnName
              accountId
            }
            logDatas {
              createTime
              logType
              assginer
              assginerInfo {
                cnName
              }
              reason
            }
          }
        }
      }
      logs: salesClaimFormLogs {
        log
        businessId
        createTime
      }
    }
  }
  ${SalesClaimFormInfoFragmentDoc}
  ${SalesClaimFormPropertieInfoFragmentDoc}
  ${SalesOrderInfoFragmentDoc}
  ${SalesOrderPropertieInfoFragmentDoc}
`

export function useSalesClaimFormByClaimFormCodeQuery(
  options: Omit<
    Urql.UseQueryArgs<SalesClaimFormByClaimFormCodeQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SalesClaimFormByClaimFormCodeQuery>({
    query: SalesClaimFormByClaimFormCodeDocument,
    ...options
  })
}
export const SalesClaimFormApprovedDocument = gql`
  mutation SalesClaimFormApproved(
    $id: String!
    $reason: String
    $orderParam: [ClaimFormRejectOrderParam!]
  ) {
    result: salesClaimFormApproved(
      id: $id
      reason: $reason
      orderParam: $orderParam
    )
  }
`

export function useSalesClaimFormApprovedMutation() {
  return Urql.useMutation<
    SalesClaimFormApprovedMutation,
    SalesClaimFormApprovedMutationVariables
  >(SalesClaimFormApprovedDocument)
}
export const SalesClaimFormReturnDocument = gql`
  mutation SalesClaimFormReturn(
    $formId: String!
    $orderIds: [String!]
    $reason: String
    $rejectOrderParam: [ClaimFormRejectOrderParam!]
  ) {
    result: salesClaimFormReturn(
      formId: $formId
      orderIds: $orderIds
      reason: $reason
      rejectOrderParam: $rejectOrderParam
    )
  }
`

export function useSalesClaimFormReturnMutation() {
  return Urql.useMutation<
    SalesClaimFormReturnMutation,
    SalesClaimFormReturnMutationVariables
  >(SalesClaimFormReturnDocument)
}
export const SalesClaimFormReturnPreReviewDocument = gql`
  mutation SalesClaimFormReturnPreReview($formId: String!, $reason: String) {
    result: salesClaimFormReturnPreReview(formId: $formId, reason: $reason)
  }
`

export function useSalesClaimFormReturnPreReviewMutation() {
  return Urql.useMutation<
    SalesClaimFormReturnPreReviewMutation,
    SalesClaimFormReturnPreReviewMutationVariables
  >(SalesClaimFormReturnPreReviewDocument)
}
export const TerminateAlipayRuleDocument = gql`
  mutation TerminateAlipayRule($orderId: String!) {
    result: terminateAlipayRule(orderId: $orderId) {
      errorCode
      success
      message
    }
  }
`

export function useTerminateAlipayRuleMutation() {
  return Urql.useMutation<
    TerminateAlipayRuleMutation,
    TerminateAlipayRuleMutationVariables
  >(TerminateAlipayRuleDocument)
}
export const UnbindAlipayRelationshipDocument = gql`
  mutation UnbindAlipayRelationship($accountId: String!) {
    result: unbindAlipayRelationship(accountId: $accountId) {
      errorCode
      success
      message
    }
  }
`

export function useUnbindAlipayRelationshipMutation() {
  return Urql.useMutation<
    UnbindAlipayRelationshipMutation,
    UnbindAlipayRelationshipMutationVariables
  >(UnbindAlipayRelationshipDocument)
}
export const HansohSalesEditOrderDocument = gql`
  mutation hansohSalesEditOrder($order: CreateHansohSalesOrderInput!) {
    result: hansohSalesEditOrder(order: $order)
  }
`

export function useHansohSalesEditOrderMutation() {
  return Urql.useMutation<
    HansohSalesEditOrderMutation,
    HansohSalesEditOrderMutationVariables
  >(HansohSalesEditOrderDocument)
}
export const UpadminDistributeSalesOrderDocument = gql`
  mutation upadminDistributeSalesOrder($accountId: String) {
    result: upadminDistributeSalesOrder(accountId: $accountId)
  }
`

export function useUpadminDistributeSalesOrderMutation() {
  return Urql.useMutation<
    UpadminDistributeSalesOrderMutation,
    UpadminDistributeSalesOrderMutationVariables
  >(UpadminDistributeSalesOrderDocument)
}
export const SalesOrderTaskNumDocument = gql`
  query SalesOrderTaskNum {
    result: searchSalesOrderTaskNum
  }
`

export function useSalesOrderTaskNumQuery(
  options: Omit<
    Urql.UseQueryArgs<SalesOrderTaskNumQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SalesOrderTaskNumQuery>({
    query: SalesOrderTaskNumDocument,
    ...options
  })
}
export const PreImportTasksDocument = gql`
  mutation PreImportTasks(
    $importId: String
    $companyId: String!
    $data: [HRTaskExcelImportInput!]!
  ) {
    result: upadminPreImportTasks(
      importId: $importId
      companyId: $companyId
      data: $data
    )
  }
`

export function usePreImportTasksMutation() {
  return Urql.useMutation<
    PreImportTasksMutation,
    PreImportTasksMutationVariables
  >(PreImportTasksDocument)
}
export const ImportTasksDocument = gql`
  mutation ImportTasks(
    $importId: String
    $companyId: String!
    $data: [HRTaskExcelImportInput!]!
  ) {
    result: upadminImportTasks(
      companyId: $companyId
      importId: $importId
      data: $data
    ) {
      errorCode
      message
      success
    }
  }
`

export function useImportTasksMutation() {
  return Urql.useMutation<ImportTasksMutation, ImportTasksMutationVariables>(
    ImportTasksDocument
  )
}
export const SearchDoctorAttenderTasksDocument = gql`
  query SearchDoctorAttenderTasks($search: DoctorTaskQueryInputWithPager) {
    result: searchDoctorAttenderTasks(search: $search) {
      items {
        id
        meetingId
        attenderId
        attenderStatus
        attenderName
        attenderPhone
        attenderFee
        attenderType
        taskIsOpen
        taskListInfo {
          status
          gmtCreated
        }
        meetingAttending {
          meetingBaseData {
            activityType
            createTime
            type
            belongProduct {
              productName
            }
            owner {
              company {
                companyId
                cnName
              }
            }
          }
          hengruiImage_activityTypeSphere
          hengruiImage_activityType
          hengruiImage_categoryName
          meetingRegion: hengruiImage_meetingRegion {
            name
          }
          status: hengruiImage_status
          statusText: hengruiImage_statusText
          meetingBaseData {
            type
            belongProduct {
              productName
            }
            owner {
              company {
                companyId
                cnName
              }
            }
          }
        }
      }
      pager {
        currentPage
        pageSize
        total
        hasMore
      }
    }
  }
`

export function useSearchDoctorAttenderTasksQuery(
  options: Omit<
    Urql.UseQueryArgs<SearchDoctorAttenderTasksQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<SearchDoctorAttenderTasksQuery>({
    query: SearchDoctorAttenderTasksDocument,
    ...options
  })
}
export const SendTaskInviteMessageDocument = gql`
  mutation SendTaskInviteMessage(
    $taskUrl: String!
    $activityName: String!
    $doctorAccountId: String!
    $hash: String
  ) {
    result: sendTaskInvitationMessage(
      taskUrl: $taskUrl
      activityName: $activityName
      doctorAccountId: $doctorAccountId
      hash: $hash
    )
  }
`

export function useSendTaskInviteMessageMutation() {
  return Urql.useMutation<
    SendTaskInviteMessageMutation,
    SendTaskInviteMessageMutationVariables
  >(SendTaskInviteMessageDocument)
}
export const SendMiniProgramTaskInviteMessageDocument = gql`
  mutation SendMiniProgramTaskInviteMessage(
    $appName: String!
    $activityName: String!
    $doctorAccountId: String!
  ) {
    result: sendMiniProgramTaskInvitationMessage(
      appName: $appName
      activityName: $activityName
      doctorAccountId: $doctorAccountId
    )
  }
`

export function useSendMiniProgramTaskInviteMessageMutation() {
  return Urql.useMutation<
    SendMiniProgramTaskInviteMessageMutation,
    SendMiniProgramTaskInviteMessageMutationVariables
  >(SendMiniProgramTaskInviteMessageDocument)
}
export const GetUrlLinkByAppNameDocument = gql`
  query GetUrlLinkByAppName($appName: String!, $doctorAccountId: String!) {
    result: getWechatUrlLink(
      appName: $appName
      doctorAccountId: $doctorAccountId
    )
  }
`

export function useGetUrlLinkByAppNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GetUrlLinkByAppNameQueryVariables>,
    'query'
  > = {}
) {
  return Urql.useQuery<GetUrlLinkByAppNameQuery>({
    query: GetUrlLinkByAppNameDocument,
    ...options
  })
}
export const RetreatAttenderTaskDocument = gql`
  mutation RetreatAttenderTask($reason: String, $taskId: String!) {
    result: retreatAttenderTask(reason: $reason, taskId: $taskId) {
      errorCode
      message
      success
    }
  }
`

export function useRetreatAttenderTaskMutation() {
  return Urql.useMutation<
    RetreatAttenderTaskMutation,
    RetreatAttenderTaskMutationVariables
  >(RetreatAttenderTaskDocument)
}
export const CurrentUserDocument = gql`
  query CurrentUser {
    user: currentUser {
      ...accountField
      roles {
        id
        name
        type
        enable
      }
      appMenus {
        ...menuField
      }
      roleAuthorities {
        appId
        appName
      }
    }
  }
  ${AccountFieldFragmentDoc}
  ${MenuFieldFragmentDoc}
`

export function useCurrentUserQuery(
  options: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<CurrentUserQuery>({
    query: CurrentUserDocument,
    ...options
  })
}
export const UserInfoDocument = gql`
  query UserInfo($accountId: String!) {
    user: queryUser(accountId: $accountId) {
      ...accountField
    }
  }
  ${AccountFieldFragmentDoc}
`

export function useUserInfoQuery(
  options: Omit<Urql.UseQueryArgs<UserInfoQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<UserInfoQuery>({ query: UserInfoDocument, ...options })
}
export const UserLoginDocument = gql`
  mutation UserLogin($password: String!, $mobile: String!) {
    result: hengruiImage_login(pwd: $password, mobile: $mobile) {
      token
      account {
        ...accountField
        roles {
          id
          name
          type
          enable
        }
        appMenus {
          ...menuField
        }
        roleAuthorities {
          appId
          appName
        }
      }
    }
  }
  ${AccountFieldFragmentDoc}
  ${MenuFieldFragmentDoc}
`

export function useUserLoginMutation() {
  return Urql.useMutation<UserLoginMutation, UserLoginMutationVariables>(
    UserLoginDocument
  )
}
export const SendCodeDocument = gql`
  mutation SendCode($mobile: String!) {
    result: hengruiImage_sendCode(mobile: $mobile)
  }
`

export function useSendCodeMutation() {
  return Urql.useMutation<SendCodeMutation, SendCodeMutationVariables>(
    SendCodeDocument
  )
}
export const GetSessionIdDocument = gql`
  mutation getSessionId($mobile: String!) {
    sessionId: hengruiImage_sendCode(mobile: $mobile)
  }
`

export function useGetSessionIdMutation() {
  return Urql.useMutation<GetSessionIdMutation, GetSessionIdMutationVariables>(
    GetSessionIdDocument
  )
}
export const EditPassDocument = gql`
  mutation editPass(
    $companyName: String!
    $newPassword: String!
    $sessionId: String!
    $code: String!
    $mobile: String!
  ) {
    result: resetPassword(
      companyName: $companyName
      newPassword: $newPassword
      sessionId: $sessionId
      code: $code
      mobile: $mobile
    )
  }
`

export function useEditPassMutation() {
  return Urql.useMutation<EditPassMutation, EditPassMutationVariables>(
    EditPassDocument
  )
}
export const PwdLoginDocument = gql`
  mutation pwdLogin($mobile: String!, $password: String!) {
    result: medicalLiteratureLoginWithPwd(
      isAdmin: true
      mobile: $mobile
      password: $password
    )
  }
`

export function usePwdLoginMutation() {
  return Urql.useMutation<PwdLoginMutation, PwdLoginMutationVariables>(
    PwdLoginDocument
  )
}
export const UserLoginWithCodeDocument = gql`
  mutation UserLoginWithCode(
    $mobile: String!
    $code: String!
    $sessionId: String!
  ) {
    result: hengruiImage_loginWithCode(
      mobile: $mobile
      verifyCode: $code
      sessionId: $sessionId
    )
  }
`

export function useUserLoginWithCodeMutation() {
  return Urql.useMutation<
    UserLoginWithCodeMutation,
    UserLoginWithCodeMutationVariables
  >(UserLoginWithCodeDocument)
}
export const UserLogOutDocument = gql`
  mutation UserLogOut {
    result: hengruiImage_logOut
  }
`

export function useUserLogOutMutation() {
  return Urql.useMutation<UserLogOutMutation, UserLogOutMutationVariables>(
    UserLogOutDocument
  )
}
