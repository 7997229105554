import React, { useState, useEffect } from 'react'
import omit from 'lodash.omit'
import { Button, Modal, message, Radio, Input, Select } from 'antd'

import {
  useUpadminDoctorListQuery,
  useChangeChannelMutation,
  useUpadminEditOrAddDoctorMutation,
  useUpadminDoctorCompaniesQuery,
  useUnBindAccountWechatRelationMutation,
  useUpdateAccountStatusMutation,
  AccountStatus,
  useDeleteOrderByAccountIdMutation,
  useResumeOrderByAccountIdMutation,
  useUpdateBudgetWhiteListByAccountIdMutation,
  useSyncDoctorVerifyResultMutation,
  useAddMeetingWhiteMutation,
  useDisableMeetingWhiteMutation,
  useDeleteMeetingWhiteMutation
} from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useTableColumns } from './data'
import DoctorInfoModal from './doctorInfoModal'
import { useUserStore } from '../../hooks/user'
import { useSearchStore } from '../../hooks/pageSearch'
import DisabledDoctorModal from './disabledDoctorModal'

type FieldTypes = {
  keyword?: string
  companyId?: string
}

const DoctorManageOfFinance: QCPage = () => {
  const { pageParams } = useSearchStore()
  const searchParams = { ...pageParams[window.location.pathname] }
  const [isChannelModalShow, setChannelModalShow] = useState(false)
  const [signatory, setSignatory] = useState('')
  const [editIndex, setEditIndex] = useState(0)
  const [isDoctorModalShow, setDoctorModalShow] = useState(false)
  const [isDoctorEdit, setIsDoctorEdit] = useState(false)
  const [isDoctorModalLoading, setDoctorModalLoading] = useState(false)
  const [isDisabledDoctor, setIsDisabledDoctor] = useState(false)
  const [curDoctorInfo, updateCurDoctorInfo] = useState<any>(null)
  const [selectValue, setSelectValue] = useState(searchParams?.companyId)
  const HANSOH_DOCCOMPANYID = process.env.HANSOH_DOCCOMPANYID

  const production = useUserStore().profile?.hrProduction || ''
  const channels = useUserStore().channels

  const [{ data: selectOptions }] = useUpadminDoctorCompaniesQuery()

  const [, unBindWechat] = useUnBindAccountWechatRelationMutation()

  const [{ fetching: updateAccountFetching }, updateAccount] =
    useUpdateAccountStatusMutation()
  const [{ fetching: deleteOrderFetching }, deleteOrder] =
    useDeleteOrderByAccountIdMutation()
  const [{ fetching: resumeOrderFetching }, resumeOrder] =
    useResumeOrderByAccountIdMutation()
  const [{ fetching: updateVipFetching }, updateVip] =
    useUpdateBudgetWhiteListByAccountIdMutation()
  const [, syncDoctorVerifyResult] = useSyncDoctorVerifyResultMutation()
  const [{ fetching: addMeetingWhiteFetching }, addMeetingWhite] =
    useAddMeetingWhiteMutation()
  const [{ fetching: deleteMeetingWhiteFetching }, deleteMeetingWhite] =
    useDeleteMeetingWhiteMutation()

  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })

  // 自动查询第一个企业的数据
  useEffect(() => {
    if (!selectValue && selectOptions?.companies[0]?.id) {
      table.updateSearchParams({
        companyId: selectOptions?.companies[0]?.id
      })
    }
  }, [JSON.stringify(selectOptions?.companies)])

  const onSelectChange = (value: string) => {
    setSelectValue(value)
  }

  const searchFields = [
    {
      name: 'companyId',
      label: '企业',
      span: 6,
      defaultValue: selectOptions?.companies[0]?.id!,
      render() {
        return (
          <Select
            showSearch
            placeholder='请选择企业'
            optionFilterProp='children'
            key={selectOptions?.companies[0]?.id || ''}
            defaultValue={selectOptions?.companies[0]?.id || ''}
            onChange={onSelectChange}
            filterOption={(input: any, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectOptions?.companies.map((company, i) => (
              <Select.Option value={company?.id || ''} key={company?.id || i}>
                {company.cnName || ''}
              </Select.Option>
            ))}
          </Select>
        )
      }
    },
    {
      name: 'keyword',
      label: null,
      span: 8,
      render() {
        return <Input allowClear placeholder='请输入老师名字、手机号搜索' />
      }
    }
  ]
  const [, changeAccountDefaultChannel] = useChangeChannelMutation()
  const [, editOrAddDoctor] = useUpadminEditOrAddDoctorMutation()

  // 拿到接口数据
  const [{ data, error, fetching }, refetchDoctorList] =
    useUpadminDoctorListQuery({
      variables: {
        pager: omit(table.pager, 'total'),
        query: Object.assign(table.searchParams, {
          companyId: selectValue || selectOptions?.companies[0]?.id
        })
      },
      pause: !selectOptions?.companies?.length || !table.searchParams?.keyword
    })

  // 打开签约渠道修改弹框
  const openChannelModal = (index: number, record: any) => {
    setSignatory(record.defaultChannel)
    setChannelModalShow(true)
    setEditIndex(index)
  }

  // 点击 radio 选择签约渠道
  const onRadioChange = (e: RadioChangeEvent) => {
    setSignatory(e.target.value)
  }

  // 点击确定按钮，确定修改签约渠道
  const onSignatoryChange = async (data: any) => {
    const result = await changeAccountDefaultChannel({
      channel: signatory,
      accountId: data.doctors.items[editIndex].accountId
    })

    if (result.error || !result.data?.result) {
      message.error(result.error || '修改失败')
      return
    }

    if (result.data?.result) {
      setChannelModalShow(false)
      refetchDoctorList()
    }
  }

  // 点击编辑信息按钮
  const openDoctorInfoModal = (index: number, record: any) => {
    updateCurDoctorInfo(record) // 当前编辑的老师信息
    setIsDoctorEdit(true)
    setDoctorModalShow(true)
    setEditIndex(index)
  }

  // 老师信息提交事件
  const onInfoCommit = async (values: any) => {
    setDoctorModalLoading(true)
    const result = await editOrAddDoctor({
      doctor: values
    })
    setDoctorModalLoading(false)
    if (!result.data?.result?.success) {
      message.error(
        result.data?.result?.message || `${isDoctorEdit ? '修改' : '添加'}失败`
      )
      return
    }
    if (result.data?.result?.success) {
      setDoctorModalShow(false)
      refetchDoctorList()
    }
  }

  const unBindAccountWechat = async (index: number, record: any) => {
    const { data, error } = await unBindWechat({
      accountId: record.accountId,
      appName: 'UPAPP_DOCTOR_MP'
    })
    if (data?.result) {
      message.success('解绑成功')
      refetchDoctorList()
    } else {
      message.error(error?.message || '解绑失败')
    }
  }

  const syncVerifyResultByAccountId = async (
    accountInfo: any,
    verifyResult: string
  ) => {
    const { data } = await syncDoctorVerifyResult({
      accountId: accountInfo.accountId,
      verifyResult,
      verifyImages: accountInfo.verifyImages
    })
    if (!data?.result?.success) {
      message.error(data?.result?.message || '修改验证结果失败')
    }
  }

  const deleteAccountOrder = async (values: any, callBack?: () => void) => {
    const { data, error } = await deleteOrder({
      accountId: curDoctorInfo?.accountId,
      disableReason: values.reason
    })
    if (data?.result) {
      message.success('禁用老师成功')
      syncVerifyResultByAccountId(curDoctorInfo, '否')
      refetchDoctorList()
      callBack && callBack()
      setIsDisabledDoctor(false)
      updateCurDoctorInfo(null)
    } else {
      message.error(error?.message || '禁用老师失败')
    }
  }

  const resumeAccountOrder = async (record: any) => {
    const { data, error } = await resumeOrder({
      accountId: record.accountId
    })
    if (data?.result) {
      message.success('启用老师成功')
      syncVerifyResultByAccountId(record, '是')
      refetchDoctorList()
    } else {
      message.error(error?.message || '启用老师失败')
    }
  }

  const updateAccountStatus = async (record: any) => {
    const isDisabled = record.status === AccountStatus.AccountDisabled
    if (isDisabled) {
      resumeAccountOrder(record)
    } else {
      updateCurDoctorInfo(record) // 当前要禁用的老师
      setIsDisabledDoctor(true)
    }
  }

  const onOpertionVip = async (record: any) => {
    console.log(record)
    const isOpenBudgetWhite = record.budgetWhite && record.budgetWhite.enabled
    const budgetWhiteDetail = record.budgetWhite || {}
    if (!budgetWhiteDetail.id) {
      budgetWhiteDetail.accountId = record.accountId
      budgetWhiteDetail.companyId = record.companyId
      budgetWhiteDetail.name = record.cnName
      budgetWhiteDetail.phone = record.companyMobile
      budgetWhiteDetail.enabled = true
    } else {
      budgetWhiteDetail.enabled = !budgetWhiteDetail.enabled
    }

    const { data, error } = await updateVip({
      budgetWhiteDetail
    })
    if (data?.result) {
      message.success(`${isOpenBudgetWhite ? '关闭vip' : '开启vip'}成功`)
      refetchDoctorList()
    } else {
      message.error(
        error?.message || `${isOpenBudgetWhite ? '关闭vip' : '开启vip'}失败`
      )
    }
  }

  const onOpertionMeetingWhite = async (record: any) => {
    console.log(record)
    const isOpenMeetingWhite =
      record.doctorMeetingWhite && record.doctorMeetingWhite.id
    let updateMeetingWhite = addMeetingWhite
    if (isOpenMeetingWhite) {
      updateMeetingWhite = deleteMeetingWhite
    }
    const { data, error } = await updateMeetingWhite({
      accountId: record.accountId,
      companyId: record.companyId
    })
    if (data?.result) {
      message.success(
        `${isOpenMeetingWhite ? '删除任务白名单' : '加入任务白名单'}成功`
      )
      refetchDoctorList()
    } else {
      message.error(
        error?.message ||
          `${isOpenMeetingWhite ? '删除任务白名单' : '加入任务白名单'}失败`
      )
    }
  }

  const tableColumns = useTableColumns({
    production,
    openChannelModal,
    openDoctorInfoModal,
    channels,
    unBindAccountWechat,
    updateAccountStatus,
    onOpertionVip,
    hansohDocCompanyId: HANSOH_DOCCOMPANYID,
    onOpertionMeetingWhite
  })

  const onTableReset = () => {
    setSelectValue(selectOptions?.companies[0]?.id || '')
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDoctorList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={{ fields: searchFields }}
        bordered
        scroll={{ x: 1950 }}
        loading={
          fetching ||
          updateAccountFetching ||
          deleteOrderFetching ||
          resumeOrderFetching ||
          updateVipFetching ||
          addMeetingWhiteFetching ||
          deleteMeetingWhiteFetching
        }
        pager={data?.doctors?.pager}
        dataSource={data?.doctors?.items || []}
        size='middle'
        rowKey={(record) => String(record.accountId)}
        columns={tableColumns}
        extraPanelContent={
          <Button
            type='primary'
            onClick={() => {
              setIsDoctorEdit(false)
              setDoctorModalShow(true)
            }}
          >
            添加老师
          </Button>
        }
        onReset={onTableReset}
      />
      <Modal
        maskClosable={false}
        destroyOnClose
        title={'修改签约渠道'}
        visible={isChannelModalShow}
        onCancel={() => setChannelModalShow(false)}
        centered
        // width={400}
        onOk={() => onSignatoryChange(data)}
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => onRadioChange(e)}
          value={signatory}
        >
          {useUserStore().channels?.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.text}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>

      {/* 每次打开弹框都强制刷新 */}
      {isDoctorModalShow ? (
        <DoctorInfoModal
          isLoading={isDoctorModalLoading}
          visible={isDoctorModalShow}
          onCancel={() => setDoctorModalShow(false)}
          onSubmit={onInfoCommit}
          isEdit={isDoctorEdit}
          initialInfo={isDoctorEdit ? curDoctorInfo : null}
        />
      ) : null}

      {isDisabledDoctor && (
        <DisabledDoctorModal
          onCancel={() => {
            setIsDisabledDoctor(false)
          }}
          onSubmit={deleteAccountOrder}
        />
      )}
    </main>
  )
}

DoctorManageOfFinance.pageTitle = '老师管理'

export default DoctorManageOfFinance
