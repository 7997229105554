import React, { useState } from 'react'
import {
  useAdminCompanyDetailQuery,
  useAdminCompanyGroupTreeQuery,
  useUpadminCompaniesQuery
} from '../../graphql'
import SearchTable from '../../components/SearchTable'
import { Button, Card, PageHeader, Tabs, Tree } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { GroupTree } from './GroupTree'
import { ApprovalFlowConfig } from './ApprovalFlowConfig'

const AdminCompanyDetail: QCPage = () => {
  const { id } = useParams<{ id: string }>()
  const [{ data }] = useAdminCompanyDetailQuery({
    variables: { companyId: id }
  })

  const [currentKey, setCurrentKey] = useState<string>('approvalFlow')

  return (
    <div>
      <PageHeader title={data?.company?.cnName}></PageHeader>

      <Tabs activeKey={currentKey} onChange={(key) => setCurrentKey(key)}>
        <Tabs.TabPane tab='事业部管理' key='structure'>
          <GroupTree companyId={id} groupType='structure' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='成本中心管理' key='costcenter'>
          <GroupTree companyId={id} groupType='costcenter' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='部门管理' key='department'>
          <GroupTree companyId={id} groupType='department' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='大区管理' key='region'>
          <GroupTree companyId={id} groupType='region' />
        </Tabs.TabPane>
        <Tabs.TabPane tab='审批流配置' key='approvalFlow'>
          <ApprovalFlowConfig companyId={id} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default AdminCompanyDetail
