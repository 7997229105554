import React, { useState, FC, useRef } from 'react'
import {
  CloudUploadOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import {
  Card,
  Table,
  Input,
  Button,
  Space,
  Result,
  message,
  Spin,
  notification
} from 'antd'

import ResolveExcel from '../../components/ResolveExcel'
import { checkPersonalPlanBudgetExcelValidate, columns, getData } from './data'
import { generateExport } from '../../components/UploadBatch'
import {
  Maybe,
  Scalars,
  usePreImportHansohInPlanBudgetMutation,
  useImportHansohInPlanBudgetMutation,
  PersonalPlanBudgetImportData
} from '../../graphql'
import { useBatchUpload } from '../../components/UploadBatch'
import { createHash } from '../../lib/createHash'
import _ from 'lodash'

export type FileProps = {
  name?: string
  list?: Array<{ [key: string]: any }>
}

const InPlanBudgetImport: FC = () => {
  const options = { concurrency: 5, chunkSize: 50 }
  const [fileName, setFileName] = useState('')
  const [tableSource, updateTableSource] = useState([] as any)
  const [excelData, updateExcelData] = useState([] as any)
  const [excelCorrect, setExcelCorrect] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [preChecking, setPreChecking] = useState(false)
  const [importing, setImporting] = useState(false)
  const importIds = useRef<any>({
    data: []
  })

  const [, startBudgePreCheck] = usePreImportHansohInPlanBudgetMutation()
  const [, startImport] = useImportHansohInPlanBudgetMutation()

  // 批量数据检查结束后，拿到所有接口的返回结果，整理数据格式
  const preCheckResultsResolver = (
    preCheckResults: Array<Maybe<Scalars['JSON']>>,
    excelData: PersonalPlanBudgetImportData[]
  ) => {
    const tableData: Array<Maybe<Scalars['JSON']>> = []
    const tableKey = createHash()
    const listWithKey: { [key: string]: PersonalPlanBudgetImportData } = {}

    excelData.forEach((item) => {
      if (item.financeNo || item.accountName) {
        listWithKey[`${item.financeNo}_${item.accountName}`] = { ...item }
      }
    })

    console.log(preCheckResults)
    preCheckResults?.forEach((result: any, index: number) => {
      if (result?.error?.length) {
        const errorData = result.error.map(
          (error: { [key: string]: any }, i: number) => {
            if (error?.financeNo || error?.accountName) {
              return {
                ...listWithKey[`${error.financeNo}_${error.accountName}`],
                ...error,
                customizedIndex: `${tableKey}_${index}_${i}`
              }
            }
          }
        )
        tableData.push(...errorData)
      }
    })

    setPreChecking(false)
    setExcelCorrect(!tableData.length)
    updateExcelData(excelData)
    updateTableSource(tableData)
  }

  const preCheckTask = async (item: PersonalPlanBudgetImportData[]) => {
    const uniqImportId = new Date().getTime().toString() + createHash()
    const result: Maybe<Scalars['JSON']> = await startBudgePreCheck({
      data: item,
      importId: uniqImportId
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      // 取出接口返回的上传任务id
      const { data = [] } = importIds.current
      data.push({ importId: uniqImportId, data: item })
      importIds.current = { data }
      return result.data?.result
    }
  }

  // 表格数据预检验
  const [budgetPreCheck] = useBatchUpload({
    task: preCheckTask,
    options: options,
    noMessage: true,
    resultResolver: preCheckResultsResolver,
    errorResolver: () => {
      setExcelCorrect(false)
      setPreChecking(false)
    }
  })

  const importTask = async (item: PersonalPlanBudgetImportData[]) => {
    let { data = [] } = importIds.current
    const importIdItem = data.find(
      (x: any) => JSON.stringify(x.data) === JSON.stringify(item)
    )
    if (importIdItem?.importId) {
      const result = await startImport({
        data: item,
        importId: importIdItem?.importId || ''
      })
      if (!result.data?.result?.success) {
        throw new Error(result.data?.result?.message || '上传失败')
      } else if (result.data?.result?.success) {
        data = _.pull(data, importIdItem)
        importIds.current = { data }
      }
    } else {
      return true
    }
  }

  // 确认导入
  const [importBudget] = useBatchUpload({
    task: importTask,
    options: options,
    noMessage: true,
    resultResolver: () => {
      setImporting(false)
      setExcelCorrect(false)
      importIds.current = { data: [] }
      // notification.destroy()
      notification.success({
        message: '日常预算导入成功',
        description: '日常预算导入成功',
        duration: 0
      })
    },
    errorResolver: () => {
      setImporting(false)
    }
  })

  // 文件读取之后进行数据校验和处理
  const onUploaded = (file: FileProps) => {
    let result: PersonalPlanBudgetImportData[] = []
    const list = file.list || []
    const validateRes = checkPersonalPlanBudgetExcelValidate(list || [])

    if (validateRes.success) {
      result = list?.map((row) => {
        return {
          accountName: row.用户姓名 ? String(row.用户姓名).trim() : '',
          year: row.预算年份 ? String(row.预算年份) : '',
          financeNo: row.报销编号 ? String(row.报销编号).trim() : '',
          monthBudget: new Array(12).fill('').map((item, index) => {
            const budget = row?.[`${index + 1}月`]
            return {
              month: `${index + 1}月`,
              budget: isNaN(budget) ? '' : String(budget)
            }
          }),
          operationRemark: row.操作说明 ? String(row.操作说明).trim() : ''
        }
      })
      importIds.current = { data: [] }
      setFileName(file.name || '')
      budgetPreCheck(result)
    } else {
      setPreChecking(false)
      // notification.destroy()
      notification.error({
        message: '日常预算导入错误',
        description: validateRes.message,
        duration: 0
      })
      // message.error(validateRes.message)
    }
  }

  const onExportClick = () => {
    setExporting(true)
    const dataResult = getData(tableSource)
    generateExport(
      dataResult,
      `日常预算导入_${new Date().getTime()}`,
      null,
      (err) => {
        setExporting(false)
        if (err) {
          message.error('导出失败')
        } else {
          message.success('EXCEL下载成功，请查收')
        }
      }
    )
  }

  const onTemplateClick = () => {
    const tagA = document.createElement('a')
    tagA.href =
      'https://yhl-upright-admin-upload.oss-cn-hangzhou.aliyuncs.com/admin/HS%E9%A2%84%E7%AE%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88/HS%E6%97%A5%E5%B8%B8%E9%A2%84%E7%AE%97%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    tagA.setAttribute('target', '_blank')
    const id = `template-hansoh-budget` + new Date().getTime()
    tagA.setAttribute('id', id)
    if (!document.getElementById(id)) {
      document.body.appendChild(tagA)
    }
    tagA.click()
    document.body.removeChild(tagA)
  }

  return (
    <Spin
      spinning={preChecking || importing}
      tip={`正在${
        importing ? '上传' : '检查'
      }日常预算信息，请不要关闭此页面...`}
    >
      <Card bordered={false}>
        <Space wrap={true} size={12}>
          <div>日常预算导入</div>
          <Input
            disabled
            placeholder={fileName || '上传excel，预算导入文件'}
            style={{
              backgroundColor: '#fff',
              width: '300px',
              marginRight: '50px'
            }}
          />

          <ResolveExcel
            buttonText='文件上传'
            buttonType='primary'
            buttonIcon={<CloudUploadOutlined />}
            onUploaded={(file) => {
              onUploaded(file)
            }}
            onStart={() => {
              setPreChecking(true)
            }}
          />

          <Button
            ghost
            type='primary'
            icon={<DownloadOutlined />}
            onClick={onTemplateClick}
          >
            模版下载
          </Button>
        </Space>
      </Card>
      <Card
        bordered={false}
        extra={
          <Space size={12}>
            <Button
              type='primary'
              disabled={!excelCorrect || importing}
              onClick={() => {
                setImporting(true)
                importBudget(excelData)
              }}
            >
              确认导入
            </Button>
            <Button
              type='primary'
              loading={exporting}
              disabled={!tableSource.length || exporting}
              onClick={onExportClick}
            >
              报表导出
            </Button>
          </Space>
        }
      >
        {excelCorrect ? (
          <Result
            status='success'
            icon={<CheckCircleOutlined />}
            subTitle={
              <div style={{ fontSize: 16 }}>
                已查验上传信息正确，继续
                <Button
                  type='link'
                  style={{ fontSize: 16, padding: 5 }}
                  onClick={() => {
                    setImporting(true)
                    importBudget(excelData)
                  }}
                >
                  确认导入
                </Button>
              </div>
            }
          />
        ) : tableSource?.length ? (
          <Table
            bordered
            dataSource={tableSource}
            columns={columns}
            rowKey={'customizedIndex'}
          />
        ) : (
          <Result
            style={{ background: '#f5f6fa' }}
            icon={<InfoCircleOutlined style={{ color: '#cccccc' }} />}
            subTitle={
              <div style={{ fontSize: 16 }}>暂无文件，请先上传文件</div>
            }
          />
        )}
      </Card>
    </Spin>
  )
}

export default InPlanBudgetImport
