import React, { useState, useEffect, Fragment } from 'react'
import {
  Skeleton,
  Card,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Row,
  Col,
  Modal,
  Image,
  Affix,
  Divider
} from 'antd'
import QRCode from 'qrcode'
import {
  useSalesClaimFormByClaimFormCodeQuery,
  useSalesClaimFormReturnMutation,
  useSalesClaimFormReturnPreReviewMutation,
  useSalesClaimFormApprovedMutation,
  SalesClaimStatus,
  useHansohSalesEditOrderMutation,
  SalesOrderStatus
} from '../../graphql'
import { useParams } from 'react-router-dom'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import { getMessage } from '../../lib/getMessage'
import ApprovalConfirmModal from './approvalConfirmModal'
import ApprovalRetreatModal from './approvalRetreatModal'
import ApprovalRetreatPreReviewModal from './approvalRetreatPreReviewModal'
import BillInfo from './billInfo'
import { HR_PRODUCTION, isMobileClient } from '../../constant/user'
import {
  formatAmountStr,
  getClaimFormStatus,
  getFormPaymentTypeText,
  getSalesBudgetChannelText
} from '../../constant/sales'
import CopiableTag from '../../components/CopiableTag'
import dayjs from 'dayjs'
import _ from 'lodash'
import SalesApprovalProcess from '../../components/SalesApprovalProcess'
import faultSvg from '../../assets/fault.svg'
import SalesStatusTag from '../../components/SalesStatusTag'
import './index.less'
import DeductionAmountModal from './deductionAmountModal'
import OperationLog from '../../components/OperationLog'

const ConsumptionReimburseDetail: QCPage = () => {
  const { claimFormCode }: { claimFormCode: string } = useParams()
  const isMobile = isMobileClient()
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo
  const [isShowRetreatPreReviewModal, setIsShowRetreatPreReviewModal] =
    useState(false)
  const [isShowRetreatModal, setIsShowRetreatModal] = useState(false)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
  const [curSalesOrder, setCurSalesOrder] = useState<any>(undefined)
  const [qrCodeImage, setQRCodeImage] = useState('')

  const [{ data, fetching, error }, refetchDetail] =
    useSalesClaimFormByClaimFormCodeQuery({
      variables: {
        claimFormCode
      },
      pause: !claimFormCode
    })

  const [{ fetching: editFetching }, editOrder] =
    useHansohSalesEditOrderMutation()

  const [{ fetching: retreatPreReviewFetching }, claimFormRetreatPreReview] =
    useSalesClaimFormReturnPreReviewMutation()

  const [{ fetching: retreatFetching }, claimFormRetreat] =
    useSalesClaimFormReturnMutation()

  const [{ fetching: confirmFetching }, claimFormConfirm] =
    useSalesClaimFormApprovedMutation()

  const onEditFinancialDeductionAmount = async (
    values: any,
    callBack?: () => void
  ) => {
    const { data: editData, error } = await editOrder({
      order: {
        id: curSalesOrder?.id!,
        financialDeductionAmount: values.financialDeductionAmount + '',
        deductionReason: values.reason
      }
    })
    if (editData?.result) {
      message.success('核减成功', 1, () => {
        callBack && callBack()
        setCurSalesOrder(undefined)
        refetchDetail()
      })
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('核减失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalRetreatPreReview = async (
    values: any,
    callBack?: () => void
  ) => {
    const { data: retreatData, error } = await claimFormRetreatPreReview({
      formId: data?.result?.[0]?.claimForm?.id!,
      reason: values?.reason
    })
    if (retreatData?.result) {
      message.success('退至初审成功', 1, () => {
        callBack && callBack()
        setIsShowRetreatPreReviewModal(false)
        refetchDetail()
        if (window.history.length === 1) {
          window.close()
        } else {
          window.history.back()
        }
      })
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退至初审失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalRetreat = async (values: any, callBack?: () => void) => {
    const orderIds: string[] = []
    _.forEach(values, function (value, key) {
      if (key.includes('orderId-')) {
        orderIds.push(_.replace(key, 'orderId-', ''))
      }
    })
    const params = orderIds.map((orderId) => {
      return {
        orderId,
        reason: values[`reason-${orderId}`]
      }
    })
    const { data: retreatData, error } = await claimFormRetreat({
      formId: data?.result?.[0]?.claimForm?.id!,
      // orderIds: values.orderIds,
      reason: values.reason,
      rejectOrderParam: params
    })
    if (retreatData?.result) {
      message.success('退回成功', 1, () => {
        callBack && callBack()
        setIsShowRetreatModal(false)
        refetchDetail()
        if (window.history.length === 1) {
          window.close()
        } else {
          window.history.back()
        }
      })
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退回失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalConfirm = async (values: any, callBack?: () => void) => {
    const orderIds: string[] = []
    _.forEach(values, function (value, key) {
      if (key.includes('orderId-')) {
        orderIds.push(_.replace(key, 'orderId-', ''))
      }
    })
    const params = orderIds.map((orderId) => {
      return {
        orderId,
        reason: values[`reason-${orderId}`]
      }
    })
    const { data: confirmData, error } = await claimFormConfirm({
      id: data?.result?.[0]?.claimForm?.id!,
      reason: values?.reason,
      orderParam: params
    })
    if (confirmData?.result) {
      message.success('审核通过', 1, () => {
        callBack && callBack()
        setIsShowConfirmModal(false)
        refetchDetail()
        if (window.history.length === 1) {
          window.close()
        } else {
          window.history.back()
        }
      })
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('审核失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const qrCodeImageUrl = async (claimCode: string) => {
    const qrCodeImage = await QRCode.toDataURL(claimCode)
    setQRCodeImage(qrCodeImage)
  }

  useEffect(() => {
    if (data?.result?.[0]?.claimForm?.claimFormCode) {
      qrCodeImageUrl(data?.result?.[0]?.claimForm?.claimFormCode)
    }
  }, [data])

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  const formData = data?.result?.[0]
  const claimForm = formData?.claimForm
  const properties = formData?.properties || []
  const reviewingReason = properties.find((x) => x.type === 'REVIEWING')?.value
  const reviewedReason = properties.find((x) => x.type === 'REVIEWED')?.value
  const logs = formData?.logs
  const orders = formData?.orders || []
  const orderTotalClaimAmount = orders.reduce(
    (total, order) => (total += Number(order.salesOrder?.claimAmount) || 0),
    0
  )
  const financialDeductionTotalAmount = orders.reduce(
    (total, order) =>
      (total += Number(order.salesOrder?.financialDeductionAmount) || 0),
    0
  )
  const isPreReviewAuth = userStore.hasAuth('uplatform_hs_financial_pre_review')
  const isReviewAuth = userStore.hasAuth('uplatform_hs_financial_review')
  const isHansohReview =
    !isQC &&
    ((claimForm?.status === SalesClaimStatus.Prereviewing && isPreReviewAuth) ||
      (claimForm?.status === SalesClaimStatus.Reviewing && isReviewAuth))
  const financialDeductionClaimAmount = Number(
    (orderTotalClaimAmount - financialDeductionTotalAmount).toFixed(2)
  )

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Card
        className='dashedBorderHead'
        title={
          <Row
            align='middle'
            className={isMobile ? 'mobileCardTitle' : ''}
            style={{ color: '#181818' }}
          >
            {claimForm?.claimFormName}
            <CopiableTag title='报销单号' text={claimForm?.claimFormCode!} />
            {isMobile && (
              <span style={{ color: '#181818' }}>
                总金额：
                <span style={{ color: '#F76060' }}>
                  ¥{financialDeductionClaimAmount || '-'}
                </span>
              </span>
            )}
          </Row>
        }
        loading={fetching}
        extra={
          <>
            {!isMobile && (
              <span style={{ color: '#181818' }}>
                总金额：
                <span style={{ color: '#F76060' }}>
                  ¥{financialDeductionClaimAmount || '-'}
                </span>
              </span>
            )}
            {claimForm?.status && (
              <SalesStatusTag
                containerStyle={{ right: isMobile ? -20 : -32 }}
                tagColor={
                  claimForm?.status === SalesClaimStatus.Init
                    ? '#5581FA'
                    : [
                        SalesClaimStatus.ApproveReturn,
                        SalesClaimStatus.Return
                      ].includes(claimForm?.status)
                    ? '#F76060'
                    : [
                        SalesClaimStatus.Approving,
                        SalesClaimStatus.Prereviewing,
                        SalesClaimStatus.Reviewing
                      ].includes(claimForm?.status)
                    ? '#EE7700'
                    : claimForm?.status === SalesClaimStatus.Reviewed
                    ? '#51A04C'
                    : ''
                }
                text={getClaimFormStatus(claimForm?.status).text}
                triangleClassName={
                  claimForm?.status === SalesClaimStatus.Init
                    ? 'triangleProgress'
                    : [
                        SalesClaimStatus.ApproveReturn,
                        SalesClaimStatus.Return
                      ].includes(claimForm?.status)
                    ? 'triangleReturn'
                    : [
                        SalesClaimStatus.Approving,
                        SalesClaimStatus.Prereviewing,
                        SalesClaimStatus.Reviewing
                      ].includes(claimForm?.status)
                    ? 'triangleWait'
                    : claimForm?.status === SalesClaimStatus.Reviewed
                    ? 'trianglePass'
                    : ''
                }
              />
            )}
          </>
        }
      >
        <Row align='middle'>
          <Col style={{ flex: 1 }}>
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              <Descriptions.Item key='accountName' label='申请人'>
                {claimForm?.accountName}
              </Descriptions.Item>
              <Descriptions.Item key='accountMobile' label='申请人手机号'>
                {claimForm?.accountMobile}
              </Descriptions.Item>
              <Descriptions.Item key='staffId' label='申请人报销编号'>
                {claimForm?.accountInfo?.staffId}
              </Descriptions.Item>
              <Descriptions.Item key='business' label='业务部'>
                {claimForm?.business}
              </Descriptions.Item>
              <Descriptions.Item key='region' label='大区'>
                {claimForm?.region}
              </Descriptions.Item>
              <Descriptions.Item key='area' label='办事处'>
                {claimForm?.area}
              </Descriptions.Item>
              <Descriptions.Item key='budgetChannel' label='预算渠道'>
                {getSalesBudgetChannelText(claimForm?.budgetChannel!)}
              </Descriptions.Item>
              <Descriptions.Item key='submitTime' label='报销单提交时间'>
                {claimForm?.submitTime
                  ? dayjs(claimForm?.submitTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='approvedTime' label='报销单审批时间'>
                {claimForm?.approvedTime
                  ? dayjs(claimForm?.approvedTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='preReviewedTime' label='财务初审时间'>
                {claimForm?.preReviewedTime
                  ? dayjs(claimForm?.preReviewedTime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='reviewedTime' label='财务复核时间'>
                {claimForm?.reviewedTime
                  ? dayjs(claimForm?.reviewedTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='orderNumber' label='消费订单数量'>
                {orders?.length}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              {[
                SalesClaimStatus.Prereviewing,
                SalesClaimStatus.Return
              ].includes(claimForm?.status!) &&
                claimForm?.reason && (
                  <Descriptions.Item
                    span={3}
                    labelStyle={{ color: '#F76060' }}
                    key='reason'
                    label='财务审核退回'
                  >
                    {claimForm?.reason || '-'}
                  </Descriptions.Item>
                )}
              {[SalesClaimStatus.Reviewing, SalesClaimStatus.Reviewed].includes(
                claimForm?.status!
              ) && (
                <>
                  <Descriptions.Item
                    span={3}
                    labelStyle={{ color: '#F76060' }}
                    key='reviewingReason'
                    label='初审通过理由'
                  >
                    {reviewingReason || '-'}
                  </Descriptions.Item>
                  {claimForm?.status === SalesClaimStatus.Reviewed && (
                    <Descriptions.Item
                      span={3}
                      labelStyle={{ color: '#F76060' }}
                      key='reviewedReason'
                      label='复核通过理由'
                    >
                      {reviewedReason || '-'}
                    </Descriptions.Item>
                  )}
                </>
              )}
            </Descriptions>
          </Col>
          <Col>
            {qrCodeImage && (
              <Image
                width={isMobile ? 60 : 116}
                height={isMobile ? 60 : 116}
                src={qrCodeImage}
              />
            )}
          </Col>
          <Divider dashed />
        </Row>

        {/* 审批信息 */}
        <SalesApprovalProcess
          approvalFlows={formData?.approvalFlows}
          isShow
          headStyle={{ padding: 0, border: 'none', color: '#888' }}
          bodyStyle={{ padding: 0 }}
          titleKey='报销'
        />
      </Card>

      {orders?.map((order, index) => {
        const salesOrder = order.salesOrder || {}
        const properties = order.properties || []
        const billInfo = properties?.filter((x) => x.type === 'receipt')
        const invoices = order.invoices || []
        const costItem = properties?.find(
          (i) => i.type === 'costItem' && i.key === 'costItemKey'
        )?.value
        const costItemTitle = JSON.parse(costItem || '{}')?.title
        const paymentInfo =
          properties?.find(
            (x) => x.type === 'paymentInfo' && x.key === 'paid'
          ) || {}
        const paidInfo = JSON.parse(paymentInfo?.value || '{}')
        const externalPaymentInfo = JSON.parse(
          paidInfo.externalPaymentInfo || '{}'
        )
        const reviewingReason = properties.find(
          (x) => x.type === 'REVIEWING'
        )?.value
        const reviewedReason = properties.find(
          (x) => x.type === 'REVIEWED'
        )?.value
        const isFinalCorrection =
          salesOrder?.finalCorrection && salesOrder?.finalCorrection !== '否'
        return (
          <Card
            key={salesOrder?.id}
            title={`订单信息（${index + 1}）`}
            headStyle={{ backgroundColor: '#E9EBF3' }}
            className='consumptionReimburseOrderDetailCard'
          >
            <Row align='middle' style={{ marginRight: 20 }}>
              <Col span={22}>
                <Descriptions
                  column={{ xs: 1, sm: 2, md: 3 }}
                  labelStyle={{ fontWeight: 'bold', color: '#181818' }}
                  contentStyle={{ color: '#888' }}
                >
                  <Descriptions.Item key='id' label='消费单号'>
                    {salesOrder.id}
                  </Descriptions.Item>
                  <Descriptions.Item key='usageScenario' label='消费分类'>
                    {order?.salesOrderBusinessCategory?.title || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='formCategory' label='订单类型'>
                    {order?.formCategoryInfo?.title || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='consumptionCategory' label='消费形式'>
                    {order?.consumptionCategory?.title}
                  </Descriptions.Item>
                  <Descriptions.Item key='targetName' label='商家名称'>
                    {salesOrder?.targetName || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    key='consumptionAmount'
                    label='预计消费金额'
                  >
                    {salesOrder?.consumptionAmount || '-'}
                    {` / `}
                    {salesOrder?.peopleNumber
                      ? `${salesOrder?.peopleNumber}人`
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='deductionAmount' label='核减预计金额'>
                    {salesOrder.deductionAmount || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    key='actualConsumptionAmount'
                    label='确认消费金额'
                  >
                    {salesOrder?.actualConsumptionAmount || '-'}
                    {` / `}
                    {salesOrder?.actualPeopleNumber
                      ? `${salesOrder?.actualPeopleNumber}人`
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='targetOccurTime' label='确认消费时间'>
                    {salesOrder?.targetOccurTime
                      ? dayjs(salesOrder?.targetOccurTime).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='paymentType' label='支付方式'>
                    {getFormPaymentTypeText(salesOrder?.paymentType!)}
                  </Descriptions.Item>
                  <Descriptions.Item key='total_amount' label='支付金额'>
                    {externalPaymentInfo?.total_amount || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='subject' label='支付商家名称'>
                    {externalPaymentInfo?.subject || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='gmt_payment' label='支付回调时间'>
                    {externalPaymentInfo?.gmt_payment
                      ? dayjs(externalPaymentInfo?.gmt_payment).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item key='claimAmount' label='报销金额'>
                    {salesOrder.claimAmount || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    key='financialDeductionAmount'
                    label='核减报销金额'
                  >
                    {salesOrder.financialDeductionAmount || 0}
                    {isHansohReview && (
                      <Button
                        type='primary'
                        style={{
                          background: 'rgb(240, 135, 28)',
                          borderColor: 'rgb(240, 135, 28)',
                          marginLeft: 15
                        }}
                        size='small'
                        onClick={() => setCurSalesOrder(salesOrder)}
                      >
                        核减报销金额
                      </Button>
                    )}
                  </Descriptions.Item>
                  {salesOrder.deductionReason && (
                    <Descriptions.Item
                      key='deductionReason'
                      label='核减报销原因'
                    >
                      {salesOrder.deductionReason || '-'}
                    </Descriptions.Item>
                  )}
                  {salesOrder?.budgetChannel !== 5 && (
                    <Descriptions.Item key='oaNumber' label='审批编号'>
                      {salesOrder?.oaNumber || '-'}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    key='finalCorrection'
                    label='是否补单'
                    labelStyle={{
                      color: isFinalCorrection ? '#EE7700' : '#181818'
                    }}
                    contentStyle={{
                      color: isFinalCorrection ? '#EE7700' : '#181818',
                      fontWeight: 'bold'
                    }}
                  >
                    {salesOrder?.finalCorrection || '-'}
                  </Descriptions.Item>
                  {isFinalCorrection && (
                    <Descriptions.Item
                      key='finalCorrectionReason'
                      label='补单原因'
                      labelStyle={{ color: '#EE7700' }}
                      contentStyle={{ color: '#EE7700', fontWeight: 'bold' }}
                    >
                      {salesOrder?.finalCorrectionReason || '-'}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                <Descriptions
                  column={{ xs: 1, sm: 2, md: 3 }}
                  labelStyle={{ fontWeight: 'bold', color: '#181818' }}
                  contentStyle={{ color: '#888' }}
                >
                  {[
                    SalesOrderStatus.AuditFailed,
                    SalesOrderStatus.FinancialReturn
                  ].includes(salesOrder?.status!) && (
                    <Descriptions.Item
                      span={3}
                      labelStyle={{ color: '#F76060' }}
                      key='reason'
                      label={
                        salesOrder?.reason!.lastIndexOf('财务退回原因:') < 0
                          ? '财务审核退回'
                          : null
                      }
                      contentStyle={{ color: '#F76060', fontWeight: 'bold' }}
                    >
                      {salesOrder?.reason || '-'}
                    </Descriptions.Item>
                  )}
                  {[
                    SalesClaimStatus.Reviewing,
                    SalesClaimStatus.Reviewed
                  ].includes(claimForm?.status!) && (
                    <>
                      <Descriptions.Item
                        span={3}
                        labelStyle={{ color: '#F76060' }}
                        key='reviewingReason'
                        label='初审通过理由'
                        contentStyle={{ color: '#F76060', fontWeight: 'bold' }}
                      >
                        {reviewingReason || '-'}
                      </Descriptions.Item>
                      {claimForm?.status === SalesClaimStatus.Reviewed && (
                        <Descriptions.Item
                          span={3}
                          labelStyle={{ color: '#F76060' }}
                          key='reviewedReason'
                          label='复核通过理由'
                          contentStyle={{
                            color: '#F76060',
                            fontWeight: 'bold'
                          }}
                        >
                          {reviewedReason || '-'}
                        </Descriptions.Item>
                      )}
                    </>
                  )}
                </Descriptions>
              </Col>
              <Col span={24}>
                {/* 票据信息 */}
                {(billInfo?.length > 0 || invoices.length > 0) && (
                  <BillInfo
                    data={billInfo}
                    invoices={invoices}
                    loading={fetching}
                  />
                )}
              </Col>
              <Divider dashed />
            </Row>

            {/* 审批信息 */}
            <SalesApprovalProcess
              approvalFlows={order.approvalFlows}
              isShow
              headStyle={{ padding: 0, border: 'none', color: '#888' }}
              bodyStyle={{ padding: 0 }}
              titleKey='订单'
            />
          </Card>
        )
      })}

      {/* 日志信息 */}
      <OperationLog logs={logs} />

      {curSalesOrder?.id ? (
        <DeductionAmountModal
          salesOrder={curSalesOrder}
          onCancel={() => {
            setCurSalesOrder(undefined)
          }}
          onSubmit={onEditFinancialDeductionAmount}
        />
      ) : null}

      {/* 审批 退至初审 */}
      {isShowRetreatPreReviewModal ? (
        <ApprovalRetreatPreReviewModal
          onCancel={() => {
            setIsShowRetreatPreReviewModal(false)
          }}
          onSubmit={startApprovalRetreatPreReview}
        />
      ) : null}

      {/* 审批 退回 */}
      {isShowRetreatModal ? (
        <ApprovalRetreatModal
          title={
            claimForm?.status === SalesClaimStatus.Reviewing ? '复核' : '初审'
          }
          orders={orders}
          onCancel={() => {
            setIsShowRetreatModal(false)
          }}
          onSubmit={startApprovalRetreat}
        />
      ) : null}

      {/* 审批 通过 */}
      {isShowConfirmModal ? (
        <ApprovalConfirmModal
          title={
            claimForm?.status === SalesClaimStatus.Reviewing ? '复核' : '初审'
          }
          orders={orders}
          onCancel={() => {
            setIsShowConfirmModal(false)
          }}
          onSubmit={startApprovalConfirm}
        />
      ) : null}

      {!isQC &&
        ((claimForm?.status === SalesClaimStatus.Prereviewing &&
          isPreReviewAuth) ||
          (claimForm?.status === SalesClaimStatus.Reviewing &&
            isReviewAuth)) && (
          <Affix offsetBottom={0} className='bottomAffix'>
            <Row
              justify='end'
              style={{
                padding: '35px 15px 35px 0'
              }}
            >
              <Space>
                {claimForm?.status === SalesClaimStatus.Reviewing &&
                  isReviewAuth && (
                    <Button
                      type='primary'
                      style={{
                        width: 100,
                        background: '#FF9847',
                        borderColor: '#FF9847'
                      }}
                      disabled={retreatPreReviewFetching || !orders?.length}
                      onClick={() => {
                        setIsShowRetreatPreReviewModal(true)
                      }}
                    >
                      退至初审
                    </Button>
                  )}
                <Button
                  type='primary'
                  danger
                  style={{ width: 100 }}
                  disabled={retreatFetching || !orders?.length}
                  onClick={() => {
                    setIsShowRetreatModal(true)
                  }}
                >
                  退回
                </Button>
                <Button
                  type='primary'
                  style={{ width: 100 }}
                  disabled={confirmFetching || !orders?.length}
                  onClick={() => {
                    setIsShowConfirmModal(true)
                  }}
                >
                  {`${
                    claimForm?.status === SalesClaimStatus.Prereviewing
                      ? '初审'
                      : '复核'
                  }通过`}
                </Button>
              </Space>
            </Row>
          </Affix>
        )}
    </Space>
  )
}

ConsumptionReimburseDetail.pageTitle = '提报单详情'

export default ConsumptionReimburseDetail
