import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { HrMeetingDetailQuery, Maybe } from '../../graphql'
import { HR_PRODUCTION } from '../../constant/user'

export const useMeetingBaseInfo = (
  detail: HrMeetingDetailQuery['detail'],
  production: string
) => {
  const fields = !detail
    ? []
    : [
        {
          label: '会议类型',
          content: detail.meetingType || ''
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        {
          label: '申请人电话',
          content: detail.base?.owner?.companyMobile
        },
        ...(production === HR_PRODUCTION.cttq &&
        detail.base?.belongProduct?.productName
          ? [
              {
                label: '产品线',
                content: detail.base?.belongProduct?.productName
              }
            ]
          : [])
      ]

  return fields
}

export const useProjectBaseInfo = (detail: HrMeetingDetailQuery['detail']) => {
  const fields = !detail
    ? []
    : [
        {
          label: '活动城市',
          content: detail.city?.name
        },
        {
          label: '参与单位',
          content: detail.hospital
        },
        {
          label: '活动开始时间',
          content: dayjs(detail.base?.startTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动结束时间',
          content: dayjs(detail.base?.endTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动介绍',
          content: detail.base?.desc
        },
        {
          label: '创建时间',
          content: dayjs(detail.base?.createTime!).format('YYYY-MM-DD')
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        {
          label: '申请人电话',
          content: detail.base?.owner?.companyMobile
        }
        // {
        //   label: '申请人省区',
        //   content: detail.base?.owner?.region?.name
        // }
      ]

  return fields
}

export const generateTotalObj = (carTotal: number, numTotal: number) => {
  if (!carTotal || carTotal === 0) {
    return {
      carTotal: 0,
      poTotal: 0,
      taxTotal: 0,
      totalAmount: 0,
      numTotal
    }
  }
  const poTotal = carTotal + numTotal * 3
  const taxTotal = (poTotal * 6) / 100

  return {
    carTotal,
    poTotal,
    taxTotal,
    totalAmount: poTotal + taxTotal,
    numTotal
  }
}
