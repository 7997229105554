import React, { FC, Fragment, useState } from 'react'
import { Card, Tag, Space, Image, Descriptions } from 'antd'
import faultSvg from '../../assets/fault.svg'

const BillInfo: FC<{
  data: any
  invoices?: any[]
  receiptTemplate?: any[]
  loading: boolean
}> = ({ data, invoices = [], receiptTemplate = [], loading }) => {
  const bill = data?.find((x: any) => x.key === 'bill')
  const billInfo = JSON.parse(bill?.value || '[]')
  const invoiceCertification = data?.find(
    (x: any) => x.key === 'invoiceCertification'
  )
  const invoiceCertificationInfo = JSON.parse(
    invoiceCertification?.value || '[]'
  )
  const ticket = data?.find((x: any) => x.key === 'ticket')
  const ticketInfo = JSON.parse(ticket?.value || '[]')
  const voucherTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'voucher'
  )
  const ticketTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'ticket'
  )
  const invoiceTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'invoice'
  )
  const isCheckInvoices = invoices.find(
    (x) => !x.checkOcr || !x.checkPassed || !x.checkRepeat || !x.checkTitle
  )

  return (
    <Card
      loading={loading}
      title='票据信息'
      bordered={false}
      headStyle={{ padding: 0, border: 'none', color: '#888' }}
      bodyStyle={{ padding: 0 }}
    >
      <Descriptions
        column={{ xs: 1, sm: 2 }}
        labelStyle={{ fontWeight: 'bold', color: '#181818' }}
      >
        <Descriptions.Item key='billInfo' label='支付凭证'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {billInfo?.length > 0 ? (
                billInfo?.map((x: any, index: number) => {
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.url || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='billInfoTemplate' label='凭证模版'>
          <Image.PreviewGroup>
            <Space>
              {voucherTemplate?.length > 0 ? (
                voucherTemplate?.map((x: any, index: number) => {
                  if (index > 0) return null
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.receiptUrl || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='ticketInfo' label='消费清单'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {ticketInfo?.length > 0 ? (
                ticketInfo?.map((x: any, index: number) => {
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.url || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='ticketInfoTemplate' label='清单模版'>
          <Image.PreviewGroup>
            <Space>
              {ticketTemplate?.length > 0 ? (
                ticketTemplate?.map((x: any, index: number) => {
                  if (index > 0) return null
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.receiptUrl || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='invoiceInfo' label='发票信息'>
          <Image.PreviewGroup>
            <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
              {invoices?.length ? (
                invoices?.map((x: any, index: number) => {
                  return (
                    <div key={index}>
                      <Image
                        width={100}
                        height={100}
                        src={x.invoiceUrl || faultSvg}
                      />
                      <div className='checkContainer'>
                        <Tag
                          color={x.checkOcr ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkOcr ? '识别验证通过' : '识别验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkPassed ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkPassed ? '验真验证通过' : '验真验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkTitle ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkTitle ? '抬头验证通过' : '抬头验证不通过'}
                        </Tag>
                        <Tag
                          color={x.checkRepeat ? 'success' : 'error'}
                          className='checkTag'
                        >
                          {x.checkRepeat ? '重复验证通过' : '重复验证不通过'}
                        </Tag>
                      </div>
                    </div>
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item key='invoiceInfoTemplate' label='发票模版'>
          <Image.PreviewGroup>
            <Space>
              {invoiceTemplate?.length > 0 ? (
                invoiceTemplate?.map((x: any, index: number) => {
                  if (index > 0) return null
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.receiptUrl || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        {isCheckInvoices && (
          <Descriptions.Item
            span={2}
            key='invoiceCertificationInfo'
            label='发票查验'
          >
            <Image.PreviewGroup>
              <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                {invoiceCertificationInfo?.length > 0 ? (
                  invoiceCertificationInfo?.map((x: any, index: number) => {
                    return (
                      <Image
                        key={index}
                        width={100}
                        height={100}
                        src={x.url || faultSvg}
                      />
                    )
                  })
                ) : (
                  <Image width={100} height={100} src={faultSvg} />
                )}
              </Space>
            </Image.PreviewGroup>
          </Descriptions.Item>
        )}
      </Descriptions>
    </Card>
  )
}

export default BillInfo
