export const CONTRACT_STATUS = { COMPLETE: '已完成' }

export const DOCTOR_INVITE_STATUS: {
  [key: string]: { name: string; value: string }
} = {
  COMPLETE: {
    name: '已完成',
    value: 'COMPLETE'
  },
  SENT: {
    name: '已邀请',
    value: 'SENT'
  },
  UNINVITE: {
    name: '待邀请',
    value: 'UNINVITE'
  },
  RECEIVED: {
    name: '已邀请',
    value: 'RECEIVED'
  }
}

export const DOCTOR_INVITE_STATUS_MAP = [
  {
    name: '待邀请',
    value: 'UNINVITE'
  },
  {
    name: '已邀请',
    value: 'SENT'
  },
  {
    name: '已邀请',
    value: 'RECEIVED'
  },
  {
    name: '已完成',
    value: 'COMPLETE'
  }
]

export const ATTENDER_BILL_STATUS: { [key: string]: string } = {
  BILL_STATUS_NONE: '未开票',
  BILL_STATUS_INVOICED: '已开票'
}

// 结算状态：目前用到了这几种
export const SETTLEMENT_STATUS_MAP: { [key: string]: string } = {
  succeed: '打款成功',
  pending: '打款中',
  failed: '打款失败',
  unpaid: '等待打款',
  delay: '延迟打款'
}

export const INVITE_STATUS_MAP: {
  [key: string]: string
} = {
  RECEIVED: '已邀请',
  COMPLETE: '已完成',
  OVERDUE: '逾期未签约',
  SENT: '已邀请'
}

export const REVIEW_STATUS_MAP: {
  [key: string]: { text: string; id: number }
} = {
  WAIT_REVIEW: { text: '已邀请待点评', id: 1 },
  REJECT: { text: '退回修改', id: 2 },
  WAIT_SCORE: { text: '待评审', id: 3 },
  HAS_SCORE: { text: '已评审', id: 4 },
  SETTLEMENT_CONFIRMING: { text: '结算确认中', id: 5 },
  IN_SETTLEMENT: { text: '结算中', id: 6 },
  COMPLETE: { text: '已完成', id: 7 }
}

export const REVIEW_LEVEL_PROPS: {
  [key: string]: {
    text: string
    id: number
    bgColor: string
    textColor: string
  }
} = {
  REJECT: { text: '修改', id: 1, bgColor: '#eaf0fc', textColor: '#1890ff' },
  GOOD: {
    text: '良好',
    id: 2,
    bgColor: 'rgba(81, 160, 76, 0.1)',
    textColor: '#51A04C'
  },
  EXCELLENT: {
    text: '优秀',
    id: 3,
    bgColor: 'rgba(238, 119, 0, 0.1)',
    textColor: '#EE7700'
  }
}

export const BUDGET_TYPE_MAP = [
  { text: '默认', value: 0 },
  { text: '个人预算', value: 1 },
  { text: '流程预算', value: 2 }
]

export const APPROVE_WAIT_SORT_FIELD: {
  [key: string]: string
} = {
  approvalTime: '审批时间',
  submitTime: '提交时间',
  startTime: '开始时间',
  endTime: '结束时间'
}

export const QCXS_APPROVE_WAIT_SORT_FIELD: {
  [key: string]: string
} = {
  approvalTime: '审批时间',
  submitTime: '提交时间',
  startTime: '开始时间',
  endTime: '结束时间'
}

export enum BudgetType {
  OWNER = 0,
  PROCESS = 1
}

export const MEETING_STATUS_MAP: {
  [key: string]: { name: string; value: string }
} = {
  MEETING_IN_PROGRESS: {
    name: '执行中',
    value: 'MEETING_IN_PROGRESS'
  },
  MEETING_IN_APPROVING: {
    name: '审批中',
    value: 'MEETING_IN_APPROVING'
  },
  MEETING_IN_SETTLEMENT: {
    name: '结算中',
    value: 'MEETING_IN_SETTLEMENT'
  },
  MEETING_RETREATED: {
    name: '审批退回',
    value: 'MEETING_RETREATED'
  },
  MEETING_FINISHED: {
    name: '已完成',
    value: 'MEETING_FINISHED'
  },
  MEETING_SETTLEMENT_WITHOUT_TAX: {
    name: '已结算',
    value: 'MEETING_SETTLEMENT_WITHOUT_TAX'
  },
  MEETING_SETTLEMENT_CONFIRMING: {
    name: '结算确认中',
    value: 'MEETING_SETTLEMENT_CONFIRMING'
  }
}

export const BUDGET_TYPES = [
  {
    value: 5,
    name: '学术'
  },
  {
    value: 3,
    name: '日常'
  },
  {
    value: 2,
    name: '成本中心'
  }
  // {
  //   value: 1,
  //   name: '项目',
  // },
  // {
  //   value: 4,
  //   name: '专项',
  // },
]
