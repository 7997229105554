import React, { useState, useEffect, Fragment, useCallback } from 'react'
import {
  Skeleton,
  Card,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Row,
  Col,
  Modal,
  Image,
  Affix,
  Form
} from 'antd'
import {
  useSalesOrderByIdQuery,
  SalesOrderStatus,
  useSalesOrderBillReturnMutation,
  useSalesOrderBillApprovedMutation
} from '../../graphql'
import { Link, useParams } from 'react-router-dom'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import { getMessage } from '../../lib/getMessage'
import ApprovalConfirmModal from './approvalConfirmModal'
import ApprovalRetreatModal from './approvalRetreatModal'
import BillInfo from './billInfo'
import dayjs from 'dayjs'
import { HR_PRODUCTION, isMobileClient } from '../../constant/user'
import {
  getFormPaymentTypeText,
  getSalesOrderStatus
} from '../../constant/sales'
import OperationLog from '../../components/OperationLog'
import CopiableTag from '../../components/CopiableTag'
import SalesStatusTag from '../../components/SalesStatusTag'
import './index.less'

const ConsumptionOrderBillApproveDetail: QCPage = () => {
  const { id }: { id: string } = useParams()
  const userStore = useUserStore()
  const isMobile = isMobileClient()
  const [isShowRetreatModal, setIsShowRetreatModal] = useState(false)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)
  const [form] = Form.useForm()

  const [{ data, fetching, error }, refetchOrderDetail] =
    useSalesOrderByIdQuery({
      variables: {
        id
      },
      pause: !id
    })

  const [{ fetching: retreatFetching }, orderRetreat] =
    useSalesOrderBillReturnMutation()

  const [{ fetching: confirmFetching }, orderConfirm] =
    useSalesOrderBillApprovedMutation()

  const startApprovalRetreat = async (values: any, callBack?: () => void) => {
    const formValues = form.getFieldsValue()
    console.log(formValues)
    const { data, error } = await orderRetreat({
      id,
      reason: values.reason,
      billReviewResult: JSON.stringify(formValues)
    })
    if (data?.result) {
      message.success('退回成功')
      callBack && callBack()
      setIsShowRetreatModal(false)
      refetchOrderDetail()
      window.history.back()
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退回失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalConfirm = async (values: any, callBack?: () => void) => {
    const formValues = form.getFieldsValue()
    console.log(formValues)
    const { data, error } = await orderConfirm({
      id,
      ticketDesc: values.ticket,
      invoiceDesc: values.invoice,
      reason: values.reason,
      billReviewResult: JSON.stringify(formValues)
    })
    if (data?.result) {
      message.success('审核通过')
      callBack && callBack()
      setIsShowConfirmModal(false)
      refetchOrderDetail()
      window.history.back()
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('审核失败:' + errorMsg)
      callBack && callBack()
    }
  }

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchOrderDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  const order = data?.result
  const orderDetail = order?.salesOrder
  const properties = order?.properties || []
  const logs = order?.logs
  const formCategoryInfo = order?.formCategoryInfo
  const consumptionCategory = order?.consumptionCategory
  const isCurrentApprover = order?.isCurrentApprover
  const auditRecord = order?.auditRecord || []
  const billInfo = properties?.filter((x) => x.type === 'receipt') || []
  const invoices = order?.invoices || []
  const receiptTemplate = order?.receiptTemplate || []
  const billReviewResultData = properties
    ?.filter((x) => x.type === 'BILLREVIEWRESULT')
    ?.sort((a, b) => Number(b.id) - Number(a.id))?.[0]
  const billReviewResult = JSON.parse(billReviewResultData?.value || '{}')
  const isSysApproved = !!properties?.find(
    (x) =>
      x.type === 'BILLACTIONLOG' && x.key === 'APPROVED' && x.value == 'sys'
  )
  const isFinalCorrection =
    orderDetail?.finalCorrection && orderDetail?.finalCorrection !== '否'

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Card
        className='dashedBorderHead consumptionOrderBillDetailCard'
        title={
          <Row align='middle' style={{ color: '#181818' }}>
            {orderDetail?.targetName || '消费订单_未选消费场所'}
            <CopiableTag title='消费订单号' text={id} />
          </Row>
        }
        loading={fetching}
        extra={
          <>
            {orderDetail?.status && (
              <SalesStatusTag
                containerStyle={{ right: isMobile ? -20 : -32 }}
                tagColor={
                  [
                    SalesOrderStatus.AuditFailed,
                    SalesOrderStatus.BookingReturn,
                    SalesOrderStatus.FinancialReturn
                  ].includes(orderDetail?.status)
                    ? '#F76060'
                    : orderDetail?.status === SalesOrderStatus.FormCancel
                    ? '#888'
                    : [
                        SalesOrderStatus.BookingProgress,
                        SalesOrderStatus.PrePaid,
                        SalesOrderStatus.PreVoucherUpload,
                        SalesOrderStatus.PreVoucherAudit,
                        SalesOrderStatus.FormReviewing
                      ].includes(orderDetail?.status)
                    ? '#EE7700'
                    : [
                        SalesOrderStatus.BookingSuccess,
                        SalesOrderStatus.VoucherUploadCompleted,
                        SalesOrderStatus.AuditCompleted,
                        SalesOrderStatus.ReviewedCompleted
                      ].includes(orderDetail?.status)
                    ? '#51A04C'
                    : ''
                }
                text={getSalesOrderStatus(orderDetail?.status).text}
                triangleClassName={
                  [
                    SalesOrderStatus.AuditFailed,
                    SalesOrderStatus.BookingReturn,
                    SalesOrderStatus.FinancialReturn
                  ].includes(orderDetail?.status)
                    ? 'triangleReturn'
                    : orderDetail?.status === SalesOrderStatus.FormCancel
                    ? 'triangleCancel'
                    : [
                        SalesOrderStatus.BookingProgress,
                        SalesOrderStatus.PrePaid,
                        SalesOrderStatus.PreVoucherUpload,
                        SalesOrderStatus.PreVoucherAudit,
                        SalesOrderStatus.FormReviewing
                      ].includes(orderDetail?.status)
                    ? 'triangleWait'
                    : [
                        SalesOrderStatus.BookingSuccess,
                        SalesOrderStatus.VoucherUploadCompleted,
                        SalesOrderStatus.AuditCompleted,
                        SalesOrderStatus.ReviewedCompleted
                      ].includes(orderDetail?.status)
                    ? 'trianglePass'
                    : ''
                }
              />
            )}
          </>
        }
      >
        <Row align='middle'>
          <Col span={24}>
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              <Descriptions.Item key='accountName' label='申请人'>
                {orderDetail?.accountName || '-'}
              </Descriptions.Item>
              <Descriptions.Item span={2} key='staffId' label='申请人工号'>
                {orderDetail?.accountInfo?.staffId || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='formCategory' label='订单类型'>
                {formCategoryInfo?.title || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                key='consumptionCategoryCode'
                label='消费形式'
              >
                {consumptionCategory?.title || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                key='actualConsumptionAmount'
                label='确认消费金额'
              >
                {orderDetail?.actualConsumptionAmount || '-'}
                {` / `}
                {orderDetail?.actualPeopleNumber
                  ? `${orderDetail?.actualPeopleNumber}人`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item span={2} key='paymentType' label='支付方式'>
                {getFormPaymentTypeText(orderDetail?.paymentType!)}
              </Descriptions.Item>
              <Descriptions.Item key='targetName' label='商家名称'>
                {orderDetail?.targetName || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                span={2}
                key='finalCorrection'
                label='是否补单'
                labelStyle={{
                  color: isFinalCorrection ? '#EE7700' : '#181818'
                }}
                contentStyle={{
                  color: isFinalCorrection ? '#EE7700' : '#181818',
                  fontWeight: 'bold'
                }}
              >
                {orderDetail?.finalCorrection || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                key='targetAddress'
                label='商户地址'
                span={isFinalCorrection ? 1 : 3}
              >
                {orderDetail?.targetAddress || '-'}
              </Descriptions.Item>
              {isFinalCorrection && (
                <Descriptions.Item
                  span={2}
                  key='finalCorrectionReason'
                  label='补单原因'
                  labelStyle={{ color: '#EE7700' }}
                  contentStyle={{ color: '#EE7700', fontWeight: 'bold' }}
                >
                  {orderDetail?.finalCorrectionReason || '-'}
                </Descriptions.Item>
              )}
              <Descriptions.Item key='submitTime' label='提交时间' span={3}>
                {orderDetail?.submitTime
                  ? dayjs(orderDetail?.submitTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            {/* 票据信息 */}
            {(billInfo.length > 0 || invoices.length > 0) && (
              <Form
                preserve={false}
                requiredMark={false}
                scrollToFirstError={true}
                form={form}
              >
                <BillInfo
                  data={billInfo}
                  invoices={invoices}
                  receiptTemplate={receiptTemplate}
                  loading={fetching}
                  isMobile={isMobile}
                  isDisabledForm={!isCurrentApprover}
                  billReviewResult={billReviewResult}
                  isSysApproved={isSysApproved}
                  auditRecord={auditRecord}
                  paymentType={orderDetail?.paymentType!}
                />
              </Form>
            )}
          </Col>
        </Row>
      </Card>

      {/* 日志信息 */}
      <OperationLog logs={logs} />

      {/* 审批 退回 */}
      {isShowRetreatModal ? (
        <ApprovalRetreatModal
          onCancel={() => {
            setIsShowRetreatModal(false)
          }}
          onSubmit={startApprovalRetreat}
        />
      ) : null}

      {/* 审批 通过 */}
      {isShowConfirmModal ? (
        <ApprovalConfirmModal
          onCancel={() => {
            setIsShowConfirmModal(false)
          }}
          onSubmit={startApprovalConfirm}
        />
      ) : null}

      {isCurrentApprover && (
        <Affix offsetBottom={0} className='bottomAffix'>
          <Row
            justify='end'
            style={{
              padding: '35px 15px 35px 0'
            }}
          >
            <Space>
              <Button
                type='primary'
                danger
                style={{ width: 100 }}
                disabled={retreatFetching}
                onClick={() => {
                  setIsShowRetreatModal(true)
                }}
              >
                退回
              </Button>
              <Button
                type='primary'
                style={{ width: 100 }}
                disabled={confirmFetching}
                onClick={() => {
                  setIsShowConfirmModal(true)
                }}
              >
                审核通过
              </Button>
            </Space>
          </Row>
        </Affix>
      )}
    </Space>
  )
}

ConsumptionOrderBillApproveDetail.pageTitle = '消费订单详情'

export default ConsumptionOrderBillApproveDetail
