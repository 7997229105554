import { UploadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import React, { FC } from 'react'
import xlsx from 'xlsx'
import { ButtonType } from 'antd/lib/button'

export interface UploadButtonProps {
  buttonText?: string
  buttonType?: ButtonType
  buttonIcon?: any
  onStart?: () => void
  onUploaded?: (obj: object) => void
  showLoading?: boolean
  disabled?: boolean
}

const ResolveExcel: FC<UploadButtonProps> = ({
  buttonText = '',
  buttonType = 'default',
  buttonIcon = <UploadOutlined />,
  onUploaded,
  onStart,
  disabled = false
}) => {
  const searchInput = React.useRef<HTMLInputElement>(null)
  // 文件名回填后触发
  const onImportExcel = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {
    onStart && onStart()

    const fileReader = new FileReader()

    // 获取文件对象列表
    const files = fileEvent.target?.files
    if (!files) {
      message.error('未选择文件')
      return
    }

    // 读取完成时触发
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const res = e.target?.result

        // 将文件读取为二进制字符串
        const workbook = xlsx.read(res, {
          type: 'buffer',
          // cellNF: true,
          cellDates: true //转换成标准时间格式
        })

        let excelList: any[] = []
        for (const sheet in workbook.Sheets) {
          if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
            // 每一行解析成一个字符串
            excelList = excelList.concat(
              xlsx.utils.sheet_to_json(workbook.Sheets[sheet], {
                raw: true
                // defval: '' // 空白单元格
              })
            )
          }
        }

        onUploaded &&
          onUploaded({
            name: files[0].name,
            list: excelList
          })
      } catch (error) {
        console.log(error)
        message.error('解析失败，请检查数据')
      }
    }

    // 以二进制方式读取文件，读取结束触发 onloadend，同时 result 属性中将包含一个 ArrayBuffer 对象表示读取到的文件数据
    files[0] && fileReader.readAsArrayBuffer(files[0])

    // 读取结束时触发
    fileReader.onloadend = (e) => {
      // if (searchInput.current && searchInput.current?.files) {
      // searchInput.current.files = null
      searchInput.current && (searchInput.current.value = '')
      // }
    }
  }

  return (
    <>
      <Button
        type={buttonType}
        style={{ width: '120px' }}
        icon={buttonIcon}
        disabled={disabled}
      >
        <input
          style={{
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            zIndex: 2,
            cursor: 'pointer',
            overflow: 'hidden',
            fontSize: 0
          }}
          ref={searchInput}
          type='file'
          accept='.xlsx, .xls, .csv'
          onChange={onImportExcel}
        />
        <span style={{ marginLeft: '5px' }}>{buttonText || '选择文件'}</span>
      </Button>
    </>
  )
}

export default ResolveExcel
