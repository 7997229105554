import React from 'react'
import { useUpadminCompaniesQuery } from '../../graphql'
import SearchTable from '../../components/SearchTable'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const AdminCompanyManage: QCPage = () => {
  const [{ data, fetching }] = useUpadminCompaniesQuery()

  return (
    <SearchTable
      bordered
      showPanel={false}
      showPagination={false}
      loading={fetching}
      dataSource={data?.companies || []}
      size='middle'
      rowKey='id'
      columns={[
        { title: '名称', dataIndex: 'cnName' },
        {
          title: '操作',
          dataIndex: 'id',
          render(id) {
            return <Link to={`/adminCompanyManage/${id}`}>去管理</Link>
          }
        }
      ]}
    />
  )
}

export default AdminCompanyManage
