import { RcFile } from 'antd/es/upload/interface'

export const COMPANY_FINANCE = process.env.ROLE_COMPANY_FINANCE
export const COMPANY_ADMIN = process.env.ROLE_COMPANY_ADMIN

export const SUPERADMIN = 'group_admin'

export enum ROLE_NAME {
  companyFinance,
  companyAdmin,
  superAdmin
}

export const HR_PRODUCTION = {
  radiation: 'RADIATION',
  anesthesiology: 'ANESTHESIOLOGY',
  hansoh: 'HANSOH',
  qilu: 'QILU',
  cttq: 'CTTQ',
  qc: 'QC',
  qcDemo: 'QC-DEMO'
}

export const ACCOUNT_STATUS: Record<string, string> = {
  ACCOUNT_ACTIVATED: '已激活',
  ACCOUNT_DISABLED: '已禁用',
  ACCOUNT_INACTIVE: '未激活',
  ACCOUNT_REVIEW_PENDING: '待审核',
  ACCOUNT_REVIEW_REFUSED: '审核被拒绝'
}

export const certificateOptions = [
  '医师执业证',
  '医师资格证',
  '职称证',
  '工作证',
  '卫健委资格证书'
]

export const credentialTypeMap = [
  {
    type: '医师执业证',
    tip: ['上传执业证1-2页', '上传执业证3-4页'],
    message: [
      '需上传原件',
      '第1、2页需带有执业证编码信息；第3、4页需带有执业地点信息',
      '证件应清晰、不反光、无遮掩；编号和日期清晰可见'
    ],
    example: [
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/doctor-license1.png',
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/doctor-license2.png'
    ]
  },
  {
    type: '医师资格证',
    tip: ['上传资格证1-2页', '上传执业证3-4页'],
    message: [
      '需上传原件',
      '第1、2页需带有资格证编码信息；第3、4页需包含毕业学校，专业等信息',
      '证件应清晰、不反光、无遮掩；编号和日期清晰可见'
    ],
    example: [
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/doctor-qualification-certificate1.png',
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/doctor-qualification-certificate2.png'
    ]
  },
  {
    type: '职称证',
    tip: ['上传资格证1-2页', '上传执业证3-4页'],
    message: ['需上传原件', '证件应清晰、不反光、无遮掩；编号和日期清晰可见'],
    example: [
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/title-certificate1.png',
      'https://hengrui-radiation-dev.oss-cn-shanghai.aliyuncs.com/doctor-license-example/title-certificate2.png'
    ]
  },
  {
    type: '工作证',
    message: ['工作证']
  },
  {
    type: '卫健委资格证书',
    message: ['卫健委资格证书']
  }
]

export const isVideoUrl = (url: string) => {
  url = url.toLocaleLowerCase()
  const index = url.lastIndexOf('.')
  url = url.substring(index)
  if (
    url === '.mp4' ||
    url === '.rmvb' ||
    url === '.avi' ||
    url === '.ts' ||
    url === '.mov'
  ) {
    return true
  }
  return false
}

export const isVideo = (fileType: string) => {
  const reg = new RegExp(/^video\//i)
  return reg.test(fileType)
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const hasProperty = <Obj, Prop extends string>(
  obj: Obj,
  prop: Prop
): obj is Obj & Record<Prop, unknown> =>
  Object.prototype.hasOwnProperty.call(obj, prop)

export const isVideoElement = (
  element: HTMLElement | null
): element is HTMLVideoElement => Boolean(element)

export const returnApprovalReasons = [
  '会议日期与会议提交日程表不符',
  'OA报告未归档，待归档后流转',
  '请上传会议签到簿',
  '请上传会议日程',
  '请上传公司产品权益照片',
  '所有上传的报告请隐藏关于隐私的信息（姓名、地址、身份证号码、联系电话、住院号、门诊号、病历号等），报告需要体现所属积分的老师姓名',
  '报告需要体现如下信息：个人脱敏信息、情况描述、检查检验信息或老师给出的意见建议及诊治方案、其它（如预后、注意事项等）',
  '患者基本信息部分，请填写患者姓名首字母',
  '缺本场会议，讲课角色老师的讲课内容照片（ppt讲课内容照片），保证会议的完整',
  '根据合规要求，需要提供会议活动签到表（线下会议需要手写签名签到表，线上会议需要打勾形式签到表）',
  '线上会议需要参会人员列表截图',
  '拒单，中级职称半小时讲课费公允积分1000',
  '中级职称一小时讲课费公允积分2000',
  '拒单，高级职称一小时讲课费公允积分3000',
  '老师执业资格照片的职称与提交的职称不符，请提供老师最新的执业资格照片，联系平台工作人员修改',
  '请针对不同的文献问题进行不同的专业点评，不要进行简单的复制粘贴，点评内容不能重复'
]

export const CATE_DOCUMENT_COMMENT_REASONS = [
  '老师执业资格照片的职称与提交的职称不符，请提供老师最新的执业资格照片，联系平台工作人员修改。',
  '请针对不同的文献问题进行不同的专业点评，不要进行简单的复制粘贴，点评内容不能重复',
  '老师上传的证件未体现出老师职称。提供体现老师职称的相关材料，比如工牌、简介、职称证等，联系平台工作人员后台帮您补充进去。'
]

export const CATE_QUESTIONNAIRE_RESEARCH_REASONS = [
  '老师执业资格照片的职称与提交的职称不符，请提供老师最新的执业资格照片，联系平台工作人员修改。',
  '老师上传的证件未体现出老师职称。提供体现老师职称的相关材料，比如工牌、简介、职称证等，联系平台工作人员后台帮您补充进去。'
]

export const CATE_CASE_COLLECTION_REASONS = [
  '老师执业资格照片的职称与提交的职称不符，请提供老师最新的执业资格照片，联系平台工作人员修改。',
  '所有上传的报告请隐藏关于隐私的信息（姓名、地址、身份证号码、联系电话、住院号、门诊号、病历号等），报告需要体现所属积分的老师姓名。',
  '报告需要体现如下信息：个人脱敏信息、情况描述、检查检验信息或老师给出的意见建议及诊治方案、其它（如预后、注意事项等）。',
  '患者基本信息部分，请填写患者姓名首字母。',
  '同一份病例报告，请勿重复提交。',
  '老师上传的证件未体现出老师职称。提供体现老师职称的相关材料，比如工牌、简介、职称证等，联系平台工作人员后台帮您补充进去。'
]

export const ACADEMIC_CONFERENCE_REASONS = [
  '缺本场会议，讲课角色老师的讲课内容照片（ppt讲课内容照片），保证会议的完整。',
  '根据合规要求，需要提供会议活动签到表（线下会议需要手写签名签到表，线上会议需要打勾形式签到表）。',
  '线上会议需要参会人员列表截图。',
  '拒单，中级职称半小时讲课费公允积分1000。',
  '中级职称一小时讲课费公允积分2000。',
  '拒单，高级职称一小时讲课费公允积分3000。',
  '老师执业资格照片的职称与提交的职称不符，请提供老师最新的执业资格照片，联系平台工作人员修改。',
  '拒单，会议日期与会议日程表日期不符。',
  '老师上传的证件未体现出老师职称。提供体现老师职称的相关材料，比如工牌、简介、职称证等，联系平台工作人员后台帮您补充进去。'
]

export const OperationOrderDeleteReasons = ['合规部要求删除']

export const OperationOrderReturnReasons = ['合规部要求退回']

export const financialReviewStatus = ['待复核', '财务审核完成', '财务退回']

export const getExportPageSizeByDataCount = (total: number) => {
  const number = total / 100 / 100
  let pageSize = Math.ceil(number) * 100
  if (pageSize > 100) pageSize = 100
  return pageSize
}

export const consumptionOrderRetreatReasons = [
  {
    type: 'warning',
    value: '支付凭证-请上传正确的支付凭证图片，保证材料清晰无涂改或缺失。'
  },
  {
    type: 'warning',
    value:
      '支付凭证-确认消费金额大于支付凭证上的支付金额，请修改消费确认金额后，重新提交。'
  },
  {
    type: 'processing',
    value: '消费清单-请上传正确的消费清单图片，保证材料清晰无涂改或缺失。'
  },
  {
    type: 'processing',
    value:
      '消费清单-确认消费金额大于消费清单上的消费金额，请修改消费确认金额后，重新提交。'
  },
  {
    type: 'processing',
    value:
      '消费清单-消费清单的商家名称与订单商家名称不符，或没有商家名称；请在消费订单上加盖发票章后再提交。'
  },
  {
    type: 'success',
    value: '发票-请上传正确的发票图片，保证材料清晰无涂改或缺失。'
  },
  {
    type: 'success',
    value: '发票-确认消费金额大于发票金额，请修改确认消费金额后，重新提交。'
  },
  {
    type: 'error',
    value:
      '后补单-取消当前订单，重新申请新的订单。申请新的订单时，选择并填写好造成后补单的原因。'
  }
]

export const consumptionOrderConfirmReasons = []

export const claimFormOrderConfirmReasons = [
  {
    type: 'warning',
    value: '【发票查验-未提供发票查验记录】'
  },
  {
    type: 'processing',
    value: '【支付路径-公务卡】'
  },
  {
    type: 'processing',
    value: '【支付路径-非公务卡】'
  },
  {
    type: 'processing',
    value: '【支付路径-零钱/花呗】'
  },
  {
    type: 'processing',
    value: '【支付路径-其他】'
  },
  {
    type: 'success',
    value: '【特殊事项——报告】'
  },
  {
    type: 'success',
    value: '【特殊事项——标准】'
  },
  {
    type: 'success',
    value: '【特殊事项——发票内容】'
  },
  {
    type: 'default',
    value: '【其他—支付早于申请】'
  },
  {
    type: 'default',
    value: '【其他—应对公付款】'
  }
]

export const claimFormOrderRetreatReasons = [
  {
    type: 'warning',
    value: '【发票-未上传发票/上传错误，请核实】'
  },
  {
    type: 'warning',
    value: '【发票-发票抬头/税号/串号/税率/发票章/项目错，请核实】'
  },
  {
    type: 'warning',
    value: '【发票-发票金额小于消费确认金额，请修改】'
  },
  {
    type: 'warning',
    value: '【发票-负面清单，不可报销】'
  },
  {
    type: 'warning',
    value: '【发票-单价超标，不可报销】'
  },
  {
    type: 'warning',
    value: '【发票查验-未提供发票查验记录】'
  },
  {
    type: 'processing',
    value: '【支付凭证-未上传支付凭证/上传错误，请核实】'
  },
  {
    type: 'processing',
    value: '【支付凭证-商户信息不符，请加盖商户用章】'
  },
  {
    type: 'processing',
    value: '【支付凭证-支付金额小于消费确认金额，请修改】'
  },
  {
    type: 'processing',
    value: '【支付凭证-支付方式为非本人公务卡，请提交本人当月盖章流水】'
  },
  {
    type: 'processing',
    value: '【支付凭证-非本人支付/无支付凭证，不可报销】'
  },
  {
    type: 'processing',
    value: '【支付凭证-支付凭证日期晚于发票日期，不可报销】'
  },
  {
    type: 'success',
    value: '【小票-未上传消费小票/上传错误，请核实】'
  },
  {
    type: 'success',
    value: '【小票-商户信息不符，请加盖商户章】'
  },
  {
    type: 'success',
    value: '【小票-小票金额小于消费确认金额，请修改】'
  },
  {
    type: 'error',
    value: '【虚假费用-支付凭证造假，终止报销】'
  },
  {
    type: 'error',
    value: '【虚假费用-票据造假，终止报销】'
  },
  {
    type: 'error',
    value: '【虚假费用-票据重复贴报，终止报销】'
  },
  {
    type: 'default',
    value: '【其他-支付/发票早于消费订单提交日期，不可报销】'
  },
  {
    type: 'default',
    value: '【其他-需对公付款，不可报销】'
  }
]

export const isMobileClient = () => {
  const touchCapable =
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  const userAgent = navigator.userAgent || ''
  const ua = userAgent.toLowerCase()
  let isMobile =
    /mobile|android|iphone|ipad|phone|ipod/i.test(ua) || touchCapable

  if (!isMobile) {
    //验证手机机型
    const phoneModelUA = new RegExp(
      'GT-|SM-|SCH-|HM|RedMi|Mi|huawei|honor|vivo',
      'i'
    )
    isMobile = phoneModelUA.test(userAgent)
  }

  return isMobile
}
