import React, { useEffect, useRef, useState } from 'react'
import {
  PagerInput,
  useAdminCompanyGroupQuery,
  useAdminCompanyGroupTreeQuery,
  useAdminGroupMembersQuery
} from '../../graphql'
import { Card, Col, Descriptions, Row, Table, Tree } from 'antd'

function convertTree(node: any) {
  if (!node) {
    return null
  }
  const newNode: any = {}

  newNode.title = node.name
  newNode.key = node.groupId

  if (node.children && node.children.length > 0) {
    newNode.children = node.children.map((child: any) => {
      return convertTree(child)
    })
  }
  return newNode
}

type Props = {
  companyId: string
  groupType?: string
}

export const GroupTree = (prop: Props) => {
  const [currentGroup, setCurrentGroup] = useState<string>()
  const prevGroupId = useRef<string>()

  const [pager, setPager] = useState<PagerInput>({
    pageSize: 15,
    currentPage: 1
  })

  const [{ data: currentGroupData }] = useAdminCompanyGroupQuery({
    variables: { groupId: currentGroup! },
    pause: !currentGroup
  })

  const [{ data: currentGroupMemberData }] = useAdminGroupMembersQuery({
    variables: { groupId: currentGroup!, pager },
    pause: !currentGroup
  })

  const [{ data, fetching }] = useAdminCompanyGroupTreeQuery({
    variables: {
      companyId: prop.companyId,
      groupType: prop.groupType || 'department'
    }
  })

  useEffect(() => {
    if (prevGroupId.current !== currentGroup) {
      prevGroupId.current = currentGroup
      setPager({ pageSize: 15, currentPage: 1 })
    }
  }, [currentGroup])

  return (
    <Card loading={fetching}>
      <Row>
        <Col span='8'>
          {data?.groupTree && (
            <Tree
              showLine
              draggable
              treeData={[convertTree(data.groupTree) as any]}
              onClick={(_, group) => setCurrentGroup(group.key as string)}
            ></Tree>
          )}
        </Col>
        {currentGroup && (
          <Col span='16'>
            <Card title='基本信息'>
              <Descriptions>
                <Descriptions.Item label='名称'>
                  {currentGroupData?.group?.name}
                </Descriptions.Item>
                <Descriptions.Item label='编号'>
                  {currentGroupData?.group?.code}
                </Descriptions.Item>
                <Descriptions.Item label='负责人'>
                  {currentGroupData?.group?.owner?.cnName}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <br></br>
            <Card title='员工'>
              <Table
                dataSource={currentGroupMemberData?.members?.items || []}
                columns={[
                  { title: '名字', dataIndex: 'cnName', key: 'cnName' },
                  {
                    title: '手机',
                    dataIndex: 'companyMobile',
                    key: 'companyMobile'
                  }
                ]}
                pagination={{
                  current: currentGroupMemberData?.members?.pager.currentPage,
                  pageSize: currentGroupMemberData?.members?.pager.pageSize,
                  total: currentGroupMemberData?.members?.pager.total,
                  onChange(page, pageSize) {
                    setPager((o) => ({ ...o, pageSize, currentPage: page }))
                  }
                }}
              ></Table>
            </Card>
          </Col>
        )}
      </Row>
    </Card>
  )
}
