import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd'

import './index.less'
import {
  AccountBaseInfo,
  NodeType,
  useAdminApprovalFlowSaveMutation,
  useAdminApprovalNodeTypesQuery,
  useQueryCompanyAccountsByKeywordQuery
} from '../../graphql'
import AccountInfo from '../../components/AccountInfo'
import { useDeepCompareEffect } from 'react-use'
import SearchTable from '../../components/SearchTable'

interface DefNode {
  /** DefNode defNodeId */
  defNodeId?: string | null

  /** DefNode approver */
  approver?: string | null

  /** DefNode sort */
  sort?: number | null
}

enum Expression {
  DEFAULT_EXPRESSION = 0,
  GT = 1,
  LT = 2,
  EQ = 3,
  LTE = 4,
  GTE = 5,
  UNEQ = 6,
  CONTAINS = 7,
  UNCONTAINS = 8,
  ISNULL = 9,
  ISNOTNULL = 10
}

interface GroupRule {
  /** GroupRule groupRuleId */
  groupRuleId?: string | null

  /** GroupRule codition */
  codition?: string | null

  /** GroupRule expression */
  expression?: Expression | null

  /** GroupRule value */
  value?: string | null

  /** GroupRule priority */
  priority?: number | null

  /** GroupRule nextNextNodeGroup */
  nextNextNodeGroup?: string | null
}

interface NodeGroup {
  /** NodeGroup nodeGroupId */
  nodeGroupId?: string | null

  /** NodeGroup nodeGroupName */
  nodeGroupName?: string | null

  /** NodeGroup nodeGroupTypeId */
  nodeGroupTypeId?: string | null

  /** NodeGroup nodeGroupTypeName */
  nodeGroupTypeName?: string | null

  /** NodeGroup nextGroupId */
  nextGroupId?: string | null

  /** NodeGroup groupRules */
  groupRules?: GroupRule[] | null

  /** NodeGroup defNodes */
  defNodes?: DefNode[] | null

  /** NodeGroup isRecursive */
  isRecursive?: number | null
}

const Node = ({
  node,
  active,
  trigger,
  index,
  isLast
}: {
  node: NodeGroup
  active?: boolean
  isLast: boolean
  index: number
  trigger: (type: string) => void
}) => {
  return (
    <div className={`flowNode ${active ? 'active' : ''}`}>
      <div>
        <div className='flowNodeButton'>
          {node.nodeGroupName || node.nodeGroupTypeName}
        </div>

        <Space>
          {index !== 0 && (
            <>
              {index !== 1 && <a onClick={() => trigger('moveBackward')}>⬅️</a>}
              {!isLast && <a onClick={() => trigger('moveForward')}>➡️</a>}
              <a onClick={() => trigger('remove')}>❎</a>
            </>
          )}
          <a onClick={() => trigger('addAfter')}>➕</a>
        </Space>
      </div>
      {!isLast && <p>&#10132;</p>}
    </div>
  )
}

const moveArrayItem = <T,>(arr: T[], n: number) => {
  const removed = arr.filter((_, index) => index !== n)
  return removed
    .slice(0, n + 1)
    .concat(arr[n], removed.slice(n + 1, removed.length))
}

const createNodeId = () => {
  let id = '902'
  const date = new Date()
  id += date.getFullYear() + ''
  id += date.getMonth() + 1 + ''
  id += date.getDate() + ''
  id += String(date.valueOf()).substring(4)
  return id
}

const CompanyMembers = ({
  companyId,
  onChoose
}: {
  companyId: string
  onChoose: (account: Partial<AccountBaseInfo>) => void
}) => {
  const [kw, updateKW] = useState<string>()
  const [{ data }] = useQueryCompanyAccountsByKeywordQuery({
    variables: { companyId, keyword: kw }
  })
  return (
    <>
      <Input
        placeholder='搜索姓名或手机'
        onInput={(ev) => updateKW((ev.target as any).value)}
      />
      <Table
        dataSource={data?.users}
        columns={[
          { dataIndex: 'cnName' },
          { dataIndex: 'companyMobile' },
          {
            render(_, account) {
              return <Button onClick={() => onChoose(account)}>选择</Button>
            }
          }
        ]}
      />
    </>
  )
}

export const ApprovalFlowEditor = ({
  nodeGroups,
  companyId,
  onSave
}: {
  companyId: string
  nodeGroups: NodeGroup[]
  onSave: (nodeGroups: NodeGroup[]) => void
}) => {
  const [localNodeGroups, setLocalNodeGroups] =
    useState<NodeGroup[]>(nodeGroups)
  const [currentNode, setCurrentNode] = useState<string | undefined | null>()

  const [show, setShow] = useState<boolean>(false)
  const [{ data: nodeTypes }] = useAdminApprovalNodeTypesQuery({
    requestPolicy: 'cache-first'
  })

  useDeepCompareEffect(() => {
    console.log('nodeGroups changed:', nodeGroups)
    setLocalNodeGroups(nodeGroups)
    setCurrentNode(nodeGroups[0]?.nodeGroupId)
  }, [nodeGroups])

  const currentNodeObj = localNodeGroups.find(
    (node) => node.nodeGroupId === currentNode
  )

  const [ruleForm] = Form.useForm()

  const addNode = (curNode: NodeGroup, type: NodeType) => {
    const newNode: NodeGroup = {
      nodeGroupId: createNodeId(),
      nodeGroupTypeId: type.id,
      nodeGroupName: type.name,
      nodeGroupTypeName: type.name,
      defNodes: []
    }

    setLocalNodeGroups((old) => {
      const insertIndex = old.findIndex(
        (n) => curNode.nodeGroupId === n.nodeGroupId
      )

      newNode.nextGroupId = old[insertIndex + 1]?.nodeGroupId || '0'

      const insertedArr = old
        .slice(0, insertIndex + 1)
        .concat(newNode, old.slice(insertIndex + 1, old.length))

      insertedArr[insertIndex].nextGroupId = newNode.nodeGroupId

      return insertedArr
    })
  }

  const onNodeAction = (type: string, node: NodeGroup) => {
    console.log(type, node)
    switch (type) {
      case 'remove':
        Modal.confirm({
          type: 'danger',
          content: '确认移除节点？',
          onOk() {
            setLocalNodeGroups((old) => {
              const removeItemIndex = old.findIndex(
                (n) => n.nodeGroupId === node.nodeGroupId
              )

              const result = old.reduce((prev, cur, index) => {
                if (index === removeItemIndex) return prev

                if (index === removeItemIndex - 1) {
                  cur.nextGroupId = old[removeItemIndex + 1]?.nodeGroupId || '0'
                }

                return prev.concat(cur)
              }, [] as NodeGroup[])

              return result
            })
          }
        })
        break
      case 'moveForward':
        setLocalNodeGroups((old) => {
          const prevIndex = old.findIndex(
            (n) => n.nodeGroupId === node.nodeGroupId
          )

          const result = moveArrayItem(old, prevIndex)
          result[prevIndex - 1] &&
            (result[prevIndex - 1].nextGroupId =
              result[prevIndex].nodeGroupId || '0')

          result[prevIndex].nextGroupId = node.nodeGroupId
          result[prevIndex + 1].nextGroupId =
            result[prevIndex + 2]?.nodeGroupId || '0'
          return result
        })
        break
      case 'moveBackward':
        setLocalNodeGroups((old) => {
          const prevIndex = old.findIndex(
            (n) => n.nodeGroupId === node.nodeGroupId
          )

          const result = moveArrayItem(old, prevIndex - 1)

          result[prevIndex - 2] &&
            (result[prevIndex - 2].nextGroupId = node.nodeGroupId)
          result[prevIndex - 1].nextGroupId =
            result[prevIndex]?.nodeGroupId || '0'
          result[prevIndex].nextGroupId =
            result[prevIndex + 1]?.nodeGroupId || '0'

          return result
        })
        break
      case 'addAfter':
        // eslint-disable-next-line no-case-declarations
        const modal = Modal.info({
          title: '选择类型',
          content: (
            <List>
              {nodeTypes?.types?.map((type) => {
                return (
                  <List.Item key={type.id}>
                    <Button
                      block
                      onClick={() => {
                        addNode(node, type)
                        modal.destroy()
                      }}
                    >
                      {type.name}
                    </Button>
                  </List.Item>
                )
              })}
            </List>
          )
        })

        break
    }
  }

  return (
    <div>
      <Card
        actions={[
          <Button key='save' onClick={() => onSave(localNodeGroups)}>
            保存
          </Button>
        ]}
      >
        <Row gutter={12}>
          {localNodeGroups.map((node, index) => (
            <Col
              key={node.nodeGroupId}
              onClick={() => setCurrentNode(node.nodeGroupId)}
            >
              <Node
                index={index}
                trigger={(type) => onNodeAction(type, node)}
                node={node}
                active={currentNode === node.nodeGroupId}
                isLast={index === localNodeGroups.length - 1}
              />
            </Col>
          ))}
        </Row>
        <br></br>
        <Card>
          <Typography.Paragraph>
            {['7', '2', '9'].includes(
              currentNodeObj?.nodeGroupTypeId || ''
            ) && (
              <Space>
                {currentNodeObj?.nodeGroupName ||
                  currentNodeObj?.nodeGroupTypeName}
                :
                {currentNodeObj?.defNodes?.map((node) => {
                  return (
                    <AccountInfo
                      key={node.defNodeId}
                      accountId={node.approver!}
                      field='name'
                    />
                  )
                })}
                <Button
                  onClick={() => {
                    setShow(true)
                  }}
                >
                  编辑
                </Button>
              </Space>
            )}
          </Typography.Paragraph>
          {/* <Typography.Paragraph>
            <Form name='ruleForm' form={ruleForm}>
              <Form.List name='rules'>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <Typography.Paragraph>
                        <Space>
                          分支规则
                          <Button onClick={() => add()}>新增分支审批流</Button>
                        </Space>
                      </Typography.Paragraph>
                      {fields.map(({ key, name, ...resetField }, index) => {
                        return (
                          <Form.Item
                            key={key}
                            noStyle
                            shouldUpdate={(...args) => {
                              console.log(args)
                              return true
                            }}
                          >
                            {() => {
                              return (
                                <Space align='baseline'>
                                  <Form.Item
                                    {...resetField}
                                    name={[name, 'condition']}
                                  >
                                    <Select
                                      options={conditionList}
                                      style={{ width: 200 }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...resetField}
                                    name={[name, 'expression']}
                                  >
                                    <Select
                                      options={
                                        Object.hasOwn(
                                          ruleGroupMap,
                                          ruleForm.getFieldValue('rules')?.[
                                            index
                                          ]?.condition
                                        )
                                          ? expressionGroup
                                          : expression
                                      }
                                      style={{ width: 120 }}
                                    />
                                  </Form.Item>
                                  <Button
                                    onClick={() => {
                                      remove(index)
                                    }}
                                    icon={<DeleteOutlined />}
                                    danger
                                  />
                                </Space>
                              )
                            }}
                          </Form.Item>
                        )
                      })}
                    </>
                  )
                }}
              </Form.List>
            </Form>
          </Typography.Paragraph> */}
        </Card>
      </Card>
      <Modal title='选择' visible={show} onCancel={() => setShow(false)}>
        <CompanyMembers
          companyId={companyId}
          onChoose={(account) => {
            setLocalNodeGroups((old) => {
              return old.reduce((prev, cur) => {
                if (cur.nodeGroupId === currentNode) {
                  return prev.concat(
                    Object.assign(cur, {
                      defNodes: [
                        {
                          defNodeId: createNodeId(),
                          approver: account.accountId
                        }
                      ]
                    })
                  )
                }
                return prev.concat(cur)
              }, [] as NodeGroup[])
            })
            setShow(false)
          }}
        />
      </Modal>
    </div>
  )
}
