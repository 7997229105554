import React from 'react'
import { DatePicker, Input } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { map, get, cloneDeep } from 'lodash'

import {
  BusinessDepartmentSelector,
  ComponyGroupSelector
} from '../../components/SelectorCollection'
import { Link } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import { getClaimFormStatus } from '../../constant/sales'

export const searchFields = (props?: { type: string }) => [
  {
    name: 'businessId',
    label: '业务部',
    span: 8,
    render() {
      return <ComponyGroupSelector level='1' />
    }
  },
  {
    name: 'submitTime',
    label: '提报时间',
    span: 8,
    render() {
      return (
        <DatePicker.RangePicker
          locale={locale}
          format='YYYY-MM-DD'
          style={{ width: '100%' }}
        />
      )
    }
  },
  {
    name: 'preReviewedTime',
    label: '初审时间',
    span: 8,
    render() {
      return (
        <DatePicker.RangePicker
          locale={locale}
          format='YYYY-MM-DD'
          style={{ width: '100%' }}
        />
      )
    }
  },
  ...(props?.type === 'approved'
    ? [
        {
          name: 'reviewedTime',
          label: '复核时间',
          span: 8,
          render() {
            return (
              <DatePicker.RangePicker
                locale={locale}
                format='YYYY-MM-DD'
                style={{ width: '100%' }}
              />
            )
          }
        }
      ]
    : []),
  {
    name: 'keyword',
    label: '搜索',
    span: 8,
    render() {
      return (
        <Input
          allowClear
          placeholder='请输入申请人名称、手机、报销单号、报销编号、消费单号进行搜索'
        />
      )
    }
  }
]

export const useTableColumns = (props: { type: string }): any => {
  return [
    {
      align: 'center',
      title: '报销单号',
      dataIndex: 'claimForm',
      key: 'claimFormCode',
      fixed: 'left',
      width: 200,
      render(v: any) {
        return (
          <Link to={`/consumptionReimburseDetail/${v.claimFormCode}`}>
            {v.claimFormCode}
          </Link>
        )
      }
    },
    {
      align: 'center',
      title: '报销单名称',
      dataIndex: 'claimForm',
      key: 'claimFormName',
      width: 200,
      render(v: any) {
        return v.claimFormName || '-'
      }
    },
    {
      align: 'center',
      title: '业务部',
      dataIndex: 'claimForm',
      key: 'business',
      width: 150,
      render(v: any) {
        return v.business || '-'
      }
    },
    {
      align: 'center',
      title: '大区',
      dataIndex: 'claimForm',
      key: 'region',
      width: 150,
      render(v: any) {
        return v.region || '-'
      }
    },
    {
      align: 'center',
      title: '办事处',
      dataIndex: 'claimForm',
      key: 'area',
      width: 150,
      render(v: any) {
        return v.area || '-'
      }
    },
    {
      align: 'center',
      title: '申请人',
      dataIndex: 'claimForm',
      key: 'accountName',
      width: 100,
      render(v: any) {
        return v.accountName || '-'
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'claimForm',
      key: 'budgetNumber',
      width: 150,
      render(v: any) {
        return v.budgetNumber || v.accountInfo?.staffId || '-'
      }
    },
    {
      align: 'center',
      title: '申请人手机',
      dataIndex: 'claimForm',
      key: 'accountMobile',
      width: 150,
      render(v: any) {
        return v.accountMobile || '-'
      }
    },
    {
      align: 'center',
      title: '提报时间',
      dataIndex: 'claimForm',
      key: 'submitTime',
      width: 150,
      render(v: any) {
        return v.submitTime ? dayjs(v.submitTime).format('YYYY-MM-DD') : '-'
      }
    },
    {
      align: 'center',
      title: '提报金额',
      dataIndex: 'claimForm',
      key: 'amount',
      width: 100,
      render(v: any, data: any) {
        const orderTotalClaimAmount = data.orders.reduce(
          (total: any, order: any) =>
            (total += Number(order.salesOrder?.claimAmount) || 0),
          0
        )
        const financialDeductionTotalAmount = data.orders.reduce(
          (total: any, order: any) =>
            (total += Number(order.salesOrder?.financialDeductionAmount) || 0),
          0
        )
        const financialDeductionClaimAmount = Number(
          (orderTotalClaimAmount - financialDeductionTotalAmount).toFixed(2)
        )
        return financialDeductionClaimAmount || v.claimAmount || v.amount || '-'
      }
    },
    {
      align: 'center',
      title: '审批时间',
      dataIndex: 'claimForm',
      key: 'approvedTime',
      width: 150,
      render(v: any) {
        return v.approvedTime ? dayjs(v.approvedTime).format('YYYY-MM-DD') : '-'
      }
    },
    ...(props.type === 'approved'
      ? [
          {
            align: 'center',
            title: '财务初审人员',
            dataIndex: 'preReviewedUser',
            key: 'preReviewedUser',
            width: 150,
            render(v: any) {
              return v?.cnName || '-'
            }
          },
          {
            align: 'center',
            title: '财务初审时间',
            dataIndex: 'claimForm',
            key: 'preReviewedTime',
            width: 150,
            render(v: any) {
              return v.preReviewedTime
                ? dayjs(v.preReviewedTime).format('YYYY-MM-DD')
                : '-'
            }
          },
          {
            align: 'center',
            title: '财务复核人员',
            dataIndex: 'reviewedUser',
            key: 'reviewedUser',
            width: 150,
            render(v: any) {
              return v?.cnName || '-'
            }
          },
          {
            align: 'center',
            title: '财务复核时间',
            dataIndex: 'claimForm',
            key: 'reviewedTime',
            width: 150,
            render(v: any) {
              return v.reviewedTime
                ? dayjs(v.reviewedTime).format('YYYY-MM-DD')
                : '-'
            }
          },
          {
            align: 'center',
            title: '状态',
            dataIndex: 'claimForm',
            key: 'status',
            width: 100,
            render(v: any) {
              const status = getClaimFormStatus(v.status)
              return <span style={{ color: status.color }}>{status.text}</span>
            }
          }
        ]
      : []),
    {
      align: 'center',
      title: '操作',
      dataIndex: 'claimForm',
      key: 'action',
      fixed: 'right',
      width: 100,
      render(v: any) {
        return (
          <Link to={`/consumptionReimburseDetail/${v.claimFormCode}`}>
            查看
          </Link>
        )
      }
    }
  ]
}

export type FieldTypes = {
  businessId?: string | undefined
  regionId?: string | undefined
  areaId?: string | undefined
  submitTime?: [Dayjs | undefined, Dayjs | undefined]
  preReviewedTime?: [Dayjs | undefined, Dayjs | undefined]
  reviewedTime?: [Dayjs | undefined, Dayjs | undefined]
  keyword?: string | undefined
}

export const excelHead = (props: { type: string }) => [
  { name: '报销单号', path: 'claimForm.claimFormCode', key: 'claimFormCode' },
  { name: '报销单名称', path: 'claimForm.claimFormName', key: 'claimFormName' },
  { name: '业务部', path: 'claimForm.business', key: 'business' },
  { name: '大区', path: 'claimForm.region', key: 'region' },
  { name: '办事处', path: 'claimForm.area', key: 'area' },
  { name: '申请人', path: 'claimForm.accountName', key: 'accountName' },
  { name: '报销编号', path: 'claimForm.budgetNumber', key: 'budgetNumber' },
  { name: '申请人手机', path: 'claimForm.accountMobile', key: 'accountMobile' },
  { name: '提报时间', path: 'claimForm.submitTime', key: 'submitTime' },
  { name: '提报金额', path: 'claimForm.claimAmount', key: 'claimAmount' },
  { name: '审批时间', path: 'claimForm.approvedTime', key: 'approvedTime' },
  ...(props.type === 'approved'
    ? [
        {
          name: '财务初审人员',
          path: 'preReviewedUser.cnName',
          key: 'preReviewedUser'
        },
        {
          name: '财务初审时间',
          path: 'claimForm.preReviewedTime',
          key: 'preReviewedTime'
        },
        {
          name: '财务复核人员',
          path: 'reviewedUser.cnName',
          key: 'reviewedUser'
        },
        {
          name: '财务复核时间',
          path: 'claimForm.reviewedTime',
          key: 'reviewedTime'
        },
        { name: '状态', path: 'claimForm.status', key: 'status' }
      ]
    : [])
]

export const getData = (data: any, head: any) => {
  const result = data ? cloneDeep(data) : []
  return map(result, (item) => {
    const obj: any = {}
    const orderTotalClaimAmount = item.orders.reduce(
      (total: any, order: any) =>
        (total += Number(order.salesOrder?.claimAmount) || 0),
      0
    )
    const financialDeductionTotalAmount = item.orders.reduce(
      (total: any, order: any) =>
        (total += Number(order.salesOrder?.financialDeductionAmount) || 0),
      0
    )
    const financialDeductionClaimAmount = Number(
      (orderTotalClaimAmount - financialDeductionTotalAmount).toFixed(2)
    )

    head.forEach((cell: any) => {
      const value = get(item, cell.path, '-')

      switch (cell.key) {
        case 'claimAmount':
          obj[cell.name] = financialDeductionClaimAmount
          break
        case 'status':
          obj[cell.name] = getClaimFormStatus(value).text
          break
        default:
          obj[cell.name] = value
          break
      }
    })
    return obj
  })
}
