import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  MutableRefObject
} from 'react'
import { Modal, Input, Spin } from 'antd'

export type event = { onStart: () => void; onCancel: () => void }
interface OpeartionScanningCodeGunProps {
  visible: boolean
  callBack: (code: string) => void
  onCancel?: (e: any) => void
  event?: MutableRefObject<event>
  tip?: string
}

const OpeartionScanningCodeGun: FC<OpeartionScanningCodeGunProps> = ({
  visible,
  callBack,
  onCancel,
  event,
  tip
}) => {
  const ref = useRef({
    code: '',
    lastTime: 0,
    lastCode: 0,
    nextTime: 0,
    nextCode: 0
  })
  // const [scanningCode, setScanningCode] = useState<string>()
  const getScanningResult = (e: any) => {
    let { code, lastTime, lastCode, nextTime, nextCode } = ref.current
    if (window.event) {
      nextCode = e.keyCode
    } else if (e.which) {
      nextCode = e.which
    }

    if (nextCode === 13) {
      if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有

      // setScanningCode('')
      ref.current = {
        code: '',
        lastTime: 0,
        lastCode: 0,
        nextTime: 0,
        nextCode: 0
      }
      callBack && callBack(code)
      // console.log(code)
      return
    }

    nextTime = new Date().getTime()

    if (!lastTime && !lastCode) {
      code = e.key
    }

    if (lastCode && lastTime && nextTime - lastTime > 100) {
      // 当扫码前有keypress事件时,防止首字缺失
      code = e.key
    } else if (lastCode && lastTime) {
      code += e.key
    }

    lastCode = nextCode
    lastTime = nextTime
    ref.current = { code, lastTime, lastCode, nextTime, nextCode }
    // console.log(code)
    // setScanningCode(code)
  }

  const onStartKeypress = () => {
    console.log('onStart keypress')
    ref.current = {
      code: '',
      lastTime: 0,
      lastCode: 0,
      nextTime: 0,
      nextCode: 0
    }
    document.addEventListener('keypress', getScanningResult)
  }

  const onCancelKeypress = () => {
    console.log('onCancel keypress')
    ref.current = {
      code: '',
      lastTime: 0,
      lastCode: 0,
      nextTime: 0,
      nextCode: 0
    }
    document.removeEventListener('keypress', getScanningResult)
  }

  useImperativeHandle(event, () => ({
    onStart: onStartKeypress,
    onCancel: onCancelKeypress
  }))

  useEffect(() => {
    onStartKeypress()
    return () => {
      onCancelKeypress()
    }
  }, [])

  return (
    <Modal
      visible={visible}
      destroyOnClose
      title='扫码搜索'
      onCancel={onCancel}
      footer={null}
      width={300}
      bodyStyle={{ textAlign: 'center', padding: 12 }}
    >
      <Spin tip={tip || '请用扫码枪对准代表提报上面的面单'}></Spin>
      <Input
        bordered={false}
        disabled
        // value={scanningCode}
        style={{ textAlign: 'center', color: 'black' }}
      />
    </Modal>
  )
}

export default OpeartionScanningCodeGun
