import React, { FC, Fragment } from 'react'
import { Button, Table, Image, Col, Row, Tooltip, Descriptions } from 'antd'
import { Document, Page } from 'react-pdf'
import { get } from 'lodash'
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import Modal from 'antd/lib/modal/Modal'
import { useBoolean, useWindowSize } from 'react-use'
import { Link } from 'react-router-dom'

import {
  HrMeetingDetailQuery,
  useHrAttenderContractQuery,
  useHrUploadAttenderPaymentVoucherMutation,
  useHrUploadAttenderInvoicesMutation,
  useHrUploadAttenderTaxReceiptsMutation,
  StandardMeetingServiceBizStatus,
  useHrQueryAttenderPaymentDetailQuery,
  HadesPaymentChannel,
  HadesReceiptType
} from '../../graphql'
import UploadButton from '../../components/UploadButton'
import { CONTRACT_STATUS } from '../../constant/hrradiation'
import { useUserStore } from '../../hooks/user'
import VideoButton from '../../components/VideoButton'
import { HR_PRODUCTION, isVideoUrl } from '../../constant/user'
import AuditRecordTag from './auditRecordTag'

// const formatOSSUrlWithQueryString = (url: string, queryString: string) => {
//   if (!url) return null
//   const [base] = url.split('?')
//   return base + queryString
// }

const getImgSource = (pic: string) => {
  const sliceIndex = pic?.indexOf('?')
  const url = sliceIndex > -1 ? pic.slice(0, sliceIndex) : pic
  if (/\.(png|jpe?g|jpeg|gif|webp|bmp)$/i.test(url)) {
    return url
  }
  return url + '?x-oss-process=image/format,jpg'
}

const formatMaterialUrls = (data: any) => {
  let urls: any[] = []
  const materials = data.materials || data.materialUrls
  materials?.map((url: any) => {
    if (url.value) {
      urls = urls.concat(url.value)
    } else {
      urls.push(url)
    }
  })
  return urls
}

const formatPanoramicPicUrls = (data: any) => {
  let urls: any[] = []
  const panoramicPicUrls =
    data.panoramicPicUrls || data.materials || data.materialUrls
  panoramicPicUrls?.map((url: any) => {
    if (url.value) {
      urls = urls.concat(url.value)
    } else {
      urls.push(url)
    }
  })
  return urls
}

const formatFrontPicUrls = (data: any) => {
  let urls: any[] = []
  const frontPicUrls = data.frontPicUrls
  frontPicUrls?.map((url: any) => {
    if (url.value) {
      urls = urls.concat(url.value)
    } else {
      urls.push(url)
    }
  })
  return urls
}

const labelStyle = {
  width: '130px',
  color: 'rgb(136, 136, 136)'
}

const successStyle = {
  color: '#52c41a'
}

const failStyle = {
  color: '#ff4d4f'
}

const PanoramicPicAuditRecordData = [
  {
    label: '重复性',
    type: '老师全景照-重复性',
    dimension: 'repeat'
  }
]

const FrontPicAuditRecordData = [
  {
    label: '重复性',
    type: '老师讲课照-重复性',
    dimension: 'repeat'
  },
  {
    label: '讲课照真实性',
    type: '老师讲课照-关联性',
    dimension: 'relation'
  }
]

const AttenderFeeAuditRecordData = [
  {
    label: '积分验证',
    type: '老师积分验证-关联性',
    dimension: 'relation'
  },
  {
    label: '终端验证',
    type: '老师终端验证-重复性',
    dimension: 'repeat'
  }
]

export interface AttenderTableProps {
  attenders: NonNullable<HrMeetingDetailQuery['detail']>['speakers']
  meetingId: string
  status?: string | null
  onRefreshed: Function
  meetingType?: string | null
  ownerName?: string | null
  mettingProvinceName?: string[] | null
  production?: string
  meetingAuditRecord?: NonNullable<
    HrMeetingDetailQuery['detail']
  >['meetingAuditRecord']
}

const PaymentDetail: FC<{
  paymentId: string | null | undefined
  channel?: HadesPaymentChannel
  type?: HadesReceiptType
}> = ({ paymentId, channel, type }) => {
  const [{ data, fetching }] = useHrQueryAttenderPaymentDetailQuery({
    variables: {
      paymentId: paymentId || '',
      channel,
      type
    },
    pause: !paymentId
  })
  return data?.detail && data.detail.length > 0 ? (
    <>
      <h3>费用明细</h3>
      <Table dataSource={(data.detail as any[]) || []}>
        <Table.Column title='会议名称' dataIndex='meetingName'></Table.Column>
        <Table.Column title='申请人' dataIndex='applicant'></Table.Column>
        <Table.Column title='会议时间' dataIndex='start'></Table.Column>
        <Table.Column title='金额' dataIndex='fee'></Table.Column>
      </Table>
    </>
  ) : null
}

// 需要同步修改 components/PreviewModalButton
const PreviewModalButton: FC<{
  file: string | null | undefined
  files?: string[] | undefined
  meetingId: string
  attenderId: string
  type: string
  status?: string | null
  onUploaded: (url: string) => Promise<void> | void
}> = ({
  file,
  files = [],
  meetingId,
  attenderId,
  onUploaded,
  type,
  status,
  children
}) => {
  const [open, toggleOpen] = useBoolean(false)
  const user = useUserStore()
  const { height } = useWindowSize()
  if (!file) {
    if (
      [
        //StandardMeetingServiceBizStatus.MeetingSettlementConfirming,
        StandardMeetingServiceBizStatus.MeetingInSettlement,
        StandardMeetingServiceBizStatus.MeetingSettlementWithoutTax
      ].includes(status as StandardMeetingServiceBizStatus) &&
      user.hasAuth('uplatform_payment_upload_opt')
    ) {
      return (
        <UploadButton
          bucket='yhl-upright-admin-upload'
          dir={`/admin/${type}/${meetingId}/${attenderId}`}
          buttonText='上传'
          onUploaded={onUploaded}
        />
      )
    }
    return null
  }

  if (files.length <= 0) files = [file]

  return (
    <>
      <a onClick={toggleOpen}>查看</a>
      <Modal
        visible={open}
        onCancel={toggleOpen}
        width='400'
        centered
        bodyStyle={{ maxHeight: height - 200, overflow: 'auto' }}
        footer={
          user.hasAuth('uplatform_payment_upload_opt') && (
            <div style={{ display: 'block', textAlign: 'left' }}>
              <UploadButton
                bucket='yhl-upright-admin-upload'
                dir={`/admin/${type}/${meetingId}/${attenderId}`}
                buttonText='重新上传'
                onUploaded={onUploaded}
              />
            </div>
          )
        }
      >
        {files.map((file, index) => {
          const forceHttpsFile = file.replace('http://', 'https://')
          const extension = file.split(/[#?]/)[0].split('.').pop()?.trim()
          return (
            <div key={index}>
              {extension?.toLowerCase() === 'pdf' ? (
                <Document
                  file={forceHttpsFile}
                  options={{
                    cMapUrl: 'https://upadmin-cdn.yihuili.net/cmaps/',
                    cMapPacked: true
                  }}
                >
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <img
                  width='480'
                  src={file}
                  style={{ display: 'block', padding: '20px 0' }}
                />
              )}
              <Button type='link' target='__blank' href={forceHttpsFile}>
                查看原文件
              </Button>
            </div>
          )
        })}
        {children}
      </Modal>
    </>
  )
}

const AttenderTable: FC<AttenderTableProps> = ({
  attenders,
  meetingId,
  status,
  onRefreshed,
  meetingType,
  ownerName,
  mettingProvinceName = [],
  production,
  meetingAuditRecord
}) => {
  const [, uploadInvoices] = useHrUploadAttenderInvoicesMutation()
  const [, uploadPaymentVoucher] = useHrUploadAttenderPaymentVoucherMutation()
  const [, uploadTaxReceipts] = useHrUploadAttenderTaxReceiptsMutation()
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo

  return (
    <>
      <Table
        dataSource={attenders || []}
        scroll={{ x: isQC ? 2350 : 2250 }}
        className='attenderTable'
        rowClassName='attenderTableRow'
        sticky
        columns={[
          {
            title: '老师',
            key: 'cnName',
            dataIndex: 'attenderInfo',
            align: 'center',
            fixed: 'left',
            width: 150,
            render(_, attender) {
              return `${attender.attenderInfo?.cnName}(${attender.attenderInfo?.companyMobile})`
            }
          },
          {
            title: '单位(职称)',
            key: 'title',
            dataIndex: 'attenderInfo',
            width: 150,
            render(_, attender) {
              return (
                attender.attenderInfo?.hospital +
                `${
                  attender.attenderInfo?.titleInfo?.name
                    ? `(${attender.attenderInfo?.titleInfo?.name})`
                    : ''
                }`
              )
            }
          },
          ...(isQC
            ? [
                {
                  title: '渠道',
                  key: 'channel',
                  dataIndex: 'attenderInfo',
                  width: 100,
                  render(_: any, attender: any) {
                    const channels = useUserStore().channels
                    return attender.channel
                      ? channels.find((i) => i.value === attender.channel)
                          ?.text || '-'
                      : '-'
                  }
                }
              ]
            : []),
          {
            title: '角色',
            key: 'role',
            dataIndex: 'attenderData',
            width: 100,
            render(data) {
              return data.role
            }
          },
          {
            title: '积分',
            dataIndex: 'attenderInfo',
            key: 'attenderFee',
            width: 100,
            render(_, attender) {
              return attender.attenderFee
            }
          },
          {
            title: '描述',
            key: 'des',
            dataIndex: 'attenderData',
            render(data) {
              return meetingType === 'MEDICAL_RESEARCH_PROJECT' ? (
                <>
                  <div>组次数: {data.count}</div>
                  <div>单次金额: {data.average}</div>
                </>
              ) : (
                <>
                  <div>课题: {data.topic}</div>
                  <div>时长: {data.duration}</div>
                </>
              )
            }
          },
          {
            title: '签约状态',
            dataIndex: 'attenderInfo',
            key: 'attenderStatus',
            width: 100,
            render(_, attender) {
              return attender.attenderStatus
                ? CONTRACT_STATUS[
                    attender.attenderStatus as keyof typeof CONTRACT_STATUS
                  ] || '未签约'
                : '未签约 '
            }
          },
          {
            title: '职称证',
            key: 'certification',
            dataIndex: 'attenderInfo',
            width: 100,
            render(data) {
              return data?.userTitleCertificationImages?.certImages.length ? (
                <Image.PreviewGroup>
                  {data?.userTitleCertificationImages?.certImages?.map(
                    (item: string, index: number) => {
                      return (
                        <Image
                          key={index}
                          width={60}
                          src={getImgSource(item)}
                        />
                      )
                    }
                  )}
                </Image.PreviewGroup>
              ) : (
                '-'
              )
            }
          },
          {
            title: '执业资格',
            key: 'certification',
            dataIndex: 'attenderInfo',
            width: 100,
            render(data) {
              return data?.userCertificationImages?.certImages.length ? (
                <Image.PreviewGroup>
                  {data?.userCertificationImages?.certImages?.map(
                    (item: string, index: number) => {
                      return (
                        <Image
                          key={index}
                          width={60}
                          src={getImgSource(item)}
                        />
                      )
                    }
                  )}
                </Image.PreviewGroup>
              ) : (
                '-'
              )
            }
          },
          {
            title: '任务信息',
            key: 'taskInfo',
            dataIndex: 'id',
            width: 100,
            render(v) {
              if (
                meetingType &&
                [
                  'CATE_DOCUMENT_COMMENT',
                  'CATE_QUESTIONNAIRE_RESEARCH',
                  'CATE_CASE_COLLECTION',
                  'CATE_DOCUMENT_COMPOSE'
                ].includes(meetingType)
              ) {
                return <Link to={`/taskDetail/${v}`}>查看</Link>
              }
              return '-'
            }
          },
          {
            title: '全景照',
            key: 'panoramicPic',
            dataIndex: 'attenderData',
            width: 100,
            render(data) {
              const urls = formatPanoramicPicUrls(data)
              return urls?.length ? (
                <Image.PreviewGroup>
                  {urls.map((item, index) => {
                    return (
                      <Image key={index} width={60} src={getImgSource(item)} />
                    )
                  })}
                </Image.PreviewGroup>
              ) : (
                '-'
              )
            }
          },
          {
            title: '正面讲课照',
            key: 'frontPic',
            dataIndex: 'attenderData',
            width: 120,
            render(data) {
              const urls = formatFrontPicUrls(data)
              return urls?.length ? (
                <Image.PreviewGroup>
                  {urls.map((item, index) => {
                    return (
                      <Image key={index} width={60} src={getImgSource(item)} />
                    )
                  })}
                </Image.PreviewGroup>
              ) : (
                '-'
              )
            }
          },
          {
            title: '复核材料',
            key: 'userComplexMaterialCertImages',
            dataIndex: 'attenderInfo',
            width: 100,
            render(data) {
              return data?.userComplexMaterialCertImages?.certImages.length ? (
                <Image.PreviewGroup>
                  {data?.userComplexMaterialCertImages?.certImages?.map(
                    (item: string, index: number) => {
                      return isVideoUrl(item) ? (
                        <VideoButton
                          key={index}
                          url={item}
                          style={{
                            width: 60,
                            height: 70,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        />
                      ) : (
                        <Image
                          key={index}
                          width={60}
                          src={getImgSource(item)}
                        />
                      )
                    }
                  )}
                </Image.PreviewGroup>
              ) : (
                '-'
              )
            }
          },
          // {
          //   title: '合同',
          //   key: 'contract',
          //   dataIndex: 'attenderData',
          //   width: 100,
          //   render(_, attender) {
          //     const [{ data, fetching, error }] = useHrAttenderContractQuery({
          //       variables: {
          //         attenderId: attender.attenderId!,
          //         meetingId
          //       }
          //     })

          //     if (error) return '获取失败'
          //     if (fetching) return <LoadingOutlined />

          //     if (!data?.contract) return '-'

          //     return (
          //       <a href={data.contract} target='__blank'>
          //         查看
          //       </a>
          //     )
          //   }
          // },
          {
            title: '打款凭证',
            key: 'paymentVouchers',
            dataIndex: 'attenderData',
            width: 100,
            render(_, attender) {
              return (
                <PreviewModalButton
                  file={get(attender.attenderData, 'paymentVouchers')?.[0]}
                  files={get(attender.attenderData, 'paymentVouchers')}
                  type='payment-vouchers'
                  attenderId={attender.attenderId!}
                  status={status}
                  meetingId={meetingId}
                  onUploaded={async (url) => {
                    await uploadPaymentVoucher({
                      urls: [url],
                      meetingId,
                      attenderId: attender.attenderId!
                    })
                    onRefreshed()
                  }}
                >
                  <PaymentDetail
                    paymentId={(attender.attenderData as any)?.paymentId}
                    channel={
                      attender.channel as HadesPaymentChannel | undefined
                    }
                    type={HadesReceiptType.Vouchaer}
                  />
                </PreviewModalButton>
              )
            }
          },
          {
            title: '完税证明',
            key: 'taxReceipts',
            dataIndex: 'attenderData',
            width: 100,
            render(_, attender) {
              return (
                <PreviewModalButton
                  file={get(attender.attenderData, 'taxReceipts')?.[0]}
                  type='tax-receipts'
                  attenderId={attender.attenderId!}
                  status={status}
                  meetingId={meetingId}
                  onUploaded={async (url) => {
                    await uploadTaxReceipts({
                      urls: [url],
                      meetingId,
                      attenderId: attender.attenderId!
                    })
                    onRefreshed()
                  }}
                >
                  <PaymentDetail
                    paymentId={(attender.attenderData as any)?.paymentId}
                    channel={
                      attender.channel as HadesPaymentChannel | undefined
                    }
                    type={HadesReceiptType.Tax}
                  />
                </PreviewModalButton>
              )
            }
          },
          {
            title: '发票',
            key: 'invoices',
            dataIndex: 'attenderData',
            width: 100,
            render(_, attender) {
              return (
                <PreviewModalButton
                  file={get(attender.attenderData, 'invoices')?.[0]}
                  type='invoices'
                  attenderId={attender.attenderId!}
                  status={status}
                  meetingId={meetingId}
                  onUploaded={async (url) => {
                    await uploadInvoices({
                      urls: [url],
                      meetingId,
                      attenderId: attender.attenderId!
                    })
                    onRefreshed()
                  }}
                >
                  <PaymentDetail
                    paymentId={(attender.attenderData as any)?.paymentId}
                    channel={
                      attender.channel as HadesPaymentChannel | undefined
                    }
                    type={HadesReceiptType.Invoice}
                  />
                </PreviewModalButton>
              )
            }
          },
          {
            title: '银行卡号',
            key: 'bankNum',
            dataIndex: 'attenderData',
            width: 150,
            render(_, attender) {
              return attender.attenderInfo?.bankInfo?.certNo
            }
          },
          {
            title: '发卡行',
            key: 'bankName',
            dataIndex: 'attenderData',
            width: 150,
            render(_, attender) {
              return attender.channel !== 'QKK'
                ? attender.attenderInfo?.bankInfo?.ssqBankName
                : attender.attenderInfo?.bankInfo?.qkkBankName
            }
          }
          // {
          //   title: '区域&客户验证',
          //   key: 'province',
          //   dataIndex: 'attenderInfo',
          //   width: 150,
          //   render(_: any, attender: any) {
          //     if (!attender?.attenderInfo?.province) return '-'

          //     const title = `${ownerName}区域对应的省份${mettingProvinceName}与${attender.attenderInfo?.cnName}老师单位${attender.attenderInfo?.hospital}所在省${attender.attenderInfo?.province}不一致`
          //     return mettingProvinceName?.includes(
          //       attender?.attenderInfo?.province
          //     ) ? (
          //       <span style={{ color: '#52c41a' }}>一致</span>
          //     ) : (
          //       <Tooltip placement='topRight' title={title}>
          //         <span style={{ color: '#ff4d4f' }}>不一致</span>
          //       </Tooltip>
          //     )
          //   }
          // }
          // {
          //   title: '验证信息',
          //   key: 'verification',
          //   dataIndex: 'attenderInfo',
          //   fixed: 'right',
          //   width: 250,
          //   render(_: any, attender: any) {
          //     const checkProvinceResult = mettingProvinceName?.includes(
          //       attender?.attenderInfo?.province!
          //     )
          //     const provinceTipTitle = `${ownerName}区域对应的省份${mettingProvinceName}与${attender.attenderInfo?.cnName}老师单位${attender.attenderInfo?.hospital}所在省${attender.attenderInfo?.province}不一致`
          //     const panoramicPicAuditRecord = meetingAuditRecord?.filter(
          //       (x) =>
          //         x.verifyType?.includes('老师全景照') &&
          //         x.attenderId === attender.id
          //     )
          //     const frontPicAuditRecord = meetingAuditRecord?.filter(
          //       (x) =>
          //         x.verifyType?.includes('老师讲课照') &&
          //         x.attenderId === attender.id
          //     )
          //     const attenderFeeAuditRecord = meetingAuditRecord?.filter(
          //       (x) =>
          //         x.verifyType == '积分终端验证' && x.attenderId === attender.id
          //     )
          //     const panoramicPicAuditRecordData =
          //       PanoramicPicAuditRecordData.map((x) => {
          //         const item =
          //           panoramicPicAuditRecord?.find(
          //             (item) =>
          //               item.verifyType === x.type &&
          //               item.verifyDimension === x.dimension
          //           ) || {}
          //         return {
          //           label: x.label,
          //           status: item.status
          //         }
          //       })
          //     const frontPicAuditRecordData = FrontPicAuditRecordData.map(
          //       (x) => {
          //         const item =
          //           frontPicAuditRecord?.find(
          //             (item) =>
          //               item.verifyType === x.type &&
          //               item.verifyDimension === x.dimension
          //           ) || {}
          //         return {
          //           label: x.label,
          //           status: item.status
          //         }
          //       }
          //     )
          //     const attenderFeeAuditRecordData = AttenderFeeAuditRecordData.map(
          //       (x) => {
          //         const item =
          //           attenderFeeAuditRecord?.find(
          //             (item) =>
          //               item.verifyType === x.type &&
          //               item.verifyDimension === x.dimension
          //           ) || {}
          //         return {
          //           label: x.label,
          //           status: item.status
          //         }
          //       }
          //     )

          //     return (
          //       <Fragment>
          //         <span className='dimensionTitle'>老师维度</span>
          //         {meetingType === 'ACADEMIC_CONFERENCE' && (
          //           <>
          //             <Descriptions
          //               title='全景照'
          //               size='small'
          //               column={1}
          //               labelStyle={labelStyle}
          //             >
          //               {panoramicPicAuditRecordData.map((item, index) => {
          //                 return (
          //                   <Descriptions.Item key={index} label={item.label}>
          //                     <AuditRecordTag status={item.status} />
          //                   </Descriptions.Item>
          //                 )
          //               })}
          //             </Descriptions>
          //             <Descriptions
          //               title='正面讲课照'
          //               size='small'
          //               column={1}
          //               labelStyle={labelStyle}
          //             >
          //               {frontPicAuditRecordData.map((item, index) => {
          //                 return (
          //                   <Descriptions.Item key={index} label={item.label}>
          //                     <AuditRecordTag status={item.status} />
          //                   </Descriptions.Item>
          //                 )
          //               })}
          //             </Descriptions>
          //           </>
          //         )}
          //         <Descriptions
          //           title='积分、终端验证'
          //           size='small'
          //           column={1}
          //           labelStyle={labelStyle}
          //         >
          //           {attenderFeeAuditRecordData.map((item, index) => {
          //             return (
          //               <Descriptions.Item key={index} label={item.label}>
          //                 <AuditRecordTag status={item.status} />
          //               </Descriptions.Item>
          //             )
          //           })}
          //         </Descriptions>
          //         <span className='dimensionTitle'>代表维度</span>
          //         <Descriptions size='small' column={1} labelStyle={labelStyle}>
          //           <Descriptions.Item
          //             label='区域&客户验证'
          //             contentStyle={
          //               checkProvinceResult ? successStyle : failStyle
          //             }
          //           >
          //             {checkProvinceResult ? (
          //               '通过'
          //             ) : (
          //               <Tooltip placement='topRight' title={provinceTipTitle}>
          //                 不通过
          //                 <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          //               </Tooltip>
          //             )}
          //           </Descriptions.Item>
          //         </Descriptions>
          //       </Fragment>
          //     )
          //   }
          // }
        ]}
      />
    </>
  )
}

export default AttenderTable
