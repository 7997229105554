import React, { useEffect, useState } from 'react'
import {
  useAdminApprovalFlowSaveMutation,
  useAdminCompanyApprovalDetailQuery,
  useAdminCompanyApprovalFormsQuery,
  useAdminCreateApprovalFlowMutation
} from '../../graphql'
import { Button, Card, Col, Form, Input, List, Modal, Row, Select } from 'antd'
import { ApprovalFlowEditor } from './ApprovalFlowEditor'
import { PageLoading } from '@ant-design/pro-layout'
import { requestFormTypes } from './metaData'

export const ApprovalFlowConfig = ({ companyId }: { companyId: string }) => {
  const [{ data }, refetch] = useAdminCompanyApprovalFormsQuery({
    variables: { companyId }
  })
  const [{ data: sysData }] = useAdminCompanyApprovalFormsQuery({
    variables: { companyId: '-999' }
  })

  const [currentForm, setCurrentForm] = useState<string>()
  const [{ data: approvalDetail, fetching: detailFetching }] =
    useAdminCompanyApprovalDetailQuery({
      variables: { formId: currentForm! },
      pause: !currentForm
    })

  const [, createFlow] = useAdminCreateApprovalFlowMutation()

  const [, saveFlow] = useAdminApprovalFlowSaveMutation()

  useEffect(() => {
    if (data?.forms && data.forms.length > 0) setCurrentForm(data.forms[0].id!)
  }, [JSON.stringify(data?.forms)])

  const openCreateModal = () => {
    const modal = Modal.info({
      title: '创建单据',
      content: (
        <Form
          onFinish={async (values) => {
            const result = await createFlow({
              id: sysData?.forms?.find((n) => n.key === values.key)?.id || '',
              name: values.name,
              key: values.key,
              desc: values.desc,
              companyId
            })
            if (result.data?.result) {
              modal.destroy()
              refetch()
            }
          }}
        >
          <Form.Item label='类型' name={'key'}>
            <Select
              options={
                sysData?.forms?.map((t) => ({
                  label: t.name!,
                  value: t.key!
                })) || []
              }
            />
          </Form.Item>
          <Form.Item label='名称' name={'name'}>
            <Input />
          </Form.Item>
          <Form.Item label='描述' name={'desc'}>
            <Input />
          </Form.Item>
          <Button htmlType='submit'>提交</Button>
        </Form>
      )
    })
  }

  return (
    <Card
      extra={
        <Button.Group>
          <Button onClick={() => refetch()}>刷新</Button>
          <Button type='primary' onClick={openCreateModal}>
            新增
          </Button>
        </Button.Group>
      }
    >
      <Row gutter={12}>
        <Col span={6}>
          <List bordered>
            {data?.forms?.map((form) => {
              return (
                <List.Item key={form.id}>
                  <Button
                    block
                    type={form.id === currentForm ? 'primary' : 'text'}
                    onClick={() => setCurrentForm(form.id!)}
                  >
                    {form.name}
                  </Button>
                </List.Item>
              )
            })}
          </List>
        </Col>
        <Col span={18}>
          {detailFetching ? (
            <PageLoading />
          ) : (
            <ApprovalFlowEditor
              companyId={companyId}
              onSave={(nodes) => {
                saveFlow({ formId: currentForm!, companyId, nodeGroups: nodes })
              }}
              nodeGroups={(approvalDetail?.detail as any)?.nodeGroups || []}
            />
          )}
        </Col>
      </Row>
    </Card>
  )
}
