import React, { FC } from 'react'
import { Button } from 'antd'
import { useUserStore } from '../hooks/user'
import { useUserLogOutMutation } from '../graphql'

const LogoutButton: FC = () => {
  // 登出接口
  const [, logOut] = useUserLogOutMutation()
  const userStore = useUserStore()

  const logingOut = () => {
    logOut()
    userStore.logout()
  }

  return <Button onClick={logingOut}>登出</Button>
}

export default LogoutButton
