/**
 * 会议详情（会议列表&&会议管理点击跳转详情 共用此页面）
 */
import React, { useState, useEffect, Fragment } from 'react'
import {
  Skeleton,
  Card,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Row,
  Col,
  Modal,
  Image
} from 'antd'
import { omit, pick, sumBy } from 'lodash'
import {
  useHrMeetingDetailQuery,
  StandardMeetingServiceBizStatus,
  useStartHrMeetingSettlementMutation,
  useHrMeetingRetreatMutation,
  useHrMeetingConfirmMutation,
  useMeetingApprovalFlowsQuery,
  StandardMeetingApprovalStatus,
  useHrMeetingForwardSignatureMutation,
  useSaveMeetingVerificationInfoMutation,
  AuditRecordResult
} from '../../graphql'
import { useParams } from 'react-router-dom'
import { useMeetingBaseInfo, useProjectBaseInfo } from './util'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import AttenderTable from './attenderTable'
import ScheduleAndCosts from './scheduleAndCosts'
import ApprovalModal from './approvalModal'
import ApprovalProcessCard from './approvalProcess'
import ForwardSignatureModal from './forwardSignatureModal'
import { getMessage } from '../../lib/getMessage'
import ApprovalConfirmModal from './approvalConfirmModal'
import ToApprovalModal from './toApprovalModal'
import OrderOperationLogTable from './orderOperationLogTable'

const getImgSource = (pic: string) => {
  const sliceIndex = pic?.indexOf('?')
  const url = sliceIndex > -1 ? pic.slice(0, sliceIndex) : pic
  if (/\.(png|jpe?g|jpeg|gif|webp|bmp)$/i.test(url)) {
    return url
  }
  return url + '?x-oss-process=image/format,jpg'
}

const getAuditRecordType = (auditRecord: AuditRecordResult) => {
  let type = {
    name: auditRecord.verifyType,
    dimension: auditRecord.verifyDimension
  }
  switch (auditRecord.verifyType) {
    case '老师全景照-重复性':
      type = { name: '全景照', dimension: '重复性' }
      break
    case '老师讲课照-重复性':
      type = { name: '正面讲课照', dimension: '重复性' }
      break
    case '老师讲课照-关联性':
      type = { name: '正面讲课照', dimension: '讲课照真实性' }
      break
    case '会议日程表-重复性':
      type = { name: '会议日程表', dimension: '重复性' }
      break
    case '会议日程表-日期关联性':
      type = { name: '会议日程表', dimension: '日期一致性' }
      break
    case '会议日程表-老师参会关联性':
      type = { name: '会议日程表', dimension: '老师参会验证' }
      break
    case '会议签到表-重复性':
      type = { name: '会议签到表', dimension: '重复性' }
      break
    case '会议签到表-关联性':
      type = { name: '会议签到表', dimension: '老师签到验证' }
      break
    default:
      break
  }
  return type
}

const MeetingDetail: QCPage = () => {
  const { meetingId }: { meetingId: string } = useParams()
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const userName = userStore.profile?.name || ''
  const isQCXS = userStore.hasAuth('uplatform_approval_manage_quanchengxueshu')
  const isYHLXS = userStore.hasAuth('uplatform_approval_manage_yihuilixueshu')
  const [isShowRetreatModal, updateRetreatModalShow] = useState(false)
  const [isShowConfirmModal, updateConfirmModalShow] = useState(false)
  const [isShowForwardSignatureModal, updateForwardSignatureModalShow] =
    useState(false)
  const [isShowToApproveModal, setIsShowToApproveModal] = useState(false)
  const [toApproveInfo, setToApproveInfo] = useState<any>(undefined)
  const [failReason, setFailReason] = useState<string | undefined>(undefined)
  const [isShowForwardSignatureTag, updateForwardSignatureTagShow] =
    useState(false)
  const [approveUserName, setApproveUserName] = useState('')

  const [{ fetching: forwardSignatureFetching }, forwardSignature] =
    useHrMeetingForwardSignatureMutation()

  const [{ fetching: approvalLoading }, meetingRetreat] =
    useHrMeetingRetreatMutation()
  const [{ fetching: confirmFetching }, meetingConfirm] =
    useHrMeetingConfirmMutation()

  const [{ fetching: markLoading }, startHrMeetingSettlementMutation] =
    useStartHrMeetingSettlementMutation()

  const [{ fetching: saveLoading }, saveMeetingVerificationInfo] =
    useSaveMeetingVerificationInfoMutation()

  const [{ data, fetching, error }, refetchDetail] = useHrMeetingDetailQuery({
    variables: {
      meetingId
    }
  })

  const [
    { data: approvalInfo, fetching: approvalsFetching },
    refetchApprovals
  ] = useMeetingApprovalFlowsQuery({
    variables: { meetingId }
  })

  useEffect(() => {
    // //验证信息不通过退回理由
    // if (data?.detail?.meetingAuditRecord?.length) {
    //   const meetingAuditRecord = data?.detail?.meetingAuditRecord?.filter(
    //     (x) => !x.status
    //   )
    //   if (meetingAuditRecord.length > 0) {
    //     const attenderAuditRecord = meetingAuditRecord.filter(
    //       (x) =>
    //         !(
    //           x.verifyType?.includes('会议日程表') ||
    //           x.verifyType?.includes('会议签到表')
    //         )
    //     )
    //     const auditRecordFailReasons: string[] = []
    //     if (attenderAuditRecord.length > 0) {
    //       const attenders =
    //         data?.detail?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
    //           ? data?.detail?.speakers
    //           : data?.detail?.doctors
    //       attenders?.forEach((attender) => {
    //         const auditRecords = attenderAuditRecord.filter(
    //           (record) => record.attenderId === attender.id
    //         )
    //         if (auditRecords?.length > 0) {
    //           auditRecords.forEach((record) => {
    //             const type = getAuditRecordType(record)
    //             auditRecordFailReasons.push(
    //               `${attender.attenderInfo?.cnName}老师，上传的${type.name},${type.dimension}验证未通过`
    //             )
    //           })
    //         }
    //       })
    //     }
    //     const scheduleAuditRecord = meetingAuditRecord.filter(
    //       (x) =>
    //         x.verifyType?.includes('会议日程表') ||
    //         x.verifyType?.includes('会议签到表')
    //     )
    //     if (scheduleAuditRecord.length > 0) {
    //       scheduleAuditRecord.forEach((record) => {
    //         const type = getAuditRecordType(record)
    //         auditRecordFailReasons.push(
    //           `${type.name},${type.dimension}验证未通过`
    //         )
    //       })
    //     }
    //     if (auditRecordFailReasons.length > 0) {
    //       setFailReason(auditRecordFailReasons.join('；'))
    //     }
    //   }
    // }

    if (
      data?.detail &&
      !fetching &&
      approvalInfo?.result &&
      !approvalsFetching
    ) {
      if (data?.detail?.approveUser) {
        try {
          let approveUserData = JSON.parse(data?.detail?.approveUser)
          if (approveUserData?.length > 0) {
            approveUserData = approveUserData.sort(
              (a: any, b: any) => a.createTime - b.createTime
            )
            for (const approval of approvalInfo?.result) {
              const approveUserItem = approval.tasks?.find(
                (x) => x.approverInfo?.roleAuthoritiesIncludeQcxs
              )
              if (approveUserItem?.approverInfo?.cnName) {
                const instanceId = approval.instanceId
                let approveUserName = approveUserData
                  .filter((x: any) => x.instanceId === instanceId)
                  .map((x: any) => x.approveUser)
                  .join('-')
                if (approveUserName) {
                  approveUserName =
                    approveUserItem?.approverInfo?.cnName +
                    `（${approveUserName}）`
                  approveUserItem.approverInfo.cnName = approveUserName
                }
              }
            }
            setApproveUserName(data?.detail?.approveUser)
          }
        } catch (error) {
          console.log(error)
        }
      }
      const approvals = approvalInfo?.result?.map((item) => item.tasks)
      const currentApproval = approvals.pop() || []
      let isShowForwardSignature = false
      for (const approval of currentApproval) {
        const afterApproval = approval.logDatas?.find(
          (x: any) => x.logType === 'BEFORE' || x.logType === 'AFTER'
        )
        if (afterApproval) {
          isShowForwardSignature = true
          break
        }
      }
      if (isShowForwardSignature) {
        updateForwardSignatureTagShow(true)
      }
    }
  }, [data, approvalInfo])

  const startHrMeetingSettlement = async () => {
    const { data } = await startHrMeetingSettlementMutation({
      meetingId
    })
    if (data?.result) {
      message.success('开始结算')
      refetchDetail()
    } else {
      message.error('失败')
    }
  }

  // 活动/会议
  const fields = useMeetingBaseInfo(
    data?.detail,
    data?.detail?.base?.type || '',
    production
  )
  // 项目
  const projectFields = useProjectBaseInfo(data?.detail)

  const attenders =
    data?.detail?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
      ? data?.detail?.speakers
      : data?.detail?.doctors

  const checkForwardSignature = () => {
    if (data?.detail?.forwardSignatureOwner) {
      updateForwardSignatureModalShow(true)
    } else {
      message.error('该业务部暂未设置“向后加签”人员')
    }
  }

  const saveVerificationInfo = async (approver?: string) => {
    const { data: saveResultData } = await saveMeetingVerificationInfo({
      meetingId,
      meetingType: data?.detail?.base?.type!,
      meetingProperties:
        data?.detail?.base?.type === 'ACADEMIC_CONFERENCE'
          ? JSON.stringify(pick(toApproveInfo, ['schedulePic', 'signPic']))
          : undefined,
      attenderProperties: JSON.stringify(
        omit(toApproveInfo, ['schedulePic', 'signPic'])
      ),
      approver
    })
    return saveResultData?.result
  }

  const startForwardSignature = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await forwardSignature({
      meetingId,
      reason: values.reason,
      approveUser: isYHLXS ? userName : values.approveUser,
      insertPosition: 'AFTER'
    })
    if (data?.result) {
      message.success('加签成功')
      callBack && callBack()
      updateForwardSignatureModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('加签失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalRetreat = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await meetingRetreat({
      meetingId,
      reason: values.reason,
      approveUser: isYHLXS ? userName : values.approveUser
    })
    if (data?.result) {
      message.success('退回成功')
      callBack && callBack()
      updateRetreatModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退回失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const meetingConfirmHandler = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await meetingConfirm({
      meetingId,
      approveUser: isYHLXS ? userName : values.approveUser
    })
    if (data?.result) {
      message.success('审批通过')
      callBack && callBack()
      isShowConfirmModal && updateConfirmModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('审批失败:' + errorMsg)
      callBack && callBack()
    }
  }

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {/* 会议名称 + 会议状态 */}
      <Card
        title={
          <Fragment>
            {data?.detail?.base?.name}
            {isShowForwardSignatureTag && (
              <Tag
                style={{ fontSize: 14, marginLeft: 4, fontWeight: 'normal' }}
                color='orange'
              >
                加签
              </Tag>
            )}
          </Fragment>
        }
        loading={fetching}
        extra={
          <Tag style={{ fontSize: 14 }} color='orange'>
            {data?.detail?.status ===
            StandardMeetingServiceBizStatus.MeetingInApproving
              ? data?.detail?.statusExtra || data?.detail?.statusText
              : data?.detail?.statusText}
          </Tag>
        }
      >
        {/* 会议概览字段展示 */}
        <Descriptions column={{ xs: 1, sm: 2, md: 4 }}>
          {(data?.detail?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
            ? fields
            : projectFields
          ).map((field) => (
            <Descriptions.Item key={field.label} label={field.label}>
              {field.content}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>

      {/* 参与老师  */}
      <Card
        title={'参与老师'}
        extra={
          <Space>
            <span>
              {attenders?.length}位老师, 共计
              {sumBy(attenders, (d) => parseFloat(d.attenderFee || '0'))}积分
            </span>
            {/* 材料审批通过，发起结算 按钮 */}
            {[
              StandardMeetingServiceBizStatus.MeetingSettlementConfirming
            ].includes(
              data?.detail?.status as StandardMeetingServiceBizStatus
            ) &&
              userStore.hasAuth('uplatform_payment_upload_opt') && [
                <Button
                  loading={markLoading}
                  disabled={markLoading}
                  key='startHrMeetingSettlementMutation'
                  type='primary'
                  onClick={() => {
                    const ssqers = attenders?.filter(
                      (attender) => attender.channel === 'SSQ'
                    )
                    if (ssqers && ssqers.length > 0) {
                      Modal.confirm({
                        content: `会议中${ssqers
                          .map((ssq) => ssq.attenderInfo?.cnName)
                          .join(
                            ','
                          )}老师签约渠道是上上签，需要自己线下打款！其他老师可用商税宝结算`,
                        async onOk() {
                          startHrMeetingSettlement()
                        }
                      })
                    } else {
                      startHrMeetingSettlement()
                    }
                  }}
                >
                  材料审批通过，发起结算
                </Button>
              ]}
            {/* 和h5审批功能相同的 拒绝、通过按钮 */}
            {data?.detail?.isCurrentApprover &&
              data?.detail?.approveStatus ===
                StandardMeetingApprovalStatus.Approving && [
                (isQCXS || isYHLXS) && (
                  <Button
                    loading={forwardSignatureFetching}
                    disabled={forwardSignatureFetching}
                    key='toApprove'
                    type='primary'
                    onClick={() => {
                      setIsShowToApproveModal(true)
                    }}
                  >
                    去审核
                  </Button>
                ),
                // !isQCXS &&
                //   !isYHLXS &&
                //   userStore.hasAuth(
                //     'uplatform_attender_task_forwardSignature'
                //   ) && (
                //     <Button
                //       loading={forwardSignatureFetching}
                //       disabled={forwardSignatureFetching}
                //       key='taskForwardSignature'
                //       type='primary'
                //       onClick={() => {
                //         checkForwardSignature()
                //       }}
                //     >
                //       向后加签
                //     </Button>
                //   ),
                !isQCXS && !isYHLXS && (
                  <Button
                    loading={approvalLoading}
                    disabled={approvalLoading}
                    key='meetingRetreat'
                    type='primary'
                    onClick={() => {
                      updateRetreatModalShow(true)
                    }}
                  >
                    审批退回
                  </Button>
                ),
                !isQCXS && !isYHLXS && (
                  <Button
                    loading={confirmFetching}
                    disabled={confirmFetching}
                    key='meetingRetreat'
                    type='primary'
                    onClick={() => {
                      meetingConfirmHandler({})
                    }}
                  >
                    审批通过
                  </Button>
                )
              ]}
          </Space>
        }
      >
        {/* 参与老师的信息 Table */}
        <AttenderTable
          onRefreshed={() => {
            refetchDetail()
            refetchApprovals()
          }}
          attenders={attenders}
          meetingId={meetingId}
          status={data?.detail?.status}
          meetingType={data?.detail?.base?.type}
          ownerName={data?.detail?.base?.owner?.cnName}
          mettingProvinceName={data?.detail?.mettingRegion?.province?.map(
            (x) => x.name
          )}
          production={production}
          // meetingAuditRecord={data?.detail?.meetingAuditRecord}
        />
      </Card>

      {/* 监察报告 */}
      {data?.detail?.base?.type === 'MEDICAL_RESEARCH_PROJECT' &&
        (data.detail.checkReports?.length || 0) > 0 && (
          <Card title='监察报告'>
            <Row gutter={16}>
              <Image.PreviewGroup>
                {data.detail.checkReports?.map((pic, index) => {
                  return (
                    <Col key={index} className='gutter-row' span={3}>
                      <Image key={index} width={100} src={getImgSource(pic)} />
                    </Col>
                  )
                })}
              </Image.PreviewGroup>
            </Row>
          </Card>
        )}

      {/* 会议日程 + 记录费用 */}
      {data?.detail?.base?.type &&
      [
        'CATE_DOCUMENT_COMMENT',
        'CATE_QUESTIONNAIRE_RESEARCH',
        'CATE_CASE_COLLECTION',
        'CATE_DOCUMENT_COMPOSE'
      ].includes(data?.detail?.base?.type) ? null : (
        <ScheduleAndCosts data={data} />
      )}

      {/* 审批信息 */}
      <ApprovalProcessCard
        loading={approvalsFetching}
        approvalInfo={approvalInfo?.result || []}
        isShow={
          data?.detail?.status !==
          StandardMeetingServiceBizStatus.MeetingInProgress
        }
      />

      {meetingId && (
        <Card title='操作日志'>
          <OrderOperationLogTable meetingId={meetingId} />
        </Card>
      )}

      {/* 审批 通过 */}
      {isShowConfirmModal ? (
        <ApprovalConfirmModal
          onCancel={() => {
            updateConfirmModalShow(false)
          }}
          onSubmit={meetingConfirmHandler}
          isQCXS={isQCXS}
        />
      ) : null}

      {/* 审批 拒绝 */}
      {isShowRetreatModal ? (
        <ApprovalModal
          onCancel={() => {
            updateRetreatModalShow(false)
          }}
          onSubmit={startApprovalRetreat}
          meetingType={data?.detail?.base?.type}
          isQCXS={isQCXS}
          failReason={failReason}
        />
      ) : null}

      {/* 加签 */}
      {isShowForwardSignatureModal ? (
        <ForwardSignatureModal
          onCancel={() => {
            updateForwardSignatureModalShow(false)
          }}
          onSubmit={startForwardSignature}
          meetingType={data?.detail?.base?.type}
          isQCXS={isQCXS}
          failReason={failReason}
        />
      ) : null}

      {/* 去审批 */}
      {isShowToApproveModal ? (
        <ToApprovalModal
          onCancel={() => {
            setToApproveInfo(undefined)
            setFailReason(undefined)
            setIsShowToApproveModal(false)
          }}
          onHandleForwardSignature={(values, failReason) => {
            setToApproveInfo(values)
            setFailReason(failReason)
            checkForwardSignature()
          }}
          onHandleRetreat={(values, failReason) => {
            setToApproveInfo(values)
            setFailReason(failReason)
            updateRetreatModalShow(true)
          }}
          onHandleConfirm={(values) => {
            setToApproveInfo(values)
            updateConfirmModalShow(true)
          }}
          meetingInfo={data?.detail}
          production={production}
        />
      ) : null}
    </Space>
  )
}

MeetingDetail.pageTitle = '详情'

export default MeetingDetail
