import { Space } from 'antd'
import React from 'react'
import { ColumnsType } from 'antd/lib/table'

import {
  MeetingCategory,
  MeetingActivity,
  DocumentSphereInfo
} from '../../graphql'
import dayjs from 'dayjs'

export const useMeetingTypeColumns = ({
  onStatusChange,
  onToggleAutoComplete,
  onEditTitleBlacklist,
  onEditProperties
}: {
  onStatusChange: (typeInfo: MeetingCategory) => void
  onToggleAutoComplete: (typeInfo: MeetingCategory) => void
  onEditTitleBlacklist: (typeInfo: MeetingCategory) => void
  onEditProperties: (typeInfo: MeetingCategory) => void
}): ColumnsType<MeetingCategory> => {
  return [
    {
      title: '会议类型',
      dataIndex: 'config',
      key: 'meetingType',
      align: 'center',
      render(v) {
        return v?.title
      }
    },
    {
      title: 'code',
      dataIndex: 'config',
      key: 'meetingCode',
      align: 'center',
      render(v) {
        return v?.code
      }
    },
    {
      title: '状态',
      dataIndex: 'enable',
      align: 'center',
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    },
    {
      title: '自动完成',
      dataIndex: 'autoComplete',
      align: 'center',
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    },
    // {
    //   title: '职称过滤',
    //   dataIndex: 'titleFilterText',
    //   align: 'center'
    // },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      render(v, typeInfo) {
        return (
          <Space size='large'>
            <a onClick={() => onStatusChange(typeInfo)}>
              {!typeInfo.enable ? '启用' : '禁用'}
            </a>
            <a onClick={() => onToggleAutoComplete(typeInfo)}>
              {!typeInfo.autoComplete ? '启用自动完成' : '禁用自动完成'}
            </a>
            {/* <a onClick={() => onEditTitleBlacklist(typeInfo)}>编辑职称过滤</a> */}
            <a onClick={() => onEditProperties(typeInfo)}>编辑属性</a>
          </Space>
        )
      }
    }
  ]
}

export const useActivityTypeColumns = ({
  onStatusChange
}: {
  onStatusChange: (typeInfo: MeetingActivity & { meetingType: string }) => void
}): ColumnsType<MeetingActivity & { meetingType: string }> => {
  return [
    {
      title: '活动类型',
      dataIndex: 'config',
      key: 'activityType',
      align: 'center',
      width: 200,
      render(v) {
        return v?.title
      }
    },
    {
      title: 'code',
      dataIndex: 'config',
      key: 'activityCode',
      align: 'center',
      width: 200,
      render(v) {
        return (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {v?.code}
          </div>
        )
      }
    },
    {
      title: '起止时间',
      dataIndex: '',
      key: 'timerange',
      align: 'center',
      width: 200,
      render(v) {
        return (
          (v?.start ? dayjs(v?.start).format('YYYY-MM-DD') : '') +
          '-' +
          (v?.end ? dayjs(v?.end).format('YYYY-MM-DD') : '')
        )
      }
    },
    {
      title: '最大份数',
      dataIndex: 'instanceMax',
      key: 'instanceMax',
      align: 'center',
      width: 100
    },
    {
      title: '最小份数',
      dataIndex: 'instanceMin',
      key: 'instanceMin',
      align: 'center',
      width: 100
    },
    {
      title: '模板code',
      dataIndex: 'templateCode',
      key: 'templateCode',
      align: 'center',
      width: 100
    },
    {
      title: '所属会议类型',
      dataIndex: 'meetingType',
      align: 'center',
      width: 120
    },
    {
      title: '所属产品',
      dataIndex: 'productType',
      key: 'productType',
      align: 'center',
      width: 100
    },
    {
      title: '模板名称',
      dataIndex: 'templateInfo',
      key: 'templateName',
      align: 'center',
      width: 100,
      render(v) {
        return v?.templateName || '-'
      }
    },
    {
      title: '所属领域',
      dataIndex: 'activitySphere',
      align: 'center',
      width: 100,
      render(v) {
        return v?.sphereName || '-'
      }
    },
    {
      title: '状态',
      dataIndex: 'enable',
      align: 'center',
      width: 100,
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'base',
      key: 'operation',
      align: 'center',
      width: 100,
      render(v, typeInfo) {
        return (
          <Space>
            <a onClick={() => onStatusChange(typeInfo)}>
              {!typeInfo.enable ? '启用' : '禁用'}
            </a>
          </Space>
        )
      }
    }
  ]
}

export const useTemplateTypeColumns = ({
  onStatusChange
}: {
  onStatusChange: (typeInfo: MeetingActivity & { meetingType: string }) => void
}): ColumnsType<MeetingActivity & { meetingType: string }> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '名称',
      dataIndex: 'templateName',
      align: 'center'
    },
    {
      title: 'code',
      dataIndex: 'templateCode',
      align: 'center'
    },
    {
      title: '单价',
      dataIndex: 'unitPrice',
      align: 'center'
    },
    {
      title: '文献类型',
      dataIndex: 'documentCategoryInfo',
      align: 'center',
      render(v) {
        return v?.title
      }
    },

    {
      title: '状态',
      dataIndex: 'enable',
      align: 'center',
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'base',
      key: 'operation',
      align: 'center',
      render(v, typeInfo) {
        return (
          <Space>
            <a onClick={() => onStatusChange(typeInfo)}>
              {!typeInfo.enable ? '启用' : '禁用'}
            </a>
          </Space>
        )
      }
    }
  ]
}

export const useSphereTypeColumns = (): ColumnsType<DocumentSphereInfo> => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '名称',
      dataIndex: 'sphereName',
      align: 'center'
    },
    {
      title: 'code',
      dataIndex: 'sphereCode',
      align: 'center'
    },
    {
      title: '描述',
      dataIndex: 'describe',
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      align: 'center',
      render(v) {
        return (
          <span style={{ color: !v ? '#ff4d4f' : '' }}>
            {!v ? '已停用' : '使用中'}
          </span>
        )
      }
    }
  ]
}
