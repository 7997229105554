import React from 'react'
import { Input, Radio, Switch } from 'antd'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'

import DatePicker from '../../components/CustomDatePicker'
import {
  APPROVE_WAIT_SORT_FIELD,
  QCXS_APPROVE_WAIT_SORT_FIELD,
  BUDGET_TYPE_MAP
} from '../../constant/hrradiation'
import { sumBy } from 'lodash'
import _ from 'lodash'
import { HR_PRODUCTION } from '../../constant/user'
import {
  ApproveUserSelector,
  CostCenterSelector,
  HRRegionSelector,
  MeetingTypeSelector
} from '../../components/SelectorCollection'

//搜索字段声明
export const approvePendingSearchFields = (
  type: string,
  searchParams: any,
  production?: string,
  companyId?: string,
  isQCXS?: boolean,
  isYHLXS?: boolean
) => [
  ...(type === 'approvePending'
    ? [
        ...(production === HR_PRODUCTION.hansoh
          ? [
              {
                name: 'meetingCostCenter',
                label: '所属成本中心',
                span: 12,
                render() {
                  return (
                    <CostCenterSelector
                      isMulti={false}
                      companyId={companyId}
                      pid={process.env.HANSOH_COSTCENTER_PID}
                    />
                  )
                }
              }
            ]
          : [
              {
                name: 'meetingRegion',
                label: '所属省区',
                span: 12,
                render() {
                  return <HRRegionSelector isMulti companyId={companyId} />
                }
              }
            ]),
        {
          name: 'dateApprovePending',
          label: '审批时间',
          span: 12,
          render() {
            return (
              <DatePicker.RangePicker locale={locale} format='YYYY-MM-DD' />
            )
          }
        },
        {
          name: 'meetingTypes',
          label: '会议类型',
          span: 12,
          render() {
            const isQC =
              production === HR_PRODUCTION.qc ||
              production === HR_PRODUCTION.qcDemo
            return (
              <MeetingTypeSelector
                mode='multiple'
                filterByCompanyId={!isQC}
                companyId={companyId}
              />
            )
          }
        },
        {
          name: 'sortApprovePending',
          label: '排序字段',
          span: 12,
          defaultValue: 'approvalTime',
          render() {
            return (
              <Radio.Group name='radiogroup' defaultValue={'approvalTime'}>
                {Object.keys(
                  isQCXS
                    ? QCXS_APPROVE_WAIT_SORT_FIELD
                    : APPROVE_WAIT_SORT_FIELD
                ).map((key) => (
                  <Radio key={key} value={key}>
                    {
                      (isQCXS
                        ? QCXS_APPROVE_WAIT_SORT_FIELD
                        : APPROVE_WAIT_SORT_FIELD)[key]
                    }
                  </Radio>
                ))}
              </Radio.Group>
            )
          }
        },
        {
          name: 'keywordApprovePending',
          label: null,
          span: 12,
          render() {
            return (
              <Input
                allowClear
                placeholder='请输入会议ID/名称、申请人名字搜索'
              />
            )
          }
        },
        {
          name: 'orderApprovePending',
          label: '排序规则',
          span: 12,
          render() {
            return (
              <Switch
                checkedChildren={'升序'}
                unCheckedChildren={'降序'}
                checked={searchParams?.orderApprovePending}
              />
            )
          }
        }
      ]
    : []),
  ...(type === 'approved'
    ? [
        ...(!isYHLXS
          ? [
              {
                name: 'dateApproved',
                label: '会议日期',
                span: 8,
                render() {
                  return (
                    <DatePicker.RangePicker
                      locale={locale}
                      format='YYYY-MM-DD'
                    />
                  )
                }
              }
            ]
          : []),
        {
          name: 'keywordApproved',
          label: null,
          span: 8,
          render() {
            return (
              <Input
                allowClear
                placeholder='请输入会议ID/名称、申请人名字搜索'
              />
            )
          }
        },
        ...(!isYHLXS
          ? [
              {
                name: 'approveUsers',
                label: '审批人员',
                span: 8,
                render() {
                  return (
                    <ApproveUserSelector
                      mode='multiple'
                      auto={true}
                      isRemoveSys={false}
                    />
                  )
                }
              }
            ]
          : [])
      ]
    : [])
]

export const useTableColumns = (props: {
  type: string
  production: string
  isQCXS?: boolean
  isYHLXS?: boolean
}): any => [
  {
    title: '会议ID',
    dataIndex: 'meetingInfo',
    key: 'meetingId',
    fixed: 'left',
    align: 'center',
    width: 200,
    render(v: any) {
      return (
        <Link
          to={`/${
            props.isQCXS || props.isYHLXS ? 'meetingApproveDetail' : 'meeting'
          }/${v?.id}`}
        >
          {v?.id}
        </Link>
      )
    }
  },
  {
    title: '会议类型',
    dataIndex: 'meetingType',
    key: 'meetingType',
    align: 'center',
    width: 100
  },
  ...(props.production === HR_PRODUCTION.hansoh
    ? [
        {
          title: '产品线',
          dataIndex: 'meetingInfo',
          key: 'productType',
          align: 'center',
          width: 100,
          render(v: any) {
            return v?.belongProduct?.productName
          }
        }
      ]
    : []),

  {
    title: '活动类型',
    dataIndex: 'activityType',
    key: 'activityType',
    align: 'center',
    width: 200
  },
  {
    title: '会议名称',
    dataIndex: 'meetingInfo',
    key: 'meetingName',
    align: 'center',
    width: 200,
    render(v: any) {
      return v?.name
    }
  },
  {
    title: '会议城市',
    dataIndex: 'city',
    key: 'city',
    align: 'center',
    width: 100,
    render(v: any) {
      return v?.name
    }
  },
  {
    title: '会议开始时间',
    dataIndex: 'meetingInfo',
    key: 'startTime',
    align: 'center',
    width: 150,
    render(v: any) {
      return dayjs(v?.startTime).format('YYYY-MM-DD')
    }
  },
  {
    title: '会议结束时间',
    dataIndex: 'meetingInfo',
    key: 'endTime',
    align: 'center',
    width: 150,
    render(v: any) {
      return dayjs(v?.endTime).format('YYYY-MM-DD')
    }
  },
  {
    title: '老师数量',
    dataIndex: 'attenders',
    key: 'peopleNumber',
    align: 'center',
    width: 100,
    render(v: any) {
      return v?.length
    }
  },
  {
    title: '总积分',
    dataIndex: 'attenders',
    key: 'totalFee',
    align: 'center',
    width: 100,
    render(v: any) {
      return `${sumBy(v, (d: any) => parseFloat(d?.attenderFee))}`
    }
  },
  {
    title: '创建时间',
    dataIndex: 'meetingInfo',
    key: 'createTime',
    align: 'center',
    width: 100,
    render(v: any) {
      return dayjs(v?.createTime).format('YYYY-MM-DD')
    }
  },
  {
    title: '提交时间',
    dataIndex: 'meetingInfo',
    key: 'submitTime',
    align: 'center',
    width: 100,
    render(v: any) {
      return v?.submitTime ? dayjs(v?.submitTime).format('YYYY-MM-DD') : ''
    }
  },
  ...(props.type === 'approved'
    ? []
    : [
        {
          title: '审批时间',
          dataIndex: 'meetingInfo',
          key: 'approvalTime',
          align: 'center',
          width: 100,
          render(v: any) {
            return v?.approvalTime
              ? dayjs(v?.approvalTime).format('YYYY-MM-DD')
              : ''
          }
        }
      ]),
  {
    title: '申请人',
    dataIndex: 'meetingInfo',
    key: 'applyPeople',
    align: 'center',
    width: 100,
    render(v: any) {
      return v?.owner?.cnName
    }
  },
  {
    title: '申请人电话',
    dataIndex: 'meetingInfo',
    key: 'applyPhone',
    align: 'center',
    width: 150,
    render(v: any) {
      return v?.owner?.companyMobile
    }
  },
  {
    title: '申请人省区',
    dataIndex: 'meetingInfo',
    key: 'applyRegion',
    align: 'center',
    width: 150,
    render(v: any) {
      return v?.owner?.region?.name
    }
  },
  {
    title: '申请人部门',
    dataIndex: 'meetingInfo',
    key: 'applyStructure',
    align: 'center',
    width: 150,
    render(v: any) {
      return v?.owner?.structure?.name
    }
  },
  ...(props.production === HR_PRODUCTION.hansoh
    ? [
        {
          title: '申请人成本中心',
          dataIndex: 'meetingInfo',
          key: 'applyCostCenter',
          align: 'center',
          width: 150,
          render(v: any, data: any) {
            const otherProperties = data?.otherProperties
            const costCenter = otherProperties?.find(
              (i: any) => i.diyType === 'HR_MEETING_OWNER_COST_CENTER'
            )?.value
            return costCenter || '-'
          }
        }
      ]
    : []),
  {
    title: '项目服务商',
    dataIndex: 'vendor',
    key: 'vendor',
    align: 'center',
    width: 100,
    render(v: any) {
      return v?.cnName
    }
  },
  ...(props.production === HR_PRODUCTION.hansoh
    ? [
        {
          title: '预算渠道',
          dataIndex: 'meetingInfo',
          key: 'channel',
          align: 'center',
          width: 100,
          render(v: any) {
            return v?.budgetChannel
              ? BUDGET_TYPE_MAP.find(
                  (i: { value: any }) => i.value === v?.budgetChannel
                )?.text || '-'
              : '-'
          }
        },
        {
          title: '预算编号',
          dataIndex: 'meetingInfo',
          key: 'budgetNumber',
          align: 'center',
          width: 100,
          render(v: any) {
            return v?.budgetNumber || ''
          }
        }
      ]
    : []),
  ...(props.isQCXS
    ? [
        {
          title: 'Owner',
          dataIndex: 'meetingInfo',
          key: 'Owner',
          align: 'center',
          width: 100,
          render(v: any, data: any) {
            return data.approverOwner || '-'
          }
        }
      ]
    : []),
  // {
  //   title: '是否使用个人预算',
  //   dataIndex: 'meetingInfo',
  //   key: 'needBudget',
  //   render(v: any) {
  //     return !v?.needBudget ? '否' : '是'
  //   }
  // },
  ...(props.type === 'approved'
    ? []
    : [
        {
          title: '操作',
          dataIndex: 'meetingInfo',
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          render(v: any) {
            return (
              <Link
                to={`/${
                  props.isQCXS || props.isYHLXS
                    ? 'meetingApproveDetail'
                    : 'meeting'
                }/${v?.id}`}
              >
                去审批
              </Link>
            )
          }
        }
      ])
]

export type FieldTypes = {
  keywordApproved?: string
  keywordApprovePending?: string
  dateApproved?: [Dayjs | undefined, Dayjs | undefined]
  dateApprovePending?: [Dayjs | undefined, Dayjs | undefined]
  sortApprovePending?: string
  orderApprovePending?: string
  meetingTypes?: string[] | undefined
  approveUsers?: string[] | undefined
  meetingRegion?: string[] | undefined
  meetingCostCenter?: string[] | undefined
}

// 表格导出字段
export const excelHead = (production: string) => [
  {
    key: 'meetingId',
    path: 'meetingInfo.id',
    name: '会议ID'
  },
  {
    key: 'meetingType',
    path: 'meetingType',
    name: '会议类型'
  },
  { key: 'activityType', path: 'activityType', name: '活动类型' },
  {
    key: 'productType',
    path: 'meetingInfo.belongProduct.productName',
    name: '产品线'
  },
  {
    key: 'meetingName',
    path: 'meetingInfo.name',
    name: '会议名称'
  },
  {
    key: 'city',
    path: 'city.name',
    name: '会议城市'
  },
  { key: 'startTime', path: 'meetingInfo.startTime', name: '会议开始时间' }, //费用
  { key: 'endTime', path: 'meetingInfo.endTime', name: '会议结束时间' }, // 服务费
  { key: 'peopleNumber', path: 'attenders', name: '老师数量' }, // 总费用
  { key: 'totalFee', path: 'attenders', name: '总积分' }, //会议ID
  { key: 'createTime', path: 'meetingInfo.createTime', name: '创建时间' }, // 会议名称
  { key: 'applyPeople', path: 'meetingInfo.owner.cnName', name: '申请人' }, //申请人
  {
    key: 'applyPhone',
    path: 'meetingInfo.owner.companyMobile',
    name: '申请人电话'
  }, //开始日期
  {
    key: 'applyRegion',
    path: 'meetingInfo.owner.region.name',
    name: '申请人省区'
  }, //结束日期
  {
    key: 'applyStructure',
    path: 'meetingInfo.owner.structure.name',
    name: '申请人部门'
  }, // 订单id
  { key: 'vendor', path: 'vendor.cnName', name: '项目服务商' }, //会议状态
  ...(production === HR_PRODUCTION.hansoh
    ? [
        {
          key: 'budgetChannel',
          path: 'meetingInfo.budgetChannel',
          name: '预算渠道'
        },
        {
          key: 'budgetNumber',
          path: 'meetingInfo.budgetNumber',
          name: '预算编号'
        }
      ]
    : [])
  // {
  //   key: 'needBudget',
  //   path: 'meetingInfo.needBudget',
  //   name: '是否使用个人预算'
  // }
]

// 整理出表格数据
export const getData = (data: any, head: any) => {
  let result = data ? _.cloneDeep(data) : []

  // 按照 excelHeader 拼装
  result = _.map(result, (item) => {
    const obj: any = {}

    head.forEach((cell: any) => {
      const value = _.get(item, cell.path, '')
      switch (cell.key) {
        // 总费用/费用/服务费用
        case 'totalFee':
          obj[cell.name] =
            sumBy(value, (d: any) => parseFloat(d?.attenderFee)) || 0
          break

        // 时间
        case 'startTime':
        case 'endTime':
        case 'createTime':
          obj[cell.name] = dayjs(value).format('YYYY-MM-DD')
          break

        case 'peopleNumber':
          obj[cell.name] = value?.length || 0
          break

        case 'needBudget':
          obj[cell.name] = !value ? '否' : '是'
          break

        case 'budgetChannel':
          obj[cell.name] = value
            ? BUDGET_TYPE_MAP.find((i: { value: any }) => i.value === value)
                ?.text || '-'
            : '-'
          break

        default:
          obj[cell.name] = value
          break
      }
    })
    return obj
  })

  return result
}
