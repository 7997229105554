import React, { FC, Fragment, useState } from 'react'
import {
  Card,
  Tag,
  Space,
  Image,
  Descriptions,
  Divider,
  Radio,
  Form
} from 'antd'
import dayjs from 'dayjs'
import faultSvg from '../../assets/fault.svg'

const BillInfo: FC<{
  data: any
  invoices?: any[]
  receiptTemplate?: any[]
  billReviewResult?: any
  isSysApproved?: boolean
  isDisabledForm?: boolean
  auditRecord?: any[]
  loading?: boolean
  isMobile?: boolean
  paymentType?: number
}> = ({
  data,
  invoices = [],
  receiptTemplate = [],
  billReviewResult = {},
  isSysApproved = false,
  isDisabledForm,
  auditRecord = [],
  loading,
  isMobile,
  paymentType
}) => {
  const bill = data?.find((x: any) => x.key === 'bill')
  const billInfo = JSON.parse(bill?.value || '[]')
  const invoiceCertification = data?.find(
    (x: any) => x.key === 'invoiceCertification'
  )
  const invoiceCertificationInfo = JSON.parse(
    invoiceCertification?.value || '[]'
  )
  const ticket = data?.find((x: any) => x.key === 'ticket')
  const ticketInfo = JSON.parse(ticket?.value || '[]')
  const voucherTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'voucher'
  )
  const ticketTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'ticket'
  )
  const invoiceTemplate = receiptTemplate?.filter(
    (x: any) => x.receiptType === 'invoice'
  )
  const isCheckInvoices = invoices.find(
    (x) => !x.checkOcr || !x.checkPassed || !x.checkRepeat || !x.checkTitle
  )

  return (
    <Card
      loading={loading}
      title='票据信息'
      bordered={false}
      headStyle={{ padding: 0, border: 'none', color: '#888' }}
      bodyStyle={{ padding: 0 }}
    >
      {paymentType !== 2 && (
        <>
          <Descriptions
            column={{ xs: 1, sm: 2 }}
            labelStyle={{ fontWeight: 'bold', color: '#181818' }}
            className='billInfo'
          >
            <Descriptions.Item
              key='voucherInfo'
              label='支付凭证'
              contentStyle={{
                flexDirection: 'column'
              }}
            >
              {billInfo?.length > 0 ? (
                billInfo?.map((x: any, index: number) => {
                  const imgUrl = x.url
                  const voucherAuditRecord =
                    auditRecord?.filter(
                      (x: any) =>
                        x.receiptType === 'voucher' && x.key === imgUrl
                    ) || []
                  const voucherAmountAuditRecord =
                    voucherAuditRecord?.find(
                      (x: any) => x.verifyType === '消费金额'
                    ) || {}
                  const voucherTimeAuditRecord =
                    voucherAuditRecord?.find(
                      (x: any) => x.verifyType === '提交时间'
                    ) || {}
                  const voucherNameAuditRecord =
                    voucherAuditRecord?.find(
                      (x: any) => x.verifyType === '商家名称'
                    ) || {}
                  return (
                    <div
                      key={index}
                      className={`${isMobile ? 'checkColumn' : 'checkRow'} ${
                        index !== billInfo?.length - 1 ? 'mrBt20' : ''
                      }`}
                    >
                      <Image
                        key={index}
                        width={100}
                        height={100}
                        src={imgUrl || faultSvg}
                      />
                      <Descriptions
                        column={{ xs: 1, sm: 3 }}
                        labelStyle={{ fontWeight: 'bold', color: '#181818' }}
                        style={{ marginLeft: isMobile ? 0 : 15, flex: 1 }}
                      >
                        <Descriptions.Item label='支付金额'>
                          ¥{voucherAmountAuditRecord.value || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                          contentStyle={{
                            fontWeight: 'bold',
                            color: '#181818'
                          }}
                        >
                          【确认消费金额】小于等于支付凭证上的【支付金额】
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <Form.Item
                            name={`checkVoucherAmount-${imgUrl}`}
                            noStyle
                            initialValue={
                              (isDisabledForm
                                ? isSysApproved
                                  ? voucherAmountAuditRecord.status
                                  : billReviewResult[
                                      `checkVoucherAmount-${imgUrl}`
                                    ]
                                : voucherAmountAuditRecord.status) || 0
                            }
                          >
                            <Radio.Group disabled={isDisabledForm}>
                              <Radio value={1}>通过</Radio>
                              <Radio value={0}>不通过</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='支付时间'>
                          {voucherTimeAuditRecord.value || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                          contentStyle={{
                            fontWeight: 'bold',
                            color: '#181818'
                          }}
                        >
                          【订单提交时间】小于等于支付凭证上的【支付时间】
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <Form.Item
                            name={`checkVoucherTime-${imgUrl}`}
                            noStyle
                            initialValue={
                              (isDisabledForm
                                ? isSysApproved
                                  ? voucherTimeAuditRecord.status
                                  : billReviewResult[
                                      `checkVoucherTime-${imgUrl}`
                                    ]
                                : voucherTimeAuditRecord.status) || 0
                            }
                          >
                            <Radio.Group disabled={isDisabledForm}>
                              <Radio value={1}>通过</Radio>
                              <Radio value={0}>不通过</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label='商家名称'>
                          {voucherNameAuditRecord.value || '-'}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Descriptions.Item>
            <Descriptions.Item
              key='billInfoTemplate'
              label='凭证模版'
              style={{ width: 310 }}
            >
              <Image.PreviewGroup>
                <Space>
                  {voucherTemplate?.length > 0 ? (
                    voucherTemplate?.map((x: any, index: number) => {
                      if (index > 0) return null
                      return (
                        <Image
                          key={index}
                          width={100}
                          height={100}
                          src={x.receiptUrl || faultSvg}
                        />
                      )
                    })
                  ) : (
                    <Image width={100} height={100} src={faultSvg} />
                  )}
                </Space>
              </Image.PreviewGroup>
            </Descriptions.Item>
          </Descriptions>
          <Divider dashed />
        </>
      )}
      <Descriptions
        column={{ xs: 1, sm: 2 }}
        labelStyle={{ fontWeight: 'bold', color: '#181818' }}
        className='billInfo'
      >
        <Descriptions.Item
          key='ticketInfo'
          label='消费清单'
          contentStyle={{
            flexDirection: 'column'
          }}
        >
          {ticketInfo?.length > 0 ? (
            ticketInfo?.map((x: any, index: number) => {
              const imgUrl = x.url
              const ticketAuditRecord =
                auditRecord?.filter(
                  (x: any) => x.receiptType === 'ticket' && x.key === imgUrl
                ) || []
              const ticketAmountAuditRecord =
                ticketAuditRecord?.find(
                  (x: any) => x.verifyType === '消费金额'
                ) || {}
              const ticketTimeAuditRecord =
                ticketAuditRecord?.find(
                  (x: any) => x.verifyType === '提交时间'
                ) || {}
              const ticketNameAuditRecord =
                ticketAuditRecord?.find(
                  (x: any) => x.verifyType === '商家名称'
                ) || {}
              return (
                <div
                  key={index}
                  className={`${isMobile ? 'checkColumn' : 'checkRow'} ${
                    index !== ticketInfo?.length - 1 ? 'mrBt20' : ''
                  }`}
                >
                  <Image
                    key={index}
                    width={100}
                    height={100}
                    src={imgUrl || faultSvg}
                  />
                  <Descriptions
                    column={{ xs: 1, sm: 3 }}
                    labelStyle={{ fontWeight: 'bold', color: '#181818' }}
                    style={{ marginLeft: isMobile ? 0 : 15, flex: 1 }}
                  >
                    <Descriptions.Item label='消费金额'>
                      ¥{ticketAmountAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【确认消费金额】小于等于消费清单上的【消费金额】
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkTicketAmount-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? ticketAmountAuditRecord.status
                              : billReviewResult[`checkTicketAmount-${imgUrl}`]
                            : ticketAmountAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='消费时间'>
                      {ticketTimeAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【订单提交时间】小于等于消费清单上的【消费时间】
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkTicketTime-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? ticketTimeAuditRecord.status
                              : billReviewResult[`checkTicketTime-${imgUrl}`]
                            : ticketTimeAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='消费商家'>
                      {ticketNameAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【订单商家名称】和消费清单上的【商家名称】有逻辑关联
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkTicketName-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? ticketNameAuditRecord.status
                              : billReviewResult[`checkTicketName-${imgUrl}`]
                            : ticketNameAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )
            })
          ) : (
            <Image width={100} height={100} src={faultSvg} />
          )}
        </Descriptions.Item>
        <Descriptions.Item
          key='ticketInfoTemplate'
          label='清单模版'
          style={{ width: 310 }}
        >
          <Image.PreviewGroup>
            <Space>
              {ticketTemplate?.length > 0 ? (
                ticketTemplate?.map((x: any, index: number) => {
                  if (index > 0) return null
                  return (
                    <Image
                      key={index}
                      width={100}
                      height={100}
                      src={x.receiptUrl || faultSvg}
                    />
                  )
                })
              ) : (
                <Image width={100} height={100} src={faultSvg} />
              )}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      <Descriptions
        column={{ xs: 1, sm: 2 }}
        labelStyle={{ fontWeight: 'bold', color: '#181818' }}
        className='billInfo'
      >
        <Descriptions.Item
          key='invoiceInfo'
          label='发票信息'
          contentStyle={{
            flexDirection: 'column'
          }}
        >
          {invoices?.length ? (
            invoices?.map((x: any, index: number) => {
              const imgUrl = x.invoiceUrl
              const invoiceAuditRecord =
                auditRecord?.filter(
                  (x: any) => x.receiptType === 'invoice' && x.key === imgUrl
                ) || []
              const invoiceAmountAuditRecord =
                invoiceAuditRecord?.find(
                  (x: any) => x.verifyType === '消费金额'
                ) || {}
              const invoiceTimeAuditRecord =
                invoiceAuditRecord?.find(
                  (x: any) => x.verifyType === '提交时间'
                ) || {}
              const invoiceNameAuditRecord =
                invoiceAuditRecord?.find(
                  (x: any) => x.verifyType === '商家名称'
                ) || {}
              const invoiceAddressAuditRecord =
                invoiceAuditRecord?.find(
                  (x: any) => x.verifyType === '商户地址'
                ) || {}
              return (
                <div
                  key={index}
                  className={`${isMobile ? 'checkColumn' : 'checkRow'} ${
                    index !== invoices?.length - 1 ? 'mrBt20' : ''
                  }`}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Image width={100} height={100} src={imgUrl || faultSvg} />
                    <div className='checkContainer'>
                      <Tag
                        color={x.checkOcr ? 'success' : 'error'}
                        className='checkTag'
                      >
                        {x.checkOcr ? '识别验证通过' : '识别验证不通过'}
                      </Tag>
                      <Tag
                        color={x.checkPassed ? 'success' : 'error'}
                        className='checkTag'
                      >
                        {x.checkPassed ? '验真验证通过' : '验真验证不通过'}
                      </Tag>
                      <Tag
                        color={x.checkTitle ? 'success' : 'error'}
                        className='checkTag'
                      >
                        {x.checkTitle ? '抬头验证通过' : '抬头验证不通过'}
                      </Tag>
                      <Tag
                        color={x.checkRepeat ? 'success' : 'error'}
                        className='checkTag'
                      >
                        {x.checkRepeat ? '重复验证通过' : '重复验证不通过'}
                      </Tag>
                    </div>
                  </div>
                  <Descriptions
                    column={{ xs: 1, sm: 3 }}
                    labelStyle={{ fontWeight: 'bold', color: '#181818' }}
                    style={{ marginLeft: isMobile ? 0 : 15, flex: 1 }}
                  >
                    <Descriptions.Item label='含税金额'>
                      ¥{invoiceAmountAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【确认消费金额】小于等于发票上的【含税金额】
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkInvoiceAmount-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? invoiceAmountAuditRecord.status
                              : billReviewResult[`checkInvoiceAmount-${imgUrl}`]
                            : invoiceAmountAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='开票日期'>
                      {invoiceTimeAuditRecord.value
                        ? dayjs(invoiceTimeAuditRecord.value).format(
                            'YYYY-MM-DD'
                          )
                        : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【订单提交时间】小于等于发票上的【开票时间】
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkInvoiceTime-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? invoiceTimeAuditRecord.status
                              : billReviewResult[`checkInvoiceTime-${imgUrl}`]
                            : invoiceTimeAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='销售方名称'>
                      {invoiceNameAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【订单商家名称】和发票上的【销售方名称】有逻辑关联
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkInvoiceName-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? invoiceNameAuditRecord.status
                              : billReviewResult[`checkInvoiceName-${imgUrl}`]
                            : invoiceNameAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='销售方地址'>
                      {invoiceAddressAuditRecord.value || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item
                      contentStyle={{
                        fontWeight: 'bold',
                        color: '#181818'
                      }}
                    >
                      【商户地址】和【销售方地址】关联性
                    </Descriptions.Item>
                    <Descriptions.Item>
                      <Form.Item
                        name={`checkInvoiceAddress-${imgUrl}`}
                        noStyle
                        initialValue={
                          (isDisabledForm
                            ? isSysApproved
                              ? invoiceAddressAuditRecord.status
                              : billReviewResult[
                                  `checkInvoiceAddress-${imgUrl}`
                                ]
                            : invoiceAddressAuditRecord.status) || 0
                        }
                      >
                        <Radio.Group disabled={isDisabledForm}>
                          <Radio value={1}>通过</Radio>
                          <Radio value={0}>不通过</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label='购买方名称' span={3}>
                      {x.salesInvoiceDetail?.purchaserName || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label='购买方纳税人识别号' span={3}>
                      {x.salesInvoiceDetail?.purchaserTaxIdentNumber || '-'}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )
            })
          ) : (
            <Image width={100} height={100} src={faultSvg} />
          )}
        </Descriptions.Item>
        <Descriptions.Item style={{ width: 310 }}>
          <Descriptions
            column={1}
            labelStyle={{ fontWeight: 'bold', color: '#181818' }}
          >
            <Descriptions.Item key='invoiceInfoTemplate' label='发票模版'>
              <Image.PreviewGroup>
                <Space>
                  {invoiceTemplate?.length > 0 ? (
                    invoiceTemplate?.map((x: any, index: number) => {
                      if (index > 0) return null
                      return (
                        <Image
                          key={index}
                          width={100}
                          height={100}
                          src={x.receiptUrl || faultSvg}
                        />
                      )
                    })
                  ) : (
                    <Image width={100} height={100} src={faultSvg} />
                  )}
                </Space>
              </Image.PreviewGroup>
            </Descriptions.Item>

            <Descriptions.Item label='购买方名称'>
              江苏豪森药业集团有限公司
            </Descriptions.Item>
            <Descriptions.Item label='购买方纳税人识别号'>
              913207006083959289
            </Descriptions.Item>
          </Descriptions>
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      {isCheckInvoices && (
        <Descriptions
          column={{ xs: 1, sm: 2 }}
          labelStyle={{ fontWeight: 'bold', color: '#181818' }}
          className='billInfo'
        >
          <Descriptions.Item
            span={2}
            key='invoiceCertificationInfo'
            label='发票查验'
          >
            <Image.PreviewGroup>
              <Space style={{ display: 'flex', flexWrap: 'wrap' }}>
                {invoiceCertificationInfo?.length > 0 ? (
                  invoiceCertificationInfo?.map((x: any, index: number) => {
                    return (
                      <Image
                        key={index}
                        width={100}
                        height={100}
                        src={x.url || faultSvg}
                      />
                    )
                  })
                ) : (
                  <Image width={100} height={100} src={faultSvg} />
                )}
              </Space>
            </Image.PreviewGroup>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  )
}

export default BillInfo
