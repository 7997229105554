import React, { useState, useEffect, FC, useRef } from 'react'
import { Button, Empty, Input, message, Row } from 'antd'
import OpeartionScanningCodeGun, {
  event
} from '../../components/OpeartionScanningCodeGun'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import { useSalesOrderByIdQuery } from '../../graphql'
import { FieldTypes, searchFields, useTableColumns } from './data'
import ErrorView from '../../components/ErrorView'
import './index.less'

const ConsumptionOrderQueryByQrCode: QCPage = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isScanning, setIsScanning] = useState(false)
  const event = useRef({} as event)

  const [{ data, fetching, error }, refetch] = useSalesOrderByIdQuery({
    variables: {
      id: table.searchParams?.keyword!
    },
    pause: !table.searchParams?.keyword
  })

  const visibilityChangeNamePropName = (
    document: Document & { msHidden?: boolean; webkitHidden?: boolean }
  ) => {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      return 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      return 'webkitvisibilitychange'
    }
    return 'visibilitychange'
  }

  const handleVisibilityChange = () => {
    const doc = document as Document & {
      msHidden?: boolean
      webkitHidden?: boolean
    }
    if (doc.hidden || doc.msHidden || doc.webkitHidden) {
      event?.current?.onCancel?.()
    } else {
      event?.current?.onStart?.()
    }
  }

  useEffect(() => {
    const visibilityChangeName = visibilityChangeNamePropName(document)
    // 处理页面可见属性的改变
    document.addEventListener(visibilityChangeName, handleVisibilityChange)
    return () => {
      document.removeEventListener(visibilityChangeName, handleVisibilityChange)
    }
  }, [])

  const tableColumns = useTableColumns()

  if (error) {
    return (
      <ErrorView
        onRefresh={refetch}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <div style={{ height: '100%', background: '#fff' }}>
      <Row justify='end' style={{ padding: '50px 24px' }}>
        <Button
          type='primary'
          onClick={() => setIsScanning(true)}
          className='scanningBtn'
        >
          扫码搜索
        </Button>
      </Row>
      <SearchTable
        table={table}
        scroll={{ x: true }}
        searchProps={{
          fields: searchFields()
        }}
        bordered
        loading={fetching}
        showPagination={false}
        dataSource={data?.result?.salesOrder ? [data?.result?.salesOrder] : []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
      />
      {/* <Empty
        description='请用扫码枪对准二维码'
        style={{
          background: '#f0f2f5',
          padding: '50px',
          margin: '0 24px',
          color: '#999'
        }}
      /> */}
      {isScanning && (
        <OpeartionScanningCodeGun
          event={event}
          visible={isScanning}
          tip='请用扫码枪对准单据上的二维码'
          callBack={(code) => {
            if (code.lastIndexOf('sales_order') < 0) {
              message.error('请对准正确的消费单二维码进行扫码')
              return
            }
            window.open(
              `/consumptionOrderBillDetail/${code.split('sales_order')[1]}`,
              '_blank'
            )
          }}
          onCancel={() => setIsScanning(false)}
        />
      )}
    </div>
  )
}

ConsumptionOrderQueryByQrCode.pageTitle = '消费订单查询'
export default ConsumptionOrderQueryByQrCode
