import { Button, Form, Input, Select, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import UploadButton from '../../components/UploadButton'
import {
  useSaveArchiveMutation,
  useProductsListQuery,
  useUpadminCompaniesQuery
} from '../../graphql'
import { useUserStore } from '../../hooks/user'

export default function SaveForm({ onSaved }: { onSaved?: () => void }) {
  const { profile } = useUserStore()

  const [companyId, setCompanyId] = useState<string>('')
  const [, saveArchive] = useSaveArchiveMutation()
  const [{ data, fetching }] = useProductsListQuery({
    variables: {
      companyId
    }
  })
  const [fileName, setFileName] = useState<string>('')

  const [isExternal, setIsExtenal] = useState<boolean>(false)

  const [{ data: companies }] = useUpadminCompaniesQuery()

  const [form] = Form.useForm<{
    archiveUrl?: string
    describe?: string
    products?: string[]
    title?: string
    companyId?: string
  }>()

  const save = async () => {
    await form.validateFields()
    const value = form.getFieldsValue()
    await saveArchive({
      params: {
        archiveUrl: value.archiveUrl!,
        title: value.title!,
        describe: value.describe!,
        products: value.products,
        companyId: value.companyId!,
        isExternal
      }
    })
    onSaved?.()
  }

  useEffect(() => {
    form.setFieldsValue({
      archiveUrl: undefined
    })
  }, [isExternal, form])

  return (
    <div>
      <Tabs defaultActiveKey='1' onChange={(key) => setIsExtenal(key === '2')}>
        <Tabs.TabPane tab='上传' key='1'></Tabs.TabPane>
        <Tabs.TabPane tab='外链' key='2'></Tabs.TabPane>
      </Tabs>
      <Form
        labelCol={{ lg: { span: 4 } }}
        form={form}
        onValuesChange={(_, values) => {
          setCompanyId(values.companyId || '') 
        }}
      >
        <Form.Item
          label={isExternal ? '链接' : '文件'}
          name='archiveUrl'
          required
          rules={[{ required: true }]}
        >
          {isExternal ? (
            <Input />
          ) : (
            <UploadButton
              bucket='yhl-upright-academic-archive'
              onChange={(fileList) =>
                setFileName(fileList[fileList.length - 1].name)
              }
              onUploaded={async (url) => {
                form.setFieldsValue({
                  archiveUrl: url
                })
              }}
              dir={`/${profile?.companyId}/${profile?.accountId}`}
              buttonText={fileName || '上传文件'}
            />
          )}
        </Form.Item>
        <Form.Item
          label='名称'
          name='title'
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='公司'
          name='companyId'
          required
          rules={[{ required: true }]}
        >
          <Select
            maxLength={100}
            options={
              companies?.companies.map((c) => ({
                label: c.cnName!,
                value: c.id!
              })) || []
            }
          />
        </Form.Item>
        {companyId && (
          <Form.Item
            label='产品线'
            name='products'
            required
            rules={[{ required: true }]}
          >
            <Select
              optionFilterProp='label'
              mode='multiple'
              allowClear
              loading={fetching}
              options={
                data?.getArchiveProductsList?.map((p) => ({
                  value: p.id || '',
                  title: p.tag || '',
                  label: p.tag || ''
                })) || []
              }
            ></Select>
          </Form.Item>
        )}
        <Form.Item
          label='简介'
          name='describe'
          required
          rules={[{ required: true }]}
        >
          <Input.TextArea maxLength={100} />
        </Form.Item>

        <Button onClick={() => save()}>提交</Button>
      </Form>
    </div>
  )
}
