import React, { FC } from 'react'

import { Document, Page } from 'react-pdf'

import { StandardMeetingServiceBizStatus } from '../graphql'

import UploadButton from '../components/UploadButton'
import Modal from 'antd/lib/modal/Modal'
import { useBoolean, useWindowSize } from 'react-use'

import { useUserStore } from '../hooks/user'
import { Button } from 'antd'

const PreviewModalButton: FC<{
  file: string | null | undefined
  files?: string[] | undefined
  meetingId: string
  attenderId: string
  type: string
  status?: string | null
  onUploaded: (url: string) => Promise<void> | void
}> = ({
  file,
  files = [],
  meetingId,
  attenderId,
  onUploaded,
  type,
  status,
  children
}) => {
  const [open, toggleOpen] = useBoolean(false)
  const user = useUserStore()
  const { height } = useWindowSize()
  if (!file) {
    if (
      [
        //StandardMeetingServiceBizStatus.MeetingSettlementConfirming,
        StandardMeetingServiceBizStatus.MeetingInSettlement,
        StandardMeetingServiceBizStatus.MeetingSettlementWithoutTax
      ].includes(status as StandardMeetingServiceBizStatus) &&
      user.hasAuth('uplatform_payment_upload_opt')
    ) {
      return (
        <UploadButton
          bucket='yhl-upright-admin-upload'
          dir={`/admin/${type}/${meetingId}/${attenderId}`}
          buttonText='上传'
          onUploaded={onUploaded}
        />
      )
    }
    return null
  }

  if (files.length <= 0) files = [file]

  return (
    <>
      <a onClick={toggleOpen}>查看</a>
      <Modal
        visible={open}
        onCancel={toggleOpen}
        width='400'
        centered
        bodyStyle={{ maxHeight: height - 200, overflow: 'auto' }}
        footer={
          user.hasAuth('uplatform_payment_upload_opt') && (
            <div style={{ display: 'block', textAlign: 'left' }}>
              <UploadButton
                bucket='yhl-upright-admin-upload'
                dir={`/admin/${type}/${meetingId}/${attenderId}`}
                buttonText='重新上传'
                onUploaded={onUploaded}
              />
            </div>
          )
        }
      >
        {files.map((file, index) => {
          const forceHttpsFile = file.replace('http://', 'https://')
          const extension = file.split(/[#?]/)[0].split('.').pop()?.trim()
          return (
            <div key={index}>
              {extension?.toLowerCase() === 'pdf' ? (
                <Document
                  file={forceHttpsFile}
                  options={{
                    cMapUrl: 'https://upadmin-cdn.yihuili.net/cmaps/',
                    cMapPacked: true
                  }}
                >
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <img
                  width='480'
                  src={file}
                  style={{ display: 'block', padding: '20px 0' }}
                />
              )}
              <Button type='link' target='__blank' href={forceHttpsFile}>
                查看原文件
              </Button>
            </div>
          )
        })}
        {children}
      </Modal>
    </>
  )
}

export default PreviewModalButton
