import React, { FC, useState } from 'react'
import {
  Modal,
  Form,
  Space,
  Button,
  Radio,
  Row,
  Image,
  Input,
  message
} from 'antd'
import {
  AccountCertInfo,
  useDeleteOrderByAccountIdMutation,
  useSyncDoctorVerifyResultMutation
} from '../../graphql'
import _ from 'lodash'
import { CloseCircleOutlined } from '@ant-design/icons'
import UploadImageButton from '../../components/UploadImageButton'
import './index.less'

const getImgSource = (pic: string) => {
  const sliceIndex = pic?.indexOf('?')
  const url = sliceIndex > -1 ? pic.slice(0, sliceIndex) : pic
  if (/\.(png|jpe?g|jpeg|gif|webp|bmp)$/i.test(url)) {
    return url
  }
  return url + '?x-oss-process=image/format,jpg'
}

const VerifyDoctorInfoModal: FC<{
  onCancel: () => void
  onHandleConfirm: (
    result: number,
    verifyImgs: string[],
    callBack?: () => void
  ) => void
  accountId?: string | null | undefined
  userCertificationImages?: AccountCertInfo | null | undefined
}> = ({ onCancel, onHandleConfirm, accountId, userCertificationImages }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [, syncDoctorVerifyResult] = useSyncDoctorVerifyResultMutation()
  const [, deleteOrder] = useDeleteOrderByAccountIdMutation()

  const onHandleConfirmClick = () => {
    form.submit()
  }

  const onFinish = async (values: any) => {
    setLoading(true)
    const { data } = await syncDoctorVerifyResult({
      accountId: accountId!,
      verifyResult: values.verifyResult === 0 ? '否' : '是',
      verifyImages:
        values.verifyResult === 0 ? values.reason : values.verifyImgs.join(',')
    })
    if (!data?.result?.success) {
      message.error(data?.result?.message || '保存验证结果失败')
      setLoading(false)
      return
    }
    if (values.verifyResult === 0) {
      const { data: deleteResult, error: deleteError } = await deleteOrder({
        accountId: accountId!,
        disableReason: values.reason || '学术审核时禁用'
      })
      if (!deleteResult?.result) {
        message.error(deleteError?.message || '禁用老师失败')
        setLoading(false)
        return
      }
    }
    onHandleConfirm(values.verifyResult, values.verifyImgs, () => {
      setLoading(false)
    })
  }

  return (
    <Modal
      maskClosable={false}
      className='verifyDoctorInfoModal'
      title='验证老师信息'
      visible
      onCancel={onCancel}
      centered
      destroyOnClose
      footer={
        <Space size={16}>
          <Button
            type='primary'
            style={{ background: '#C1C1C1', color: 'white', border: 'none' }}
            onClick={onCancel}
          >
            取消
          </Button>
          <Button
            type='primary'
            style={{ background: '#4467F8', color: 'white', border: 'none' }}
            loading={loading}
            onClick={onHandleConfirmClick}
          >
            确定
          </Button>
        </Space>
      }
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#cccccc' }} />
      }
    >
      <Row justify='start' align='middle' style={{ marginBottom: 24 }}>
        <span style={{ fontSize: 18, color: '#4467F8', fontWeight: 'bold' }}>
          验证老师信息
        </span>
      </Row>
      <Form
        preserve={false}
        requiredMark={false}
        scrollToFirstError={true}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <label style={{ fontWeight: 'bold' }}>
              {userCertificationImages?.cnName}
            </label>
          }
          labelCol={{ span: 8 }}
          labelAlign='left'
          name='certImages'
        >
          <Image.PreviewGroup>
            {userCertificationImages?.certImages?.map(
              (item: string, index: number) => {
                return (
                  <Image
                    key={index}
                    width={100}
                    height={100}
                    src={getImgSource(item)}
                  />
                )
              }
            )}
          </Image.PreviewGroup>
        </Form.Item>
        <Form.Item
          label={<label style={{ fontWeight: 'bold' }}>验证结果</label>}
          labelCol={{ span: 8 }}
          labelAlign='left'
          name='verifyResult'
          rules={[
            {
              required: true,
              message: '请选择验证结果'
            }
          ]}
        >
          <Radio.Group>
            <Radio value={1}>通过</Radio>
            <Radio value={0}>不通过</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.verifyResult !== curValues.verifyResult
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('verifyResult') === 1 ? (
              <Form.Item
                label={
                  <label style={{ fontWeight: 'bold' }}>验证图片上传</label>
                }
                labelCol={{ span: 8 }}
                labelAlign='left'
                name='verifyImgs'
                rules={[
                  {
                    required: true,
                    message: '请上传验证图片'
                  }
                ]}
              >
                <UploadImageButton
                  bucket='yhl-upright-user-idcard-images'
                  accept='.gif,.jpg,.jpeg,.png,.GIF,.JPG,.JPEG,.PNG'
                  uploadImgLength={6}
                  onUploaded={(url) => {
                    form.setFieldsValue({
                      verifyImgs: url
                    })
                  }}
                  dir={`/admin/verifyImgs/${accountId}`}
                  noMessage
                />
              </Form.Item>
            ) : getFieldValue('verifyResult') === 0 ? (
              <Form.Item
                label={<label style={{ fontWeight: 'bold' }}>不通过理由</label>}
                labelCol={{ span: 8 }}
                labelAlign='left'
                name='reason'
                rules={[
                  {
                    required: true,
                    message: '请填写审核不通过理由'
                  }
                ]}
                initialValue='卫健委网站，医院网站平台没有找到老师信息'
              >
                <Input.TextArea
                  placeholder='请填写审核不通过理由'
                  rows={2}
                  maxLength={50}
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default VerifyDoctorInfoModal
