import React, { useState, useEffect } from 'react'
import omit from 'lodash.omit'
import Modal from 'antd/lib/modal/Modal'
import { message, Spin } from 'antd'
import { useLocation } from 'react-router-dom'

import { searchFields, useTableColumns, FieldTypes, excelHead } from './data'
import { useHrMeetingListQuery, useHrMeetingExportQuery } from '../../graphql'
import SearchTable, { useSearchTable } from '../../components/SearchTable'
import ErrorView from '../../components/ErrorView'
import { generateExport } from '../../components/UploadBatch'
import { getData } from '../../lib/getExportList'
import { useUserStore } from '../../hooks/user'

const MeetingList: QCPage = () => {
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [isExporting, setExporting] = useState(false)
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''

  const useLocation_ = useLocation()
  // 是否是葱介入项目过来的
  const isInterventionalProject =
    useLocation_?.pathname === '/interventionalList'

  const queryVariable = {
    ...omit(table.searchParams, ['date', 'approvalDate']),
    meetingType: isInterventionalProject
      ? 'INTERVENTION_SPECIAL_LINE_PROJECT'
      : 'ACADEMIC_CONFERENCE',
    startTime:
      (table.searchParams?.date?.[0]?.startOf('day').unix() || 0) * 1000 ||
      undefined, // 0 will not be ignore by server
    endTime:
      (table.searchParams?.date?.[1]?.endOf('day').unix() || 0) * 1000 ||
      undefined, // 0 will not be ignore by server
    approvalStartTime:
      (table.searchParams?.approvalDate?.[0]?.startOf('day').unix() || 0) *
        1000 || undefined,
    approvalEndTime:
      (table.searchParams?.approvalDate?.[1]?.endOf('day').unix() || 0) *
        1000 || undefined
  }

  const [{ data, error, fetching }, refetchMeetingList] = useHrMeetingListQuery(
    {
      variables: {
        pageQuery: {
          pager: omit(table.pager, 'total'),
          query: { ...queryVariable }
        }
      }
    }
  )

  // 复用请求列表的接口，用于导出数据
  const [{ data: exportResult, error: exportErr }] = useHrMeetingExportQuery({
    variables: {
      pageQuery: {
        pager: {
          currentPage: 1,
          pageSize: 2000
        },
        query: { ...queryVariable }
      }
    },
    pause: !isExporting
  })

  const tableColumns = useTableColumns({
    onCancel: refetchMeetingList,
    production,
    isInterventionalProject
  })

  const onExportHandle = () => {
    setExporting(true)

    if (exportErr) {
      setExporting(false)
      message.error('导出失败')
      return
    }
  }

  const concatAttenders = (source: any[]) => {
    source.forEach((element) => {
      element.speakers = element.speakers.concat(element.attenders)
    })

    return source
  }

  useEffect(() => {
    if (isExporting) {
      const dataResult = getData(
        concatAttenders(exportResult?.meetings?.items || []),
        excelHead(production)
      )
      generateExport(
        dataResult,
        `学术会议_${new Date().getTime()}`,
        5000,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [exportResult])

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchMeetingList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        searchProps={{
          fields: searchFields(production, isInterventionalProject)
        }}
        bordered
        loading={fetching}
        pager={data?.meetings?.pager}
        dataSource={data?.meetings?.items || []}
        size='middle'
        rowKey='id'
        columns={tableColumns}
        showExportBtn
        onExport={onExportHandle}
      />

      <Modal
        visible={!!isExporting}
        footer={null}
        maskClosable={false}
        closable={false}
        width={400}
      >
        <Spin tip='正在为您生成EXCEL文件，请不要关闭此网页…' />
      </Modal>
    </main>
  )
}

export default MeetingList
