import React from 'react'
import { Button, Input, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import dayjs, { Dayjs } from 'dayjs'
import { cloneDeep, map, get } from 'lodash'
import {
  getSalesBudgetChannelText,
  getSalesOrderStatus
} from '../../constant/sales'

//搜索字段声明
export const searchFields = () => [
  {
    name: 'keyword',
    label: '搜索',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return <Input allowClear placeholder='请输入消费订单ID进行搜索' />
    }
  }
]

export const useTableColumns = (): any => [
  {
    title: '消费订单ID',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    align: 'center',
    width: 200,
    render(v: any) {
      return <Link to={`/consumptionOrderBillDetail/${v}`}>{v}</Link>
    }
  },
  {
    title: '消费订单名称',
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    width: 200,
    render(v: any) {
      return v || '消费订单_未选消费场所'
    }
  },
  {
    title: '申请人',
    dataIndex: 'accountName',
    key: 'accountName',
    align: 'center',
    width: 100
  },
  {
    title: '申请人手机',
    dataIndex: 'accountMobile',
    key: 'accountMobile',
    align: 'center',
    width: 150
  },
  {
    title: '预算渠道',
    dataIndex: 'budgetChannel',
    key: 'budgetChannel',
    align: 'center',
    width: 150,
    render(v: any) {
      return getSalesBudgetChannelText(v)
    }
  },
  {
    title: '预计',
    dataIndex: 'consumptionAmount',
    key: 'consumptionAmount',
    align: 'center',
    width: 100
  },
  {
    title: '实际',
    dataIndex: 'actualConsumptionAmount',
    key: 'actualConsumptionAmount',
    align: 'center',
    width: 100
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    key: 'submitTime',
    align: 'center',
    width: 150,
    render(v: any) {
      return v ? dayjs(v).format('YYYY-MM-DD') : '-'
    }
  },
  {
    title: '消费时间',
    dataIndex: 'targetOccurTime',
    key: 'targetOccurTime',
    align: 'center',
    width: 150,
    render(v: any) {
      return v ? dayjs(v).format('YYYY-MM-DD') : '-'
    }
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    fixed: 'right',
    width: 120,
    render(v: any) {
      const status = getSalesOrderStatus(v)
      return <span style={{ color: status.color }}>{status.text}</span>
    }
  }
]

export type FieldTypes = {
  keyword?: string
}

// 表格导出字段
export const excelHead = () => [
  {
    key: 'id',
    path: 'id',
    name: '消费订单ID'
  },
  {
    key: 'targetName',
    path: 'targetName',
    name: '消费订单名称'
  },
  {
    key: 'accountName',
    path: 'accountName',
    name: '申请人'
  },
  {
    key: 'accountMobile',
    path: 'accountMobile',
    name: '申请人手机'
  },
  {
    key: 'budgetChannel',
    path: 'budgetChannel',
    name: '预算渠道'
  },
  { key: 'consumptionAmount', path: 'consumptionAmount', name: '预计' },
  {
    key: 'actualConsumptionAmount',
    path: 'actualConsumptionAmount',
    name: '实际'
  },
  { key: 'submitTime', path: 'submitTime', name: '提交时间' },
  { key: 'targetOccurTime', path: 'targetOccurTime', name: '消费时间' },
  {
    key: 'status',
    path: 'status',
    name: '订单状态'
  }
]

// 整理出表格数据
export const getData = (data: any, head: any) => {
  const result = data ? cloneDeep(data) : []
  return map(result, (item) => {
    const obj: any = {}

    head.forEach((cell: any) => {
      const value = get(item, cell.path, '-')

      switch (cell.key) {
        case 'targetName':
          obj[cell.name] = value || '消费订单_未选消费场所'
          break
        case 'budgetChannel':
          obj[cell.name] = getSalesBudgetChannelText(value)
          break
        case 'status':
          obj[cell.name] = getSalesOrderStatus(value).text
          break
        default:
          obj[cell.name] = value
          break
      }
    })

    return obj
  })
}
