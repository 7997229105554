import React from 'react'
import { Button, Input, Popconfirm } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { map, get } from 'lodash'

import { HansohBudget, ProcessBudget } from '../../graphql'
import { HRCompanySelector } from '../../components/SelectorCollection'
import { Link } from 'react-router-dom'
import { ACCOUNT_STATUS } from '../../constant/user'

export const searchFields = (onCompanyChange?: (value: string) => void) => [
  {
    name: 'companyId',
    label: '企业',
    span: 8,
    screenColumn: { xs: 24, sm: 12, md: 8 },
    render() {
      return (
        <HRCompanySelector
          isFromDoctorPage={false}
          onChange={onCompanyChange}
          style={{ minWidth: 120 }}
        />
      )
    }
  },
  {
    name: 'keyword',
    label: '搜索',
    span: 12,
    screenColumn: { xs: 24, sm: 24, md: 12 },
    render() {
      return <Input allowClear placeholder='请输入姓名、手机号搜索' />
    }
  }
]

export const useTableColumns = (props: {
  unbindAlipayRelationship: (accountId: string) => void
  onEdit: (accountInfo: any) => void
  operateAttention: (accountId: string, isAttention: boolean) => void
  isMobile?: boolean
}): ColumnsType<any> => {
  return [
    {
      align: 'center',
      title: '姓名',
      dataIndex: 'cnName',
      key: 'cnName',
      fixed: 'left',
      width: props.isMobile ? 50 : 100,
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '手机号',
      dataIndex: 'companyMobile',
      key: 'companyMobile',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '邮箱',
      dataIndex: 'companyEmail',
      key: 'companyEmail',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '报销编号',
      dataIndex: 'staffId',
      key: 'staffId',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return v || '-'
      }
    },
    {
      align: 'center',
      title: '业务部',
      dataIndex: 'structure',
      key: 'structure',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return v?.name || '-'
      }
    },
    {
      align: 'center',
      title: '成本中心',
      dataIndex: 'costcenter',
      key: 'costcenter',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return v?.name || '-'
      }
    },
    {
      align: 'center',
      title: '账号状态',
      dataIndex: 'status',
      key: 'status',
      width: props.isMobile ? 100 : 150,
      render(v) {
        return ACCOUNT_STATUS[v]
      }
    },
    {
      align: 'center',
      title: '操作',
      dataIndex: 'accountId',
      key: 'accountId',
      fixed: 'right',
      width: props.isMobile ? 100 : 150,
      render(v, data) {
        const isAttention = data.verifyResult === '是'
        return (
          <>
            <Popconfirm
              placement='topRight'
              title='确认解除该用户已绑的支付宝吗？'
              onConfirm={() => props.unbindAlipayRelationship(v)}
              okText='确认'
              cancelText='取消'
            >
              <Button type='link'>解绑支付宝</Button>
            </Popconfirm>
            <Button type='link' onClick={() => props.onEdit(data)}>
              编辑
            </Button>
            <Popconfirm
              placement='topRight'
              title={
                isAttention
                  ? '取消关注后，该用户提交的订单不再特殊标记。'
                  : '关注成功后，该用户提交的订单会被特殊标记。'
              }
              onConfirm={() => props.operateAttention(v, isAttention)}
              okText='确认'
              cancelText='取消'
            >
              <Button type='link'>{isAttention ? '取消关注' : '关注'}</Button>
            </Popconfirm>
          </>
        )
      }
    }
  ]
}

export type FieldTypes = {
  companyId?: string
  keyword?: string
}
