import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Form, InputNumber, Select, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import FormItem from 'antd/lib/form/FormItem'
import { HrMeetingDetailQuery, Maybe } from '../../graphql'
import { CitySelector } from '../../components/SelectorCollection'
import { Store } from 'antd/lib/form/interface'
import omit from 'lodash.omit'
import { BUDGET_TYPE_MAP } from '../../constant/hrradiation'
import { HR_PRODUCTION } from '../../constant/user'

const fieldName = (index: number, name: string) => `quotation${index}.${name}`

export const useMeetingBaseInfo = (
  detail: HrMeetingDetailQuery['detail'],
  meetingType: string,
  production: string
) => {
  const fields = !detail
    ? []
    : [
        ...([
          'CATE_DOCUMENT_COMMENT',
          'CATE_QUESTIONNAIRE_RESEARCH',
          'CATE_CASE_COLLECTION',
          'CATE_DOCUMENT_COMPOSE'
        ].includes(meetingType)
          ? [
              {
                label: '会议类型',
                content: detail.meetingType || ''
              },
              {
                label: '产品类型',
                content: detail?.base?.belongProduct?.productName || ''
              }
            ]
          : []),
        ...(detail.city?.name
          ? [
              {
                label: '城市',
                content: detail.city?.name
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.radiation ||
        production === HR_PRODUCTION.anesthesiology
          ? [
              {
                label: '成本中心',
                content: detail.costcenter?.name
              }
            ]
          : []),

        {
          label: '开始时间',
          content: dayjs(detail.base?.startTime!).format('YYYY-MM-DD')
        },
        {
          label: '结束时间',
          content: dayjs(detail.base?.endTime!).format('YYYY-MM-DD')
        },
        {
          label: '创建时间',
          content: dayjs(detail.base?.createTime!).format('YYYY-MM-DD')
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        {
          label: '申请人电话',
          content: detail.base?.owner?.companyMobile
        },
        {
          label: '申请人省区',
          content: detail.mettingRegion?.name
        },
        ...(production === HR_PRODUCTION.radiation && detail.vendor?.cnName
          ? [{ label: '项目服务商', content: detail.vendor?.cnName }]
          : []),
        ...([
          'ADVERTISING_ACTIVITIES',
          'CATE_DOCUMENT_COMMENT',
          'CATE_QUESTIONNAIRE_RESEARCH',
          'CATE_CASE_COLLECTION',
          'CATE_DOCUMENT_COMPOSE'
        ].includes(meetingType)
          ? [
              {
                label: '活动类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(meetingType === 'NORTHERN_FUJIAN_CASE'
          ? [
              {
                label: '闽北病例类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(meetingType === 'JIANGXI_CASE'
          ? [
              {
                label: '江西病例类型',
                content: detail.activityType || ''
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.hansoh
          ? [
              {
                label: '预算渠道',
                content: detail.base?.budgetChannel
                  ? BUDGET_TYPE_MAP.find(
                      (i: { value: Maybe<number> | undefined }) =>
                        i.value === detail.base?.budgetChannel
                    )?.text || '-'
                  : '-'
              },
              {
                label: '预算编号',
                content: detail.base?.budgetNumber || ''
              }
            ]
          : []),
        ...(production === HR_PRODUCTION.cttq &&
        detail.base?.belongProduct?.productName
          ? [
              {
                label: '产品线',
                content: detail.base?.belongProduct?.productName
              }
            ]
          : []),
        ...(meetingType === 'ACADEMIC_CONFERENCE'
          ? [
              {
                label: '会议类型',
                content: detail.activityType || '-'
              },
              {
                label: '会议平台',
                content:
                  JSON.parse(detail.conferencePlatformInfo || '{}')
                    .conferencePlatform || '-'
              },
              {
                label: '会议号',
                content:
                  JSON.parse(detail.conferencePlatformInfo || '{}')
                    .conferenceNo || '-'
              }
            ]
          : []),
        ...([
          HR_PRODUCTION.qc,
          HR_PRODUCTION.qcDemo,
          HR_PRODUCTION.hansoh
        ].includes(production) && meetingType === 'ACADEMIC_CONFERENCE'
          ? [
              {
                label: '会议种类',
                content: detail.meetingCategory || '-'
              }
            ]
          : [])
      ]

  return fields
}

export const useProjectBaseInfo = (detail: HrMeetingDetailQuery['detail']) => {
  const fields = !detail
    ? []
    : [
        {
          label: '活动城市',
          content: detail.city?.name
        },
        {
          label: '参与单位',
          content: detail.hospital
        },
        {
          label: '活动开始时间',
          content: dayjs(detail.base?.startTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动结束时间',
          content: dayjs(detail.base?.endTime!).format('YYYY-MM-DD')
        },
        {
          label: '活动介绍',
          content: detail.base?.desc
        },
        {
          label: '创建时间',
          content: dayjs(detail.base?.createTime!).format('YYYY-MM-DD')
        },
        {
          label: '申请人',
          content: detail.base?.owner?.cnName
        },
        {
          label: '申请人电话',
          content: detail.base?.owner?.companyMobile
        }
        // {
        //   label: '申请人省区',
        //   content: detail.base?.owner?.region?.name
        // }
      ]

  return fields
}

export const generateTotalObj = (carTotal: number, numTotal: number) => {
  if (!carTotal || carTotal === 0) {
    return {
      carTotal: 0,
      poTotal: 0,
      taxTotal: 0,
      totalAmount: 0,
      numTotal
    }
  }
  const poTotal = carTotal + numTotal * 3
  const taxTotal = (poTotal * 6) / 100

  return {
    carTotal,
    poTotal,
    taxTotal,
    totalAmount: poTotal + taxTotal,
    numTotal
  }
}
