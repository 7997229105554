import { useRef, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.()
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay)
      return () => clearTimeout(id)
    }
  }, [delay])
}

export const useQueryParams = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function usePrevious<T>(
  value: T,
  setValueOnInitial?: boolean
): T | undefined {
  const ref = useRef<T | undefined>(setValueOnInitial ? value : undefined)

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
