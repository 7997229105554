import { Route as ADLayoutRoute } from '@ant-design/pro-layout/lib/typings'
import MeetingList from './pages/MeetingList'
import ProjectList from './pages/ProjectList'
import MeetingDetail from './pages/MeetingDetail'
import MeetingApproveDetail from './pages/MeetingApproveDetail'
import { UnorderedListOutlined } from '@ant-design/icons'
import DoctorManage from './pages/DoctorManage'
import CampainList from './pages/CampainList'
import BalanceManage from './pages/BalanceManage'
import BalanceDetail from './pages/BalanceDetail'
import ArchiveList from './pages/ArchiveList'
import OrderManage from './pages/OrderManage'
import SettlementManage from './pages/SettlementManage'
import MeetingManage from './pages/MeetingManage'
import DoctorManageOfFinance from './pages/DoctorManageOfFinance'
import BalanceManageOfFinance from './pages/BalanceManageOfFinance'
import ApproveManage from './pages/ApproveManage'
import LiteratureReview from './pages/LiteratureReview'
import BudgetImport from './pages/BudgetImport'
import PersonalBudgetImport from './pages/BudgetImport/personalImport'
import ProcessBudgetImport from './pages/BudgetImport/processImport'
import BudgetList from './pages/BudgetList'
import PersonalBudgetList from './pages/BudgetList/personalBudget'
import ProcessBudgetList from './pages/BudgetList/processBudget'
import ProcessBudgetDetail from './pages/BudgetDetail/processBudgetDetail'
import PersonalBudgetDetail from './pages/BudgetDetail/personalBudgetDetail'
import CategoriesManage from './pages/CategoriesManage'
import CompanyCategoriesManage from './pages/CompanyCategoriesManage'
import LiteratureManage from './pages/LiteratureManage'
import LiteraturePreview from './pages/LiteraturePreview'
import MeetingActivityList from './pages/MeetingActivityList'
import TaskDetail from './pages/TaskDetail'
import TaskImport from './pages/TaskImport'
import TaskList from './pages/TaskList'
import DoctorImport from './pages/DoctorImport'
import RegionManageOfFinance from './pages/RegionManageOfFinance'
import InPlanBudgetImport from './pages/BudgetImport/inPlanBudgetImport'
import InPlanBudgetList from './pages/BudgetList/inPlanBudget'
import InPlanBudgetDetail from './pages/BudgetDetail/inPlanBudgetDetail'
import OutPlanBudgetImport from './pages/BudgetImport/outPlanBudgetImport'
import OutPlanBudgetList from './pages/BudgetList/outPlanBudget'
import OutPlanBudgetDetail from './pages/BudgetDetail/outPlanBudgetDetail'
import CostCenterBudgetImport from './pages/BudgetImport/costCenterBudgetImport'
import CostCenterBudgetList from './pages/BudgetList/costCenterBudget'
import CostCenterBudgetDetail from './pages/BudgetDetail/costCenterBudgetDetail'
import ConsumptionReimburseManage from './pages/ConsumptionReimburseManage'
import ConsumptionReimbursePreApprove from './pages/ConsumptionReimbursePreApprove'
import ConsumptionReimburseApprove from './pages/ConsumptionReimburseApprove'
import ConsumptionReimburseDetail from './pages/ConsumptionReimburseDetail'
import ConsumptionOrderQueryByQrCode from './pages/ConsumptionOrderQueryByQrCode'
import ConsumptionOrderManage from './pages/ConsumptionOrderManage'
import ConsumptionOrderBillApprove from './pages/ConsumptionOrderBillApprove'
import ConsumptionOrderBillDetail from './pages/ConsumptionOrderBillDetail'
import ConsumptionOrderBillApproveDetail from './pages/ConsumptionOrderBillApproveDetail'
import UserManage from './pages/UserManage'
import StaffManage from './pages/StaffManage'
import YhlOrderManage from './pages/YhlOrderManage'
import YHLMeetingDetail from './pages/YHLMeetingDetail'
import AdminCompanyManage from './pages/AdminCompanyList'
import AdminCompanyDetail from './pages/AdminCompanyDetail'

export const defaultRoutes: ADLayoutRoute = {
  routes: [
    {
      code: 'uplatform_hrimg_meetinglist',
      path: '/meetingList',
      name: '学术会议列表',
      key: 'meetingList',
      component: MeetingList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_projectlist',
      path: '/projectList',
      name: '医学研究项目列表',
      key: 'projectList',
      component: ProjectList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_campaignlist',
      path: '/campaignlist',
      name: '推广活动列表',
      key: 'campainList',
      component: CampainList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_fujiannorthlist',
      path: '/northernFujianList',
      name: '闽北病例列表',
      key: 'northernFujianList',
      component: CampainList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_jiangxiCaseList',
      path: '/jiangxiCaseList',
      name: '江西病例列表',
      key: 'jiangxiCaseList',
      component: CampainList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_interventionallist',
      path: '/interventionalList',
      name: '介入性专线项目列表',
      key: 'interventionalList',
      component: MeetingList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_activitylist',
      path: '/meetingActivityList',
      name: '学术活动列表',
      key: 'meetingActivityList',
      component: MeetingActivityList,
      icon: UnorderedListOutlined
    },
    {
      path: '/meeting/:meetingId',
      name: '会议详情',
      hideInMenu: true,
      component: MeetingDetail
    },
    {
      path: '/yhlmeeting/:meetingId',
      name: '会议详情',
      hideInMenu: true,
      component: YHLMeetingDetail
    },
    {
      code: 'uplatform_hrimg_approveManage',
      path: '/meetingApproveDetail/:meetingId',
      name: '会议审核详情',
      hideInMenu: true,
      component: MeetingApproveDetail
    },
    {
      path: '/meeting/:meetingId/:action',
      name: '会议报价',
      hideInMenu: true,
      component: MeetingDetail
    },
    {
      code: 'uplatform_hrimg_doctorManage',
      path: '/doctorManage',
      name: '老师管理',
      key: 'doctorManage',
      component: DoctorManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_balanceManage',
      path: '/balanceManage',
      name: '余额管理',
      key: 'balanceManage',
      component: BalanceManage,
      icon: UnorderedListOutlined
    },
    {
      path: '/balanceDetail/:detail',
      name: '余额明细',
      key: 'balanceDetail',
      hideInMenu: true,
      component: BalanceDetail
    },
    {
      code: 'uplatform_hrimg_archiveList',
      path: '/archiveList',
      name: '资料上传',
      key: 'archiveList',
      component: ArchiveList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_orderManage',
      path: '/orderManage',
      name: '订单管理',
      key: 'orderManage',
      component: OrderManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_yhlorderManage',
      path: '/yhlOrderManage',
      name: '易惠利学术会议管理',
      key: 'yhlOrderManage',
      component: YhlOrderManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_settlementManage',
      path: '/settlementManage',
      name: '结算管理',
      key: 'settlementManage',
      component: SettlementManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_meetingManage',
      path: '/meetingManage',
      name: '会议管理',
      key: 'meetingManage',
      component: MeetingManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_doctorImport',
      path: '/doctorImport',
      name: '老师导入',
      key: 'doctorImport',
      component: DoctorImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_doctorManageOfFinance',
      path: '/doctorManageOfFinance',
      name: '老师管理',
      key: 'doctorManageOfFinance',
      component: DoctorManageOfFinance,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_balanceManageOfFinance',
      path: '/balanceManageOfFinance',
      name: '余额管理',
      key: 'balanceManageOfFinance',
      component: BalanceManageOfFinance,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_regionManageOfFinance',
      path: '/regionManageOfFinance',
      name: '区域管理',
      key: 'regionManageOfFinance',
      component: RegionManageOfFinance,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_approveManage',
      path: '/approveManage',
      name: '审批管理',
      key: 'approveManage',
      component: ApproveManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_reviewManageOfFinance',
      path: '/literatureReview',
      name: '文献点评',
      key: 'literatureReview',
      component: LiteratureReview,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_budgetImport',
      path: '/budgetImport',
      name: '预算导入',
      key: 'budgetImport',
      component: BudgetImport,
      icon: UnorderedListOutlined,
      hideInMenu: true
    },
    {
      code: 'uplatform_hrimg_budgetList',
      path: '/budgetList',
      name: '预算查询',
      key: 'budgetList',
      component: BudgetList,
      icon: UnorderedListOutlined,
      hideInMenu: true
    },
    {
      code: 'uplatform_hrimg_process_budgetImport',
      path: '/processBudgetImport',
      name: '流程预算导入',
      key: 'processBudgetImport',
      component: ProcessBudgetImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_process_budgetList',
      path: '/processBudgetList',
      name: '流程预算查询',
      key: 'processBudgetList',
      component: ProcessBudgetList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_process_budgetList',
      path: '/processBudgetDetail/:id',
      name: '流程预算详情',
      key: 'processBudgetDetail',
      hideInMenu: true,
      component: ProcessBudgetDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_personal_budgetImport',
      path: '/personalBudgetImport',
      name: '个人预算导入',
      key: 'personalBudgetImport',
      component: PersonalBudgetImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_personal_budgetList',
      path: '/personalBudgetList',
      name: '个人预算查询',
      key: 'personalBudgetList',
      component: PersonalBudgetList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_personal_budgetList',
      path: '/personalBudgetDetail/:id',
      name: '个人预算详情',
      key: 'personalBudgetDetail',
      hideInMenu: true,
      component: PersonalBudgetDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_meetingManage',
      path: '/categoriesManage',
      name: '类型管理',
      key: 'categoriesManage',
      component: CategoriesManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_meetingManage',
      path: '/companyCategoriesManage',
      name: '企业类型管理',
      key: 'companyCategoriesManage',
      component: CompanyCategoriesManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_meetingManage',
      path: '/literatureManage',
      name: '文献管理',
      key: 'literatureManage',
      component: LiteratureManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_meetingManage',
      path: '/literaturePreview/:archiveId',
      name: '文献预览',
      key: 'literaturePreview',
      hideInMenu: true,
      component: LiteraturePreview,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hr_anesthesiology_literatureManage',
      path: '/literatureManage',
      name: '文献管理',
      key: 'literatureManage',
      component: LiteratureManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hr_anesthesiology_literatureManage',
      path: '/literaturePreview/:archiveId',
      name: '文献预览',
      key: 'literaturePreview',
      hideInMenu: true,
      component: LiteraturePreview,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_taskImport',
      path: '/taskImport',
      name: '任务导入',
      key: 'taskImport',
      component: TaskImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hrimg_taskList',
      path: '/taskList',
      name: '任务查询',
      key: 'taskList',
      component: TaskList,
      icon: UnorderedListOutlined
    },
    {
      path: '/taskDetail/:attenderId',
      name: '任务详情',
      key: 'taskDetail',
      hideInMenu: true,
      component: TaskDetail
    },
    {
      code: 'uplatform_hs_in_plan_budgetImport',
      path: '/inPlanBudgetImport',
      name: '日常预算导入',
      key: 'inPlanBudgetImport',
      component: InPlanBudgetImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_in_plan_budgetList',
      path: '/inPlanBudgetList',
      name: '日常预算查询',
      key: 'inPlanBudgetList',
      component: InPlanBudgetList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_in_plan_budgetList',
      path: '/inPlanBudgetDetail/:id',
      name: '日常预算详情',
      key: 'inPlanBudgetDetail',
      hideInMenu: true,
      component: InPlanBudgetDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_out_plan_budgetImport',
      path: '/outPlanBudgetImport',
      name: '专项预算导入',
      key: 'outPlanBudgetImport',
      component: OutPlanBudgetImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_out_plan_budgetList',
      path: '/outPlanBudgetList',
      name: '专项预算查询',
      key: 'outPlanBudgetList',
      component: OutPlanBudgetList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_out_plan_budgetList',
      path: '/outPlanBudgetDetail/:id',
      name: '专项预算详情',
      key: 'outPlanBudgetDetail',
      hideInMenu: true,
      component: OutPlanBudgetDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_costCenter_budgetImport',
      path: '/costCenterBudgetImport',
      name: '成本中心预算导入',
      key: 'costCenterBudgetImport',
      component: CostCenterBudgetImport,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_costCenter_budgetList',
      path: '/costCenterBudgetList',
      name: '成本中心预算查询',
      key: 'costCenterBudgetList',
      component: CostCenterBudgetList,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_costCenter_budgetList',
      path: '/costCenterBudgetDetail/:id',
      name: '成本中心预算详情',
      key: 'costCenterBudgetDetail',
      hideInMenu: true,
      component: CostCenterBudgetDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_financial_pre_review',
      path: '/consumptionReimbursePreApprove',
      name: '财务初审',
      key: 'consumptionReimbursePreApprove',
      component: ConsumptionReimbursePreApprove,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_financial_pre_review',
      path: '/consumptionReimburseDetail/:claimFormCode',
      name: '财务初审提报单详情',
      key: 'consumptionReimburseDetail',
      hideInMenu: true,
      component: ConsumptionReimburseDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_financial_review',
      path: '/consumptionReimburseApprove',
      name: '财务复核',
      key: 'consumptionReimburseApprove',
      component: ConsumptionReimburseApprove,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_financial_review',
      path: '/consumptionReimburseDetail/:claimFormCode',
      name: '财务复核提报单详情',
      key: 'consumptionReimburseDetail',
      hideInMenu: true,
      component: ConsumptionReimburseDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_qc_consumption_reimburse_manage',
      path: '/consumptionReimburseManage',
      name: '提报单管理',
      key: 'consumptionReimburseManage',
      component: ConsumptionReimburseManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_qc_consumption_reimburse_manage',
      path: '/consumptionReimburseDetail/:claimFormCode',
      name: '提报单详情',
      key: 'consumptionReimburseDetail',
      hideInMenu: true,
      component: ConsumptionReimburseDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hs_consumption_order_bill_approve',
      path: '/consumptionOrderBillApprove',
      name: '票据审核',
      key: 'consumptionOrderBillApprove',
      component: ConsumptionOrderBillApprove,
      icon: UnorderedListOutlined
    },
    {
      path: '/consumptionOrderBillApproveDetail/:id',
      name: '票据审核详情',
      key: 'consumptionOrderBillApproveDetail',
      hideInMenu: true,
      component: ConsumptionOrderBillApproveDetail,
      icon: UnorderedListOutlined
    },
    {
      path: '/consumptionOrderBillDetail/:id',
      name: '消费订单详情',
      key: 'consumptionOrderBillDetail',
      hideInMenu: true,
      component: ConsumptionOrderBillDetail,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_qc_consumption_order_manage',
      path: '/consumptionOrderManage',
      name: '消费订单管理',
      key: 'consumptionOrderManage',
      component: ConsumptionOrderManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_qc_consumption_order_qrcode_query',
      path: '/consumptionOrderQueryByQrCode',
      name: '消费订单查询',
      key: 'consumptionOrderQueryByQrCode',
      component: ConsumptionOrderQueryByQrCode,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_hansoh_budget_userManage',
      path: '/userManage',
      name: '用户管理',
      key: 'userManage',
      component: UserManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_staffManage',
      path: '/staffManage',
      name: '员工管理',
      key: 'staffManage',
      component: StaffManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_adminCompanyList',
      path: '/adminCompanyManage',
      name: '入驻公司管理',
      key: 'adminCompanyManage',
      component: AdminCompanyManage,
      icon: UnorderedListOutlined
    },
    {
      code: 'uplatform_adminCompanyDetail',
      path: '/adminCompanyManage/:id',
      name: '入驻公司管理详情',
      key: 'adminCompanyDetail',
      hideInMenu: true,
      component: AdminCompanyDetail,
      icon: UnorderedListOutlined
    }
  ]
}
