import React, { FC, useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Space,
  Button,
  Radio,
  Row,
  Tag,
  Divider,
  Col,
  Table,
  Image,
  InputNumber,
  Tooltip,
  message,
  Spin,
  Affix
} from 'antd'
import { RuleObject } from 'antd/lib/form'
import { StoreValue } from 'antd/lib/form/interface'
import { useWindowSize } from 'react-use'
import {
  AccountCertInfo,
  HrMeetingDetailQuery,
  StandardMeetingServiceBizStatus,
  useDoctorTitleRuleConfigQuery,
  useMeetingApprovalFlowsQuery
} from '../../graphql'
import _ from 'lodash'
import dayjs from 'dayjs'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import VideoButton from '../../components/VideoButton'
import { HR_PRODUCTION, isVideoUrl } from '../../constant/user'
import VerifyDoctorInfoModal from './verifyDoctorInfoModal'
import './index.less'
import RepeatTag from '../../components/RepeatTag'
import ApprovalProcessCard from './approvalProcess'

const getImgSource = (pic: string) => {
  const sliceIndex = pic?.indexOf('?')
  const url = sliceIndex > -1 ? pic.slice(0, sliceIndex) : pic
  if (/\.(png|jpe?g|jpeg|gif|webp|bmp)$/i.test(url)) {
    return url
  }
  return url + '?x-oss-process=image/format,jpg'
}

const formatPanoramicPicUrls = (data: any) => {
  let urls: any[] = []
  const panoramicPicUrls =
    data.panoramicPicUrls || data.materials || data.materialUrls
  panoramicPicUrls?.map((url: any) => {
    if (url.value) {
      urls = urls.concat(url.value)
    } else {
      urls.push(url)
    }
  })
  return urls
}

const formatFrontPicUrls = (data: any) => {
  let urls: any[] = []
  const frontPicUrls = data.frontPicUrls
  frontPicUrls?.map((url: any) => {
    if (url.value) {
      urls = urls.concat(url.value)
    } else {
      urls.push(url)
    }
  })
  return urls
}

const ApprovalInfo: FC<{
  onCancel: () => void
  onHandleForwardSignature: (values: any, failReason?: string) => void
  onHandleRetreat: (values: any, failReason?: string) => void
  onHandleConfirm: (values: any) => void
  meetingInfo?: HrMeetingDetailQuery['detail']
  production?: string
  isApprover?: boolean
  verificationData?: any
  meetingAuditRecord?: any[] | undefined | null
}> = ({
  onCancel,
  onHandleForwardSignature,
  onHandleRetreat,
  onHandleConfirm,
  meetingInfo,
  production,
  isApprover,
  verificationData,
  meetingAuditRecord = []
}) => {
  const [form] = Form.useForm()
  const [isShowModal, setIsShowModal] = useState(false)
  const [curAttenderId, setCurAttenderId] = useState<string | null | undefined>(
    undefined
  )
  const [curAttenderCertImages, setCurAttenderCertImages] = useState<
    AccountCertInfo | null | undefined
  >(undefined)
  const [verifyData, setVerifyData] = useState<any>([])
  // 当前验证参会时长老师信息
  const [validatorDoctor, setValidatorDoctor] = useState<any>(undefined)
  // 参会时长验证记录
  const [validatorDurationData, setValidatorDurationData] = useState<any>([])
  // 参会时长验证未通过原因
  const [failReasonData, setFailReasonData] = useState<any>([])
  const [approveUserName, setApproveUserName] = useState('')
  const { width, height } = useWindowSize()

  const [{ data: approvalInfo, fetching: approvalsFetching }] =
    useMeetingApprovalFlowsQuery({
      variables: { meetingId: meetingInfo?.base?.id! }
    })

  useEffect(() => {
    if (approvalInfo?.result && !approvalsFetching) {
      if (meetingInfo?.approveUser) {
        try {
          let approveUserData = JSON.parse(meetingInfo?.approveUser)
          if (approveUserData?.length > 0) {
            approveUserData = approveUserData.sort(
              (a: any, b: any) => a.createTime - b.createTime
            )
            for (const approval of approvalInfo?.result) {
              const approveUserItem = approval.tasks?.find(
                (x) => x.approverInfo?.roleAuthoritiesIncludeQcxs
              )
              if (approveUserItem?.approverInfo?.cnName) {
                const instanceId = approval.instanceId
                let approveUserName = approveUserData
                  .filter((x: any) => x.instanceId === instanceId)
                  .map((x: any) => x.approveUser)
                  .join('-')
                if (approveUserName) {
                  approveUserName =
                    approveUserItem?.approverInfo?.cnName +
                    `（${approveUserName}）`
                  approveUserItem.approverInfo.cnName = approveUserName
                }
              }
            }
            setApproveUserName(meetingInfo?.approveUser)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [approvalInfo])

  const isHANSOH = production === HR_PRODUCTION.hansoh

  const schedulePicAuditRecord =
    meetingAuditRecord?.find(
      (item) =>
        item.verifyType === '会议日程表' && item.verifyDimension === 'repeat'
    ) || {}

  const signPicAuditRecord =
    meetingAuditRecord?.find(
      (item) =>
        item.verifyType === '会议签到表' && item.verifyDimension === 'repeat'
    ) || {}

  const panoramicPicAuditRecords =
    meetingAuditRecord?.filter(
      (item) =>
        item.verifyType === '老师全景照' && item.verifyDimension === 'repeat'
    ) || []

  const frontPicRecords =
    meetingAuditRecord?.filter(
      (item) =>
        item.verifyType === '老师讲课照' && item.verifyDimension === 'repeat'
    ) || []

  const attenders =
    meetingInfo?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
      ? meetingInfo?.speakers
      : meetingInfo?.doctors

  const noVerifyLength =
    attenders?.filter((attender) => !attender.attenderInfo?.verifyResult)
      ?.length || 0

  const [
    { data: validatorData, fetching: validatorFetching },
    queryDoctorTitleRuleConfig
  ] = useDoctorTitleRuleConfigQuery({
    variables: {
      searchInput: {
        companyId: validatorDoctor?.attenderInfo?.companyId,
        title: validatorDoctor?.attenderInfo?.titleInfo?.name,
        role: validatorDoctor?.attenderData?.role,
        categoryCode: meetingInfo?.base?.type,
        duration: validatorDoctor?.duration
      }
    },
    pause: true
  })

  const onValuesChange = (changedValues: any, allValues: any) => {
    const changeField = _.keys(changedValues)[0]
    if (changeField.includes('panoramicPic-')) {
      const changeValue = _.values(changedValues)[0]
      const newAllValues = _.mapValues(allValues, function (value, key) {
        if (key.includes('panoramicPic-')) {
          return changeValue
        }
        return value
      })
      form.setFieldsValue(newAllValues)
    } else if (changeField.includes('duration-')) {
      const accountId = changeField.replace('duration-', '')
      form.setFieldsValue({
        [`attenderFee-${accountId}`]: undefined
      })
    }
  }

  const onValidatorDuration = (attender: any, duration: number) => {
    if (duration >= 0) {
      const accountId = attender.attenderId
      const validatorItem = validatorDurationData.find(
        (x: any) => x.accountId === accountId
      )
      if (validatorItem?.duration !== duration) {
        setValidatorDoctor({ ...attender, duration })
      }
    }
  }

  useEffect(() => {
    if (validatorDoctor?.id) {
      queryDoctorTitleRuleConfig()
    }
  }, [validatorDoctor])

  useEffect(() => {
    if (validatorData) {
      const accountId = validatorDoctor.attenderId
      if (validatorData?.result?.id) {
        const newValidatorDurationData = _.cloneDeep(validatorDurationData)
        const validatorItem = newValidatorDurationData.find(
          (x: any) => x.accountId === accountId
        )
        if (validatorItem?.duration) {
          validatorItem.duration = validatorDoctor.duration
        } else {
          newValidatorDurationData.push({
            accountId,
            duration: validatorDoctor.duration
          })
        }
        setValidatorDurationData(newValidatorDurationData)

        let newFailReasonData = _.cloneDeep(failReasonData)
        const failReasonItem = newFailReasonData.find(
          (x: any) => x.accountId === accountId
        )
        if (
          Number(validatorDoctor.attenderFee) >
          validatorData?.result?.pointLimit!
        ) {
          let reason
          if (
            ['讲师', '主席', '主持'].includes(
              validatorDoctor?.attenderData?.role
            )
          ) {
            reason = `${validatorDoctor.attenderInfo?.cnName}老师为${validatorData?.result?.titleGrade}职称，在本次会议中担任${validatorData?.result?.role}，时长${validatorDoctor.duration}分钟，最多支付${validatorData?.result?.pointLimit}。`
          } else if (
            ['嘉宾', '评委'].includes(validatorDoctor?.attenderData?.role)
          ) {
            reason = `${validatorDoctor.attenderInfo?.cnName}老师为${validatorData?.result?.titleGrade}职称，担任嘉宾、评委，每次积分最多${validatorData?.result?.pointLimit}。`
          }
          if (failReasonItem?.reason !== reason) {
            if (failReasonItem?.reason) {
              failReasonItem.reason = reason
            } else {
              let roleIndex = 0
              switch (validatorDoctor?.attenderData?.role) {
                case '讲师':
                  roleIndex = 0
                  break
                case '主席':
                  roleIndex = 1
                  break
                case '主持':
                  roleIndex = 2
                  break
                case '嘉宾':
                  roleIndex = 3
                  break
                case '评委':
                default:
                  roleIndex = 4
                  break
              }
              newFailReasonData.push({
                accountId,
                reason: reason,
                roleIndex
              })
            }
            newFailReasonData = _.sortBy(newFailReasonData, function (o) {
              return o.roleIndex
            })
            setFailReasonData(newFailReasonData)
          }

          form.setFieldsValue({
            [`attenderFee-${accountId}`]: 0
          })
        } else {
          if (failReasonItem) {
            _.pull(newFailReasonData, failReasonItem)
            setFailReasonData(newFailReasonData)
          }
          form.setFieldsValue({
            [`attenderFee-${accountId}`]: 1
          })
        }
      } else {
        message.warning('参会时长积分验证失败')
        form.setFieldsValue({
          [`attenderFee-${accountId}`]: 0
        })
        // form.setFields([
        //   {
        //     name: `duration-${accountId}`,
        //     errors: ['参会时长积分验证失败']
        //   }
        // ])
      }
    }
  }, [validatorData])

  const getFailReasonData = (values: any) => {
    //老师的资质验证、职称验证
    const checkFailReasons = []
    const cNames1: string[] = []
    const cNames2: string[] = []
    const cNames3: string[] = []
    _.forOwn(values, function (value, key) {
      const attender = attenders?.find(
        (x: any) => x.attenderId === key.split('-')[1]
      )
      if (key.indexOf('certification-') >= 0 && value === 0) {
        cNames1.push(attender?.attenderInfo?.cnName!)
      }
      if (key.indexOf('titleCertification-') >= 0 && value === 0) {
        cNames2.push(attender?.attenderInfo?.cnName!)
      }
      if (key.indexOf('task-') >= 0 && value === 0) {
        cNames3.push(attender?.attenderInfo?.cnName!)
      }
    })
    if (cNames1?.length) {
      checkFailReasons.push({
        reason: cNames1.join('、') + '老师，资质验证不通过。'
      })
    }
    if (cNames2?.length) {
      checkFailReasons.push({
        reason: cNames2.join('、') + '老师，职称验证不通过。'
      })
    }
    if (cNames3?.length) {
      checkFailReasons.push({
        reason: cNames3.join('、') + '老师，任务验证不通过。'
      })
    }

    return _.concat(checkFailReasons, failReasonData)
  }

  const onHandleClick = (type: number) => {
    form.submit()
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        if (type === 0) {
          const includesFailed = _.findKey(values, function (value, key) {
            return key.indexOf('duration-') < 0 && value === 0
          })
          if (!includesFailed) {
            message.warn('上述审核任务都为通过状态，不能加签操作')
            return
          }
          const failReasonData = getFailReasonData(values)
          onHandleForwardSignature(
            values,
            failReasonData.map((x: any) => x.reason).join('\n')
          )
        } else if (type === 1) {
          const includesFailed = _.findKey(values, function (value, key) {
            return key.indexOf('duration-') < 0 && value === 0
          })
          if (!includesFailed) {
            message.warn('上述审核任务都为通过状态，不能退回操作')
            return
          }
          const failReasonData = getFailReasonData(values)
          onHandleRetreat(
            values,
            failReasonData.map((x: any) => x.reason).join('\n')
          )
        } else if (type === 2) {
          const includesFailed = _.findKey(values, function (value, key) {
            return key.indexOf('duration-') < 0 && value === 0
          })
          if (includesFailed) {
            message.warn('上述审核任务有项目未通过，不能通过审核')
            return
          }
          onHandleConfirm(values)
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo)
      })
  }

  const meetingProperties = JSON.parse(
    verificationData?.meetingProperties || '{}'
  )

  const attenderProperties = JSON.parse(
    verificationData?.attenderProperties || '{}'
  )

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <div style={{ backgroundColor: '#fff', padding: 25 }}>
        <Spin spinning={validatorFetching}>
          <Form
            preserve={false}
            requiredMark={false}
            scrollToFirstError={true}
            form={form}
            onValuesChange={onValuesChange}
          >
            {meetingInfo?.base?.type === 'ACADEMIC_CONFERENCE' && (
              <>
                <Row
                  justify='start'
                  align='middle'
                  style={{ paddingTop: 25, paddingBottom: 25 }}
                >
                  <span
                    style={{
                      fontSize: 18,
                      color: '#4467F8',
                      fontWeight: 'bold'
                    }}
                  >
                    会议基本信息验证
                  </span>
                  <Tooltip
                    placement='rightTop'
                    title={
                      <div>
                        会议日程表：请验证会议日程表上的时间是否一致
                        <br />
                        会议签到表：请验证签到表上是否包含列表所有老师姓名
                      </div>
                    }
                    color='#F2F3F9'
                    overlayInnerStyle={{
                      color: '#828282',
                      padding: 15,
                      width: 400
                    }}
                  >
                    <Tag
                      style={{
                        border: 'none',
                        marginLeft: 15,
                        background: '#F2F3F9',
                        color: '#7D7F7F'
                      }}
                    >
                      验证标准
                      <InfoCircleOutlined style={{ marginLeft: 5 }} />
                    </Tag>
                  </Tooltip>
                </Row>
                <Row align='top'>
                  <Col span={12}>
                    <Row
                      justify='start'
                      align='middle'
                      style={{ marginTop: 15 }}
                    >
                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        会议日程表
                      </span>
                      <span style={{ color: '#7D7F7F', marginLeft: 10 }}>
                        (会议时间：
                        {dayjs(meetingInfo?.base?.startTime).format(
                          'YYYY.MM.DD'
                        )}
                        -
                        {dayjs(meetingInfo?.base?.endTime).format('YYYY.MM.DD')}
                        )
                      </span>
                    </Row>
                    <Row
                      gutter={16}
                      justify='start'
                      align='middle'
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <Image.PreviewGroup>
                        {meetingInfo?.schedulePics?.map((pic, index) => {
                          return (
                            <Col key={index} className='gutter-row'>
                              <Image
                                width={100}
                                height={100}
                                key={index}
                                src={getImgSource(pic)}
                              />
                            </Col>
                          )
                        })}
                      </Image.PreviewGroup>
                    </Row>
                    <RepeatTag
                      label='日程表验重'
                      repeatStatus={schedulePicAuditRecord.status}
                      properties={
                        schedulePicAuditRecord.properties?.length > 0
                          ? schedulePicAuditRecord.properties?.split(',')
                          : []
                      }
                    />
                    <Form.Item
                      label={
                        <label style={{ fontWeight: 'bold' }}>验证结果</label>
                      }
                      labelCol={{ span: 12, md: { span: 6 }, xl: { span: 4 } }}
                      labelAlign='left'
                      name='schedulePic'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message:
                            '请审核会议的开始结束时间和日程表中的会议开始结束时间是否一致'
                        }
                      ]}
                      initialValue={
                        isApprover ? undefined : meetingProperties.schedulePic
                      }
                    >
                      <Radio.Group disabled={!isApprover}>
                        <Radio value={1}>通过</Radio>
                        <Radio value={0}>不通过</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row
                      justify='start'
                      align='middle'
                      style={{ marginTop: 15 }}
                    >
                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        会议签到表
                      </span>
                    </Row>
                    <Row
                      gutter={16}
                      justify='start'
                      align='middle'
                      style={{ marginTop: 15, marginBottom: 15 }}
                    >
                      <Image.PreviewGroup>
                        {meetingInfo?.signPics?.map((pic, index) => {
                          return (
                            <Col key={index} className='gutter-row'>
                              <Image
                                width={100}
                                height={100}
                                key={index}
                                src={getImgSource(pic)}
                              />
                            </Col>
                          )
                        })}
                      </Image.PreviewGroup>
                    </Row>
                    <RepeatTag
                      label='签到表验重'
                      repeatStatus={signPicAuditRecord.status}
                      properties={
                        signPicAuditRecord.properties?.length > 0
                          ? signPicAuditRecord.properties?.split(',')
                          : []
                      }
                    />
                    <Form.Item
                      label={
                        <label style={{ fontWeight: 'bold' }}>验证结果</label>
                      }
                      labelCol={{ span: 12, md: { span: 6 }, xl: { span: 4 } }}
                      labelAlign='left'
                      name='signPic'
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message:
                            '请审核会议签到表中的老师信息是否和下面列表中邀请老师的信息一致'
                        }
                      ]}
                      initialValue={
                        isApprover ? undefined : meetingProperties.signPic
                      }
                    >
                      <Radio.Group disabled={!isApprover}>
                        <Radio value={1}>通过</Radio>
                        <Radio value={0}>不通过</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </>
            )}
            <Row
              justify='start'
              align='middle'
              style={{ paddingTop: 25, paddingBottom: 25 }}
            >
              <span
                style={{ fontSize: 18, color: '#4467F8', fontWeight: 'bold' }}
              >
                参会老师信息验证
              </span>
              <Tooltip
                placement='rightTop'
                title={
                  <div>
                    资质验证：判断老师的执业资格图片以及复核材料
                    {meetingInfo?.base?.type === 'ACADEMIC_CONFERENCE' && (
                      <>
                        <br />
                        全景照验证：是否有会场的全景照片
                        <br />
                        讲课照验证：是否有老师讲课的正面照片
                        <br />
                        参会时长：请填写会议日程图片中老师的参会时间，单位是分钟
                        <br />
                        积分验证：判断老师的职称，参会角色，参会时长，产生的积分是否合规
                      </>
                    )}
                  </div>
                }
                color='#F2F3F9'
                overlayInnerStyle={{
                  color: '#828282',
                  padding: 15,
                  width:
                    meetingInfo?.base?.type === 'ACADEMIC_CONFERENCE'
                      ? 500
                      : 350
                }}
              >
                <Tag
                  style={{
                    border: 'none',
                    marginLeft: 15,
                    background: '#F2F3F9',
                    color: '#7D7F7F'
                  }}
                >
                  验证标准
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tag>
              </Tooltip>
            </Row>
            <Row>
              <Table
                dataSource={attenders || []}
                scroll={{ x: 1800 }}
                bordered
                sticky
                pagination={false}
                columns={[
                  {
                    title: '老师姓名',
                    key: 'cnName',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    fixed: 'left',
                    width: 100,
                    render(_, attender) {
                      return `${attender.attenderInfo?.cnName}(${attender.attenderInfo?.companyMobile})`
                    }
                  },
                  {
                    title: '老师职称',
                    key: 'title',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 100,
                    render(_, attender) {
                      return attender.attenderInfo?.titleInfo?.name
                    }
                  },
                  {
                    title: '医院名称',
                    key: 'hospital',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 100,
                    render(_, attender) {
                      return attender.attenderInfo?.hospital
                    }
                  },
                  {
                    title: '医院所属省份',
                    key: 'province',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 150,
                    render(_, attender) {
                      return attender.attenderInfo?.province
                    }
                  },
                  {
                    title: '任务',
                    key: 'taskInfo',
                    dataIndex: 'id',
                    align: 'center',
                    width: 100,
                    render(v) {
                      if (
                        meetingInfo?.base?.type &&
                        [
                          'CATE_DOCUMENT_COMMENT',
                          'CATE_QUESTIONNAIRE_RESEARCH',
                          'CATE_CASE_COLLECTION',
                          'CATE_DOCUMENT_COMPOSE'
                        ].includes(meetingInfo?.base?.type)
                      ) {
                        return (
                          <Button
                            type='link'
                            onClick={() => {
                              window.open(`/taskDetail/${v}`)
                            }}
                          >
                            查看
                          </Button>
                        )
                      }
                      return '-'
                    }
                  },
                  {
                    title: '老师上传',
                    key: 'certification',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 100,
                    render(data) {
                      return data?.userCertificationImages?.certImages
                        .length ? (
                        <Image.PreviewGroup>
                          {data?.userCertificationImages?.certImages?.map(
                            (item: string, index: number) => {
                              return (
                                <Image
                                  key={index}
                                  width={60}
                                  height={60}
                                  src={getImgSource(item)}
                                />
                              )
                            }
                          )}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '职称证',
                    key: 'userTitleCertificationImages',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 100,
                    render(data) {
                      return data?.userTitleCertificationImages?.certImages
                        .length ? (
                        <Image.PreviewGroup>
                          {data?.userTitleCertificationImages?.certImages?.map(
                            (item: string, index: number) => {
                              return (
                                <Image
                                  key={index}
                                  width={60}
                                  height={60}
                                  src={getImgSource(item)}
                                />
                              )
                            }
                          )}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '全程审核验证',
                    key: 'verifyImages',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 150,
                    render(data, attender) {
                      let verifyImgs =
                        data?.verifyImages && data?.verifyImages.split(',')
                      if (!verifyImgs?.length) {
                        const accountId = attender.attenderId
                        const verifyItem = verifyData?.find(
                          (x: any) => x.accountId === accountId
                        )
                        if (verifyItem) {
                          verifyImgs = verifyItem.verifyImgs
                        }
                      }
                      return verifyImgs?.length ? (
                        <Image.PreviewGroup>
                          {verifyImgs.map((pic: string, index: number) => {
                            const reg =
                              /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/).*/
                            if (!reg.test(pic)) return null
                            return (
                              <Image
                                key={index}
                                width={60}
                                height={60}
                                src={getImgSource(pic)}
                              />
                            )
                          })}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '全程审核结果',
                    key: 'verifyResult',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 150,
                    render(_, attender) {
                      let verifyResult = '-'
                      switch (attender.attenderInfo?.verifyResult) {
                        case '是':
                          verifyResult = '通过'
                          break
                        case '否':
                          verifyResult = '未通过'
                          break
                        default:
                          break
                      }
                      return verifyResult
                    }
                  },
                  {
                    title: '全程运营复核',
                    key: 'userComplexMaterialCertImages',
                    dataIndex: 'attenderInfo',
                    align: 'center',
                    width: 150,
                    render(data) {
                      return data?.userComplexMaterialCertImages?.certImages
                        .length ? (
                        <Image.PreviewGroup>
                          {data?.userComplexMaterialCertImages?.certImages?.map(
                            (item: string, index: number) => {
                              return isVideoUrl(item) ? (
                                <VideoButton
                                  key={index}
                                  url={item}
                                  style={{
                                    width: 60,
                                    height: 60,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                />
                              ) : (
                                <Image
                                  key={index}
                                  width={60}
                                  height={60}
                                  src={getImgSource(item)}
                                />
                              )
                            }
                          )}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '参会角色',
                    key: 'role',
                    dataIndex: 'attenderData',
                    align: 'center',
                    width: 100,
                    render(data) {
                      return data.role
                    }
                  },
                  {
                    title: '积分',
                    dataIndex: 'attenderInfo',
                    key: 'attenderFee',
                    align: 'center',
                    width: 100,
                    render(_, attender) {
                      return attender.attenderFee
                    }
                  },
                  {
                    title: '全景照',
                    key: 'panoramicPic',
                    dataIndex: 'attenderData',
                    align: 'center',
                    width: 100,
                    render(data) {
                      const urls = formatPanoramicPicUrls(data)
                      return urls?.length ? (
                        <Image.PreviewGroup>
                          {urls.map((item, index) => {
                            return (
                              <Image
                                key={index}
                                width={60}
                                height={60}
                                src={getImgSource(item)}
                              />
                            )
                          })}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '讲课照',
                    key: 'frontPic',
                    dataIndex: 'attenderData',
                    align: 'center',
                    width: 100,
                    render(data) {
                      const urls = formatFrontPicUrls(data)
                      return urls?.length ? (
                        <Image.PreviewGroup>
                          {urls.map((item, index) => {
                            return (
                              <Image
                                key={index}
                                width={60}
                                height={60}
                                src={getImgSource(item)}
                              />
                            )
                          })}
                        </Image.PreviewGroup>
                      ) : (
                        '-'
                      )
                    }
                  },
                  {
                    title: '验证信息',
                    key: 'verificationInfo',
                    dataIndex: 'attenderData',
                    align: 'center',
                    fixed: 'right',
                    width: 300,
                    render(data, attender) {
                      const accountId = attender.attenderId
                      const verifyItem = verifyData?.find(
                        (x: any) => x.accountId === accountId
                      )
                      const isGreaterMinPointLimit =
                        Number(attender.attenderFee) >
                        attender.attenderInfo?.minPointLimitConfig?.pointLimit!

                      const panoramicPicAuditRecord =
                        panoramicPicAuditRecords?.find(
                          (x: any) => x.accountId === accountId
                        ) || {}

                      const frontPicRecord =
                        frontPicRecords?.find(
                          (x: any) => x.accountId === accountId
                        ) || {}

                      return (
                        <>
                          <Form.Item
                            label={
                              <label style={{ fontWeight: 'bold' }}>
                                资质验证
                              </label>
                            }
                            labelCol={{ span: 10 }}
                            labelAlign='left'
                            name={`certification-${accountId}`}
                            rules={[
                              {
                                required: true,
                                message: '请审核老师资质'
                              }
                            ]}
                            initialValue={
                              isApprover
                                ? undefined
                                : attenderProperties[
                                    `certification-${accountId}`
                                  ]
                            }
                          >
                            {attender.attenderInfo?.verifyResult ||
                            verifyItem ? (
                              <Radio.Group disabled={!isApprover}>
                                <Radio value={1}>通过</Radio>
                                <Radio value={0}>不通过</Radio>
                              </Radio.Group>
                            ) : (
                              <Button
                                type='link'
                                style={{ padding: 0 }}
                                onClick={() => {
                                  setIsShowModal(true)
                                  setCurAttenderId(accountId)
                                  setCurAttenderCertImages(
                                    attender.attenderInfo
                                      ?.userCertificationImages
                                  )
                                }}
                              >
                                验证老师资质
                              </Button>
                            )}
                          </Form.Item>
                          <Form.Item
                            label={
                              <label style={{ fontWeight: 'bold' }}>
                                职称验证
                              </label>
                            }
                            labelCol={{ span: 10 }}
                            labelAlign='left'
                            name={`titleCertification-${accountId}`}
                            rules={[
                              {
                                required: true,
                                message: '请审核老师职称'
                              }
                            ]}
                            initialValue={
                              isApprover
                                ? undefined
                                : attenderProperties[
                                    `titleCertification-${accountId}`
                                  ]
                            }
                          >
                            <Radio.Group disabled={!isApprover}>
                              <Radio value={1}>通过</Radio>
                              <Radio value={0}>不通过</Radio>
                            </Radio.Group>
                          </Form.Item>
                          {[
                            'CATE_DOCUMENT_COMMENT',
                            'CATE_QUESTIONNAIRE_RESEARCH',
                            'CATE_CASE_COLLECTION',
                            'CATE_DOCUMENT_COMPOSE'
                          ].includes(meetingInfo?.base?.type!) ? (
                            <>
                              <Form.Item
                                label={
                                  <label style={{ fontWeight: 'bold' }}>
                                    任务验证
                                  </label>
                                }
                                labelCol={{ span: 10 }}
                                labelAlign='left'
                                name={`task-${accountId}`}
                                rules={[
                                  {
                                    required: true,
                                    message: '请审核任务'
                                  }
                                ]}
                                initialValue={
                                  isApprover
                                    ? undefined
                                    : attenderProperties[`task-${accountId}`]
                                }
                              >
                                <Radio.Group disabled={!isApprover}>
                                  <Radio value={1}>通过</Radio>
                                  <Radio value={0}>不通过</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : (
                            <RepeatTag
                              label='全景照验重：'
                              repeatStatus={panoramicPicAuditRecord.status}
                              properties={
                                panoramicPicAuditRecord.properties?.length > 0
                                  ? panoramicPicAuditRecord.properties?.split(
                                      ','
                                    )
                                  : []
                              }
                              contentStyle={{
                                marginLeft: 22
                              }}
                            />
                          )}
                          {meetingInfo?.base?.type ===
                            'ACADEMIC_CONFERENCE' && (
                            <>
                              <Form.Item
                                label={
                                  <label style={{ fontWeight: 'bold' }}>
                                    全景照验证
                                  </label>
                                }
                                labelCol={{ span: 10 }}
                                labelAlign='left'
                                name={`panoramicPic-${accountId}`}
                                rules={[
                                  {
                                    required: true,
                                    message: '请审核全景照'
                                  }
                                ]}
                                initialValue={
                                  isApprover
                                    ? undefined
                                    : attenderProperties[
                                        `panoramicPic-${accountId}`
                                      ]
                                }
                              >
                                <Radio.Group disabled={!isApprover}>
                                  <Radio value={1}>通过</Radio>
                                  <Radio value={0}>不通过</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <RepeatTag
                                label='讲课照验重：'
                                repeatStatus={frontPicRecord.status}
                                properties={
                                  frontPicRecord.properties?.length > 0
                                    ? frontPicRecord.properties?.split(',')
                                    : []
                                }
                                contentStyle={{
                                  marginLeft: 22
                                }}
                              />
                              <Form.Item
                                label={
                                  <label style={{ fontWeight: 'bold' }}>
                                    讲课照验证
                                  </label>
                                }
                                labelCol={{ span: 10 }}
                                labelAlign='left'
                                name={`frontPic-${accountId}`}
                                rules={[
                                  {
                                    required: true,
                                    message: '请审核讲课照'
                                  }
                                ]}
                                initialValue={
                                  isApprover
                                    ? undefined
                                    : attenderProperties[
                                        `frontPic-${accountId}`
                                      ]
                                }
                              >
                                <Radio.Group disabled={!isApprover}>
                                  <Radio value={1}>通过</Radio>
                                  <Radio value={0}>不通过</Radio>
                                </Radio.Group>
                              </Form.Item>
                              {isHANSOH ? (
                                isGreaterMinPointLimit ? (
                                  <>
                                    <Form.Item
                                      label={
                                        <label style={{ fontWeight: 'bold' }}>
                                          参会时长
                                          <span
                                            style={{
                                              color: '#7D7F7F',
                                              fontWeight: 'normal'
                                            }}
                                          >
                                            (分钟)
                                          </span>
                                        </label>
                                      }
                                      labelCol={{ span: 10 }}
                                      labelAlign='left'
                                      name={`duration-${accountId}`}
                                      validateFirst={true}
                                      validateTrigger={[
                                        'onBlur',
                                        'onPressEnter'
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: '请填写参会时长'
                                        },
                                        {
                                          validateTrigger: [
                                            'onBlur',
                                            'onPressEnter'
                                          ],
                                          validator: (
                                            rule: RuleObject,
                                            value: StoreValue,
                                            callback: (error?: string) => void
                                          ) => {
                                            onValidatorDuration(attender, value)
                                            return Promise.resolve()
                                          }
                                        }
                                      ]}
                                      initialValue={
                                        isApprover
                                          ? undefined
                                          : attenderProperties[
                                              `duration-${accountId}`
                                            ]
                                      }
                                    >
                                      <InputNumber
                                        placeholder='请填写参会时长'
                                        min={0}
                                        max={10000}
                                        disabled={!isApprover}
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label={
                                        <label style={{ fontWeight: 'bold' }}>
                                          积分验证
                                        </label>
                                      }
                                      labelCol={{ span: 10 }}
                                      labelAlign='left'
                                      name={`attenderFee-${accountId}`}
                                      rules={[
                                        {
                                          required: true,
                                          message: '请输入参会时长'
                                        }
                                      ]}
                                      initialValue={
                                        isApprover
                                          ? undefined
                                          : attenderProperties[
                                              `attenderFee-${accountId}`
                                            ]
                                      }
                                    >
                                      <Radio.Group disabled>
                                        <Radio value={1}>通过</Radio>
                                        <Radio value={0}>不通过</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </>
                                ) : null
                              ) : (
                                <>
                                  <Form.Item
                                    label={
                                      <label style={{ fontWeight: 'bold' }}>
                                        参会时长
                                        <span
                                          style={{
                                            color: '#7D7F7F',
                                            fontWeight: 'normal'
                                          }}
                                        >
                                          (分钟)
                                        </span>
                                      </label>
                                    }
                                    labelCol={{ span: 10 }}
                                    labelAlign='left'
                                    name={`duration-${accountId}`}
                                    initialValue={
                                      isApprover
                                        ? undefined
                                        : attenderProperties[
                                            `duration-${accountId}`
                                          ]
                                    }
                                  >
                                    <InputNumber
                                      placeholder='请填写参会时长'
                                      min={0}
                                      max={10000}
                                      disabled={!isApprover}
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label={
                                      <label style={{ fontWeight: 'bold' }}>
                                        积分验证
                                      </label>
                                    }
                                    labelCol={{ span: 10 }}
                                    labelAlign='left'
                                    name={`attenderFee-${accountId}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: '请审核积分'
                                      }
                                    ]}
                                    initialValue={
                                      isApprover
                                        ? undefined
                                        : attenderProperties[
                                            `attenderFee-${accountId}`
                                          ]
                                    }
                                  >
                                    <Radio.Group disabled={!isApprover}>
                                      <Radio value={1}>通过</Radio>
                                      <Radio value={0}>不通过</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )
                    }
                  }
                ]}
              />
            </Row>
          </Form>
        </Spin>
        {isShowModal && curAttenderId ? (
          <VerifyDoctorInfoModal
            onCancel={() => {
              setIsShowModal(false)
              setCurAttenderId(undefined)
            }}
            onHandleConfirm={(
              result: number,
              verifyImgs: string[],
              callBack?: () => void
            ) => {
              console.log(result)
              const newVerifyData = _.cloneDeep(verifyData)
              newVerifyData.push({
                accountId: curAttenderId,
                verifyImgs
              })
              setVerifyData(newVerifyData)
              setTimeout(() => {
                form.setFieldsValue({
                  [`certification-${curAttenderId}`]: result
                })
                callBack && callBack()
                setIsShowModal(false)
                setCurAttenderId(undefined)
                setCurAttenderCertImages(undefined)
              }, 100)
            }}
            accountId={curAttenderId}
            userCertificationImages={curAttenderCertImages}
          />
        ) : null}
      </div>

      {/* 审批信息 */}
      <ApprovalProcessCard
        loading={approvalsFetching}
        approvalInfo={approvalInfo?.result || []}
        isShow={
          meetingInfo?.status !==
          StandardMeetingServiceBizStatus.MeetingInProgress
        }
      />

      {isApprover && (
        <Affix offsetBottom={0} className='bottomAffix'>
          <Row
            justify='end'
            style={{
              padding: '35px 15px 35px 0'
            }}
          >
            <Space size={16}>
              {/* <Button
                type='primary'
                style={{
                  background:
                    noVerifyLength - verifyData.length > 0
                      ? '#C1C1C1'
                      : '#4467F8',
                  color: 'white',
                  border: 'none'
                }}
                disabled={noVerifyLength - verifyData.length > 0}
                onClick={() => onHandleClick(0)}
              >
                向后加签
              </Button> */}
              <Button
                type='primary'
                style={{
                  background:
                    noVerifyLength - verifyData.length > 0
                      ? '#C1C1C1'
                      : '#EB454C',
                  color: 'white',
                  border: 'none'
                }}
                disabled={noVerifyLength - verifyData.length > 0}
                onClick={() => onHandleClick(1)}
              >
                审批退回
              </Button>
              <Button
                type='primary'
                style={{
                  background:
                    noVerifyLength - verifyData.length > 0
                      ? '#C1C1C1'
                      : '#418E3A',
                  color: 'white',
                  border: 'none'
                }}
                disabled={noVerifyLength - verifyData.length > 0}
                onClick={() => onHandleClick(2)}
              >
                审批通过
              </Button>
            </Space>
          </Row>
        </Affix>
      )}
    </Space>
  )
}

export default ApprovalInfo
