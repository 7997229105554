import React, { useState, useEffect, Fragment, useCallback } from 'react'
import {
  Skeleton,
  Card,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Row,
  Col,
  Divider
} from 'antd'
import {
  useSalesOrderByIdQuery,
  SalesOrderStatus,
  useSalesOrderBillReturnMutation,
  useSalesOrderBillApprovedMutation
} from '../../graphql'
import { Link, useParams } from 'react-router-dom'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import { getMessage } from '../../lib/getMessage'
import ApprovalConfirmModal from './approvalConfirmModal'
import ApprovalRetreatModal from './approvalRetreatModal'
import BillInfo from './billInfo'
import dayjs from 'dayjs'
import { HR_PRODUCTION, isMobileClient } from '../../constant/user'
import {
  getFormPaymentTypeText,
  getSalesBudgetChannelText,
  getSalesOrderStatus
} from '../../constant/sales'
import OperationLog from '../../components/OperationLog'
import CopiableTag from '../../components/CopiableTag'
import SalesStatusTag from '../../components/SalesStatusTag'
import './index.less'
import SalesApprovalProcess from '../../components/SalesApprovalProcess'

const ConsumptionOrderBillDetail: QCPage = () => {
  const { id }: { id: string } = useParams()
  const userStore = useUserStore()
  const production = userStore.profile?.hrProduction || ''
  const isMobile = isMobileClient()
  const isQC =
    production === HR_PRODUCTION.qc || production === HR_PRODUCTION.qcDemo
  const [isShowRetreatModal, setIsShowRetreatModal] = useState(false)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)

  const [{ data, fetching, error }, refetchOrderDetail] =
    useSalesOrderByIdQuery({
      variables: {
        id
      },
      pause: !id
    })

  const [{ fetching: retreatFetching }, orderRetreat] =
    useSalesOrderBillReturnMutation()

  const [{ fetching: confirmFetching }, orderConfirm] =
    useSalesOrderBillApprovedMutation()

  const startApprovalRetreat = async (values: any, callBack?: () => void) => {
    const { data, error } = await orderRetreat({
      id,
      reason: values.reason
    })
    if (data?.result) {
      message.success('退回成功')
      callBack && callBack()
      setIsShowRetreatModal(false)
      refetchOrderDetail()
      window.history.back()
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退回失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalConfirm = async (values: any, callBack?: () => void) => {
    const { data, error } = await orderConfirm({
      id,
      ticketDesc: values.ticket,
      invoiceDesc: values.invoice
    })
    if (data?.result) {
      message.success('审核通过')
      callBack && callBack()
      setIsShowConfirmModal(false)
      refetchOrderDetail()
      window.history.back()
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('审核失败:' + errorMsg)
      callBack && callBack()
    }
  }

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchOrderDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  const order = data?.result
  const orderDetail = order?.salesOrder
  const properties = order?.properties || []
  const logs = order?.logs
  const formCategoryInfo = order?.formCategoryInfo
  const businessCategory = order?.salesOrderBusinessCategory
  const consumptionCategory = order?.consumptionCategory
  const isCurrentApprover = order?.isCurrentApprover
  const billInfo = properties?.filter((x) => x.type === 'receipt') || []
  const paymentInfo =
    properties?.find((x) => x.type === 'paymentInfo' && x.key === 'paid') || {}
  const paidInfo = JSON.parse(paymentInfo?.value || '{}')
  const externalPaymentInfo = JSON.parse(paidInfo.externalPaymentInfo || '{}')
  const costItem = properties?.find(
    (i) => i.type === 'costItem' && i.key === 'costItemKey'
  )?.value
  const costItemTitle = JSON.parse(costItem || '{}')?.title
  const invoices = order?.invoices || []
  const receiptTemplate = order?.receiptTemplate || []
  const reviewingReason = properties.find((x) => x.type === 'REVIEWING')?.value
  const reviewedReason = properties.find((x) => x.type === 'REVIEWED')?.value
  const isFinalCorrection =
    orderDetail?.finalCorrection && orderDetail?.finalCorrection !== '否'

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Card
        className='dashedBorderHead consumptionOrderBillDetailCard'
        title={
          <Row align='middle' style={{ color: '#181818' }}>
            {orderDetail?.targetName || '消费订单_未选消费场所'}
            <CopiableTag title='消费订单号' text={id} />
          </Row>
        }
        loading={fetching}
        extra={
          <>
            {/* <span style={{ color: '#181818', fontWeight: 500 }}>
              计划号：
              <Link
                to={`/consumptionPlanDetail/${salesPlan?.id}`}
                style={{ color: '#5581FA' }}
              >
                {salesPlan?.id}
              </Link>
            </span> */}
            {orderDetail?.status && (
              <SalesStatusTag
                containerStyle={{ right: isMobile ? -20 : -32 }}
                tagColor={
                  [
                    SalesOrderStatus.AuditFailed,
                    SalesOrderStatus.BookingReturn,
                    SalesOrderStatus.FinancialReturn
                  ].includes(orderDetail?.status)
                    ? '#F76060'
                    : orderDetail?.status === SalesOrderStatus.FormCancel
                    ? '#888'
                    : [
                        SalesOrderStatus.BookingProgress,
                        SalesOrderStatus.PrePaid,
                        SalesOrderStatus.PreVoucherUpload,
                        SalesOrderStatus.PreVoucherAudit,
                        SalesOrderStatus.FormReviewing
                      ].includes(orderDetail?.status)
                    ? '#EE7700'
                    : [
                        SalesOrderStatus.BookingSuccess,
                        SalesOrderStatus.VoucherUploadCompleted,
                        SalesOrderStatus.AuditCompleted,
                        SalesOrderStatus.ReviewedCompleted
                      ].includes(orderDetail?.status)
                    ? '#51A04C'
                    : ''
                }
                text={getSalesOrderStatus(orderDetail?.status).text}
                triangleClassName={
                  [
                    SalesOrderStatus.AuditFailed,
                    SalesOrderStatus.BookingReturn,
                    SalesOrderStatus.FinancialReturn
                  ].includes(orderDetail?.status)
                    ? 'triangleReturn'
                    : orderDetail?.status === SalesOrderStatus.FormCancel
                    ? 'triangleCancel'
                    : [
                        SalesOrderStatus.BookingProgress,
                        SalesOrderStatus.PrePaid,
                        SalesOrderStatus.PreVoucherUpload,
                        SalesOrderStatus.PreVoucherAudit,
                        SalesOrderStatus.FormReviewing
                      ].includes(orderDetail?.status)
                    ? 'triangleWait'
                    : [
                        SalesOrderStatus.BookingSuccess,
                        SalesOrderStatus.VoucherUploadCompleted,
                        SalesOrderStatus.AuditCompleted,
                        SalesOrderStatus.ReviewedCompleted
                      ].includes(orderDetail?.status)
                    ? 'trianglePass'
                    : ''
                }
              />
            )}
          </>
        }
      >
        <Row align='middle'>
          <Col span={24}>
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              <Descriptions.Item key='accountName' label='申请人'>
                {orderDetail?.accountName || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='accountMobile' label='申请人手机号'>
                {orderDetail?.accountMobile || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='staffId' label='申请人报销编号'>
                {orderDetail?.accountInfo?.staffId || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='businessName' label='业务部'>
                {orderDetail?.businessName || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='costcenterName' label='成本中心'>
                {orderDetail?.costcenterName || '-'}
              </Descriptions.Item>
            </Descriptions>
            <Divider dashed style={{ margin: '12px 0' }} />
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              <Descriptions.Item key='targetName' label='商家名称'>
                {orderDetail?.targetName || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='budgetChannel' label='预算渠道'>
                {getSalesBudgetChannelText(orderDetail?.budgetChannel!)}
              </Descriptions.Item>
              <Descriptions.Item key='usageScenario' label='消费分类'>
                {businessCategory?.title || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='formCategory' label='订单类型'>
                {formCategoryInfo?.title || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='submitTime' label='提交时间'>
                {orderDetail?.submitTime
                  ? dayjs(orderDetail?.submitTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='consumptionCategoryCode' label='消费形式'>
                {consumptionCategory?.title || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                key='actualConsumptionAmount'
                label='确认消费金额'
              >
                {orderDetail?.actualConsumptionAmount || '-'}
                {` / `}
                {orderDetail?.actualPeopleNumber
                  ? `${orderDetail?.actualPeopleNumber}人`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='targetOccurTime' label='确认消费时间'>
                {orderDetail?.targetOccurTime
                  ? dayjs(orderDetail?.targetOccurTime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item key='consumptionAmount' label='预计消费金额'>
                {orderDetail?.consumptionAmount || '-'}
                {` / `}
                {orderDetail?.peopleNumber
                  ? `${orderDetail?.peopleNumber}人`
                  : '-'}
              </Descriptions.Item>
              {orderDetail?.budgetChannel === 5 && (
                <Descriptions.Item key='budgetNumber' label='学术编号'>
                  {orderDetail?.budgetNumber || '-'}
                </Descriptions.Item>
              )}
              <Descriptions.Item key='claimAmount' label='报销金额'>
                {orderDetail?.claimAmount || '-'}
              </Descriptions.Item>
              {orderDetail?.budgetChannel !== 5 && (
                <Descriptions.Item key='oaNumber' label='审批编号'>
                  {orderDetail?.oaNumber || '-'}
                </Descriptions.Item>
              )}
              <Descriptions.Item
                key='finalCorrection'
                label='是否补单'
                labelStyle={{
                  color: isFinalCorrection ? '#EE7700' : '#181818'
                }}
                contentStyle={{
                  color: isFinalCorrection ? '#EE7700' : '#181818',
                  fontWeight: 'bold'
                }}
              >
                {orderDetail?.finalCorrection || '-'}
              </Descriptions.Item>
              {isFinalCorrection && (
                <Descriptions.Item
                  key='finalCorrectionReason'
                  label='补单原因'
                  labelStyle={{ color: '#EE7700' }}
                  contentStyle={{ color: '#EE7700', fontWeight: 'bold' }}
                >
                  {orderDetail?.finalCorrectionReason || '-'}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Divider dashed style={{ margin: '12px 0' }} />
            <Descriptions
              column={{ xs: 1, sm: 2, md: 3 }}
              labelStyle={{ fontWeight: 'bold', color: '#181818' }}
              contentStyle={{ color: '#888' }}
            >
              <Descriptions.Item key='deductionAmount' label='核减预计金额'>
                {orderDetail?.deductionAmount || '-'}
              </Descriptions.Item>
              <Descriptions.Item
                key='financialDeductionAmount'
                label='核减报销金额'
              >
                {orderDetail?.financialDeductionAmount || 0}
              </Descriptions.Item>
              <Descriptions.Item key='paymentType' label='支付方式'>
                {getFormPaymentTypeText(orderDetail?.paymentType!)}
              </Descriptions.Item>
              <Descriptions.Item key='total_amount' label='支付金额'>
                {externalPaymentInfo?.total_amount || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='subject' label='支付商家'>
                {externalPaymentInfo?.subject || '-'}
              </Descriptions.Item>
              <Descriptions.Item key='gmt_payment' label='支付回调时间'>
                {externalPaymentInfo?.gmt_payment
                  ? dayjs(externalPaymentInfo?.gmt_payment).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : '-'}
              </Descriptions.Item>
              {[
                SalesOrderStatus.AuditFailed,
                SalesOrderStatus.FinancialReturn
              ].includes(orderDetail?.status!) && (
                <Descriptions.Item
                  labelStyle={{ color: '#F76060' }}
                  key='reason'
                  label={
                    orderDetail?.reason &&
                    orderDetail?.reason.lastIndexOf('财务退回原因:') < 0
                      ? '退回原因'
                      : null
                  }
                  contentStyle={{ color: '#F76060', fontWeight: 'bold' }}
                >
                  {orderDetail?.reason || '-'}
                </Descriptions.Item>
              )}
              {[
                SalesOrderStatus.FormReviewing,
                SalesOrderStatus.ReviewedCompleted
              ].includes(orderDetail?.status!) && (
                <>
                  <Descriptions.Item
                    labelStyle={{ color: '#F76060' }}
                    key='reviewingReason'
                    label='初审通过理由'
                    contentStyle={{ color: '#F76060', fontWeight: 'bold' }}
                  >
                    {reviewingReason || '-'}
                  </Descriptions.Item>
                  {orderDetail?.status ===
                    SalesOrderStatus.ReviewedCompleted && (
                    <Descriptions.Item
                      span={3}
                      labelStyle={{ color: '#F76060' }}
                      key='reviewedReason'
                      label='复核通过理由'
                      contentStyle={{
                        color: '#F76060',
                        fontWeight: 'bold'
                      }}
                    >
                      {reviewedReason || '-'}
                    </Descriptions.Item>
                  )}
                </>
              )}
            </Descriptions>
            <Divider dashed style={{ margin: '12px 0' }} />
          </Col>
          <Col span={24}>
            {/* 票据信息 */}
            {(billInfo.length > 0 || invoices.length > 0) && (
              <BillInfo
                data={billInfo}
                invoices={invoices}
                receiptTemplate={receiptTemplate}
                loading={fetching}
              />
            )}
          </Col>
        </Row>
      </Card>

      {/* {isCurrentApprover &&
        !isQC &&
        orderDetail?.status === SalesOrderStatus.PreVoucherAudit && (
          <Card
            title='审核要求'
            className='dashedBorderHead'
            headStyle={{ color: '#181818' }}
          >
            <ul style={{ listStyleType: 'decimal', color: '#888' }}>
              <li>审核商户名称，是否一致</li>
              <li>审核金额，是否一致</li>
              <li>审核消费日期，是否一致</li>
            </ul>
          </Card>
        )} */}

      {/* 审批信息 */}
      {isQC && (
        <SalesApprovalProcess
          className='dashedBorderHead'
          approvalFlows={order?.approvalFlows}
          isShow
          bordered={false}
          titleKey='订单'
        />
      )}
      {/* 日志信息 */}
      <OperationLog logs={logs} />

      {/* 审批 退回 */}
      {/* {isShowRetreatModal ? (
        <ApprovalRetreatModal
          onCancel={() => {
            setIsShowRetreatModal(false)
          }}
          onSubmit={startApprovalRetreat}
        />
      ) : null} */}

      {/* 审批 通过 */}
      {/* {isShowConfirmModal ? (
        <ApprovalConfirmModal
          onCancel={() => {
            setIsShowConfirmModal(false)
          }}
          onSubmit={startApprovalConfirm}
        />
      ) : null} */}

      {/* {isCurrentApprover &&
        !isQC &&
        orderDetail?.status === SalesOrderStatus.PreVoucherAudit && (
          <Affix offsetBottom={0} className='bottomAffix'>
            <Row
              justify='end'
              style={{
                padding: '35px 15px 35px 0'
              }}
            >
              <Space>
                <Button
                  type='primary'
                  danger
                  style={{ width: 100 }}
                  disabled={retreatFetching}
                  onClick={() => {
                    setIsShowRetreatModal(true)
                  }}
                >
                  退回
                </Button>
                <Button
                  type='primary'
                  style={{ width: 100 }}
                  disabled={confirmFetching}
                  onClick={() => {
                    setIsShowConfirmModal(true)
                  }}
                >
                  审核通过
                </Button>
              </Space>
            </Row>
          </Affix>
        )} */}
    </Space>
  )
}

ConsumptionOrderBillDetail.pageTitle = '消费订单详情'

export default ConsumptionOrderBillDetail
