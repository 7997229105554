import React, { useEffect, useState } from 'react'
import omit from 'lodash.omit'
import { message } from 'antd'

import SearchTable, { useSearchTable } from '../../components/SearchTable'
import {
  useUpadminDoctorListQuery,
  useUnbindAlipayRelationshipMutation,
  useUpdateAccountFinalMutation,
  useSyncDoctorVerifyResultMutation
} from '../../graphql'
import { searchFields, FieldTypes, useTableColumns } from './data'
import ErrorView from '../../components/ErrorView'
import { useSearchStore } from '../../hooks/pageSearch'
import _ from 'lodash'
import EditModal from './editModal'
import { isMobileClient } from '../../constant/user'

const StaffManage: QCPage = () => {
  const { pageParams } = useSearchStore()
  const isMobile = isMobileClient()
  const searchParams = { ...pageParams[window.location.pathname] }
  const table = useSearchTable<FieldTypes>({}, { pageSize: 15, currentPage: 1 })
  const [curCompanyId, setCompanyId] = useState<string | undefined>(
    searchParams?.companyId
  )
  const [curAccount, setCurAccount] = useState<any>()

  const onCompanyChange = (value: string) => {
    setCompanyId(value)
  }

  const onTableReset = () => {
    setCompanyId(undefined)
  }

  const [{ data, error, fetching }, refetchList] = useUpadminDoctorListQuery({
    variables: {
      pager: omit(table.pager, 'total'),
      query: Object.assign(table.searchParams, {
        companyId: curCompanyId
      })
    },
    pause: !curCompanyId
  })

  const [, unbindAlipay] = useUnbindAlipayRelationshipMutation()

  const [, updateAccountFinal] = useUpdateAccountFinalMutation()
  const [, syncDoctorVerifyResult] = useSyncDoctorVerifyResultMutation()

  const unbindAlipayRelationship = async (accountId: string) => {
    const data = await unbindAlipay({ accountId })
    if (data?.data?.result?.success) {
      message.success('解绑支付宝成功')
    } else {
      message.error(data?.data?.result?.message || '解绑支付宝失败')
    }
  }

  const onEdit = (accountInfo: any) => {
    setCurAccount(accountInfo)
  }

  const onEditSubmit = async (values: any, callBack?: () => void) => {
    const data = await updateAccountFinal({
      account: {
        accountId: curAccount?.accountId,
        companyId: curAccount?.companyId,
        companyMobile: values?.mobile
      }
    })
    if (data?.data?.result) {
      message.success('编辑成功')
      callBack && callBack()
      setCurAccount(undefined)
      refetchList()
    } else {
      message.error('编辑失败')
      callBack && callBack()
    }
  }

  const operateAttention = async (accountId: string, isAttention: boolean) => {
    const { data } = await syncDoctorVerifyResult({
      accountId,
      verifyResult: isAttention ? '否' : '是'
    })
    if (data?.result?.success) {
      message.success(isAttention ? '取消成功' : '关注成功')
      refetchList()
    } else {
      message.error(
        data?.result?.message || (isAttention ? '取消失败' : '关注失败')
      )
    }
  }

  const tableColumns = useTableColumns({
    unbindAlipayRelationship,
    onEdit,
    operateAttention,
    isMobile
  })

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchList}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <main>
      <SearchTable
        table={table}
        scroll={{ x: isMobile ? 750 : 1250 }}
        searchProps={{
          fields: searchFields(onCompanyChange)
        }}
        bordered
        loading={fetching}
        pager={data?.doctors?.pager}
        dataSource={data?.doctors?.items || []}
        rowKey={'budgetId'}
        columns={tableColumns}
        onReset={onTableReset}
        showExportBtn={false}
      />
      {curAccount?.accountId && (
        <EditModal
          onCancel={() => setCurAccount(undefined)}
          onSubmit={onEditSubmit}
          accountInfo={curAccount}
        />
      )}
    </main>
  )
}

export default StaffManage
