import React, { useState, FC } from 'react'
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  DatePicker,
  Select,
  Space
} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  LiteratureAuthSelector,
  HRCompanySelector,
  LiteratureProductSelector,
  DomainSelector
} from '../../components/SelectorCollection'
import { useUserStore } from '../../hooks/user'
import UploadButton from '../../components/UploadButton'
import { Document } from '../../graphql'
import dayjs from 'dayjs'
import moment from 'moment'

const UploadModal: FC<{
  onCancel: () => void
  onSubmit: (values: any) => void
  onSubmitEdit: (values: any) => void
  loading: boolean
  isQC?: boolean
  userCompanyId?: string
  document?: Document
}> = ({
  onSubmit,
  onSubmitEdit,
  onCancel,
  loading,
  isQC = true,
  userCompanyId = '',
  document = {}
}) => {
  const { profile } = useUserStore()
  const [fileName, setFileName] = useState<string>('')

  const [companyId, setCompanyId] = useState<string>(isQC ? '' : userCompanyId)

  const [form] = Form.useForm()
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  }

  const {
    id: documentId,
    title,
    author,
    source,
    releaseTimestamp,
    company,
    productInfo,
    category,
    documentSphere,
    language,
    describe
  } = document

  const onSubmitHandle = async () => {
    try {
      await form.validateFields()
      const value = form.getFieldsValue()

      if (documentId) {
        onSubmitEdit && onSubmitEdit(value)
      } else {
        onSubmit && onSubmit(value)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      // confirmLoading={loading}
      bodyStyle={{ padding: '30px 100px 40px' }}
      maskClosable={false}
      title={null}
      visible
      onCancel={onCancel}
      centered
      width={600}
      footer={null}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: 20, color: '#cccccc' }} />
      }
    >
      <Row justify='center'>
        <span style={{ fontSize: 17, color: '#181818', marginTop: 12 }}>
          {documentId ? '编辑文献' : '上传文献'}
        </span>
      </Row>

      <Form
        style={{ margin: '30px 0' }}
        {...layout}
        form={form}
        initialValues={
          documentId
            ? {
                title,
                author,
                source,
                publishDate: moment(
                  dayjs(Number(releaseTimestamp)).format('YYYY-MM-DD'),
                  'YYYY-MM-DD'
                ),
                companyId: company?.cnName,
                productCode: productInfo?.productName,
                categoryId: category?.title,
                sphere: documentSphere?.sphereName || '-',
                remark: language,
                describe
              }
            : undefined
        }
      >
        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>文献名称</span>}
          name='title'
          rules={[
            { required: true, message: '不能为空' },
            { max: 255, message: '最长255个字符' }
          ]}
        >
          <Input placeholder='请填写文献名称' />
        </Form.Item>

        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>通讯作者</span>}
          name='author'
          rules={[{ required: true, message: '不能为空' }]}
        >
          <Input placeholder='请填写通讯作者' />
        </Form.Item>

        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>文献来源</span>}
          name='source'
          rules={[{ required: true, message: '不能为空' }]}
        >
          <Input placeholder='请填写文献来源' />
        </Form.Item>

        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>发布时间</span>}
          name='publishDate'
          rules={[{ required: true, message: '不能为空' }]}
        >
          <DatePicker style={{ width: '100%' }} placeholder='请选择发布时间' />
        </Form.Item>
        {isQC && (
          <Form.Item
            colon={false}
            label={<span style={{ color: '#888' }}>公司</span>}
            name='companyId'
            rules={documentId ? [] : [{ required: true, message: '不能为空' }]}
          >
            {documentId ? (
              <Input
                className='documentEditDisableInput'
                disabled
                bordered={false}
              />
            ) : (
              <HRCompanySelector
                placeholder='请选择公司'
                onChange={(val) => {
                  form.setFieldsValue({
                    productCode: undefined,
                    categoryId: undefined,
                    sphere: undefined
                  })
                  setCompanyId(val)
                }}
              />
            )}
          </Form.Item>
        )}
        <Form.Item
          shouldUpdate
          colon={false}
          label={<span style={{ color: '#888' }}>产品类型</span>}
          name='productCode'
          rules={documentId ? [] : [{ required: true, message: '不能为空' }]}
        >
          {documentId ? (
            <Input
              className='documentEditDisableInput'
              disabled
              bordered={false}
            />
          ) : (
            <LiteratureProductSelector
              placeholder='请选择产品类型'
              companyId={companyId}
              auto={true}
            />
          )}
        </Form.Item>
        <Form.Item
          shouldUpdate
          colon={false}
          label={<span style={{ color: '#888' }}>文献类型</span>}
          name='categoryId'
          rules={documentId ? [] : [{ required: true, message: '不能为空' }]}
        >
          {documentId ? (
            <Input
              className='documentEditDisableInput'
              disabled
              bordered={false}
            />
          ) : (
            <LiteratureAuthSelector
              placeholder='请选择文献类型'
              companyId={companyId}
              auto={true}
            />
          )}
        </Form.Item>
        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>领域</span>}
          name='sphere'
        >
          {documentId ? (
            <Input
              className='documentEditDisableInput'
              disabled
              bordered={false}
            />
          ) : (
            <DomainSelector companyId={companyId} auto={true} />
          )}
        </Form.Item>
        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>中英文</span>}
          name='remark'
          rules={documentId ? [] : [{ required: true, message: '不能为空' }]}
        >
          {documentId ? (
            <Input
              className='documentEditDisableInput'
              disabled
              bordered={false}
            />
          ) : (
            <Select allowClear showSearch placeholder='请选择'>
              {['中文', '英文'].map((key) => (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          colon={false}
          label={<span style={{ color: '#888' }}>文献简介</span>}
          name='describe'
        >
          <Input.TextArea
            style={{ height: 80, resize: 'none' }}
            placeholder='请填写文献简介'
          />
        </Form.Item>

        {!documentId && (
          <Form.Item
            colon={false}
            label={<span style={{ color: '#888' }}>文献上传</span>}
          >
            <Form.Item
              colon={false}
              name='url'
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: '不能为空' }]}
            >
              <UploadButton
                bucket='yhl-upright-academic-archive'
                onChange={(fileList) =>
                  setFileName(fileList[fileList.length - 1].name)
                }
                onUploaded={async (url) => {
                  form.setFieldsValue({
                    url: url
                  })
                }}
                dir={`/${profile?.companyId}/${profile?.accountId}`}
                buttonText={fileName || '上传文件'}
                accept='.pdf'
                noMessage
              />
            </Form.Item>
            <span style={{ color: '#ccc' }}>支持扩展名：.pdf</span>
          </Form.Item>
        )}
      </Form>

      <Row justify='center'>
        {documentId ? (
          <Space>
            <Button style={{ width: 100 }} onClick={onCancel}>
              取消
            </Button>
            <Button
              type='primary'
              style={{ width: 100 }}
              onClick={onSubmitHandle}
              disabled={loading}
              loading={loading}
            >
              确认
            </Button>
          </Space>
        ) : (
          <Button
            type='primary'
            style={{ width: 100 }}
            onClick={onSubmitHandle}
            disabled={loading}
            loading={loading}
          >
            上传
          </Button>
        )}
      </Row>
    </Modal>
  )
}

export default UploadModal
