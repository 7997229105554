/**
 * 会议详情（会议列表&&会议管理点击跳转详情 共用此页面）
 */
import React, { useState, useEffect, Fragment } from 'react'
import {
  Skeleton,
  Card,
  Space,
  Tag,
  Descriptions,
  Button,
  message,
  Row,
  Col,
  Modal,
  Image
} from 'antd'
import { omit, pick, sumBy } from 'lodash'
import {
  useHrMeetingDetailQuery,
  StandardMeetingServiceBizStatus,
  useHrMeetingRetreatMutation,
  useHrMeetingConfirmMutation,
  StandardMeetingApprovalStatus,
  useHrMeetingForwardSignatureMutation,
  useSaveMeetingVerificationInfoMutation,
  useMeetingVerificationInfoQuery
} from '../../graphql'
import { useParams } from 'react-router-dom'
import { useMeetingBaseInfo, useProjectBaseInfo } from './util'
import ErrorView from '../../components/ErrorView'
import { useUserStore } from '../../hooks/user'
import ApprovalModal from './approvalModal'
import ForwardSignatureModal from './forwardSignatureModal'
import { getMessage } from '../../lib/getMessage'
import ApprovalConfirmModal from './approvalConfirmModal'
import ApprovalInfo from './approvalInfo'
import CopiableTag from '../../components/CopiableTag'
import SalesStatusTag from '../../components/SalesStatusTag'
import { isMobileClient } from '../../constant/user'

const MeetingApproveDetail: QCPage = () => {
  const { meetingId }: { meetingId: string } = useParams()
  const userStore = useUserStore()
  const isMobile = isMobileClient()
  const production = userStore.profile?.hrProduction || ''
  const userName = userStore.profile?.name || ''
  const isQCXS = userStore.hasAuth('uplatform_approval_manage_quanchengxueshu')
  const isYHLXS = userStore.hasAuth('uplatform_approval_manage_yihuilixueshu')
  const [isShowRetreatModal, updateRetreatModalShow] = useState(false)
  const [isShowConfirmModal, updateConfirmModalShow] = useState(false)
  const [isShowForwardSignatureModal, updateForwardSignatureModalShow] =
    useState(false)
  const [isShowToApproveModal, setIsShowToApproveModal] = useState(false)
  const [toApproveInfo, setToApproveInfo] = useState<any>(undefined)
  const [failReason, setFailReason] = useState<string | undefined>(undefined)

  const [, forwardSignature] = useHrMeetingForwardSignatureMutation()

  const [, meetingRetreat] = useHrMeetingRetreatMutation()
  const [, meetingConfirm] = useHrMeetingConfirmMutation()

  const [, saveMeetingVerificationInfo] =
    useSaveMeetingVerificationInfoMutation()

  const [{ data, fetching, error }, refetchDetail] = useHrMeetingDetailQuery({
    variables: {
      meetingId
    }
  })

  const [{ data: verificationData }] = useMeetingVerificationInfoQuery({
    variables: {
      meetingId
    }
  })

  // 活动/会议
  const fields = useMeetingBaseInfo(data?.detail, production)
  // 项目
  const projectFields = useProjectBaseInfo(data?.detail)

  const attenders =
    data?.detail?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
      ? data?.detail?.speakers
      : data?.detail?.doctors

  const checkForwardSignature = () => {
    if (data?.detail?.forwardSignatureOwner) {
      updateForwardSignatureModalShow(true)
    } else {
      message.error('该业务部暂未设置“向后加签”人员')
    }
  }

  const saveVerificationInfo = async (approver?: string) => {
    const { data: saveResultData } = await saveMeetingVerificationInfo({
      meetingId,
      meetingType: data?.detail?.base?.type!,
      meetingProperties:
        data?.detail?.base?.type === 'ACADEMIC_CONFERENCE'
          ? JSON.stringify(pick(toApproveInfo, ['schedulePic', 'signPic']))
          : undefined,
      attenderProperties: JSON.stringify(
        omit(toApproveInfo, ['schedulePic', 'signPic'])
      ),
      approver
    })
    return saveResultData?.result
  }

  const startForwardSignature = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await forwardSignature({
      meetingId,
      reason: values.reason,
      approveUser: isYHLXS ? userName : values.approveUser,
      insertPosition: 'AFTER'
    })
    if (data?.result) {
      message.success('加签成功')
      callBack && callBack()
      updateForwardSignatureModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('加签失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const startApprovalRetreat = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await meetingRetreat({
      meetingId,
      reason: values.reason,
      approveUser: isYHLXS ? userName : values.approveUser
    })
    if (data?.result) {
      message.success('退回成功')
      callBack && callBack()
      updateRetreatModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('退回失败:' + errorMsg)
      callBack && callBack()
    }
  }

  const meetingConfirmHandler = async (values: any, callBack?: () => void) => {
    if (toApproveInfo) {
      const saveResult = await saveVerificationInfo(
        isYHLXS ? userName : values.approveUser
      )
      if (!saveResult) {
        message.error('验证信息保存失败')
        callBack && callBack()
        return
      }
    }

    const { data, error } = await meetingConfirm({
      meetingId,
      approveUser: isYHLXS ? userName : values.approveUser
    })
    if (data?.result) {
      message.success('审批通过')
      callBack && callBack()
      isShowConfirmModal && updateConfirmModalShow(false)
      isShowToApproveModal && setIsShowToApproveModal(false)
      refetchDetail()
      setTimeout(() => {
        window.history.back()
      }, 1000)
    } else {
      const errorMsg = getMessage(error?.message)
      message.error('审批失败:' + errorMsg)
      callBack && callBack()
    }
  }

  if (fetching) {
    return <Skeleton active />
  }

  if (error) {
    return (
      <ErrorView
        onRefresh={refetchDetail}
        title={error.name}
        message={error.message}
      />
    )
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {/* 会议名称 + 会议状态 */}
      <Card
        className='dashedBorderHead meetingDetailCard'
        title={
          <Row align='middle' style={{ color: '#181818' }}>
            {data?.detail?.base?.name}
            {data?.detail?.base?.type === 'ACADEMIC_CONFERENCE' &&
              data?.detail?.activityType && (
                <Tag color='success' style={{ marginLeft: 10, border: 'none' }}>
                  {data?.detail?.activityType}
                </Tag>
              )}
            <CopiableTag title='会议ID' text={meetingId} />
          </Row>
        }
        loading={fetching}
        extra={
          <SalesStatusTag
            containerStyle={{ right: isMobile ? -20 : -32, fontSize: 14 }}
            tagColor='#EE7700'
            text={
              data?.detail?.status ===
              StandardMeetingServiceBizStatus.MeetingInApproving
                ? data?.detail?.statusExtra! || data?.detail?.statusText!
                : data?.detail?.statusText!
            }
            triangleClassName='triangleWait'
          />
        }
      >
        {/* 会议概览字段展示 */}
        <Descriptions
          column={{ xs: 1, sm: 2, md: 3 }}
          labelStyle={{ fontWeight: 'bold', color: '#181818' }}
          contentStyle={{ color: '#888' }}
        >
          {(data?.detail?.base?.type !== 'MEDICAL_RESEARCH_PROJECT'
            ? fields
            : projectFields
          ).map((field) => (
            <Descriptions.Item key={field.label} label={field.label}>
              {field.content}
              {field.label === '申请人' &&
                data?.detail?.base?.owner?.verifyResult === '是' && (
                  <Tag color='error' style={{ marginLeft: 10 }}>
                    关注
                  </Tag>
                )}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>

      {/* 审批 通过 */}
      {isShowConfirmModal ? (
        <ApprovalConfirmModal
          onCancel={() => {
            updateConfirmModalShow(false)
          }}
          onSubmit={meetingConfirmHandler}
          isQCXS={isQCXS}
        />
      ) : null}

      {/* 审批 拒绝 */}
      {isShowRetreatModal ? (
        <ApprovalModal
          onCancel={() => {
            updateRetreatModalShow(false)
          }}
          onSubmit={startApprovalRetreat}
          meetingType={data?.detail?.base?.type}
          isQCXS={isQCXS}
          failReason={failReason}
        />
      ) : null}

      {/* 加签 */}
      {isShowForwardSignatureModal ? (
        <ForwardSignatureModal
          onCancel={() => {
            updateForwardSignatureModalShow(false)
          }}
          onSubmit={startForwardSignature}
          meetingType={data?.detail?.base?.type}
          isQCXS={isQCXS}
          failReason={failReason}
        />
      ) : null}

      {data?.detail && verificationData?.result && (
        <ApprovalInfo
          onCancel={() => {
            setToApproveInfo(undefined)
            setFailReason(undefined)
            setIsShowToApproveModal(false)
          }}
          onHandleForwardSignature={(values, failReason) => {
            setToApproveInfo(values)
            setFailReason(failReason)
            checkForwardSignature()
          }}
          onHandleRetreat={(values, failReason) => {
            setToApproveInfo(values)
            setFailReason(failReason)
            updateRetreatModalShow(true)
          }}
          onHandleConfirm={(values) => {
            setToApproveInfo(values)
            updateConfirmModalShow(true)
          }}
          meetingInfo={data?.detail}
          production={production}
          isApprover={
            data?.detail?.isCurrentApprover &&
            data?.detail?.approveStatus ===
              StandardMeetingApprovalStatus.Approving &&
            (isQCXS || isYHLXS)
          }
          verificationData={
            verificationData?.result?.sort(
              (a, b) => Number(b.id) - Number(a.id)
            )?.[0]
          }
          meetingAuditRecord={data?.detail?.meetingAuditRecord}
        />
      )}
    </Space>
  )
}

MeetingApproveDetail.pageTitle = '会议审核详情'

export default MeetingApproveDetail
