import React, { useState, FC, useEffect } from 'react'
import {
  CloudUploadOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import {
  Card,
  Table,
  Input,
  Button,
  Space,
  Result,
  Spin,
  notification,
  Select,
  message
} from 'antd'
import ResolveExcel from '../../components/ResolveExcel'
import { checkExcelValidate, getData, replaceBlank } from './data'
import {
  Maybe,
  Scalars,
  useImportDoctorsMutation,
  HrDoctorExcelImportInput,
  usePreImportDoctorsMutation
} from '../../graphql'
import { generateExport, useBatchUpload } from '../../components/UploadBatch'
import { createHash } from '../../lib/createHash'
import { HRCompanySelector } from '../../components/SelectorCollection'
import { useUserStore } from '../../hooks/user'

export type FileProps = {
  name?: string
  list?: Array<{ [key: string]: any }>
}

const DoctorImport: FC = () => {
  const options = { concurrency: 2, chunkSize: 100 }
  const [fileName, setFileName] = useState('')
  const [importId, setImportId] = useState('')
  const [tableSource, updateTableSource] = useState([] as any)
  const [excelData, updateExcelData] = useState([] as any)
  const [excelCorrect, setExcelCorrect] = useState(false)
  const [preChecking, setPreChecking] = useState(false)
  const [importing, setImporting] = useState(false)

  const [, starDoctorsPreCheck] = usePreImportDoctorsMutation()
  const [, startDoctorsImport] = useImportDoctorsMutation()

  const [curCompanyId, setCurCompanyId] = useState<string>()
  const [channel, setCurChannel] = useState<string>()
  const [fileResult, setFileResult] = useState([] as any)
  const [uniqImportId, setUniqImportId] = useState<string>()

  const [isExporting, setExporting] = useState(false)

  const userStore = useUserStore()

  // 批量数据检查结束后，拿到所有接口的返回结果，整理数据格式
  const preCheckResultsResolver = (
    preCheckResults: Array<Maybe<Scalars['JSON']>>,
    excelData: HrDoctorExcelImportInput[]
  ) => {
    const tableData: Array<Maybe<Scalars['JSON']>> = []
    preCheckResults?.forEach((result: any) => {
      if (result?.error?.length) {
        result.error.forEach((x: any, index: number) => {
          if (!x.key) {
            x.key = index
          }
        })
        tableData.push(...result.error)
      }
    })

    setPreChecking(false)
    setExcelCorrect(!tableData.length)
    updateExcelData(excelData)
    updateTableSource(tableData)
  }

  const preCheckTask = async (
    item: HrDoctorExcelImportInput[],
    uniqId?: string
  ) => {
    if (!curCompanyId) {
      notification.error({
        message: '异常',
        description: '请选择所属公司',
        duration: 0
      })
      return
    }
    if (!channel) {
      notification.error({
        message: '异常',
        description: `请选择签约渠道`,
        duration: 0
      })
      return
    }
    const result: Maybe<Scalars['JSON']> = await starDoctorsPreCheck({
      data: item,
      importId: uniqId,
      companyId: curCompanyId,
      chanel: channel
    })
    if (result?.error) {
      throw new Error(result.error?.message)
    } else if (result?.data) {
      // 取出接口返回的上传任务id
      setImportId(result.data?.result?.importId)
      return result.data?.result
    }
  }

  // 表格数据预检验
  const [preCheck] = useBatchUpload({
    task: preCheckTask,
    options: options,
    noMessage: true,
    resultResolver: preCheckResultsResolver,
    errorResolver: () => {
      setExcelCorrect(false)
      setPreChecking(false)
    }
  })

  const importTask = async (
    item: HrDoctorExcelImportInput[],
    uniqId?: string
  ) => {
    if (!curCompanyId) {
      notification.error({
        message: '异常',
        description: '请选择所属公司',
        duration: 0
      })
      return
    }
    if (!channel) {
      notification.error({
        message: '异常',
        description: `请选择签约渠道`,
        duration: 0
      })
      return
    }
    const result = await startDoctorsImport({
      companyId: curCompanyId,
      importId: uniqId,
      chanel: channel
    })
    if (!result?.data?.result?.success) {
      throw new Error(result.data?.result?.message || '上传失败')
    }
  }

  // 确认导入
  const [importBudget] = useBatchUpload({
    task: importTask,
    options: options,
    noMessage: true,
    resultResolver: () => {
      setImporting(false)
      notification.success({
        message: '导入成功',
        description: '老师任务信息导入成功',
        duration: 0
      })
    },
    errorResolver: () => {
      setImporting(false)
    }
  })

  // 文件读取之后进行数据校验和处理
  const onUploaded = (file: FileProps, uniqImportId: string) => {
    let result: Array<HrDoctorExcelImportInput> = []
    const list = file.list || []
    const validateRes = checkExcelValidate(list || [])

    if (validateRes.success) {
      result = list.map((row) => {
        return {
          name: replaceBlank(String(row.老师名称)),
          mobile: String(row.老师手机),
          province: replaceBlank(String(row.所属省份)),
          city: replaceBlank(String(row.所属城市)),
          hospital: replaceBlank(String(row.所属单位)),
          department: replaceBlank(String(row.所属部门)),
          title: String(row.老师职称)
        }
      })
      setFileName(file.name || '')
      setFileResult(result)
      setUniqImportId(uniqImportId)
      preCheck(result, uniqImportId)
    } else {
      setPreChecking(false)
      notification.error({
        message: '异常',
        description: validateRes.message,
        duration: 0
      })
    }
  }

  const onTemplateClick = () => {
    const tagA = document.createElement('a')
    tagA.href =
      'https://yhl-upright-admin-upload.oss-cn-hangzhou.aliyuncs.com/admin/%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88v2.xlsx'
    tagA.setAttribute('target', '_blank')
    const id = `template-hansoh-budget` + new Date().getTime()
    tagA.setAttribute('id', id)
    if (!document.getElementById(id)) {
      document.body.appendChild(tagA)
    }
    tagA.click()
    document.body.removeChild(tagA)
  }

  // 企业选项改变
  const onCompanyChange = (value: string) => {
    setCurCompanyId(value)
  }

  // 签约渠道选项改变
  const onChannelChange = (value: string) => {
    setCurChannel(value)
  }

  useEffect(() => {
    if (fileResult.length > 0 && uniqImportId) {
      preCheck(fileResult, uniqImportId)
    }
  }, [curCompanyId, channel])

  useEffect(() => {
    if (isExporting && tableSource?.length > 0) {
      const dataResult = getData(tableSource)
      generateExport(
        dataResult,
        `老师导入异常报表_${new Date().getTime()}`,
        dataResult.length,
        (err) => {
          setExporting(false)
          if (err) {
            message.error('导出失败')
          } else {
            message.success('EXCEL下载成功，请查收')
          }
        }
      )
    }
  }, [isExporting])

  return (
    <Spin
      spinning={preChecking || importing}
      tip={`正在${importing ? '上传' : '检查'}导入信息，请不要关闭此页面...`}
    >
      <Card bordered={false}>
        <Space wrap={true} size={12}>
          <div>
            所属企业
            <HRCompanySelector
              isFromDoctorPage
              onChange={onCompanyChange}
              style={{
                minWidth: 150,
                marginLeft: 12
              }}
            />
          </div>
          <div>
            签约渠道
            <Select
              showSearch
              allowClear
              placeholder=''
              optionFilterProp='children'
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChannelChange}
              style={{
                minWidth: 150,
                marginLeft: 12
              }}
            >
              {userStore.channels?.map((k) => {
                return (
                  <Select.Option value={k.value} key={k.value}>
                    {k.text}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <div>
            上传文件
            <Input
              disabled
              placeholder={fileName || `请选择excel`}
              style={{
                backgroundColor: '#fff',
                width: '300px',
                marginLeft: 12
              }}
            />
          </div>

          <ResolveExcel
            buttonText='上传文件'
            buttonType='primary'
            buttonIcon={<CloudUploadOutlined />}
            onUploaded={(file) => {
              // 定义当前这次上传的唯一 id
              const uniqImportId =
                new Date().getTime().toString() + createHash()
              onUploaded(file, uniqImportId)
            }}
            onStart={() => {
              setPreChecking(true)
            }}
            disabled={!curCompanyId || !channel}
          />

          <Button
            ghost
            type='primary'
            icon={<DownloadOutlined />}
            onClick={onTemplateClick}
          >
            老师导入模板下载
          </Button>
        </Space>
      </Card>
      <Card
        bordered={false}
        extra={
          <Space size={12}>
            <Button
              type='primary'
              loading={isExporting}
              disabled={excelCorrect || !tableSource?.length}
              onClick={() => {
                console.log(tableSource)
                setExporting(true)
              }}
            >
              报表导出
            </Button>
            <Button
              type='primary'
              disabled={!excelCorrect || importing || !curCompanyId || !channel}
              onClick={() => {
                setImporting(true)
                importBudget(excelData, importId)
              }}
            >
              确认导入
            </Button>
          </Space>
        }
      >
        {excelCorrect && curCompanyId && channel ? (
          <Result
            status='success'
            icon={<CheckCircleOutlined />}
            subTitle={
              <div style={{ fontSize: 16 }}>
                上传信息查验正确，继续
                <Button
                  type='link'
                  style={{ fontSize: 16, padding: 5 }}
                  onClick={() => {
                    setImporting(true)
                    importBudget(excelData, importId)
                  }}
                >
                  确认导入
                </Button>
              </div>
            }
          />
        ) : tableSource?.length ? (
          <Table
            bordered
            dataSource={tableSource}
            pagination={{
              showSizeChanger: true,
              total: tableSource.length
            }}
            columns={[
              {
                align: 'center',
                title: '老师名称',
                dataIndex: 'name',
                key: 'name'
              },
              {
                align: 'center',
                title: '老师手机',
                dataIndex: 'mobile',
                key: 'mobile'
              },
              {
                align: 'center',
                title: '所属省份',
                dataIndex: 'province',
                key: 'province'
              },
              {
                align: 'center',
                title: '所属城市',
                dataIndex: 'city',
                key: 'city'
              },
              {
                align: 'center',
                title: '所属单位',
                dataIndex: 'hospital',
                key: 'hospital'
              },
              {
                align: 'center',
                title: '所属部门',
                dataIndex: 'department',
                key: 'department'
              },
              {
                align: 'center',
                title: '老师职称',
                dataIndex: 'title',
                key: 'title'
              },
              {
                align: 'center',
                title: '报错信息',
                dataIndex: 'message',
                key: 'message',
                render(v: string) {
                  return <span style={{ color: 'red' }}>{v || '-'}</span>
                }
              }
            ]}
          />
        ) : (
          <Result
            style={{ background: '#f5f6fa' }}
            icon={<InfoCircleOutlined style={{ color: '#cccccc' }} />}
            subTitle={
              <div style={{ fontSize: 16 }}>暂无文件，请先上传文件</div>
            }
          />
        )}
      </Card>
    </Spin>
  )
}

export default DoctorImport
